import React, { useMemo } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DocumentStatusBox from './DocumentStatusBox';
import DocumentStatusHistoryTooltip from './DocumentStatusHistoryTooltip';
import { COLOR } from '../../../containers/App/AppStyles';

const DocumentItem = ({
  documents,
  label,
  contractName = '',
  contractCreatedDate,
  contractUpdatedDate,
  showOnlyFirst = false,
  excludeStatuses = [],
  openTooltipId,
  setOpenTooltipId,
  isPartner,
}) => {
  const sortedDocuments = useMemo(() => (documents.filter(
    doc => !excludeStatuses.includes(doc.status),
  ).sort((a, b) => {
    if (a.status === 'ACTIVE' && b.status !== 'ACTIVE') {
      return -1;
    }
    if (a.status !== 'ACTIVE' && b.status === 'ACTIVE') {
      return 1;
    }
    return b.id - a.id;
  })), [documents]);

  const hasDocuments = sortedDocuments.length > 0;
  const documentsToDisplay = showOnlyFirst
    ? [sortedDocuments[0]]
    : sortedDocuments;

  const isChecked = documents.some(doc => doc.status === 'ACTIVE');
  const handleTooltipToggle = (tooltipId) => {
    setOpenTooltipId(prevId => (prevId === tooltipId ? null : tooltipId));
  };
  const renderTooltipContent = document => (
    <Box>
      {contractName && (
        <Typography fontSize="12px">
          Договір: <b>{contractName}</b>
        </Typography>
      )}
      {contractCreatedDate && (
        <Typography fontSize="12px">
          Дата створення: <b>{contractCreatedDate}</b>
        </Typography>
      )}
      {contractUpdatedDate && (
        <Typography fontSize="12px">
          Дата оновлення: <b>{contractUpdatedDate}</b>
        </Typography>
      )}
      {!contractName && document.createdAt && (
        <Typography fontSize="12px">
          Дата створення: <b>{document.createdAt}</b>
        </Typography>
      )}
      {!contractName && document.sedDocumentUpdatedAt && (
        <Typography fontSize="12px">
          Дата оновлення: <b>{document.sedDocumentUpdatedAt}</b>
        </Typography>
      )}
    </Box>
  );

  return (
    <Grid item xs={12} lg={12}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        sx={{ ml: '-11px' }}
      >
        <Box display="flex" alignItems="center">
          <Checkbox disabled checked={isChecked} inputProps={{ 'aria-label': 'controlled' }} />
          <Typography fontSize="14px" color={COLOR.grey.main} sx={{ marginRight: 1 }}>
            {label}
          </Typography>
          {hasDocuments && (
            <Tooltip
              title={renderTooltipContent(documentsToDisplay[0])}
              placement="bottom"
            >
              <DocumentStatusBox
                isHer={documentsToDisplay[0]?.type === 'POWER_OF_ATTORNEY'}
                status={documentsToDisplay[0]?.status}
              />
            </Tooltip>
          )}
          {!showOnlyFirst && documentsToDisplay[1] && (
            <Tooltip
              title={renderTooltipContent(documentsToDisplay[1])}
              placement="bottom"
            >
              <DocumentStatusBox
                isHer={documentsToDisplay[1]?.type === 'POWER_OF_ATTORNEY'}
                status={documentsToDisplay[1]?.status}
              />
            </Tooltip>
          )}
        </Box>
        {documents.length > 0 && !isPartner && (
          <DocumentStatusHistoryTooltip
            contractName={contractName}
            documents={documents}
            tooltipId={label}
            openTooltipId={openTooltipId}
            setOpenTooltipId={setOpenTooltipId}
            onClickAway={() => {
              handleTooltipToggle(false);
            }}
            onToggle={() => handleTooltipToggle(label)}
          />
        )}
      </Box>
    </Grid>
  );
};

export default DocumentItem;
