import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import DrawerHeader from './DrawerHeader';

const Drawer = ({
  open, onClose, title, children, cancelButtonTitle, saveButtonTitle, handleCancel, handleSave, saveButtonDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <MuiDrawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{
        minWidth: '300px', maxWidth: '550px', p: '16px 16px 0px 16px', position: 'relative',
      }}
      >
        <DrawerHeader onClose={onClose} title={title} />
        {children}
      </Box>
      {(cancelButtonTitle || saveButtonTitle) && (
      <Box sx={{
        position: 'sticky',
        bottom: '0px',
        width: '100%',
        boxShadow: '0px -4px 4px 0px #00000014',
        backgroundColor: 'white',
        height: '52px',
        p: '8px',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '8px',
      }}
      >
        <Button variant="outlined" onClick={handleCancel}>{cancelButtonTitle || t('Отменить')}</Button>
        <Button variant="contained" onClick={handleSave} disabled={saveButtonDisabled}>{saveButtonTitle}</Button>
      </Box>
      )}
    </MuiDrawer>
  );
};

Drawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  cancelButtonTitle: PropTypes.string,
  saveButtonTitle: PropTypes.string,
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
  saveButtonDisabled: PropTypes.bool,
};

export default Drawer;
