// Core
import { apply, put } from 'redux-saga/effects';

// Engine
import { actions } from '../../actions';
import { api } from '../../../../config/api';

export function* callGetOrdersShippingTypesList() {
  yield put(actions.setShippingTypes({ pending: true }));
  const response = yield apply(api, api.orders.getOrdersShippingTypesList);
  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    yield put(actions.setShippingTypes({
      items: responseData.items,
      pending: false,
    }));
  }
}
