// Core
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
// Parts
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useMemo } from 'react';
import RenderFormFieldDimensions from './components/RenderFormFieldDimensions';
import RenderWeightFormField from './components/RenderWeightFormField';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/logisticsDataByCategory/actions';
import { asyncActions } from '../../../../../../engine/core/logisticsDataByCategory/saga/asyncActions';
// Helpers
import { useStyles } from '../../../../../hooks/useStyles';
import { formName, formFields } from './form';
import {
  classNames, StyledForm, StyledLoadingButton, Wrapper,
} from './styles';
import { validators } from '../../../../../../_helpers/validationsForm';

function OffCanvas(props) {
  const { handleSubmit, valid, dirty } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles(classNames);
  const mode = useSelector(selectors.logisticsDataByCategory.itemByHashIdMode);
  const data = useSelector(selectors.logisticsDataByCategory.itemByHashIdData);
  const pending = useSelector(selectors.logisticsDataByCategory.itemByHashIdPutPending);
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const normalizeFormValues = useMemo(() => {
    const values = formValues.toJS();
    return Object.keys(values).reduce((accumulator, key) => {
      if (values[key] != null && values[key] !== '' && key !== formFields.ean) {
        accumulator[key] = values[key];
      }
      return accumulator;
    }, {});
  }, [formValues]);
  const isShowMode = mode === 'show';
  const disableFields = isShowMode || pending;
  const disabledSend = !valid || !dirty || pending;
  const validate = Object.keys(normalizeFormValues).length < 5 && Object.keys(normalizeFormValues).length
    ? [validators.required]
    : [];
  const handleClose = () => {
    dispatch(actions.setItemByHashIdMode(null));
    dispatch(actions.setItemByHashIdData({}));
  };
  const onSubmit = (value) => {
    dispatch(asyncActions.putItemByHashIdAsync({
      hashId: data.get('hashId'),
      parentId: data.get('parentId'),
      values: value.toJS(),
    }));
  };
  return (
    <Drawer
      anchor="right"
      open={Boolean(mode)}
      onClose={handleClose}
    >
      <Wrapper>
        <Box display="flex" alignItems="flex-start">
          <Typography component="h2" fontWeight="bold">{t('Редактирование логистических данных категории:')} &#171;{data.get('name')}&#187;</Typography>
          <IconButton sx={{ marginTop: '-5px' }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Box height="100%">
            <Field
              wrapperClassName={classes.formField}
              name={formFields.height}
              variant="outlined"
              component={RenderFormFieldDimensions}
              label={t('Высота в упаковке')}
              fullWidth
              validation
              validate={validate}
              margin="none"
              disabled={disableFields}
            />
            <Field
              wrapperClassName={classes.formField}
              name={formFields.width}
              variant="outlined"
              component={RenderFormFieldDimensions}
              label={t('Ширина в упаковке')}
              fullWidth
              validation
              validate={validate}
              disabled={disableFields}
              margin="none"
            />
            <Field
              wrapperClassName={classes.formField}
              name={formFields.length}
              variant="outlined"
              component={RenderFormFieldDimensions}
              label={t('Длина в упаковке')}
              fullWidth
              validation
              validate={validate}
              disabled={disableFields}
              margin="none"
            />
            <Field
              wrapperClassName={classes.formField}
              name={formFields.weight}
              variant="outlined"
              component={RenderWeightFormField}
              label={t('Вес в упаковке')}
              fullWidth
              validation
              validate={[validators.notZero, validators.maxWeightLimit]}
              disabled={disableFields}
              margin="none"
            />
          </Box>
          <StyledLoadingButton
            loading={pending}
            disabled={disabledSend}
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            {t('Сохранить')}
          </StyledLoadingButton>
        </StyledForm>
      </Wrapper>
    </Drawer>
  );
}
export default reduxForm({
  form: formName,
})(OffCanvas);
