import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';

const CancelModal = ({
  closeModal,
  handleCloseModal,
  handleCancel,
  text,
  titleText,
  sendText,
  cancelText,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      modalOpen={closeModal}
      handleModalToggle={handleCloseModal}
      handleModalCancel={handleCloseModal}
      handleModalSend={handleCancel}
      title={titleText ?? t('Хотите закрыть страницу без сохранения?')}
      modalMaxWidth="sm"
      sendText={sendText ?? t('Закрыть страницу')}
      cancelText={cancelText ?? t('Отменить')}
      buttonSendIcon={null}
    >
      <Box sx={{ padding: '16px' }}>{text ?? t('Если вы покинете эту страницу, внесенные данные не сохранятся.')}</Box>
    </Modal>
  );
};

CancelModal.propTypes = {
  closeModal: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleCloseModal: PropTypes.func,
  text: PropTypes.string,
  titleText: PropTypes.string,
  sendText: PropTypes.string,
  cancelText: PropTypes.string,
};

export default CancelModal;
