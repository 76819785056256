/* eslint-disable react/no-unescaped-entities */
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ResetFilterButton from '../../components/_Table/DxTable/components/ResetFilterButton';
import PageHeader from '../../components/PageHeader/PageHeader';
import RefundRequestsTable from './components/RefundRequestsTable';
import { selectors } from '../../../engine/config/selectors';
import { actions } from '../../../engine/core/refundRequests/actions';
import Informer from '../../components/Informer/Informer';

const RefundRequestsPage = () => {
  const dispatch = useDispatch();

  const filters = useSelector(selectors.refundRequests.filters);
  const sorting = useSelector(selectors.refundRequests.sorting);
  const showNewRequestInformer = useSelector(selectors.refundRequests.showNewRequestInformer);

  const onResetFilters = async () => {
    dispatch(actions.removeFilters());
  };

  return (
    <>
      <PageHeader title="Заявки на повернення">
        <ResetFilterButton onReset={onResetFilters} disabled={!filters.size && !sorting.size} />
      </PageHeader>
      {showNewRequestInformer && (
      <Informer borderColor="black" sx={{ mb: '10px', alignItems: 'start', gap: '8px' }}>
        <InfoOutlinedIcon />
        <Typography fontSize={14}>
          Будь ласка, зв'яжіться з покупцем, щоб нагадати про необхідність відправки товару для обробки заявки на повернення та переконайтесь, що покупець має чіткі інструкції щодо процедури повернення, включаючи адресу для відправки, строки та необхідні документи або інформацію.
          Обов'язково переведіть заявку в наступний статус, інакше вона потрапить у "Протерміновані" та буде розглядатися адміністрацією АЛЛО.
        </Typography>
      </Informer>
      )}
      <RefundRequestsTable />
    </>
  );
};


export default RefundRequestsPage;
