import React from 'react';

export function GridSettingsIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="4.5" stroke="#303030" strokeOpacity="0.08" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.3 9.3V22.7H12.7V9.3H9.3ZM9 8C8.44772 8 8 8.44772 8 9V23C8 23.5523 8.44772 24 9 24H12.7H13H14H14.3H17.7L18 24L19 24H19.3H23C23.5523 24 24 23.5523 24 23L24 9C24 8.44771 23.5523 8 23 8H19.3H19H18H17.7H14.3H14H13H12.7H9ZM17.7 9.3H14.3V22.7H17.7V9.3ZM22.7 9.3L22.7 22.7H19.3L19.3 9.3L22.7 9.3Z" fill="#303030" />
      <path
        d="M9.3 22.7H9.25V22.75H9.3V22.7ZM9.3 9.3V9.25H9.25V9.3H9.3ZM12.7 22.7V22.75H12.75V22.7H12.7ZM12.7 9.3H12.75V9.25H12.7V9.3ZM17.7 24V24.05V24ZM18 24V23.95V24ZM19 24V24.05V24ZM24 23H23.95H24ZM24 9H24.05H24ZM14.3 9.3V9.25H14.25V9.3H14.3ZM17.7 9.3H17.75V9.25H17.7V9.3ZM14.3 22.7H14.25V22.75H14.3V22.7ZM17.7 22.7V22.75H17.75V22.7H17.7ZM22.7 22.7V22.75H22.75V22.7H22.7ZM22.7 9.3H22.75V9.25H22.7V9.3ZM19.3 22.7H19.25V22.75H19.3V22.7ZM19.3 9.3V9.25H19.25V9.3H19.3ZM9.35 22.7V9.3H9.25V22.7H9.35ZM12.7 22.65H9.3V22.75H12.7V22.65ZM12.65 9.3V22.7H12.75V9.3H12.65ZM9.3 9.35H12.7V9.25H9.3V9.35ZM8.05 9C8.05 8.47533 8.47533 8.05 9 8.05V7.95C8.4201 7.95 7.95 8.4201 7.95 9H8.05ZM8.05 23V9H7.95V23H8.05ZM9 23.95C8.47533 23.95 8.05 23.5247 8.05 23H7.95C7.95 23.5799 8.4201 24.05 9 24.05V23.95ZM12.7 23.95H9V24.05H12.7V23.95ZM13 23.95H12.7V24.05H13V23.95ZM14 23.95H13V24.05H14V23.95ZM14.3 23.95H14V24.05H14.3V23.95ZM17.7 23.95H14.3V24.05H17.7V23.95ZM18 23.95L17.7 23.95V24.05H18V23.95ZM18 24.05H19V23.95L18 23.95V24.05ZM19 24.05H19.3V23.95H19V24.05ZM19.3 24.05H23V23.95H19.3V24.05ZM23 24.05C23.5799 24.05 24.05 23.5799 24.05 23H23.95C23.95 23.5247 23.5247 23.95 23 23.95V24.05ZM24.05 23L24.05 9H23.95L23.95 23H24.05ZM24.05 9C24.05 8.4201 23.5799 7.95 23 7.95V8.05C23.5247 8.05 23.95 8.47533 23.95 9H24.05ZM23 7.95H19.3V8.05H23V7.95ZM19.3 7.95H19V8.05H19.3V7.95ZM19 7.95H18V8.05H19V7.95ZM17.7 8.05H18V7.95H17.7V8.05ZM14.3 8.05H17.7V7.95H14.3V8.05ZM14 8.05H14.3V7.95H14V8.05ZM13 8.05H14V7.95H13V8.05ZM12.7 8.05H13V7.95H12.7V8.05ZM9 8.05H12.7V7.95H9V8.05ZM14.3 9.35H17.7V9.25H14.3V9.35ZM14.35 22.7V9.3H14.25V22.7H14.35ZM17.7 22.65H14.3V22.75H17.7V22.65ZM17.65 9.3V22.7H17.75V9.3H17.65ZM22.75 22.7L22.75 9.3H22.65L22.65 22.7H22.75ZM19.3 22.75H22.7V22.65H19.3V22.75ZM19.25 9.3L19.25 22.7H19.35L19.35 9.3H19.25ZM22.7 9.25L19.3 9.25V9.35L22.7 9.35V9.25Z"
        fill="#303030"
      />
    </svg>
  );
}
