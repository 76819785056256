// Core
import { fromJS, List, Map } from 'immutable';
// Engine
import { pageSizes } from '../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = fromJS({
  productPriceComparisonList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    reload: false,
    selection: List(),
  }),
});

export const stateKeys = Object.freeze({
  controller: 'productPriceComparison',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  pageSize: 'pageSize',
  pending: 'pending',
  reload: 'reload',
  selection: 'selection',
  sorting: 'sorting',
  productPriceComparisonList: 'productPriceComparisonList',
  totalCount: 'totalCount',
});

export const productPriceComparisonReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PRODUCT_PRICE_COMPARISON_LIST: {
      const {
        data: items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.productPriceComparisonList, stateKeys.pending], pending);
      }
      return state.mergeIn([stateKeys.productPriceComparisonList], {
        [stateKeys.pending]: pending,
        [stateKeys.items]: List(items),
        [stateKeys.totalCount]: totalCount,
      });
    }
    case types.SET_PRODUCT_PRICE_COMPARISON_SORTING: {
      return state.setIn([stateKeys.productPriceComparisonList, stateKeys.sorting], List(payload));
    }
    case types.SET_PRODUCT_PRICE_COMPARISON_FILTERS: {
      const filters = payload.filter(item => item.value !== null && item.value !== undefined);
      return state.setIn([stateKeys.productPriceComparisonList, stateKeys.filters], List(filters));
    }
    case types.SET_PRODUCT_PRICE_COMPARISON_PAGE_SIZE: {
      return state.setIn([stateKeys.productPriceComparisonList, stateKeys.pageSize], payload);
    }
    case types.SET_PRODUCT_PRICE_COMPARISON_SELECTION: {
      return state.setIn([stateKeys.productPriceComparisonList, stateKeys.selection], List(payload));
    }
    case types.SET_PRODUCT_PRICE_COMPARISON_CURRENT_PAGE: {
      return state.setIn([stateKeys.productPriceComparisonList, stateKeys.currentPage], payload);
    }
    case types.SET_PRODUCT_PRICE_COMPARISON_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting,
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.productPriceComparisonList, stateKeys.sorting]);

      return state.mergeIn(stateKeys.productPriceComparisonList, {
        currentPage,
        pageSize,
        totalCount,
        filters: List(filters),
        sorting: List(currentSorting),
        reload,
      });
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
