import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../engine/core/orders/saga/asyncActions';
import Loading from '../../../../components/Loading/Loading';
import DxTable from '../../../../components/_Table/DxTable/DxTable';
import TabsPanel from '../../../../containers/Tabs/TabsPanel';

const disableFiltering = [
  { columnName: 'createdAt', filteringEnabled: false },
  { columnName: 'oldTrackingNumber', filteringEnabled: false },
  { columnName: 'newTrackingNumber', filteringEnabled: false },
  { columnName: 'changedBy', filteringEnabled: false },
];

const ShippingTrackingHistory = ({ orderId, tabIndex, tabValue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items, pending } = useSelector(selectors.orders.getOrderShippingHistory).toJS();

  useEffect(() => {
    if (orderId) {
      dispatch(asyncActions.getShippingTrackingHistoryList(orderId));
    }
  }, [dispatch, orderId]);

  const columns = [
    {
      name: 'createdAt',
      type: 'text',
      title: t('Дата зміни ТТН'),
      getCellValue: row => row.createdAt,
    },
    {
      name: 'oldTrackingNumber',
      type: 'text',
      title: t('Старий номер ТТН'),
      getCellValue: row => row.oldTrackingNumber || '-',
    },
    {
      name: 'newTrackingNumber',
      type: 'text',
      title: t('Новий номер ТТН'),
      getCellValue: row => row.newTrackingNumber,
    },
    {
      name: 'changedBy',
      type: 'text',
      title: t('Змінено користувачем'),
      getCellValue: (row) => {
        if (row.changedBy) {
          return `${row.changedBy}`;
        }
        return t('Система');
      },
    },
  ];

  if (pending) {
    return (
      <TabsPanel
        index={tabIndex}
        value={tabValue}
      >
        <Box sx={{
          width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
        }}
        >
          <Loading isLoading={pending} />
        </Box>
      </TabsPanel>
    );
  }

  return (
    <TabsPanel
      index={tabIndex}
      value={tabValue}
    >
      {items && items.length > 0 ? (
        <DxTable
          showShortText
          filteringExtensions={disableFiltering}
          rows={items}
          columns={columns}
          defaultSorting={[{ columnName: 'createdAt', direction: 'desc' }]}
          pageSizes={[10, 25, 50]}
        />
      ) : (
        <Box>
          {t('Історія змін трекінг-номеру відсутня')}
        </Box>
      )}
    </TabsPanel>
  );
};

export default ShippingTrackingHistory;
