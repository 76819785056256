// Core
import React, { memo, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Controller } from 'react-hook-form';
// Parts
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import Box from '@mui/material/Box';
import { Loader } from '../../../../../../../components/Loader/Loader';
import StatusIcon from '../../StatusIcon';
import CheckBoxForm from '../../../../../../../components/_Form/Checkbox/CheckBoxForm';
// Helpers
import { COLOR } from '../../../../../../../containers/App/AppStyles';
// Engine
import { productFeedCategoriesAttributesConst } from '../../../../../../../../engine/core/products/const';
import { selectors } from '../../../../../../../../engine/config/selectors';
import EditableContainer
  from '../../../../../../../components/_Table/TableCell/components/EditableContainer/EditableContainer';
import CategorySelect from './CategorySelect';
import { generateInput, generateMeta } from '../../../../../Grouping/Add/utils/generateInput';
import { api } from '../../../../../../../../engine/config/api';
import { actions } from '../../../../../../../../engine/core/products/productFeedCategories/actions';
import { setErrorMessage, setSuccessMessage } from '../../../../../../../../engine/core/ui/saga/asyncActionNotificationMessages';


export const handleResponse = (response, message, dispatch, t, handleSuccessResponse, handleErrorResponse) => {
  if (response.status >= 200 && response.status < 400) {
    dispatch(handleSuccessResponse(message, t('Успех')));
  } else {
    dispatch(handleErrorResponse(message, t('Ошибка')));
  }
};

const memoCompareFunc = (prev, current) => {
  if (current.value?.value) {
    return prev.value?.value === current.value?.value;
  }

  return prev.value === current.value;
};

function TableCell(props) {
  const {
    column,
    row,
    value,
    control,
    getValues,
    errors,
    touchedFields,
    ...restProps
  } = props;
  const { hashId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);

  const inAutoUpdateProcess = row?.categoryStatus?.value === productFeedCategoriesAttributesConst.AUTO_CONNECT_STATUS;
  const currentProductFeedCategory = getValues(`${row?.id}_category`);
  const currentIgnored = getValues(`${row?.id}_ignored`);
  const canEdit = useSelector(selectors.feeds.isItemByHashIdItem).get('canEdit');
  const stepsData = useSelector(selectors.feeds.isItemByHashIdItem).get('stepsData');
  const productFeedCategoryStatuses = stepsData[2].productFeedCategoryStatuses;
  const getCurrentDesc = statusId => productFeedCategoryStatuses.filter(item => item.value === statusId)[0]?.desc;
  const isTranslationNeeded = column.name === 'category' && row?.language !== 'ru';
  const pickableCategoriesList = useSelector(selectors.categories.parentCategoriesListContext);


  const options = useMemo(() => {
    if (column.name !== 'category') {
      return [];
    }
    /**  add dummy option for Category Select to be able to show tooltips for the last element */
    const dummyOption = { id: -1, label: '' };
    if (isTranslationNeeded) {
      const translatedCategoriesList = pickableCategoriesList.toArray().map(category => ({
        ...category,
        label: `${category.idMagentoCategory} | ${category.nameUa ?? category.name}`,
      }));
      return [...translatedCategoriesList, dummyOption,
        dummyOption];
    }

    return [...pickableCategoriesList, dummyOption, dummyOption];
  }, [isTranslationNeeded]);


  const handleChangeIgnore = async (event) => {
    setPending(true);
    const response = await api.productFeedCategories.putItemByHashId({
      hashId: row.hashId,
      ignored: Boolean(event.target.checked),
      category: currentProductFeedCategory?.[0]?.value,
      feedHashId: hashId,
    });
    handleResponse(response, response.data.message, dispatch, t, setSuccessMessage, setErrorMessage);
    dispatch(actions.setListItemByHashId(response.data?.item));
    setPending(false);
  };
  const handleChangeCategory = async (categoryValue) => {
    setPending(true);
    const response = await api.productFeedCategories.putItemByHashId({
      hashId: row.hashId,
      ignored: Boolean(currentIgnored),
      category: categoryValue?.[0]?.value,
      feedHashId: hashId,
    });
    handleResponse(response, response.data.message, dispatch, t, setSuccessMessage, setErrorMessage);
    dispatch(actions.setListItemByHashId(response.data?.item));
    setPending(false);
  };

  return (
    <VirtualTable.Cell
      {...restProps}
      tableColumn={column}
      tableRow={row}
    >
      {column.name === 'productFeedCategory'
        && (
          <EditableContainer isEditable={false} text={value.label} fullText={false}>
            {value.label}
          </EditableContainer>
        )
        }
      {column.name === 'category' && (
        <Box sx={{ position: 'relative' }}>
          {pending && <Loader />}
          <Controller
            name={`${row.id}_category`}
            control={control}
            render={({ field }) => {
              const input = generateInput(field);
              const meta = generateMeta(errors.category?.message, !!touchedFields.category);
              return (
                <CategorySelect
                  input={input}
                  meta={meta}
                  options={options}
                  variant="outlined"
                  onlyChild
                  label=""
                  onlyNotPortal
                  canClear={false}
                  marginBottom={false}
                  placeholder={t('Выбрать')}
                  selectedCategory={Array.isArray(currentProductFeedCategory) ? currentProductFeedCategory[0] : currentProductFeedCategory}
                  disabled={Boolean(!row.canEdit || !canEdit || currentIgnored || inAutoUpdateProcess || pending)}
                  handleChange={handleChangeCategory}
                  isCategorySelect
                  optionsLanguage={row?.language}
                  isMulti={false}
                />
              );
            }}
          />
        </Box>
      )
}
      {column.name === 'ignored' && (
      <Box sx={{ position: 'relative' }}>
        {pending && <Loader />}
        <Controller
          name={`${row.id}_ignored`}
          control={control}
          render={({ field }) => {
            const input = generateInput(field);
            const meta = generateMeta(errors[`${row.id}_ignored`], !!touchedFields[`${row.id}_ignored`]);
            return (
              <CheckBoxForm
                disabled={Boolean(!row.canIgnore || !row.canEdit || !canEdit || inAutoUpdateProcess || pending)}
                handleChange={handleChangeIgnore}
                label={t('Игнорировать')}
                name={`${row.id}_ignored`}
                input={input}
                meta={meta}
              />
            );
          }}
        />
      </Box>
      )}
      {column.name === 'categoryStatus' && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StatusIcon iconName={value.icon} />
          <Box sx={{ ml: 1 }}>{value.label}</Box>
          {getCurrentDesc(value.value) && (
            <Tooltip title={getCurrentDesc(value.value)} arrow>
              <InfoOutlinedIcon sx={{
                cursor: 'pointer',
                fill: COLOR.black.default,
                marginLeft: 'auto',
                fontSize: '18px',
              }}
              />
            </Tooltip>
          )}
        </Box>
      )}
      {column.name === 'feedProductQuantity' && <span>{value}</span> }
    </VirtualTable.Cell>
  );
}

export default memo(TableCell, memoCompareFunc);
