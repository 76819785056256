import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import Paper from '@mui/material/Paper';
import { asyncActions } from '../../../../engine/core/promotions/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { pageLinks } from '../../../../engine/config/routes';
import { PromoForm } from './PromoForm';
import { collectCategoryIds } from '../../../../engine/config/promotions';
import Loading from '../../../components/Loading/Loading';
import CancelModal from '../../../containers/Modals/CancelModal/CancelModal';
import { PageHeaderWithControls } from '../../../components/PageHeader/PageHeaderWithControls';
import { SplitButtonLight } from '../../../components/Buttons/SplitButtonLight';

const DEFAULT_VALUES = {
  promoName: '',
  promoDateRange: '',
  promoRegistrationDateRange: '',
  specialPrice: false,
  partialPay: false,
  freeDelivery: false,
  categories: [],
  stopBrands: [],
  description: '',
};

export function PromotionCreatePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isPending = useSelector(selectors.promotions.isPending);

  const methods = useForm({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isDirty, isValid },
  } = methods;

  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const onSubmit = (data) => {
    const categoriesIds = collectCategoryIds(data.categories);
    const brandsIds = data.stopBrands.map(brand => brand.value);

    const promotionData = {
      ...data,
      categories: categoriesIds,
      stopBrands: brandsIds,
    };

    dispatch(asyncActions.createPromotionAsync(promotionData));
  };
  const handleNavigateBack = () => {
    navigate(pageLinks.advertising.promoList);
  };

  const handleReturn = () => {
    if (isDirty) {
      setCancelModalOpen(true);
    } else {
      handleNavigateBack();
    }
  };

  if (isPending) {
    return (
      <>
        <Box sx={{
          width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
        }}
        >
          <Loading isLoading={isPending} />
        </Box>
      </>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageHeaderWithControls returnBack={handleReturn} title={t('Створення акції')}>
          <SplitButtonLight
            disabled={isPending || !isDirty}
            options={[
              {
                title: t('Зберегти і повернутися'),
                onClick: () => {
                  handleSubmit(onSubmit)();
                  handleNavigateBack();
                },
                disabled: isPending || !isValid || !isDirty,
              },
              {
                title: t('Зберегти зміни'),
                onClick: () => {
                  handleSubmit(onSubmit)();
                },
                disabled: isPending || !isValid || !isDirty,
              },
            ]}
          />
        </PageHeaderWithControls>
        <Paper sx={{ ml: '20px', mr: '20px' }}>
          <Grid container>
            <Grid item md={4}>
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                sx={{ p: '20px', border: '1px solid rgba(48, 48, 48, 0.08)' }}
              >
                <Typography fontSize={'14px'} color={'rgba(48, 48, 48, 0.54)'}>
                  {t('Назва акції')}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={8}>
              <PromoForm />
            </Grid>
          </Grid>
        </Paper>
        <CancelModal
          sendText={t('Покинути сторінку')}
          titleText={t('Хотите закрыть страницу без сохранения?')}
          text={t('Подчеркиваем, что введенные данные сохранены не будут.')}
          cancelText={t('Отменить')}
          closeModal={cancelModalOpen}
          handleCancel={() => {
            setCancelModalOpen(false);
            handleNavigateBack();
          }}
          handleCloseModal={() => setCancelModalOpen(false)}
        />
      </form>
    </FormProvider>
  );
}
