// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = fromJS({
  ordersList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    expandedRowIds: List(),
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
  }),
  shippingHistoryList: Map({
    items: [],
    pending: true,
  }),
  ordersStatistics: Map({
    items: {},
    pending: false,
    modalOpen: false,
  }),
  csvData: Map({
    csv: '',
    filename: '',
  }),
  orderEdit: Map({
    pending: false,
  }),
  ordersStatusesList: {},
  ordersStatusesListPending: false,
  ordersSettingStatus: false,
  ordersSettingPending: false,
  orderItemByHashId: Map({
    pending: true,
    item: {},
  }),
  ordersPaymentTypes: Map({
    items: [],
    pending: false,
  }),
  ordersShippingType: Map({
    items: [],
    pending: false,
  }),
  downloadFullOrders: Map({
    pending: false,
  }),
  ordersUpload: Map({
    error: [],
    pending: false,
    success: '',
    modalOpen: false,
  }),
  orderUnbindShippingTracking: Map({
    pending: false,
  }),
  ordersDownloadCsv: Map({
    pending: false,
  }),
  errorModal: Map({
    title: '',
    message: '',
    modalOpen: false,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'orders',
  ordersList: 'ordersList',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  modalOpen: 'modalOpen',
  pending: 'pending',
  sorting: 'sorting',
  error: 'error',
  expandedRowIds: 'expandedRowIds',
  csvData: 'csvData',
  ordersDownloadCsv: 'ordersDownloadCsv',
  ordersUpload: 'ordersUpload',
  orderUnbindShippingTracking: 'orderUnbindShippingTracking',
  orderEdit: 'orderEdit',
  success: 'success',
  ordersStatistics: 'ordersStatistics',
  ordersStatusesList: 'ordersStatusesList',
  ordersStatusesListPending: 'ordersStatusesListPending',
  ordersPaymentTypes: 'ordersPaymentTypes',
  ordersShippingType: 'ordersShippingType',
  downloadFullOrders: 'downloadFullOrders',
  totalCount: 'totalCount',
  cancelStatuses: 'cancelStatuses',
  orderStatuses: 'orderStatuses',
  statusesFlow: 'statusesFlow',
  pageSize: 'pageSize',
  orderItemByHashId: 'orderItemByHashId',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
  errorModal: 'errorModal',
  shippingHistoryList: 'shippingHistoryList',
});

export const ordersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ORDERS_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.ordersList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.ordersList, stateKeys.pending], pending)
        .setIn([stateKeys.ordersList, stateKeys.items], List(items))
        .setIn([stateKeys.ordersList, stateKeys.totalCount], totalCount);
    }
    case types.SET_ORDER_SHIPPING_TRACKING_HISTORY_REQUEST: {
      const { items, pending } = payload;
      return state
        .setIn([stateKeys.shippingHistoryList, stateKeys.items], List(items))
        .setIn([stateKeys.shippingHistoryList, stateKeys.pending], pending);
    }
    case types.SET_ORDERS_STATUSES_LIST_PENDING: {
      return state.set(stateKeys.ordersStatusesListPending, payload);
    }
    case types.SET_ORDERS_SORTING: {
      return state.setIn([stateKeys.ordersList, stateKeys.sorting], List(payload));
    }
    case types.SET_ORDERS_RELOAD: {
      return state.setIn([stateKeys.ordersList, stateKeys.reload], payload);
    }
    case types.SET_ORDERS_EXPANDED_ROW_IDS: {
      return state.setIn([stateKeys.ordersList, stateKeys.expandedRowIds], List(payload));
    }
    case types.SET_ORDERS_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.ordersList, stateKeys.filters], List(filters));
    }
    case types.SET_ORDERS_PAGE_SIZE: {
      return state.setIn([stateKeys.ordersList, stateKeys.pageSize], payload);
    }
    case types.SET_ORDERS_CURRENT_PAGE: {
      return state.setIn([stateKeys.ordersList, stateKeys.currentPage], payload);
    }
    case types.SET_ORDERS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.ordersList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.ordersList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.ordersList, stateKeys.filters], List(filters))
        .setIn([stateKeys.ordersList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.ordersList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.ordersList, stateKeys.reload], reload)
        .setIn([stateKeys.ordersList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_ORDERS_SETTING_STATUS: {
      return state.setIn([stateKeys.ordersList, stateKeys.settingStatus], payload);
    }
    case types.SET_ORDERS_SETTING_PENDING: {
      return state.setIn([stateKeys.ordersList, stateKeys.settingPending], payload);
    }
    case types.SET_ORDERS_ITEM_BY_HASH_ID: {
      return state.set(stateKeys.orderItemByHashId, Map(payload));
    }
    case types.MERGE_ORDER_EDIT: {
      return state.mergeIn([stateKeys.orderEdit], Map(payload));
    }
    case types.SET_ORDERS_STATUSES_LIST: {
      return state.set(stateKeys.ordersStatusesList, Map(payload));
    }
    case types.SET_ORDERS_PAYMENT_TYPES: {
      return state.mergeIn([stateKeys.ordersPaymentTypes], Map(payload));
    }
    case types.SET_ORDERS_SHIPPING_TYPES: {
      return state.mergeIn([stateKeys.ordersShippingType], Map(payload));
    }
    case types.SET_IS_DOWNLOAD_FULL_ORDERS_LOADING: {
      return state.setIn([stateKeys.downloadFullOrders, stateKeys.pending], payload);
    }
    case types.MERGE_ORDERS_UPLOAD: {
      return state.mergeIn([stateKeys.ordersUpload], Map(payload));
    }
    case types.MERGE_ORDER_UNBIND_SHIPPING_TRACKING: {
      return state.mergeIn([stateKeys.orderUnbindShippingTracking], Map(payload));
    }
    case types.SET_ORDERS_DOWNLOAD_CSV: {
      return state.setIn([stateKeys.ordersDownloadCsv, stateKeys.pending], payload);
    }
    case types.SET_ORDERS_CSV_DATA: {
      return state.setIn([stateKeys.csvData], Map(payload));
    }
    case types.MERGE_ORDERS_STATISTICS: {
      return state.mergeIn([stateKeys.ordersStatistics], Map(payload));
    }
    case types.MERGE_ERROR_MODAL: {
      return state.mergeIn([stateKeys.errorModal], Map(payload));
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
