import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generateInput, generateMeta } from '../utils/generateInput';
import CommonPartnerSelect from '../../../../../components/_Form/Selects/PartnerSelect';

const PartnerSelect = ({
  control, errors, editMode, touchedFields,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name="partner"
      control={control}
      render={({ field }) => {
        const input = generateInput(field);
        const meta = generateMeta(errors.partner?.message, !!touchedFields.partner);
        return (
          <CommonPartnerSelect
            {...field}
            input={input}
            required
            meta={meta}
            customOnBlur={field.onBlur}
            isSearchable
            hasSearch={false}
            isCategorySelect
            disabled={editMode}
          />
        );
      }}
      rules={{ required: t('Это обязательное поле') }}
    />
  );
};

export default PartnerSelect;
