import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link as MaterialLink } from '@mui/material';
import ModalFooter from '../../../containers/Modals/Modal/ModalFooter/ModalFooter';
import Modal from '../../../containers/Modals/Modal/Modal';

export function ConfirmChangePowerOfAttorney({
  onModalOpen, open, isSendingData, handleSend,
}) {
  const modalFooterComponent = () => (
    <ModalFooter>
      <Box mt={2}>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
        >
          <Grid item xs={12} sm="auto">
            <Button
              sx={{ mr: '10px' }}
              disabled={false}
              variant="outlined"
              color="primary"
              onClick={() => {
                onModalOpen(false);
              }}
            >
              {'Скасувати'}
            </Button>
            <Button
              disabled={isSendingData}
              variant="contained"
              color="primary"
              onClick={() => {
                handleSend(false);
                onModalOpen(false);
              }}
            >
              {'Зберегти'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalFooter>
  );

  return (
    <>
      <Modal
        title={'Зміна даних "АЛЛО Express"'}
        modalOpen={open}
        handleModalToggle={() => {
          onModalOpen(false);
        }}
        hideButtons
        fullWidth
        noPadding
        modalMaxWidth="sm"
        modalFooterComponent={modalFooterComponent}
      >
        <Grid marginBottom={'15px'} container justifyContent="flex-end" flexDirection="column">
          <Grid sx={{ p: '20px 20px' }} item sm={12}>
            <Typography><b>Увага!</b> Зміна даних призведе до деактивації змінених документів. Для активації нових документів - необхідно підписати їх у <MaterialLink target={'_blank'} href={'https://vchasno.ua/'}>Вчасно</MaterialLink>.</Typography>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}
