// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
} from './workers';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_PRODUCT_PRICE_COMPARISON_LIST_ASYNC, callGetListWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_PRICE_COMPARISON_LIST_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_PRODUCT_PRICE_COMPARISON_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_PRODUCT_PRICE_COMPARISON_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_PRODUCT_PRICE_COMPARISON_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_PRODUCT_PRICE_COMPARISON_SORTING, callSetToLocalDataWorker);
}

export function* watchersProductPriceComparison() {
  yield all([
    watchGetList(),
    watchGetInitialState(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
  ]);
}
