import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DxTable from '../../../components/_Table/DxTable/DxTable';
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/promotions/actions';
import { usePromotionsList } from './_hooks/usePromotionsList';
import { asyncActions } from '../../../../engine/core/promotions/saga/asyncActions';
import { pageLinks } from '../../../../engine/config/routes';
import { useAccessList } from '../../../hooks/useAccessList';
import { accessList } from '../../../../engine/config/access';
import { TableCell } from './table/TableCell';
import EditComponent from './table/EditComponent';

function PromotionsTable() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.promotions.isLoading);
  const rows = useSelector(selectors.promotions.list);

  const currentPage = useSelector(selectors.promotions.currentPage);
  const pageSize = useSelector(selectors.promotions.pageSize);
  const totalCount = useSelector(selectors.promotions.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  const sorting = useSelector(selectors.promotions.sorting);
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const settingStatus = useSelector(selectors.promotions.settingStatus);
  const settingPending = useSelector(selectors.promotions.settingPending);
  const filters = useSelector(selectors.promotions.filters);
  const editLink = pageLinks.advertising.edit;
  const showLink = pageLinks.advertising.show;
  const hasAccessToEdit = useAccessList(accessList.promotions_update);
  const gridSettings = useSelector(selectors.user.userSettings);

  const sortingColumnExtensions = [
    { columnName: 'promoTypes', sortingEnabled: false },
    { columnName: 'stopBrands', sortingEnabled: false },
    { columnName: 'categories', sortingEnabled: false },
  ];

  return (
    <DxTable
      name="promotionList"
      fixedColumn
      showShortText
      isLoading={isLoading}
      rows={rows}
      updateHooks={usePromotionsList}
      editComponent={props => (
        <EditComponent
          {...props}
          hasAccessEdit={hasAccessToEdit}
          editLink={editLink}
          hasAccessShow={!hasAccessToEdit}
          showLink={showLink}
        />
      )}
      // Paging properties
      pageSize={pageSize}
      totalCount={totalCount}
      onPageSize={onPageSize}
      currentPage={currentPage}
      customCellComponent={TableCell}
      onCurrentPage={onCurrentPage}
      // Sorting properties
      onSortingChange={onSortingChange}
      sorting={sorting.toJS()}
      // Filtering properties
      filters={filters.toJS()}
      filtersAction={actions.setFilters}
      // (Optional) you can disable sorting on specific columns if needed
      // Grid settings (from user settings)
      gridSettings={gridSettings.promotionList.gridSettings}
      sortingStateColumnExtensions={sortingColumnExtensions}
      // Settings for saving grid layout, etc.
      settingStatus={settingStatus}
      settingPending={settingPending}
      settingAction={asyncActions.putSettingsAsync}
    />
  );
}

export default memo(PromotionsTable);
