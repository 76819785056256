import { COLOR } from '../../ui/containers/App/AppStyles';

export const promotionStatuses = Object.freeze({
  STATUS_SCHEDULED: 'scheduled',
  STATUS_APPLICATION_OPEN: 'application_open',
  STATUS_ACTIVE: 'active',
  STATUS_FINISHED: 'finished',
});

export const promotionTypes = Object.freeze({
  specialPrice: 'specialPrice',
  partialPay: 'partialPay',
  freeDelivery: 'freeDelivery',
});

export const promotionTypesSelectValues = [
  { value: promotionTypes.specialPrice, label: 'Знижки' },
  { value: promotionTypes.partialPay, label: 'Оплата частинами' },
  { value: promotionTypes.freeDelivery, label: 'Безкоштовна доставка' },
];

export function getJoinedLabels(items) {
  return items?.map(item => item.label).join(', ') || '';
}

export const getLabelByPromotionType = (type) => {
  const item = promotionTypesSelectValues.find(entry => entry.value === type);
  return item ? item.label : 'Unknown type';
};

export function getStatusColor(status) {
  switch (status) {
    case promotionStatuses.STATUS_SCHEDULED:
      return COLOR.promotions.scheduled;
    case promotionStatuses.STATUS_APPLICATION_OPEN:
      return COLOR.promotions.applicationOpen;
    case promotionStatuses.STATUS_ACTIVE:
      return COLOR.promotions.active;
    case promotionStatuses.STATUS_FINISHED:
      return COLOR.promotions.finished;
    default:
      return COLOR.grey.main;
  }
}

export function collectCategoryIds(categories, acc = []) {
  categories.forEach((cat) => {
    acc.push(cat.value);
    if (Array.isArray(cat.children) && cat.children.length > 0) {
      collectCategoryIds(cat.children, acc);
    }
  });
  return acc;
}
