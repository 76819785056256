import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import { generateInput, generateMeta } from '../utils/generateInput';
import TextField from '../../../../../components/_Form/TextField/TextField/TextField';


const GroupNameField = ({ control, errors }) => {
  const { t } = useTranslation();
  return (
    <Controller
      name="groupName"
      control={control}
      render={({ field }) => {
        const input = generateInput(field);
        const meta = generateMeta(errors.groupName?.message, true);
        return (
          <Box sx={{ paddingBottom: '20px', flex: 1 }}>
            <TextField
              input={input}
              meta={meta}
              fullWidth
              label={t('Название группы')}
              defaultValue=""
              inputLabelProps={{ shrink: true }}
              validation
              maxLength={100}
            />
          </Box>
        );
      }}
      rules={{ required: t('Это обязательное поле') }}
    />
  );
};

export default GroupNameField;
