import { getIn, List } from 'immutable';
// Store keys
import { stateKeys as refundRequestsKeys } from './reducer';
import { pageSizes } from '../../config/globalConfig';

export const refundRequests = Object.freeze({
  currentPage: state => getIn(state, [refundRequestsKeys.controller, refundRequestsKeys.refundRequestsList, refundRequestsKeys.currentPage], 0),
  filters: state => getIn(state, [refundRequestsKeys.controller, refundRequestsKeys.refundRequestsList, refundRequestsKeys.filters], List()),
  isLoading: state => getIn(state, [refundRequestsKeys.controller, refundRequestsKeys.refundRequestsList, refundRequestsKeys.pending], false),
  list: state => getIn(state, [refundRequestsKeys.controller, refundRequestsKeys.refundRequestsList, refundRequestsKeys.items], List()),
  pageSize: state => getIn(state, [refundRequestsKeys.controller, refundRequestsKeys.refundRequestsList, refundRequestsKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [refundRequestsKeys.controller, refundRequestsKeys.refundRequestsList, refundRequestsKeys.reload], false),
  totalCount: state => getIn(state, [refundRequestsKeys.controller, refundRequestsKeys.refundRequestsList, refundRequestsKeys.totalCount], 0),
  sorting: state => getIn(state, [refundRequestsKeys.controller, refundRequestsKeys.refundRequestsList, refundRequestsKeys.sorting], List()),
  showNewRequestInformer: state => getIn(state, [refundRequestsKeys.controller, refundRequestsKeys.refundRequestsList, refundRequestsKeys.hasNewRequest], false),
});
