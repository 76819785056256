// Core
import React, { useEffect, useMemo, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import Select from './Select/Select';
import FieldWithClearButton from '../../../containers/FieldWithClearButton/FieldWithClearButton';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { asyncActions } from '../../../../engine/core/brands/saga/asyncActions';

function BrandsSelect(props) {
  const {
    input, margin, menuPlacement,
    withClear, label, isPortal,
    isMulti, closeMenuOnSelect, disabled, required, meta,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const options = useSelector(selectors.brands.brandsListContext);
  const isLoading = useSelector(selectors.brands.brandsListContextPending);
  const selectProps = useMemo(() => ({
    input,
    margin,
    menuPlacement,
    options,
    isMulti,
    isPortal,
    isLoading,
    closeMenuOnSelect,
    required,
    meta,
    hasSearch: true,
    label: label ?? t('Выберите бренд'),
    searchErrorLabel: t('Неверно ввели бренд'),
    variant: 'outlined',
    placeholder: '',
  }), [
    input,
    margin,
    menuPlacement,
    options,
    isMulti,
    isPortal,
    isLoading,
    label,
    closeMenuOnSelect,
    required,
    meta,
  ]);

  useEffect(() => {
    dispatch(asyncActions.getBrandsListsContextAsync());
  }, []);

  return withClear
    ? (
      <FieldWithClearButton
        margin={margin}
        value={input.value}
        onChange={input.onChange}
        name={input.name}
      >
        <Select disabled={disabled} {...selectProps} />
      </FieldWithClearButton>
    )
    : <Select disabled={disabled} {...selectProps} />;
}

export default memo(BrandsSelect);
