// Core
import { fromJS, List, Map } from 'immutable';

// lodash
import isNil from 'lodash/isNil';
import difference from 'lodash/difference';
import keys from 'lodash/keys';
import forEach from 'lodash/forEach';

// Engine
import { alloExpressConfig, pageSizes } from '../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = fromJS({
  contentProductList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    reload: false,
    selection: List(),
    editValue: Map(),
    sendQuery: false,
    selectedItems: List(),
  }),
  categoriesList: Map({
    active: undefined,
    pending: true,
    items: List(),
  }),
  productStatusesLog: Map({
    showModal: false,
    hashId: null,
    historyType: null,
  }),
  modal: Map({
    changeCategoryErrorOpen: false,
  }),
  modalWidthCategory: Map({
    showModal: false,
    value: Map(),
  }),
  alloExpressModal: Map({
    showModal: false,
    type: 0,
  }),
  alloExpressWarningPopup: Map({
    showModal: false,
    productId: 0,
    productsValues: {},
  }),
});


export const stateKeys = Object.freeze({
  controller: 'contentProduct',
  modal: 'modal',
  changeCategoryErrorOpen: 'changeCategoryErrorOpen',
  contentProductList: 'contentProductList',
  currentPage: 'currentPage',
  editValue: 'editValue',
  filters: 'filters',
  selection: 'selection',
  items: 'items',
  cancelStatus: 'cancelStatus',
  productStatus: 'productStatus',
  error: 'error',
  active: 'active',
  success: 'success',
  categoriesList: 'categoriesList',
  pending: 'pending',
  sorting: 'sorting',
  changedValue: 'changedValue',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
  sendQuery: 'sendQuery',
  productStatusesLog: 'productStatusesLog',
  showModal: 'showModal',
  hashId: 'hashId',
  historyType: 'historyType',
  modalWidthCategory: 'modalWidthCategory',
  value: 'value',
  type: 'type',
  selectedItems: 'selectedItems',
  alloExpressWarningPopup: 'alloExpressWarningPopup',
});

export const contentProductReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_CONTENT_PRODUCT_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.contentProductList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.contentProductList, stateKeys.pending], pending)
        .setIn([stateKeys.contentProductList, stateKeys.items], List(items))
        .setIn([stateKeys.contentProductList, stateKeys.sendQuery], false)
        .setIn([stateKeys.contentProductList, stateKeys.totalCount], totalCount);
    }
    case types.SET_CONTENT_PRODUCT_SEND_QUERY: {
      return state.setIn([stateKeys.contentProductList, stateKeys.sendQuery], payload);
    }
    case types.SET_MODAL_CHANGE_CATEGORY_ERROR_OPEN: {
      return state.setIn([stateKeys.modal, stateKeys.changeCategoryErrorOpen], payload);
    }
    case types.SET_CONTENT_PRODUCT_ITEMS: {
      return state.setIn([stateKeys.contentProductList, stateKeys.items], List(payload));
    }
    case types.SET_CONTENT_PRODUCT_SORTING: {
      return state.setIn([stateKeys.contentProductList, stateKeys.sorting], List(payload));
    }
    case types.SET_CONTENT_PRODUCT_RELOAD: {
      return state.setIn([stateKeys.contentProductList, stateKeys.reload], payload);
    }
    case types.SET_CONTENT_PRODUCT_SELECTION: {
      // return state.setIn([stateKeys.contentProductList, stateKeys.selection], List(payload));
      const items = state.getIn([stateKeys.contentProductList, stateKeys.items]);
      const previoslySelectedItems = state.getIn([stateKeys.contentProductList, stateKeys.selectedItems]);
      const selectedItems = payload.filter(it => items.find((item) => {
        const itemHeight = item.logisticsHeight?.value ?? item.logisticsHeight;
        const itemWeight = item.logisticsWeight?.value ?? item.logisticsWeight;
        const itemWidth = item.logisticsWidth?.value ?? item.logisticsWidth;
        const itemLength = item.logisticsLength?.value ?? item.logisticsLength;
        return (item.id === it
          && item.alloExpressEnabled?.value === false
          && item.idMagentoProduct?.value
          && item.idMagentoProduct?.value !== 'Відсутнє'
          && item.idMagentoProduct?.value !== 'Отсутствует'
          && item.categoryLogistics.height !== null
          && itemHeight < alloExpressConfig.logisticsHeight
          && itemWidth < alloExpressConfig.logisticsWidth
          && itemWeight < alloExpressConfig.logisticsWeight
          && itemLength < alloExpressConfig.logisticsLength
        ) || previoslySelectedItems.includes(it);
      }));
      const sel = new Set(selectedItems);
      return state.setIn([stateKeys.contentProductList, stateKeys.selectedItems], List(sel))
        .setIn([stateKeys.contentProductList, stateKeys.selection], List(payload));
    }
    case types.SET_CONTENT_PRODUCT_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.contentProductList, stateKeys.filters], List(filters));
    }
    case types.SET_CONTENT_PRODUCT_PAGE_SIZE: {
      return state.setIn([stateKeys.contentProductList, stateKeys.pageSize], payload);
    }
    case types.SET_CONTENT_PRODUCT_CURRENT_PAGE: {
      return state.setIn([stateKeys.contentProductList, stateKeys.currentPage], payload);
    }
    case types.MERGE_CONTENT_PRODUCT_STATUS_VALUE: {
      const { productId, value } = payload;
      return state.mergeIn([stateKeys.contentProductList, stateKeys.editValue, productId], value);
    }
    case types.SET_CONTENT_PRODUCT_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.contentProductList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.contentProductList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.contentProductList, stateKeys.filters], List(filters))
        .setIn([stateKeys.contentProductList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.contentProductList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.contentProductList, stateKeys.reload], reload)
        .setIn([stateKeys.contentProductList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_CONTENT_PRODUCT_SETTING_STATUS: {
      return state.setIn([stateKeys.contentProductList, stateKeys.settingStatus], payload);
    }
    case types.SET_CONTENT_PRODUCT_SETTING_PENDING: {
      return state.setIn([stateKeys.contentProductList, stateKeys.settingPending], payload);
    }
    case types.SET_IS_CONTENT_PRODUCT_CATEGORIES_LIST_LOADING: {
      return state.setIn([stateKeys.categoriesList, stateKeys.pending], payload);
    }
    case types.SET_ACTIVE_PRODUCT_CATEGORY: {
      return state.withMutations((mutable) => {
        mutable
          .setIn([stateKeys.categoriesList, stateKeys.active], fromJS(payload))
          .setIn([stateKeys.contentProductList, stateKeys.selection], List());
      });
    }
    case types.CHANGE_PRODUCT_DATA_ACTIVE_EDIT: {
      const { productId, status } = payload;
      const allProducts = state.getIn([stateKeys.contentProductList, stateKeys.items]);
      const index = allProducts.findIndex(product => product.id === productId);
      return state.withMutations((mutable) => {
        if (!isNil(status)) {
          mutable.setIn([stateKeys.contentProductList, stateKeys.items, index, stateKeys.productStatus, stateKeys.active], status);
          mutable.setIn([stateKeys.contentProductList, stateKeys.items, index, stateKeys.cancelStatus, stateKeys.active], status);
        }
      });
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    case types.SET_PRODUCT_CATEGORIES_LIST: {
      return state.setIn([stateKeys.categoriesList, stateKeys.items], List(payload));
    }
    case types.SET_ALL_PRODUCTS_FEATURE_STATE: {
      const {
        error, key, pending, productId, success, label, value, newProductData,
      } = payload;
      const allProducts = state.getIn([stateKeys.contentProductList, stateKeys.items]);
      const index = allProducts.findIndex(product => product.id === productId);
      const currentProduct = state.getIn([stateKeys.contentProductList, stateKeys.items, index]);
      if (index !== -1) {
        const featureState = {
          error,
          pending,
          success,
          label,
          value,
        };

        if (error || pending) {
          delete featureState.label;
          delete featureState.value;
        }

        return state.withMutations((mutable) => {
          if (newProductData) {
            const diffValueFromResponseAndCurrentItem = difference(keys(currentProduct), keys(newProductData));

            diffValueFromResponseAndCurrentItem.map(
              diffItem => diffItem !== 'editable' && mutable.deleteIn([stateKeys.contentProductList, stateKeys.items, index, diffItem]),
            );

            forEach(newProductData, (itemValue, itemKey) => {
              if (!isNil(itemValue)) {
                switch (itemKey) {
                  case 'logisticsWidth':
                  case 'logisticsHeight':
                  case 'logisticsWeight':
                  case 'logisticsLength': {
                    mutable.setIn([stateKeys.contentProductList, stateKeys.items, index, itemKey], Map({ value: itemValue }));
                    break;
                  }
                  case 'sku': {
                    mutable.setIn([stateKeys.contentProductList, stateKeys.items, index, itemKey], Map(itemValue));
                    break;
                  }

                  default: {
                    mutable.setIn([stateKeys.contentProductList, stateKeys.items, index, itemKey], itemValue);
                    break;
                  }
                }
              }
            });
          }
          mutable.mergeIn([stateKeys.contentProductList, stateKeys.items, index, key], featureState);
        });
      }

      return state;
    }
    case types.MERGE_CONTENT_PRODUCT_LOG: {
      return state.mergeIn([stateKeys.productStatusesLog], Map(payload));
    }
    case types.SET_MODAL_WIDTH_CATEGORY_OPEN: {
      return state.setIn([stateKeys.modalWidthCategory, stateKeys.showModal], payload);
    }
    case types.SET_MODAL_WIDTH_CATEGORY_VALUE: {
      return state.setIn([stateKeys.modalWidthCategory, stateKeys.value], payload);
    }
    case types.SET_ALLO_EXPRESS_MODAL: {
      return state.set(stateKeys.alloExpressModal, payload);
    }
    case types.SET_ALLO_EXPRESS_WARNING_POPUP: {
      return state.set(stateKeys.alloExpressWarningPopup, payload);
    }
    default: {
      return state;
    }
  }
};
