// Core
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Parts
import TextField from '@mui/material/TextField';
import TableHeadFilterCell from './TableHeadFilterCell/TableHeadFilterCell';
import { tableDataTypes } from '../../../../../_helpers/data/tableDataTypes';
import NumberFormatCustom from '../../../NumberFormat/NumberFormatCustom';

const PriceNumberFormat = memo((props) => {
  const { name, ...rest } = props;
  const decimalScale =
    name === tableDataTypes.feedSalePrice.name
    || name === tableDataTypes.feedPrice.name
      ? 2
      : 0;
  return (
    <NumberFormatCustom
      {...rest}
      decimalScale={decimalScale}
      decimalSeparator="."
      fixedDecimalScale
      thousandSeparator=""
    />
  );
});

const PriceTableFilter = (props) => {
  const {
    disabled, setFilterValue, filterValue, resetFilter, name,
  } = props;

  const { t } = useTranslation();
  const [value, onChange] = useState(filterValue);
  const timerId = React.useRef(0);
  const changePriceValue = (args) => {
    onChange(args.value);
  };

  useEffect(() => {
    if (value !== filterValue) {
      const timeout = 500;
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
      timerId.current = setTimeout(() => {
        setFilterValue(value);
      }, timeout);
      return () => clearTimeout(timerId.current);
    }
  }, [filterValue, setFilterValue, value]);

  return (
    <TableHeadFilterCell disabled={disabled} resetFilter={resetFilter}>
      <TextField
        fullWidth
        placeholder={t('Поиск')}
        value={value}
        name={name}
        onChange={changePriceValue}
        InputProps={{
          inputComponent: PriceNumberFormat,
        }}
      />
    </TableHeadFilterCell>
  );
};

export default memo(PriceTableFilter);
