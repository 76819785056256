// Core
import React, { memo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@mui/styles';
import cx from 'classnames';
import { isMobile } from 'react-device-detect';
// UI
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RefreshVerticalIcon from '../icons/RefreshVerticalIcon';
import { COLOR } from './App/AppStyles';

export const HtmlTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 400,
  },
}))(Tooltip);
const useStyles = makeStyles({
  iconRoot: {
    width: '100%',
    height: '100%',
    color: COLOR.grey.main,
  },
  iconButtonRoot: {
    padding: 0,
    width: 19,
    height: 19,
  },
  iconButtonRefresh: {
    marginRight: '14px',
    width: 26,
    height: 26,
  },
  iconRefresh: {
    color: COLOR.grey['72'],
  },
});

function ComponentWithTooltip(props) {
  const {
    children, description, onRefresh, refresh, refreshDescription, refreshDisabled, classesTooltipHelp,
  } = props;
  const classes = useStyles();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const tooltipMobileProps = isMobile ? {
    open: isTooltipOpen,
    onClose: handleTooltipClose,
    onOpen: handleTooltipOpen,
    enterTouchDelay: 0,
    leaveTouchDelay: 4500,
  } : {};


  return (
    <>
      {children}
      {refresh
        && (
          <HtmlTooltip title={refreshDescription}>
            <IconButton
              disabled={refreshDisabled}
              onClick={onRefresh}
              className={cx([classes.iconButtonRoot], [classes.iconButtonRefresh])}
              size="large"
              {...tooltipMobileProps}
            >
              <RefreshVerticalIcon className={cx([classes.iconRoot], [classes.iconRefresh])} />
            </IconButton>
          </HtmlTooltip>
        )
      }
      {description
        && (
          <HtmlTooltip
            title={description}
            className={classesTooltipHelp}
            PopperProps={{ style: { zIndex: 10000 } }}
            {...tooltipMobileProps}
          >
            <IconButton classes={{ root: classes.iconButtonRoot }} size="large">
              <InfoOutlinedIcon classes={{ root: classes.iconRoot }} />
            </IconButton>
          </HtmlTooltip>
        )
      }
    </>
  );
}

ComponentWithTooltip.propTypes = {
  children: PropTypes.node,
  onRefresh: PropTypes.func,
  refresh: PropTypes.bool,
  refreshDisabled: PropTypes.bool,
  refreshDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  classesTooltipHelp: PropTypes.string,
};

export default memo(ComponentWithTooltip);
