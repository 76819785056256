// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_TORPS_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_TORPS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_TORPS_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_TORPS_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_TORPS_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_TORPS_INITIAL_STATE, initialState);
  },
  setSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_TORPS_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_TORPS_SETTING_PENDING, settingPending);
  },
  setSelection(selected) {
    return getActionCreator(types.SET_TORPS_SELECTION, selected);
  },
  setDeleteTORPModal(payload) {
    return getActionCreator(types.SET_DELETE_TORP_MODAL, payload);
  },
});
