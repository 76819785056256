import { getIn, List } from 'immutable';
import { stateKeys } from './reducer';
import { pageSizes } from '../../config/globalConfig';

export const promotionsSelectors = {
  currentPage: state => getIn(state, [stateKeys.controller, stateKeys.promotionsList, stateKeys.currentPage], 0),
  filters: state => getIn(state, [stateKeys.controller, stateKeys.promotionsList, stateKeys.filters], List()),
  isLoading: state => getIn(state, [stateKeys.controller, stateKeys.promotionsList, stateKeys.pending], false),
  list: state => getIn(state, [stateKeys.controller, stateKeys.promotionsList, stateKeys.items], List()),
  pageSize: state => getIn(state, [stateKeys.controller, stateKeys.promotionsList, stateKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [stateKeys.controller, stateKeys.promotionsList, stateKeys.reload], true),
  totalCount: state => getIn(state, [stateKeys.controller, stateKeys.promotionsList, stateKeys.totalCount], 0),
  sorting: state => getIn(state, [stateKeys.controller, stateKeys.promotionsList, stateKeys.sorting], List()),
  selection: state => getIn(state, [stateKeys.controller, stateKeys.promotionsList, stateKeys.selection], List()),
  isPending: state => getIn(state, [stateKeys.controller, stateKeys.isPending], true),
  settingStatus: state => getIn(state, [stateKeys.controller, stateKeys.promotionsList, stateKeys.settingStatus], true),
  settingPending: state => getIn(state, [stateKeys.controller, stateKeys.promotionsList, stateKeys.settingPending], false),
  getPromotionForEdit: state => getIn(state, [stateKeys.controller, stateKeys.promotionForEdit], {}),
  getPromotionHistoryList: state => getIn(state, [stateKeys.controller, stateKeys.promotionHistoryList], List()),
};
