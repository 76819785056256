import { useState, useMemo } from 'react';
import declineWord from 'decline-word';
import { useDispatch, useSelector } from 'react-redux';
import { isImmutable } from 'immutable';
import { isEmpty } from 'lodash';
import Modal from '../../../../containers/Modals/Modal/Modal';
import { api } from '../../../../../engine/config/api';
import { selectors } from '../../../../../engine/config/selectors';
import { setErrorMessage, setSuccessMessage } from '../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { actions } from '../../../../../engine/core/logisticsDataByCategory/actions';
import { asyncActions } from '../../../../../engine/core/logisticsDataByCategory/saga/asyncActions';
import { convertSortingObjectToUrlParams } from '../../../../../_helpers/convertDataToUrlParams';

const ADD_ALLO_EXPRESS_CATEGORY_SELECT_OPTION = 'Додати категорії до АЛЛО Express';


const AlloExpressCategoriesModal = ({ modal, onClose }) => {
  const { open, type } = modal;

  const selection = useSelector(selectors.logisticsDataByCategory.selection);
  const selectedItems = useSelector(selectors.logisticsDataByCategory.selectedItems);
  const currentPage = useSelector(selectors.logisticsDataByCategory.currentPage);
  const limit = useSelector(selectors.logisticsDataByCategory.pageSize);
  const filters = useSelector(selectors.logisticsDataByCategory.filters);
  const sorting = useSelector(selectors.logisticsDataByCategory.sorting);
  const treeDataRowIds = useSelector(selectors.logisticsDataByCategory.treeDataRowIds);

  const offset = limit * currentPage;
  const getTreeDataRowIds = useMemo(() => isImmutable(treeDataRowIds)
    ? treeDataRowIds.toJS()
    : treeDataRowIds,
  [treeDataRowIds]);
  const isEmptyTreeDataRowIds = isEmpty(getTreeDataRowIds);

  const orderBy = useMemo(() => (
    convertSortingObjectToUrlParams(sorting.toJS())
  ), [sorting]);
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const isAdd = type === ADD_ALLO_EXPRESS_CATEGORY_SELECT_OPTION;

  const categoryWord = declineWord(isAdd ? selectedItems.size : selection.size - selectedItems.size, 'категор', 'ію', 'ії', 'ій');


  const noCategoriesToEditText = `Обрані категорії вже ${isAdd ? 'додані до' : 'видалені з'} функціоналу "ALLO Express".`;
  const addText = `До функціоналу "ALLO Express" буде додано ${selectedItems.size} ${categoryWord}. Порожні значення логістичних даних будуть автоматично заповнені значеннями: ШВГ-120см*70см*70см, вага-30кг.`;
  const deleteText = `З функціоналу "ALLO Express" буде видалено ${selection.size - selectedItems.size} ${categoryWord}. Значення логістичних даних за замовчуванням (ШВГ-120см*70см*70см, вага-30кг) будуть автоматично видалені.`;

  const text = (() => {
    if (isAdd && selectedItems.size) {
      return addText;
    } if (!isAdd && (selection.size - selectedItems.size)) {
      return deleteText;
    }
    return noCategoriesToEditText;
  })();

  const handleSend = async () => {
    if (isAdd && selectedItems.size === 0) {
      onClose();
      return;
    }
    if (!isAdd && ((selection.size - selectedItems.size) === 0)) {
      onClose();
      return;
    }
    const data = (() => {
      const filter = isAdd ? selectedItems.toArray() : selection.filter(sel => !selectedItems.includes(sel)).toArray();
      return { filter: { ids: filter }, data: { alloExpressEnabled: isAdd } };
    })();
    setIsLoading(true);
    const response = await api.logisticsDataByCategory.updateByFilter(data);
    if (response.status >= 200 && response.status < 400) {
      dispatch(setSuccessMessage(isAdd ? 'Категорії додані до "ALLO Express"' : 'Категорії видалені з "ALLO Express"', response.data.statusTitle));
      onClose();
      dispatch(actions.setSelection([]));

      const defaultFilters = {
        loadRootCategories: isEmptyTreeDataRowIds,
        ...(isEmpty(filters.toJS()) ? {
          parentId: 0,
        } : {}),
        orderBy: isEmpty(orderBy) ? 'name ASC' : orderBy,
      };

      const paramsByFilter = filters.reduce((acc, { columnName, value }) => {
        acc[columnName] = value;
        return acc;
      }, {});

      const params = {
        ...paramsByFilter,
        ...defaultFilters,
        ...(isEmptyTreeDataRowIds ? {
          limit,
          offset,
        } : {}),
      };
      dispatch(asyncActions.getListAsync(params, getTreeDataRowIds));
    } else {
      dispatch(setErrorMessage(response.data?.errors ?? 'Виникла помилка', response.data?.statusTitle));
    }
    setIsLoading(false);
  };

  return (
    <>
      {open && (
      <Modal
        modalOpen={open}
        handleModalToggle={onClose}
        title={isAdd ? 'Додавання категорій до функціоналу "ALLO Express"' : 'Видалення категорій з "ALLO Express"'}
        buttonSendIcon={null}
        sendText="OK"
        modalMaxWidth="sm"
        handleModalSend={handleSend}
        disabledSend={loading}
      >
        <b>Увага!</b> {text}
      </Modal>
      )}
    </>
  );
};

export default AlloExpressCategoriesModal;
