import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';


const DrawerHeader = ({ title, onClose }) => (
  <Box sx={{
    width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '24px',
  }}
  >
    <Typography fontWeight={700} fontSize={16}>{title}</Typography>
    <Button onClick={onClose} variant="text" sx={{ color: 'inherit', '&:hover': { backgroundColor: 'transparent' } }}><CloseIcon /></Button>
  </Box>
);


export default DrawerHeader;
