// Core
import React, { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// UI
import Button from '@mui/material/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DxTable from '../../../components/_Table/DxTable/DxTable';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/refundRequests/actions';
// Hooks
import { useRefundRequestsList } from '../hooks/useRefundRequestsList';
import RefundRequestsTableCell from './TableCell';
import RefundRequestEdit from './RefundRequestEdit';
import { finalStatuses } from '../constants';


const EditComponent = ({ setOpenEditModal, setSelectedRowId, row }) => {
  const handleClick = () => {
    setSelectedRowId(row.id);
    setOpenEditModal(true);
  };
  const icon = finalStatuses.includes(row.status) ? <RemoveRedEyeOutlinedIcon /> : <EditOutlinedIcon />;
  return <Button onClick={handleClick} variant="text" sx={{ color: 'inherit', width: '100%', '&:hover': { backgroundColor: 'transparent' } }}>{icon}</Button>;
};

function RefundRequestsTable() {
  const [refresh, setRefresh] = useState(false);
  const getListAsync = useRefundRequestsList(refresh);
  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.refundRequests.isLoading);
  // Rows
  const list = useSelector(selectors.refundRequests.list);
  // Paging
  const gridSettings = useSelector(selectors.user.userSettings);
  const currentPage = useSelector(selectors.refundRequests.currentPage);
  const pageSize = useSelector(selectors.refundRequests.pageSize);
  const totalCount = useSelector(selectors.refundRequests.totalCount);
  const onPageSize = size => dispatch(actions.setPageSize(size));
  const onCurrentPage = page => dispatch(actions.setCurrentPage(page));
  // Sorting
  const onSortingChange = currentSorting => dispatch(actions.setSorting(currentSorting));
  const sorting = useSelector(selectors.refundRequests.sorting);
  // Filtering
  const filters = useSelector(selectors.refundRequests.filters);
  const rows = useMemo(() => list.toJS(), [list]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  return (
    <>
      <DxTable
        name="refundRequestsTable"
        fixedColumn
        showShortText
        isLoading={isLoading}
        rows={rows}
        editComponent={editProps => <EditComponent setOpenEditModal={setOpenEditModal} setSelectedRowId={setSelectedRowId} {...editProps} />}
        customCellComponent={RefundRequestsTableCell}
      // Paging
        pageSize={pageSize}
        totalCount={totalCount}
        onPageSize={onPageSize}
        currentPage={currentPage}
        onCurrentPage={onCurrentPage}
      // Sorting
        onSortingChange={onSortingChange}
        sorting={sorting.toJS()}
      // Filters
        filters={filters.toJS()}
        filtersAction={actions.setFilters}
        refreshHook={getListAsync}
      // Settings
        gridSettings={gridSettings.orderReturnRequest.gridSettings}
      />
      <RefundRequestEdit open={openEditModal} setOpenEditModal={setOpenEditModal} selectedRowId={selectedRowId} setRefresh={setRefresh} />
    </>
  );
}

export default memo(RefundRequestsTable);
