import React, { Fragment, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  isAfter, isBefore, isValid, parse, startOfDay,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { CalendarIcon } from '@mui/x-date-pickers';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import FormTextFieldInput from '../../ShippingIntegration/components/input/FormTextFieldInput';
import BoxWithShadow from '../../../containers/Box/BoxWithShadow';
import { PromoNoteSvgIcon } from '../icons/PromoNoteSvgIcon';
import { getDateWithoutHours } from '../../../../_helpers/getDate';
import { PromoTypeSvgIcon } from '../icons/PromoTypeSvgIcon';
import { PromoGeneralConditionsSvgIcon } from '../icons/PromoGeneralConditionsSvgIcon';
import BrandsSelect from '../../../components/_Form/Selects/BrandsSelect';
import { PromoDescriptionSvgIcon } from '../icons/PromoDescriptionSvgIcon';
import SelectCategoryTreeViewForm from '../../../components/_Form/Selects/SelectCategoryTreeView/SelectCategoryTreeViewForm';
import DateRange from '../../../components/DateRange/DateRange';
import { COLOR } from '../../../containers/App/AppStyles';

const NameMaxLength = 100;

export function PromoForm({ disabledEdit }) {
  const {
    control,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  const specialPrice = watch('specialPrice', false);
  const partialPay = watch('partialPay', false);
  const freeDelivery = watch('freeDelivery', false);

  const promoRangeDate = watch('promoDateRange', '');
  const promoStartDate = promoRangeDate ? parse(promoRangeDate.split(' — ')[0], 'dd.MM.yyyy', new Date()) : null;

  const { t } = useTranslation();
  const [promoRangeAnchor, setPromoRangeAnchor] = useState(null);
  const [registrationRangeAnchor, setRegistrationRangeAnchor] = useState(null);
  const [showPromoRangeModal, setShowPromoRangeModal] = useState(false);
  const [showRegistrationRangeModal, setShowRegistrationRangeModal] = useState(false);
  const endDate = new Date();
  const startDate = new Date();
  const promoRangeInputRef = useRef(null);
  const registrationRangeInputRef = useRef(null);

  const handlePromoDateRangeToggle = () => {
    setShowPromoRangeModal(prev => !prev);
    setPromoRangeAnchor(prevAnchor => (prevAnchor ? null : promoRangeInputRef.current));
  };

  const handleRegistrationDateRangeToggle = () => {
    setShowRegistrationRangeModal(prev => !prev);
    setRegistrationRangeAnchor(prevAnchor => (prevAnchor ? null : registrationRangeInputRef.current));
  };

  const onPromoDateRangeChange = (changes) => {
    const promoDateRange = `${getDateWithoutHours(changes.startDate)} — ${getDateWithoutHours(changes.endDate)}`;
    setValue('promoDateRange', promoDateRange, {
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue('promoRegistrationDateRange', '', {
      shouldDirty: true,
      shouldValidate: true,
    });

    trigger('promoRegistrationDateRange');
  };

  const onPromoRegistrationDateRangeChange = (changes) => {
    const promoRegistrationDateRange = `${getDateWithoutHours(changes.startDate)} — ${getDateWithoutHours(changes.endDate)}`;
    setValue('promoRegistrationDateRange', promoRegistrationDateRange, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const validateNotPastDate = (value) => {
    if (!value) {
      return true;
    }

    const [rawStart, rawEnd] = value.split(' — ');
    if (!rawStart || !rawEnd) {
      return true;
    }

    const start = parse(rawStart, 'dd.MM.yyyy', new Date());
    const end = parse(rawEnd, 'dd.MM.yyyy', new Date());

    if (!isValid(start) || !isValid(end)) {
      return t('Невірний формат дати');
    }

    const today = startOfDay(new Date());
    if (isBefore(start, today) || isBefore(end, today)) {
      return t('Неможливо обрати дату в минулому');
    }

    return true;
  };


  const validateDateRanges = () => {
    const promoRange = watch('promoDateRange', '').split(' — ');
    const registrationRange = watch('promoRegistrationDateRange', '').split(' — ');

    if (promoRange.length === 2 && registrationRange.length === 2) {
      const promoEndDate = parse(promoRange[1], 'dd.MM.yyyy', new Date());
      const registrationStartDate = parse(registrationRange[0], 'dd.MM.yyyy', new Date());
      const registrationEndDate = parse(registrationRange[1], 'dd.MM.yyyy', new Date());

      if (isAfter(registrationEndDate, promoEndDate)) {
        return t('Дата закінчення прийому заявок не може бути пізніше початку акції');
      }
      const today = startOfDay(new Date());
      if (isBefore(registrationStartDate, today) || isBefore(registrationEndDate, today)) {
        return t('Неможливо обрати дату в минулому');
      }
    }
    return true;
  };

  const validateCheckboxes = () => {
    if (!specialPrice && !partialPay && !freeDelivery) {
      return t('Оберіть хоча б один варіант');
    }
    return true;
  };

  return (
    <>
      <Box>
        <Box sx={{ m: '20px' }}>
          <Typography fontSize={'16px'} fontWeight={700}>
            {t('Акція')}
          </Typography>
        </Box>

        <BoxWithShadow>
          <Box display="flex" alignItems="center" gap={1} sx={{ mb: '15px' }}>
            <PromoNoteSvgIcon />
            <Typography fontSize={'14px'} fontWeight={700}>
              {t('Про акцію')}
            </Typography>
          </Box>
          <Divider sx={{ mb: '20px' }} />

          <Grid container spacing={5}>
            <Grid item xs={6}>
              {/* Назва акції */}
              <Controller
                name="promoName"
                control={control}
                disabled={disabledEdit}
                rules={{
                  required: t('Назва обов\'язкова'),
                  maxLength: NameMaxLength,
                  validate: (value) => {
                    if (value.trim().length === 0) {
                      return t('Назва акції не може складатися тільки з пробілів');
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <FormTextFieldInput
                    {...field}
                    input={'text'}
                    variant="outlined"
                    label={'Назва акції'}
                    fullWidth
                    error={!!errors.promoName}
                    helperText={errors.promoName?.message}
                    placeholder={'Чорна п\'ятниця'}
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              {/* Період акції */}
              <Controller
                name="promoDateRange"
                control={control}
                disabled={disabledEdit}
                rules={{
                  required: t('Період акції обов\'язковий'),
                  validate: validateNotPastDate,
                }}
                render={({ field }) => (
                  <FormTextFieldInput
                    {...field}
                    input={'text'}
                    ref={promoRangeInputRef}
                    variant="outlined"
                    label={'Період акції'}
                    fullWidth
                    onChange={() => {}}
                    error={!!errors.promoDateRange}
                    helperText={errors.promoDateRange?.message}
                    placeholder={'дд.мм.рррр — дд.мм.рррр'}
                    required
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handlePromoDateRangeToggle} edge="end">
                            <CalendarIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Popover
                open={showPromoRangeModal}
                anchorEl={promoRangeAnchor}
                onClose={handlePromoDateRangeToggle}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <DateRange
                  range
                  minDate={new Date()}
                  onChange={onPromoDateRangeChange}
                  startDate={startDate}
                  endDate={endDate}
                  columnName={'promoPeriod'}
                />
              </Popover>
            </Grid>
            <Grid item xs={6}>
              {/* Прийом заявок */}
              <Controller
                name="promoRegistrationDateRange"
                control={control}
                disabled={disabledEdit}
                rules={{
                  required: t('Прийом заявок обов\'язковий'),
                  validate: validateDateRanges,
                }}
                render={({ field }) => (
                  <FormTextFieldInput
                    {...field}
                    input={'text'}
                    variant="outlined"
                    label={'Прийом заявок'}
                    ref={registrationRangeInputRef}
                    onChange={() => {}}
                    fullWidth
                    error={!!errors.promoRegistrationDateRange}
                    helperText={errors.promoRegistrationDateRange?.message}
                    placeholder={'дд.мм.рррр — дд.мм.рррр'}
                    required
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleRegistrationDateRangeToggle}
                            edge="end"
                          >
                            <CalendarIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Popover
                open={showRegistrationRangeModal}
                anchorEl={registrationRangeAnchor}
                onClose={handleRegistrationDateRangeToggle}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <DateRange
                  range
                  maxDate={isValid(promoStartDate) ? promoStartDate : undefined}
                  onChange={onPromoRegistrationDateRangeChange}
                  startDate={startDate}
                  endDate={endDate}
                  columnName={'promoRegistrationPeriod'}
                />
              </Popover>
            </Grid>
          </Grid>
        </BoxWithShadow>
        <BoxWithShadow>
          <Box display="flex" alignItems="center" gap={1} sx={{ mb: '15px' }}>
            <PromoTypeSvgIcon />
            <Typography fontSize={'14px'} fontWeight={700}>
              Тип
            </Typography>
          </Box>
          <Divider sx={{ mb: '20px' }} />
          <Box display="flex" flexDirection="column">
            <Typography fontSize={'14px'} color={COLOR.error}>
              {errors.promoType?.message}
            </Typography>
            <Controller
              name="promoTypeValidation"
              control={control}
              rules={{ validate: validateCheckboxes }}
              render={() => null}
            />
            <Controller
              name="specialPrice"
              control={control}
              disabled={disabledEdit}
              render={({ field }) => (
                <FormControlLabel
                  control={(
                    <Checkbox
                      {...field}
                      checked={field.value}
                    />
                    )}
                  label={(
                    <Tooltip title={'“Знижки” - товари зі знижкою.'} arrow>
                      <Typography fontSize={'14px'} color={'#303030'}>
                        Знижки
                      </Typography>
                    </Tooltip>
                    )}
                />
              )}
            />
            <Controller
              name="partialPay"
              control={control}
              disabled={disabledEdit}
              render={({ field }) => (
                <FormControlLabel
                  control={(
                    <Checkbox
                      {...field}
                      checked={field.value}
                    />
                    )}
                  label={(
                    <Tooltip title={' “Оплата частинами” - товари, які можна сплатити частинами лише на час акції (для участі у вас має бути підключена послуга “Оплата частинами Monobank” або “Покупка частинами ПриватБанк”).'} arrow>
                      <Typography fontSize={'14px'} color={'#303030'}>
                        Оплата частинами
                      </Typography>
                    </Tooltip>
                    )}
                />
              )}
            />
            <Controller
              name="freeDelivery"
              control={control}
              disabled={disabledEdit}
              render={({ field }) => (
                <FormControlLabel
                  control={(<Checkbox {...field} checked={field.value} />)}
                  label={(
                    <Tooltip title={'“Безкоштовна доставка” - товари з безкоштовною доставкою за рахунок продавця.'} arrow>
                      <Typography fontSize={'14px'} color={'#303030'}> {t('Безкоштовна доставка')}</Typography>
                    </Tooltip>
                    )}
                />
              )
                }
            />
            {errors.promoTypeValidation && (
              <Typography fontSize={'0.75rem'} color={COLOR.error}>
                {errors.promoTypeValidation.message}
              </Typography>
            )}
          </Box>
        </BoxWithShadow>
        <BoxWithShadow>
          <Box display="flex" alignItems="center" gap={1} sx={{ mb: '15px' }}>
            <PromoGeneralConditionsSvgIcon />
            <Typography fontSize={'14px'} fontWeight={700}>
              {t('Загальні умови')}
            </Typography>
          </Box>
          <Divider sx={{ mb: '20px' }} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="categories"
                disabled={disabledEdit}
                control={control}
                rules={{
                  validate: val => (val?.length > 0 ? true : t('Категорія обов\'язкова')),
                }}
                render={({ field, fieldState }) => (
                  <SelectCategoryTreeViewForm
                    input={{
                      name: field.name,
                      onChange: field.onChange,
                      onBlur: field.onBlur,
                      value: field.value,
                    }}
                    meta={{
                      touched: true,
                      error: fieldState.error?.message,
                    }}
                    required
                    isMulti
                    disabled={disabledEdit}
                    variant="outlined"
                    withClear
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="stopBrands"
                control={control}
                disabled={disabledEdit}
                rules={{
                  validate: val => (val?.length > 0 ? true : t('Бренди обов\'язкові')),
                }}
                render={({ field, fieldState }) => (
                  <BrandsSelect
                    {...field}
                    required
                    isMulti
                    input={{
                      name: field.name,
                      onChange: field.onChange,
                      onBlur: field.onBlur,
                      label: 'Стоп-бренди',
                      value: field.value,
                    }}
                    meta={{
                      touched: true,
                      error: fieldState.error?.message,
                    }}
                    variant="outlined"
                    label={t('Стоп-бренди')}
                    error={!!errors.stopBrands}
                    helperText={errors.stopBrands?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </BoxWithShadow>
        <BoxWithShadow>
          <Box display="flex" alignItems="center" gap={1} sx={{ mb: '15px' }}>
            <PromoDescriptionSvgIcon />
            <Typography fontSize={'14px'} fontWeight={700}>
              Загальні умови
            </Typography>
          </Box>
          <Divider sx={{ mb: '20px' }} />
          <Controller
            name="description"
            control={control}
            disabled={disabledEdit}
            rules={{
              required: t('Опис обов\'язковий'),
              maxLength: 2000,
            }}
            render={({ field }) => (
              <FormTextFieldInput
                {...field}
                variant="outlined"
                multiline
                rows={4}
                label={'Загальні умови'}
                fullWidth
                error={!!errors.description}
                helperText={errors.description?.message}
                required
                sx={{
                  '& .MuiInputBase-root textarea': {
                    resize: 'vertical',
                  },
                }}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </BoxWithShadow>
      </Box>
    </>
  );
}
