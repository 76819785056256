import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

export function TableControlPanel({ children }) {
  return (
    <Paper>
      <Grid
        container
        justifyContent="end"
        alignItems="center"
        sx={{
          pr: '15px',
          height: '54px',
          width: '100%',
        }}
      >{children}
      </Grid>
    </Paper>
  );
}
