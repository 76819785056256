import PreviewTable from '../../../../../components/shared/PreviewTable';

const columns = [
  {
    name: 'vendorCode', title: 'Код постачальника', type: 'text', visible: true,
  },
  {
    name: 'partnerMagentoIds', title: 'M-ID Партнерів', type: 'text',
  },
  {
    name: 'productNames', title: 'Назви товарів', type: 'text',
  },
  {
    name: 'productsSku', title: 'SKU товарів', type: 'text',
  }];

const TORPsPreviewTable = ({ rows, setRows }) => <PreviewTable rows={rows} setRows={setRows} columns={columns} rowId="vendorCode" />;


export default TORPsPreviewTable;
