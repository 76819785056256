// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_TORPS_LIST_ASYNC, params);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_TORPS_SETTINGS_ASYNC, param);
  },
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_TORPS_INITIAL_STATE_ASYNC, path);
  },
});
