// Core
import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// Engine
import { asyncActions as contentAsyncActions } from '../../../../../../engine/core/content/saga/asyncActions';
import { asyncActions } from '../../../../../../engine/core/contentProduct/saga/asyncActions';
import { accessList } from '../../../../../../engine/config/access';
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/contentProduct/actions';
// Hooks
import { useAccessList } from '../../../../../hooks/useAccessList';
// Data
import { requestDelay } from '../../../../../../engine/config/globalConfig';
// Helpers
import { convertSortingObjectToUrlParams } from '../../../../../../_helpers/convertDataToUrlParams';
import { useCallbackAfterPostUser } from '../../../../../hooks/useCallbackAfterPostUser';
import { usePrevious } from '../../../../../hooks/usePrevious';
import { useQueryParams } from '../../../../../hooks/useQueryParams';

export function useContentProductList(id, refresh) {
  const currentPage = useSelector(selectors.contentProduct.currentPage);
  const limit = useSelector(selectors.contentProduct.pageSize);
  const sendQuery = useSelector(selectors.contentProduct.sendQuery);
  const filters = useSelector(selectors.contentProduct.filters);
  const sorting = useSelector(selectors.contentProduct.sorting);
  const reload = useSelector(selectors.contentProduct.reload);
  const path = useSelector(selectors.router.pathname);
  const dispatch = useDispatch();
  const hasAccessList = useAccessList(accessList.productList);
  const offset = limit * currentPage;
  const filtersStringify = JSON.stringify(filters.toJS());
  const sortingStringify = JSON.stringify(sorting.toJS());
  const { categoryId } = useParams();
  const attributeSetId = useQueryParams('attributeSetId');

  const orderBy = useMemo(() => (
    convertSortingObjectToUrlParams(JSON.parse(sortingStringify))
  ), [sortingStringify]);

  const paramsByFilter = useMemo(() => {
    const params = {};

    return JSON.parse(filtersStringify).reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, params);
  }, [filtersStringify]);

  const getListAsync = useCallback(() => {
    if (hasAccessList && (
      sendQuery
      || refresh
    )) {
      const params = {
        ...paramsByFilter,
        categoryId,
        attributeSetId: attributeSetId ? Number(attributeSetId) : undefined,
        orderBy,
        limit,
        offset,
      };
      dispatch(asyncActions.getListAsync(params));
    }
  }, [
    hasAccessList, sendQuery, paramsByFilter, refresh,
    offset, orderBy, limit, dispatch, categoryId,
  ]);

  useCallbackAfterPostUser(() => {
    dispatch(actions.setSendQuery(true));
    getListAsync();
  }, []);

  const getInitialStateAsync = useCallback(() => {
    dispatch(asyncActions.getInitialStateAsync(path));
  }, [dispatch, path]);

  const timeout = requestDelay;
  const timeoutId = useRef(0);
  const getInitialState = useRef(true);
  const prevSendQuery = usePrevious(sendQuery);
  const prevCategoryId = usePrevious(categoryId);

  useEffect(() => {
    if (
      Boolean(sendQuery) && (
        Boolean(prevSendQuery) === false
        || (categoryId !== prevCategoryId)
      ) && !attributeSetId
    ) {
      const params = {
        context: 'grid',
        ...categoryId && { categoryId },
      };
      dispatch(contentAsyncActions.getProductsSettingsAsync(params));
    }
  }, [dispatch, categoryId, sendQuery, prevSendQuery]);

  useEffect(() => {
    if (attributeSetId) {
      dispatch(contentAsyncActions.getProductsSettingsAsync({ attributeSetId, context: 'grid' }));
    }
  }, [attributeSetId]);

  useEffect(() => {
    if (getInitialState.current && !refresh) {
      getInitialStateAsync();
      getInitialState.current = false;
    }
    if (reload || refresh || sendQuery) {
      timeoutId.current = setTimeout(() => {
        getListAsync();
      }, timeout);
    }
    return () => clearTimeout(timeoutId.current);
  }, [getListAsync,
    getInitialStateAsync, sendQuery,
    reload, refresh, timeout]);
}
