// Core
import React, {
  memo, useEffect, useState,
} from 'react';
import { change, Field } from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StatusIcon from '../../StatusIcon';
import CheckBoxForm from '../../../../../../../components/_Form/Checkbox/CheckBoxForm';
import FormSelect from '../../../../../../../components/_Form/Selects/FormSelect/FormSelect';
import Select from '../../../../../../../components/_Form/Selects/Select/Select';
import UpdateFromMagentoButton from '../../../../../Content/ContentProducts/components/ContentProductsSidebar/UpdateFromMagentoButton';
import UpdateFeaturesFromMagentoButton from '../../../../../Content/ContentProducts/components/ContentProductsSidebar/UpdateFeaturesFromMagentoButton';
// Helpers
import { FEATURES_TYPES } from './RowDetailAttributes';
import { formName } from '../ProductFeedCategoriesAttributesTable';
import { COLOR } from '../../../../../../../containers/App/AppStyles';
import { useStyles } from '../../../../../../../hooks/useStyles';
// Engine
import { selectors } from '../../../../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../../../../engine/core/products/productFeedCategoriesAttributes/saga/asyncActions';

const RenderFeature = (props) => {
  const {
    handleChange, canEdit, row, column, options,
  } = props;
  const isItemByHashIdItem = useSelector(selectors.feeds.isItemByHashIdItem);
  const canEditFeatures = isItemByHashIdItem.get('canEditFeatures');
  const loadingRowIds = useSelector(selectors.productFeedCategoriesAttributes.categoriesLoadingRowIds);
  const isLoading = loadingRowIds.toJS().includes(row.id);
  const parsePending = useSelector(selectors.feedsProducts.parsePending);
  const isDisabled =
    !canEdit
    || isLoading
    || parsePending
    || canEditFeatures === false
    || row.ignored
    || row.disabled;

  const requiredLkProps = row?.feature && row?.isRequiredLk ? {
    label: ' ',
    required: row.isRequiredLk,
  } : {};

  return (
    <>
      {isLoading && (
        <CircularProgress
          sx={{
            position: 'absolute',
            left: 'calc(50% - 10px)',
            top: 'calc(50% - 10px)',
            zIndex: 1,
          }}
          color="secondary"
          size={20}
        />
      )}
      <Field
        component={Select}
        variant="outlined"
        options={options}
        hasSearch
        closeMenuOnScroll
        handleChange={handleChange}
        disabled={isDisabled}
        name={`${row.id}_${column.name}`}
        {...requiredLkProps}
      />
    </>
  );
};

const RenderFeatureValue = (props) => {
  const {
    handleChange, canEdit, row, column, options, type,
  } = props;
  const isItemByHashIdItem = useSelector(selectors.feeds.isItemByHashIdItem);
  const canEditFeatures = isItemByHashIdItem.get('canEditFeatures');
  const parsePending = useSelector(selectors.feedsProducts.parsePending);
  const [value, setValue] = useState(row.featureValues);
  const [isLoading, setIsLoading] = useState(false);
  const isDisabled =
    !canEdit
    || isLoading
    || parsePending
    || canEditFeatures === false
    || row.disabled;

  const sendValueSelect = (event) => {
    handleChange(event, setIsLoading);
  };
  const sendValueMultiSelect = () => {
    if (JSON.stringify(value) !== JSON.stringify(row.featureValues)) {
      handleChange(value, setIsLoading);
    }
  };

  return (
    <>
      {isLoading && (
        <CircularProgress
          sx={{
            position: 'absolute',
            left: 'calc(50% - 10px)',
            top: 'calc(50% - 10px)',
            zIndex: 1,
          }}
          color="secondary"
          size={20}
        />
      )}
      {type === FEATURES_TYPES.SELECT && (
        <Field
          component={FormSelect}
          options={options}
          fullWidth
          multiline
          closeMenuOnScroll
          variant="outlined"
          handleChange={sendValueSelect}
          disabled={isDisabled}
          name={`${row.id}_${column.name}`}
          formSelectWidth="100%"
        />
      )}
      {type === FEATURES_TYPES.MULTI_SELECT && (
        <Field
          component={Select}
          variant="outlined"
          options={options}
          isMulti
          closeMenuOnSelect={false}
          hasSearch
          closeMenuOnScroll
          disabled={isDisabled}
          onMenuClose={sendValueMultiSelect}
          name={`${row.id}_${column.name}`}
          onChange={setValue}
          hideSelectedOptions={false}
        />
      )}
    </>
  );
};

const RenderIgnore = (props) => {
  const {
    handleChangeIgnore, canEdit, row,
  } = props;
  const { t } = useTranslation();
  const loadingRowIds = useSelector(selectors.productFeedCategoriesAttributes.categoriesLoadingRowIds);
  const isLoading = loadingRowIds.toJS().includes(row.id);
  const isItemByHashIdItem = useSelector(selectors.feeds.isItemByHashIdItem);
  const canEditFeatures = isItemByHashIdItem.get('canEditFeatures');

  const isDisabled =
    row.canIgnore === false
    || canEdit === false
    || isLoading
    || canEditFeatures === false
    || row.disabled
    || (row.isRequiredLk && row?.feature);
  return (
    <>
      {isLoading && (
      <CircularProgress
        sx={{
          position: 'absolute',
          left: 'calc(50% - 10px)',
          top: 'calc(50% - 10px)',
          zIndex: 1,
        }}
        color="secondary"
        size={20}
      />
      )}
      <Field
        disabled={isDisabled}
        handleChange={handleChangeIgnore}
        component={CheckBoxForm}
        label={t('Игнорировать')}
        name={`${row.id}_ignored`}
      />
    </>
  );
};

const Components = (props) => {
  const {
    column, value, row,
    tableRow, type, hashId, id,
  } = props;

  const isItemByHashIdItem = useSelector(selectors.feeds.isItemByHashIdItem);
  const canParse = isItemByHashIdItem.get('canParse');
  const autoConnectPending = useSelector(selectors.productFeedCategoriesAttributes.autoConnectPending);
  const inProcessAutoConnect = autoConnectPending || !canParse;
  const dispatch = useDispatch();
  const getFormValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const normalizeFormValue = getFormValues.toJS();
  const currentFormFieldValue = normalizeFormValue[`${row.id}_${column.name}`];
  const canEdit = useSelector(selectors.feeds.isItemByHashIdItem).get('canEdit');
  const stepsData = useSelector(selectors.feeds.isItemByHashIdItem).get('stepsData');
  const categoriesItem = useSelector(selectors.productFeedCategoriesAttributes.categoriesItems);
  const attributesItems = useSelector(selectors.productFeedCategoriesAttributes.attributesItems);
  const productFeedAttributeStatuses = stepsData?.[3].productFeedAttributeStatuses;
  const getCurrentDesc = statusId => productFeedAttributeStatuses.filter(item => item.value === statusId)[0]?.desc;
  const handleChangeIgnore = (event) => {
    dispatch(asyncActions.putCategoriesByHashId({
      position: tableRow.rowId.split('_')[0],
      itemHashId: hashId,
      hashId: row.id,
      feature: row.feature?.value,
      ignored: Boolean(event.target.checked),
    }));
  };
  const handleChangeFeature = (inputValue) => {
    dispatch(asyncActions.putCategoriesByHashId({
      position: tableRow.rowId.split('_')[0],
      itemHashId: hashId,
      hashId: row.id,
      feature: inputValue?.value,
      ignored: Boolean(row.ignored),
    }));
  };
  const handleChangeFeatureValues = (currentValue, setLoading) => {
    const featureValues = Array.isArray(currentValue)
      ? currentValue.map(item => item.value).filter(Boolean)
      : [currentValue];

    dispatch(asyncActions.putAttributesByHashId({
      position: tableRow.rowId.split('_')[0],
      itemHashId: id,
      hashId: row.id,
      featureValues,
      setLoading,
    }));
  };

  useEffect(() => {
    if (value) {
      if (column.name === 'ignored' && currentFormFieldValue === undefined) {
        dispatch(change(formName, `${row.id}_ignored`, `${row.ignored}`));
      }
      if (column.name === 'feature' && currentFormFieldValue === undefined) {
        dispatch(change(formName, `${row.id}_feature`, row.feature));
      }
      if (column.name === 'featureValues' && currentFormFieldValue === undefined) {
        const featureValues = type === FEATURES_TYPES.SELECT
          ? row.featureValues[0]
          : row.featureValues;
        dispatch(change(formName, `${row.id}_featureValues`, featureValues));
      }
    }
  }, [dispatch, value]);

  switch (column.name) {
    case 'ignored': {
      return (
        <RenderIgnore
          handleChangeIgnore={handleChangeIgnore}
          canEdit={canEdit}
          row={row}
        />
      );
    }
    case 'feature': {
      return (
        <RenderFeature
          options={categoriesItem.get(hashId)?.availableFeatures || []}
          handleChange={handleChangeFeature}
          canEdit={canEdit}
          row={row}
          column={column}
        />
      );
    }
    case 'featureValues': {
      return (
        <RenderFeatureValue
          options={attributesItems.get(id)?.availableFeatureValues || []}
          handleChange={handleChangeFeatureValues}
          canEdit={canEdit}
          row={row}
          type={type}
          column={column}
        />
      );
    }
    case 'status':
    case 'attributeStatus': {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StatusIcon iconName={value.icon} />
          <Box sx={{ ml: 1 }}>{value.label}</Box>
          {getCurrentDesc(value.value) && (
            <Tooltip title={getCurrentDesc(value.value)} arrow>
              <InfoOutlinedIcon sx={{
                cursor: 'pointer',
                fill: COLOR.black.default,
                marginLeft: 'auto',
                fontSize: '18px',
              }}
              />
            </Tooltip>
          )}
        </Box>
      );
    }
    case 'productFeedCategory': {
      return (
        <>
          <Box component="span" sx={{ mr: 1 }}>{value?.label}</Box>
          {row.category?.value && (
            <UpdateFromMagentoButton
              disabled={inProcessAutoConnect}
              categoryId={row.category.value}
              type="small"
            />
          )}
        </>
      );
    }
    case 'attribute': {
      return (
        <>
          <Box component="span" sx={{ mr: 1 }}>{value?.label}</Box>
          {row.feature?.canUpdate && (
            <UpdateFeaturesFromMagentoButton
              disabled={inProcessAutoConnect}
              featureId={row.feature?.value}
              small
            />
          )}
        </>
      );
    }
    case 'feedAttributesQuantity': {
      return null;
    }
    default: {
      return typeof value !== 'object'
        ? value || null
        : value?.label || null;
    }
  }
};

function TableCell(props) {
  const {
    column, row, tableRow, tableColumn,
    value, hashId, id, type, ...restProps
  } = props;

  const classes = useStyles(() => ({
    wrapper: { overflow: 'initial !important' },
  }));

  return (
    <VirtualTable.Cell
      {...restProps}
      column={column}
      row={row}
      tableRow={tableRow}
      tableColumn={tableColumn}
      value={value}
      className={classes.wrapper}
    >
      <Components
        column={column}
        value={value}
        row={row}
        tableRow={tableRow}
        type={type}
        hashId={hashId}
        id={id}
      />
    </VirtualTable.Cell>
  );
}

export default memo(TableCell);
