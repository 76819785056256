// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setList(data) {
    return getActionCreator(types.SET_REFUND_REQUESTS_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_REFUND_REQUESTS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_REFUND_REQUESTS_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_REFUND_REQUESTS_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_REFUND_REQUESTS_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_REFUND_REQUESTS_INITIAL_STATE, initialState);
  },
  removeFilters() {
    return getActionCreator(types.REMOVE_REFUND_REQUESTS_FILTERS, null);
  },
});
