// Core
import React, { useState, memo } from 'react';
import * as PropTypes from 'prop-types';
import { List, isImmutable } from 'immutable';
import { useTranslation } from 'react-i18next';
import Select from '../../../../../../../components/_Form/Selects/Select/Select';
import CustomOption from './CustomOption';

const CategorySelect = (props) => {
  const {
    options, disabled, input, handleChange: handleConfirm,
    isLoading, selectedCategory, optionsLanguage,
  } = props;

  const { t } = useTranslation();

  const [value, setValue] = useState(selectedCategory ? [selectedCategory] : []);

  const handleChange = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      setValue([newValue]);
    }
  };

  const onConfirm = () => {
    handleConfirm(value);
  };


  const inputValue = input.value && isImmutable(input.value) ? input.value.toJS() : (input.value || []);
  const normalizeInputValue = Array.isArray(inputValue) ? inputValue : [inputValue];

  return (
    <Select
      {...props}
      options={options}
      disabled={disabled}
      onConfirm={onConfirm}
      hasConfirmButton
      filters={{ value: normalizeInputValue }}
      isLoading={isLoading}
      hideSelectedOptions={false}
      searchErrorLabel={t('Неверно введена категория')}
      handleChange={handleChange}
      hasSearch={false}
      isSearchable
      optionsLanguage={optionsLanguage}
      variant="outlined"
      isCategorySelect
      input={input}
      CustomOption={CustomOption}
    />
  );
};

CategorySelect.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.instanceOf(List),
    PropTypes.array,
  ]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  selectedCategory: PropTypes.object,
  optionsLanguage: PropTypes.string,
};

CategorySelect.defaultProps = {
  options: [],
};

export default memo(CategorySelect);
