import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import HistoryIcon from '@mui/icons-material/History';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import RefreshIcon from '@mui/icons-material/Refresh';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tooltip from '@mui/material/Tooltip';
import { IconButtonStyles } from '../../components/_Table/TableCell/Styles';
import { api } from '../../../engine/config/api';
import Loading from '../../components/Loading/Loading';
import { BUTTON_SIZE, COLOR } from '../../containers/App/AppStyles';
import TabsContainer from '../../containers/Tabs/TabsContainer';
import TabsNav from '../../containers/Tabs/TabsNav';
import TabsNavMenu from '../../containers/Tabs/TabsNavMenu';
import TabItemMenu from '../../components/Tabs/TabMenuItem/TabItemMenu';
import ContactsForm from './components/contacts/ContactsForm';
import TrusteesForm from './components/contacts/TrusteesForm';
import TabsPanel from '../../containers/Tabs/TabsPanel';
import { findDifferences, formatIban, formatPhoneNumber } from './_helpers/formHelper';
import { useUserRole } from '../../hooks/useUserRole';
import { userRoles } from '../../../engine/config/userRoles';
import { setErrorMessage, setSuccessMessage } from '../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { useStyles } from '../../hooks/useStyles';
import { FormSelectStyle } from '../../components/_Form/Selects/FormSelect/FormSelectStyle';
import {
  cancellationReasons, getStatusColor, isPartnerCanCancelRequest, serviceRequestsStatuses,
} from '../../../engine/config/serviceRequestsStatuses';
import { ChangeHistoryTable } from './components/ChangeHistoryTable';
import { useUserSomeRole } from '../../hooks/useUserSomeRole';
import { ConfirmCancelRequestModal } from './components/ConfirmCancelRequestModal';
import FormTextFieldInput from './components/input/FormTextFieldInput';
import { validators } from '../../../_helpers/validationsForm';
import SplitButton from './components/button/SplitButton';
import DocumentItem from './components/DocumentItem';
import { selectors } from '../../../engine/config/selectors';
import { ConfirmChangePowerOfAttorney } from './components/ConfirmChangePowerOfAttorney';

const selectSxStyle = {
  '& fieldset': {
    borderColor: 'rgba(111,126,140,0.24) !important',
    borderWidth: '1px !important',
  },
  '&.MuiInputBase-root': {
    fontSize: '14px !important',
  },
};
const selectSxInputStyle = {
  fontSize: '14px',
  '&.Mui-focused': {
    color: COLOR.black[38],
  },
};

const createFileFromBase64 = (fileContent, fileName, fileType) => {
  const byteCharacters = atob(fileContent);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: fileType });
  return new File([blob], fileName, { type: fileType });
};

const fieldsToCompare = ['fullName', 'passportType', 'passportNumber', 'phoneNumber', 'ipn'];
const fieldsToCompareWithoutPhone = ['fullName', 'passportType', 'passportNumber', 'ipn'];

export default function AlloExpressEditPage() {
  const { hashId } = useParams();

  const navigate = useNavigate();
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [isSendingData, setIsSendingData] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [partnerServiceRequest, setPartnerServiceRequest] = useState(null);
  const [statusColor, setStatusColor] = useState(COLOR.grey.main);
  const [selectedTab, setSelectedTab] = useState('editRequests');
  const [contactForm, setContactForm] = useState(null);
  const [contactFormIsValid, setContactFormIsValid] = useState(true);
  const [partnerInfoFieldsValues, setPartnerInfoFieldsValues] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const isAdminRole = useUserRole(userRoles.admin);
  const [powerOfAttorneyDateFromRequest, setPowerOfAttorneyDateFromRequest] = useState(null);
  const [confirmCancelRequestModalOpen, setConfirmCancelRequestModalOpen] = useState(false);
  const [confirmChangePoaDate, setConfirmChangePoaDate] = useState(false);
  const [isNeedToShowConfirmModal, setIsNeedToShowConfirmModal] = useState(true);
  const isPartnerRoles = useUserSomeRole([
    userRoles.partnerActive,
  ]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles(FormSelectStyle);
  const [fetchHistory, setFetchHistory] = useState(false);
  const [cancellationReason, setCancellationReason] = useState('');
  const [cancellationComment, setCancellationComment] = useState('');
  const [isContractForSenseBankExists, setIsContractForSenseBankExists] = useState(false);
  const [fileFromServer, setFileFromServer] = useState({});
  const [partnerServiceRequestContracts, setPartnerServiceRequestContracts] = useState([]);
  const [partnerServiceRequestPoas, setPartnerServiceRequestPoas] = useState([]);
  const [partnerServiceRequestInfoLetters, setPartnerServiceRequestInfoLetters] = useState([]);
  const [openTooltipId, setOpenTooltipId] = useState(null);

  const handleCancellationChange = (event) => {
    setCancellationReason(event.target.value);
    if (event.target.value !== cancellationReasons.CANCEL_REASON_OTHER) {
      setCancellationComment('');
    }
  };

  const fetchHistoryHandler = () => {
    setFetchHistory(prevState => !prevState);
  };

  const trusteesFormInitialState = {
    trustees: [],
    doNotCreateTrustees: false,
    powerOfAttorneyDate: null,
    isFormValid: false,
  };
  const [trusteesFormData, setTrusteesFormData] = useState(trusteesFormInitialState);

  useEffect(() => {
    if (partnerServiceRequest) {
      const file = createFileFromBase64(partnerServiceRequest.fileContent, partnerServiceRequest.fileName, partnerServiceRequest.fileType);
      // Copy for check diff
      setFileFromServer(
        createFileFromBase64(partnerServiceRequest.fileContent, partnerServiceRequest.fileName, partnerServiceRequest.fileType),
      );
      setSelectedStatus(partnerServiceRequest.statusValue);
      setCancellationReason(partnerServiceRequest.cancelReason);
      setCancellationComment(partnerServiceRequest.cancelComment);
      setIsContractForSenseBankExists(partnerServiceRequest.isContractForSenseBankExists);

      if (partnerServiceRequest.partnerServiceRequestDocuments) {
        setPartnerServiceRequestContracts(
          partnerServiceRequest.partnerServiceRequestDocuments.filter(item => item.type === 'CONTRACT'),
        );
        setPartnerServiceRequestInfoLetters(
          partnerServiceRequest.partnerServiceRequestDocuments.filter(item => item.type === 'INFO_LETTER'),
        );
        setPartnerServiceRequestPoas(
          partnerServiceRequest.partnerServiceRequestDocuments.filter(item => item.type === 'POWER_OF_ATTORNEY'),
        );
      }

      setContactForm({
        isUseCurrentAccount: partnerServiceRequest.isUseCurrentAccount,
        phone: formatPhoneNumber(partnerServiceRequest.phone),
        iban: formatIban(partnerServiceRequest.iban),
        edrpou: partnerServiceRequest.edrpou,
        bankMfo: partnerServiceRequest.bankMfo,
        bankName: partnerServiceRequest.bankName,
        certificate: {
          size: file.size,
          name: file.name,
          type: file.type,
          extension: file.name.split('.').pop(),
          fileItem: file,
        },
      });
    }
  }, [partnerServiceRequest]);

  const fetchData = async () => {
    try {
      setPageIsLoading(true);
      const serviceRequestResponse = await api.partnerServiceRequests.getPartnerServiceRequest(hashId);
      let trustees = [];
      if (serviceRequestResponse.data.trustees && serviceRequestResponse.data.trustees.length > 0) {
        trustees = serviceRequestResponse.data.trustees.map(trustee => ({ ...trustee, errors: {} }));
      }
      setPowerOfAttorneyDateFromRequest(moment(serviceRequestResponse.data.powerOfAttorneyDate));
      setTrusteesFormData({
        powerOfAttorneyDate: moment(serviceRequestResponse.data.powerOfAttorneyDate),
        doNotCreateTrustees: !(serviceRequestResponse.data.trustees && serviceRequestResponse.data.trustees.length > 0),
        trustees,
      });
      setPartnerServiceRequest(serviceRequestResponse.data);
      const partnerResponse = await api.partner.getDataByHashId(hashId);
      const partner = partnerResponse.data;
      const { infoFieldValues } = partner;

      setPartnerInfoFieldsValues({
        isUseCurrentAccount: true,
        email: infoFieldValues.contactEmail.current,
        phone: formatPhoneNumber(infoFieldValues.directorPhone.current),
        iban: formatIban(infoFieldValues.iban.current),
        edrpou: infoFieldValues.bankClassifierCode.current,
        bankMfo: infoFieldValues.interBranchTurnover.current,
        bankName: infoFieldValues.bankName.current,
      });
    } finally {
      setPageIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (partnerServiceRequest && contactForm && partnerServiceRequest && partnerInfoFieldsValues) {
      const isPowerOfAttorneyTheSame = powerOfAttorneyDateFromRequest.isSame(trusteesFormData.powerOfAttorneyDate);
      const diff = findDifferences(partnerServiceRequest.trustees, trusteesFormData.trustees, fieldsToCompare);
      const diffWithoutPhone = findDifferences(partnerServiceRequest.trustees, trusteesFormData.trustees, fieldsToCompareWithoutPhone);

      const isFieldThatRequireModalChanged =
        partnerServiceRequest.bankMfo !== contactForm.bankMfo
        || partnerServiceRequest.bankName !== contactForm.bankName
        || partnerServiceRequest.edrpou !== contactForm.edrpou
        || partnerServiceRequest.trustees && partnerServiceRequest.trustees.length > 0 && trusteesFormData.doNotCreateTrustees
        || formatIban(partnerServiceRequest.iban) !== contactForm.iban
        || !contactForm.certificate
        || (contactForm.certificate && (fileFromServer.name !== contactForm.certificate.name))
        || (contactForm.certificate && (fileFromServer.size !== contactForm.certificate.size))
        || !trusteesFormData.doNotCreateTrustees && diffWithoutPhone.length > 0
        || !trusteesFormData.doNotCreateTrustees && !isPowerOfAttorneyTheSame;

      if (isFieldThatRequireModalChanged) {
        setIsNeedToShowConfirmModal(true);
      } else {
        setIsNeedToShowConfirmModal(false);
      }

      const isFieldWithDoNotRequireModal = partnerServiceRequest.statusValue !== selectedStatus
        || isContractForSenseBankExists !== partnerServiceRequest.isContractForSenseBankExists
        || !trusteesFormData.doNotCreateTrustees && diff.length > 0
        || partnerServiceRequest.isUseCurrentAccount !== contactForm.isUseCurrentAccount
        || formatPhoneNumber(partnerServiceRequest.phone) !== contactForm.phone
        || (partnerServiceRequest.statusValue === serviceRequestsStatuses.CANCELED
        && cancellationReason !== partnerServiceRequest.cancelReason)
        || (partnerServiceRequest.statusValue === serviceRequestsStatuses.CANCELED
        && cancellationComment !== partnerServiceRequest.cancelComment);

      if (isFieldThatRequireModalChanged || isFieldWithDoNotRequireModal) {
        setIsFormChanged(true);
      } else {
        setIsFormChanged(false);
      }
    }
  }, [
    trusteesFormData,
    contactForm,
    partnerServiceRequest,
    partnerInfoFieldsValues,
    selectedStatus,
    cancellationReason,
    cancellationComment,
    isContractForSenseBankExists,
  ]);

  useEffect(() => {
    if (partnerServiceRequest) {
      setStatusColor(getStatusColor(partnerServiceRequest.statusValue));
    }
  }, [partnerServiceRequest]);

  const isContractAlreadySigned = () => {
    if (!partnerServiceRequest || !partnerServiceRequest.partnerServiceRequestDocuments) {
      return false;
    }

    const validStatuses = ['NEW', 'SENT', 'ACTIVE', 'SIGNED_BY_PARTNER'];
    return partnerServiceRequest.partnerServiceRequestDocuments.some(
      item => item.type === 'CONTRACT' && validStatuses.includes(item.status),
    );
  };

  const disabledSendTrustees = trusteesFormData.doNotCreateTrustees || trusteesFormData.trustees.length === 0;
  const handleSend = async (showConfirm = true) => {
    if (isNeedToShowConfirmModal && showConfirm) {
      setConfirmChangePoaDate(true);
      return;
    }
    setIsSendingData(true);
    const requestFormData = new FormData();
    Object.keys(contactForm).forEach((key) => {
      if (key !== 'certificate') {
        requestFormData.append(key, contactForm[key]);
      } else {
        requestFormData.append('certificate', contactForm.certificate.fileItem.file);
      }
    });
    requestFormData.append('serviceName', 'ALLO_EXPRESS');
    requestFormData.append('doNotCreateTrustees', trusteesFormData.doNotCreateTrustees);
    if (!trusteesFormData.doNotCreateTrustees) {
      requestFormData.append('powerOfAttorneyDate', trusteesFormData.powerOfAttorneyDate.format('YYYY-MM-DD'));
      trusteesFormData.trustees.forEach((trustee, index) => {
        Object.keys(trustee).forEach((key) => {
          requestFormData.append(`trustees[${index}][${key}]`, trustee[key]);
        });
      });
    }

    requestFormData.append('isContractForSenseBankExists', isContractForSenseBankExists);

    if (partnerServiceRequest.statusValue !== selectedStatus) {
      requestFormData.append('selectedStatus', selectedStatus);
    }

    if (partnerServiceRequest.cancelReason !== cancellationReason) {
      requestFormData.append('cancelReason', cancellationReason);
    }

    if (partnerServiceRequest.cancelComment !== cancellationComment) {
      requestFormData.append('cancelComment', cancellationComment);
    }

    try {
      const response = await api.partnerServiceRequests.updateServiceIntegrationRequest(hashId, partnerServiceRequest.id, requestFormData);
      if (response.status === 200) {
        dispatch(setSuccessMessage(response.data.status, t('Успех')));
      }
      if (response.status >= 400) {
        dispatch(setErrorMessage(response.data.status, t('Ошибка')));
      }
      if (isPartnerRoles) {
        navigate(-1);
      } else {
        fetchData();
      }
    } catch (error) {
      dispatch(setErrorMessage(error, t('Ошибка')));
    } finally {
      setIsSendingData(false);
    }
  };

  const sendDocument = async (documentType) => {
    if (isSendingData) {
      return;
    }
    try {
      setIsSendingData(true);
      if (documentType === 'CONTRACT' && isContractAlreadySigned()) {
        return;
      }
      const documentTypes = documentType === 'ALL' ? ['INFO_LETTER'] : [documentType];
      if (documentType === 'ALL' && !disabledSendTrustees) {
        documentTypes.push('POWER_OF_ATTORNEY');
      }

      if (documentType === 'ALL' && !isContractAlreadySigned()) {
        documentTypes.push('CONTRACT');
      }

      const response = await api.partnerServiceRequests
        .sendDocumentToSed(hashId, partnerServiceRequest.id, documentTypes);

      if (response.status === 200) {
        dispatch(setSuccessMessage(response.data.status, t('Успех')));
      } else if (response.status === 400) {
        dispatch(setErrorMessage(response.data.status, t('Ошибка')));
      }
    } catch (error) {
      dispatch(setErrorMessage('Системна помилка', t('Ошибка')));
    } finally {
      fetchData();
      setIsSendingData(false);
    }
  };
  const config = useSelector(selectors.user.configurations);
  const alloExpressData = config.get('ALLO_EXPRESS_ALLOW_TO_USE');
  const isAllowForTheUser = (alloExpressData && alloExpressData.has('allow_to_send_documents'))
    ? alloExpressData.get('allow_to_send_documents') : false;

  const isPartnerAllowToSeeDocumentStatus = (alloExpressData && alloExpressData.has('allow_to_see_documents_statuses'))
    ? alloExpressData.get('allow_to_see_documents_statuses') : false;

  const onCancelRequest = (reason, comment) => {
    if (hashId) {
      setIsSendingData(true);
      api.partnerServiceRequests.changeServiceRequestStatus(hashId, partnerServiceRequest.id, {
        reason,
        comment,
        status: serviceRequestsStatuses.CANCELED,
      }).then((response) => {
        dispatch(setSuccessMessage(response.data.status, t('Успех')));
        if (isPartnerRoles) {
          navigate(-1);
        } else {
          fetchData();
        }
      }).catch(() => {
        dispatch(setErrorMessage('error', t('Ошибка')));
      }).finally(() => {
        setIsSendingData(false);
      });
    }
  };

  const titlesStyle = {
    fontSize: '12px',
    color: 'rgba(48, 48, 48, 0.87)',
  };

  const fieldsStyle = {
    fontSize: '14px',
    textAlign: 'end',
  };

  const boxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    borderTopLeftRadius: '6px',
  };

  const handleChangeTab = (event, value) => {
    setSelectedTab(value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    if (event.target.value !== serviceRequestsStatuses.CANCELED) {
      setCancellationReason('');
      setCancellationComment('');
    } else if (event.target.value === partnerServiceRequest.statusValue && partnerServiceRequest.statusValue === serviceRequestsStatuses.CANCELED) {
      setCancellationReason(partnerServiceRequest.cancelReason);
      setCancellationComment(partnerServiceRequest.cancelComment);
    }
  };

  const displayTab = currentTab => (selectedTab === currentTab) ? 'flex' : 'none';

  if (isSendingData || pageIsLoading || !partnerServiceRequest || !partnerInfoFieldsValues || !trusteesFormData) {
    return (
      <>
        <Box sx={{
          width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
        }}
        >
          <Loading isLoading={isSendingData || pageIsLoading} />
        </Box>
      </>
    );
  }

  const contractChangeHandler = (e) => {
    const { checked } = e.target;
    setIsContractForSenseBankExists(!!checked);
  };

  const options = [
    {
      title: 'Відправити договір',
      isDisabled: () => isContractAlreadySigned() || isSendingData,
      action: () => sendDocument('CONTRACT'),
      tooltipText: 'У заявки є Активний існуючий договір',
    },
    {
      title: 'Відправити довіреність',
      isDisabled: () => isSendingData || disabledSendTrustees,
      action: () => sendDocument('POWER_OF_ATTORNEY'),
      tooltipText: 'У заявки відсутні довірені особи',
    },
    {
      title: 'Відправити інформаційний лист',
      isDisabled: () => isSendingData,
      action: () => sendDocument('INFO_LETTER'),
      tooltipText: '',
    },
  ];

  const selectedStatusValid = () => {
    if (selectedStatus === serviceRequestsStatuses.CANCELED) {
      if (cancellationReason === cancellationReasons.CANCEL_REASON_CONDITION) {
        return true;
      }
      if (cancellationReason === cancellationReasons.CANCEL_REASON_LOCATION) {
        return true;
      }
      if (cancellationReason === cancellationReasons.CANCEL_REASON_OTHER && cancellationComment !== '') {
        return true;
      }
      return false;
    }
    return true;
  };

  const isStatusDisabled = partnerServiceRequest.statusValue === serviceRequestsStatuses.DISABLED
    || partnerServiceRequest.statusValue === serviceRequestsStatuses.CANCELED;

  return (
    <>
      <Box>
        <Paper sx={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: 0,
          justifyContent: 'space-between',
          height: '48px',
          top: '50%',
        }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'start', paddingTop: '10px' }}>
            <Link
              onClick={() => {
                navigate(-1);
              }}
            >
              <IconButtonStyles sx={{ mb: '5px', mr: '10px', ml: '10px' }} color="inherit" size="small">
                <KeyboardBackspaceIcon fontSize="medium" />
              </IconButtonStyles>
            </Link>
            <Typography sx={{ mb: '0' }} color={'#303030'} fontWeight={700} fontSize={20} gutterBottom>
              Редагування заявки на послугу АЛЛО Express
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex', justifyContent: 'end', alignItems: 'center', top: '50%',
          }}
          >
            {(isPartnerRoles || isAdminRole) && isPartnerCanCancelRequest(partnerServiceRequest.statusValue) && (
            <Button
              variant="outlined"
              color="primary"
              sx={{ marginRight: '15px' }}
              onClick={() => {
                setConfirmCancelRequestModalOpen(true);
              }}
            >Скасувати заявку
            </Button>
            )}
            {(!(contactFormIsValid && trusteesFormData.isFormValid && selectedStatusValid())) && (
              <>
                <Tooltip
                  title={(
                    <Box>
                      <Typography fontSize={'12px'}>Форма не валідна.</Typography>
                      <Typography fontSize={'12px'}>Переконайтесь, що всі обовязкові поля заповнено вірно.</Typography>
                    </Box>
                )}
                  placement="bottom"
                >
                  <span>
                    <Button
                      disabled={(!(contactFormIsValid && trusteesFormData.isFormValid && selectedStatusValid() && isFormChanged) || isSendingData)}
                      variant="contained"
                      color="primary"
                      sx={{
                        p: BUTTON_SIZE.outlinedMiddle,
                        marginRight: '15px',
                        '&.Mui-disabled': {
                          pointerEvents: 'auto',
                        },
                      }}
                      onClick={handleSend}
                    >Зберегти зміни
                    </Button>
                  </span>
                </Tooltip>
              </>
            )}
            {!(!(contactFormIsValid && trusteesFormData.isFormValid && selectedStatusValid())) && (
            <Button
              disabled={(!(contactFormIsValid && trusteesFormData.isFormValid && selectedStatusValid() && isFormChanged) || isSendingData)}
              variant="contained"
              color="primary"
              sx={{ p: BUTTON_SIZE.outlinedMiddle, marginRight: '15px' }}
              onClick={handleSend}
            >Зберегти зміни
            </Button>
            )}
            {isAdminRole && isAllowForTheUser && (
              <>
                <SplitButton
                  sendAllDocuments={() => {
                    sendDocument('ALL');
                  }}
                  isSending={isSendingData}
                  isFormChanged={isFormChanged}
                  options={options}
                  sendDocument={sendDocument}
                />
              </>
            )}
          </Box>
        </Paper>
      </Box>
      <Grid
        container
        justifyContent="center"
        sx={{
          mt: '10px', pr: '20px', pl: '20px', btlr: '6px !important', height: '92%',
        }}
      >
        <TabsContainer padding={'0px'}>
          <TabsNav>
            <Paper sx={{ p: '20px' }}>
              <Box sx={boxStyle}>
                <Typography sx={titlesStyle}>Статус:</Typography>
                <Box width={'10px'} />
                <Typography sx={fieldsStyle}><Box sx={{ color: statusColor }}>{partnerServiceRequest.statusLabel}</Box></Typography>
              </Box>
              <Box sx={boxStyle}>
                <Typography sx={titlesStyle}>Назва магазина:</Typography>
                <Box width={'10px'} />
                <Typography sx={fieldsStyle}>{partnerServiceRequest.partnerCompanyName}</Typography>
              </Box>
              <Box sx={boxStyle}>
                <Typography sx={titlesStyle}>ID магазина:</Typography>
                <Box width={'10px'} />
                <Typography sx={fieldsStyle}>{partnerServiceRequest.partnerMId}</Typography>
              </Box>
              <Box sx={boxStyle}>
                <Typography sx={titlesStyle}>Назва підприємства:</Typography>
                <Box width={'10px'} />
                <Typography sx={fieldsStyle}>{partnerServiceRequest.enterpriseCompanyName}</Typography>
              </Box>
              <Box sx={boxStyle}>
                <Typography sx={titlesStyle}>Дата створення:</Typography>
                <Box width={'10px'} />
                <Typography sx={fieldsStyle}>{partnerServiceRequest.createdAt}</Typography>
              </Box>
              <Box sx={boxStyle}>
                <Typography sx={titlesStyle}>Дата оновлення:</Typography>
                <Box width={'10px'} />
                <Typography sx={fieldsStyle}>{partnerServiceRequest.updatedAt}</Typography>
              </Box>
            </Paper>
            <TabsNavMenu
              value={selectedTab}
              onChange={handleChangeTab}
            >
              <TabItemMenu value="editRequests" label={'Редагування заявки'} />
              <TabItemMenu value="editStatus" label={'Статус послуги'} />
              {isAdminRole && (<TabItemMenu value={'changeHistory'} label={'Історія змін'} />)}
            </TabsNavMenu>
          </TabsNav>
          <TabsPanel style={{ padding: '0', display: displayTab('editRequests') }} value={'editRequests'} index={'editRequests'} padding={'0px'}>
            <Grid item xs={12} lg={12}>
              {contactForm && partnerServiceRequest && partnerInfoFieldsValues && (
                <>
                  <Box sx={{ mb: '10px' }}>
                    <Typography color={'#303030'} fontWeight={700} fontSize={18}>Редагування заявки</Typography>
                  </Box>
                  <ContactsForm
                    partnerFields={partnerInfoFieldsValues}
                    formData={contactForm}
                    setFormData={setContactForm}
                    setFormValid={setContactFormIsValid}
                    editMode
                    isDisabled={isStatusDisabled}
                    isAdminRole={isAdminRole}
                  />
                  <TrusteesForm
                    editMode
                    isDisabled={isStatusDisabled}
                    requestStatus={partnerServiceRequest.statusValue}
                    formData={trusteesFormData}
                    setTrusteesFormData={setTrusteesFormData}
                  />
                </>
              )}
            </Grid>
          </TabsPanel>
          <TabsPanel style={{ padding: '0', display: displayTab('editStatus') }} value={'editStatus'} index={'editStatus'} padding={'0px'}>
            <Grid item xs={12} lg={12}>
              <Box sx={{ width: 'inherit' }}>
                <Box sx={{ mb: '10px' }}>
                  <Typography color={'#303030'} fontWeight={700} fontSize={18}>Статус послуги</Typography>
                </Box>
                <Paper sx={{
                  p: '10px', pl: '15px', pb: '20px', mb: '10px',
                }}
                >
                  <Box>
                    <Typography color={'#303030'} fontWeight={600} fontSize={14} gutterBottom>
                      Про статус
                    </Typography>
                  </Box>
                  <Divider marginBottom={'10px'} />
                  {isAdminRole ? (
                    <>
                      <Box sx={{ m: '20px' }}>
                        <Grid container>
                          <Grid item xs={12} lg={12}>
                            <FormControlLabel
                              value="end"
                              control={(
                                <Checkbox
                                  checked={isContractForSenseBankExists}
                                  onChange={contractChangeHandler}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              )}
                              label={<Typography fontSize={'14px'} color={'#303030'}>Є договір з Sense банком</Typography>}
                              labelPlacement="end"
                            />
                          </Grid>
                          <DocumentItem
                            documents={partnerServiceRequestContracts}
                            label="Договір"
                            contractName={partnerServiceRequest.contractName}
                            contractCreatedDate={partnerServiceRequest.contractCreatedDate}
                            contractUpdatedDate={partnerServiceRequest.contractUpdatedDate}
                            showOnlyFirst
                            openTooltipId={openTooltipId}
                            setOpenTooltipId={setOpenTooltipId}
                          />
                          <DocumentItem
                            documents={partnerServiceRequestPoas}
                            label="Довіреність"
                            excludeStatuses={['INACTIVE']}
                            showOnlyFirst
                            openTooltipId={openTooltipId}
                            setOpenTooltipId={setOpenTooltipId}
                          />
                          <DocumentItem
                            documents={partnerServiceRequestInfoLetters}
                            label="Інформаційний лист"
                            excludeStatuses={['INACTIVE']}
                            openTooltipId={openTooltipId}
                            setOpenTooltipId={setOpenTooltipId}
                          />
                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                              <Box sx={{ mt: '20px' }}>
                                <FormControl variant={'outlined'} className={classes.wrapperFullWidth}>
                                  <InputLabel sx={selectSxInputStyle}>Статус</InputLabel>
                                  <Select
                                    size="small"
                                    touched
                                    focused
                                    sx={selectSxStyle}
                                    input={<OutlinedInput sx={{ fontSize: '14px' }} label="Статус" />}
                                    onChange={(e) => {
                                      handleStatusChange(e);
                                    }}
                                    fullWidth
                                    label={'Статус'}
                                    placeholder=""
                                    value={selectedStatus}
                                  >
                                    {partnerServiceRequest.statusValue === serviceRequestsStatuses.SIGNING_THE_CONTRACT ? [
                                      <MenuItem key={serviceRequestsStatuses.SIGNING_THE_CONTRACT} style={{ fontSize: '14px' }} value={serviceRequestsStatuses.SIGNING_THE_CONTRACT}>Підписання документів</MenuItem>,
                                      <MenuItem key={serviceRequestsStatuses.CANCELED} style={{ fontSize: '14px' }} value={serviceRequestsStatuses.CANCELED}>Скасовано</MenuItem>,
                                    ] : null}
                                    {partnerServiceRequest.statusValue === serviceRequestsStatuses.AWAITING_APPROVAL ? [
                                      <MenuItem key={serviceRequestsStatuses.AWAITING_APPROVAL} style={{ fontSize: '14px' }} value={serviceRequestsStatuses.AWAITING_APPROVAL}>Очікує розгляду</MenuItem>,
                                      <MenuItem key={serviceRequestsStatuses.CANCELED} style={{ fontSize: '14px' }} value={serviceRequestsStatuses.CANCELED}>Скасовано</MenuItem>,
                                    ] : null}
                                    {partnerServiceRequest.statusValue === serviceRequestsStatuses.CANCELED ? [
                                      <MenuItem key={serviceRequestsStatuses.CANCELED} style={{ fontSize: '14px' }} value={serviceRequestsStatuses.CANCELED}>Скасовано</MenuItem>,
                                      <MenuItem key={serviceRequestsStatuses.AWAITING_APPROVAL} style={{ fontSize: '14px' }} value={serviceRequestsStatuses.AWAITING_APPROVAL}>Очікує розгляду</MenuItem>,
                                    ] : null}
                                    {partnerServiceRequest.statusValue === serviceRequestsStatuses.DISABLED ? [
                                      <MenuItem style={{ fontSize: '14px' }} value={serviceRequestsStatuses.CONNECTED}>Увімкнено</MenuItem>,
                                      <MenuItem style={{ fontSize: '14px' }} value={serviceRequestsStatuses.DISABLED}>Вимкнено</MenuItem>,
                                    ] : null}
                                    {partnerServiceRequest.statusValue === serviceRequestsStatuses.CONNECTED ? [
                                      <MenuItem style={{ fontSize: '14px' }} value={serviceRequestsStatuses.CONNECTED}>Увімкнено</MenuItem>,
                                      <MenuItem style={{ fontSize: '14px' }} value={serviceRequestsStatuses.DISABLED}>Вимкнено</MenuItem>,
                                    ] : null}
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>
                            {selectedStatus === serviceRequestsStatuses.CANCELED && (
                              <>
                                <Grid item xs={12} lg={6} spacing={2}>
                                  <Box sx={{ mt: '20px' }}>
                                    <FormControl
                                      variant={'outlined'}
                                      focused
                                      className={classes.wrapperFullWidth}
                                    >
                                      <InputLabel sx={selectSxInputStyle}>Причина скасування</InputLabel>
                                      <Select
                                        size="small"
                                        sx={selectSxStyle}
                                        touched
                                        focused
                                        input={<OutlinedInput sx={{ fontSize: '14px' }} label="Причина скасування" />}
                                        onChange={handleCancellationChange}
                                        fullWidth
                                        placeholder=""
                                        value={cancellationReason}
                                      >
                                        <MenuItem sx={{ fontSize: '14px' }} value={cancellationReasons.CANCEL_REASON_CONDITION}>
                                          Не влаштовують умови організації перевезень і видачі замовлень
                                        </MenuItem>
                                        <MenuItem sx={{ fontSize: '14px' }} value={cancellationReasons.CANCEL_REASON_LOCATION}>
                                          Немає пунктів прийому/видачі замовлень у моєму місті
                                        </MenuItem>
                                        <MenuItem sx={{ fontSize: '14px' }} value={cancellationReasons.CANCEL_REASON_OTHER}>Інше</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} lg={6} mb={'25px'}>
                                  {cancellationReason === cancellationReasons.CANCEL_REASON_OTHER && (
                                    <>
                                      <Box sx={{ mt: '20px' }}>
                                        <FormTextFieldInput
                                          variant="outlined"
                                          focused
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            setCancellationComment(value.replace(/^\s+/, ''));
                                          }}
                                          fullWidth
                                          inputProps={{ maxLength: 50 }}
                                          value={cancellationComment}
                                          label="Коментар"
                                          validate={validators.maxLength50}
                                        />
                                        <Typography variant="body2" color={COLOR.black[38]} align="right">
                                          {(cancellationComment && cancellationComment.length ? cancellationComment.length : '0')}/50 символів
                                        </Typography>
                                      </Box>
                                    </>
                                  )}
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        width={'200px'}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box sx={{ pt: '10px' }}>
                          <Typography sx={titlesStyle}>Статус:</Typography>
                        </Box>
                        <Box width={'10px'} />
                        <Typography sx={{
                          fontSize: '14px',
                          textAlign: 'end',
                          padding: '10px',
                        }}
                        >
                          <Box sx={{ ...titlesStyle, color: statusColor }}>{partnerServiceRequest.statusLabel}</Box>
                        </Typography>
                      </Box>
                      {isPartnerAllowToSeeDocumentStatus && (
                      <Grid container>
                        <Grid item xs={12} lg={6}>
                          <DocumentItem
                            documents={partnerServiceRequestContracts}
                            label="Договір"
                            contractName={partnerServiceRequest.contractName}
                            contractCreatedDate={partnerServiceRequest.contractCreatedDate}
                            contractUpdatedDate={partnerServiceRequest.contractUpdatedDate}
                            showOnlyFirst
                            isPartner={isPartnerRoles}
                            openTooltipId={openTooltipId}
                            setOpenTooltipId={setOpenTooltipId}
                          />
                          <DocumentItem
                            documents={partnerServiceRequestPoas}
                            label="Довіреність"
                            showOnlyFirst
                            excludeStatuses={['INACTIVE']}
                            isPartner={isPartnerRoles}
                            setOpenTooltipId={setOpenTooltipId}
                          />
                          <DocumentItem
                            documents={partnerServiceRequestInfoLetters}
                            label="Інформаційний лист"
                            excludeStatuses={['INACTIVE']}
                            isPartner={isPartnerRoles}
                            setOpenTooltipId={setOpenTooltipId}
                          />
                        </Grid>
                      </Grid>
                      )}
                    </>
                  )}
                </Paper>
              </Box>
            </Grid>
          </TabsPanel>
          {isAdminRole && (
            <>
              <TabsPanel style={{ display: displayTab('changeHistory') }} value={'changeHistory'} index={'changeHistory'} padding={'0px'}>
                <Grid item xs={12} lg={12} sx={{ p: '5px' }}>
                  <Box sx={{ mb: '5px', width: 'inherit' }}>
                    <Box sx={{ mb: '15px' }}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      >
                        <Typography color={'#303030'} fontWeight={700} fontSize={18}>Історія змін</Typography>
                        <IconButtonStyles
                          sx={{
                            borderRadius: '5px', padding: '5px', mr: '10px', border: '1px solid rgba(48, 48, 48, 0.08)',
                          }}
                          onClick={fetchHistoryHandler}
                          color="inherit"
                          size="large"
                        >
                          <RefreshIcon />
                        </IconButtonStyles>
                      </Box>
                    </Box>
                    <Paper sx={{ p: '10px' }}>
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        padding: '10px',
                      }}
                      >
                        <IconButtonStyles disabled sx={{ borderRadius: '5px', padding: '5px', mr: '10px' }} color="inherit" size="large">
                          <HistoryIcon />
                        </IconButtonStyles>
                        <Typography fontWeight={700} fontSize={'14px'}>Історія змін</Typography>
                        <Divider />
                      </Box>
                      <ChangeHistoryTable fetchHistory={fetchHistory} partnerHash={hashId} partnerServiceRequestId={partnerServiceRequest.id} />
                    </Paper>
                  </Box>
                </Grid>
              </TabsPanel>
            </>
          )}
        </TabsContainer>
      </Grid>
      <ConfirmChangePowerOfAttorney
        isSendingData={false}
        open={confirmChangePoaDate}
        handleSend={handleSend}
        onModalOpen={setConfirmChangePoaDate}
      />
      { (isPartnerRoles || isAdminRole) && isPartnerCanCancelRequest(partnerServiceRequest.statusValue) && (
        <>
          <ConfirmCancelRequestModal
            open={confirmCancelRequestModalOpen}
            onModalOpen={setConfirmCancelRequestModalOpen}
            onCancelRequest={onCancelRequest}
          />
        </>
      )}
    </>
  );
}
