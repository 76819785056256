import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import React from 'react';
import IconButton from '@mui/material/IconButton';

export function PageHeaderWithControls({
  returnBack, title, children, ...props
}) {
  return (
    <Paper sx={{ borderRadius: '0px', mb: '10px' }} {...props}>
      <Grid
        sx={{
          pl: '20px', pr: '20px', height: '48px',
        }}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          alignItems="center"
        >
          <IconButton onClick={returnBack} sx={{ color: 'black', p: '0px' }}>
            <KeyboardArrowLeftIcon sx={{ fontSize: '26px' }} />
          </IconButton>
          <Typography variant="h5" fontWeight="bold" sx={{ mt: '2px', color: 'black' }}>
            {title}
          </Typography>
        </Box>
        <Box>
          {children}
        </Box>
      </Grid>
    </Paper>
  );
}
