import { apply, put } from 'redux-saga-test-plan/matchers';
import { actions } from '../../actions';
import { api } from '../../../../config/api';

export function* getOrderShippingHistoryWorker({ payload }) {
  yield put(actions.setShippingHistoryList({ pending: true }));
  const response = yield apply(api, api.orders.getTtnHistoryList, [payload]);
  const responseData = response.data;

  let data = {
    items: [],
    pending: false,
  };

  if (response && response.status >= 200) {
    data = {
      items: responseData,
      pending: false,
    };
  }
  yield put(actions.setShippingHistoryList(data));
}
