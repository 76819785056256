

export function PromoGeneralConditionsSvgIcon() {
  return (
    <>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="30" height="30" rx="7" fill="#303030" fillOpacity="0.08" />
        <g clipPath="url(#clip0_223_33306)">
          <path
            d="M20.7554 6C21.8205 6 22.684 6.86345 22.684 7.92857V12.6102C22.684 13.3489 22.3906 14.0574 21.8683 14.5798L21.8458 14.6023C21.4665 14.3574 21.0557 14.1572 20.6206 14.0093L20.9591 13.6708C21.2403 13.3895 21.3983 13.008 21.3983 12.6102V7.92857C21.3983 7.57353 21.1105 7.28571 20.7554 7.28571H16.0709C15.6727 7.28571 15.2909 7.44401 15.0096 7.72572L7.7134 15.0307C7.13892 15.6177 7.14328 16.5593 7.72601 17.1409L11.5499 20.9591C12.0967 21.5038 12.9583 21.5408 13.5475 21.0708C13.6884 21.4877 13.8773 21.8825 14.1076 22.2486C13.0302 22.9345 11.5845 22.8084 10.642 21.8694L6.81766 18.0508C5.72869 16.9639 5.72729 15.2 6.81442 14.1113L14.0998 6.81714C14.6224 6.29397 15.3315 6 16.0709 6H20.7554ZM18.3983 9.00187C19.1083 9.00187 19.684 9.57749 19.684 10.2876C19.684 10.9976 19.1083 11.5733 18.3983 11.5733C17.6882 11.5733 17.1126 10.9976 17.1126 10.2876C17.1126 9.57749 17.6882 9.00187 18.3983 9.00187ZM23.5411 19.2857C23.5411 16.6821 21.4304 14.5714 18.8268 14.5714C16.2232 14.5714 14.1125 16.6821 14.1125 19.2857C14.1125 21.8893 16.2232 24 18.8268 24C21.4304 24 23.5411 21.8893 23.5411 19.2857ZM18.2915 21.861C18.2915 21.5654 18.5312 21.3257 18.8268 21.3257C19.1224 21.3257 19.3621 21.5654 19.3621 21.861C19.3621 22.1566 19.1224 22.3963 18.8268 22.3963C18.5312 22.3963 18.2915 22.1566 18.2915 21.861ZM17.2382 17.9635C17.2289 16.989 17.9286 16.2884 18.8268 16.2884C19.7106 16.2884 20.4155 17.0133 20.4155 17.9594C20.4155 18.4445 20.2566 18.7423 19.8472 19.2002L19.6194 19.4492L19.5329 19.5482C19.3209 19.7984 19.2554 19.9443 19.2554 20.1434C19.2554 20.3801 19.0635 20.5719 18.8268 20.5719C18.5901 20.5719 18.3983 20.3801 18.3983 20.1434C18.3983 19.6504 18.5584 19.3499 18.9735 18.8857L19.2009 18.6371L19.2858 18.54C19.4938 18.2946 19.5583 18.1516 19.5583 17.9594C19.5583 17.4814 19.2317 17.1455 18.8268 17.1455C18.4036 17.1455 18.0906 17.4589 18.0953 17.9552C18.0976 18.1919 17.9076 18.3856 17.6709 18.3879C17.4342 18.3902 17.2405 18.2002 17.2382 17.9635Z"
            fill="#303030"
          />
        </g>
        <defs>
          <clipPath id="clip0_223_33306">
            <rect width="18" height="18" fill="white" transform="translate(6 6)" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
