export const warrantyDeniedOptions = [
  { label: 'обслуговування товару надає тільки офіційний сервісний центр', value: 1 },
  { label: 'механічні пошкодження та сліди зовнішнього втручання', value: 2 },
  { label: 'порушення умов експлуатації', value: 3 },
  { label: 'природне зношення або вичерпання ресурсу', value: 4 },
  { label: 'недотримання гарантійних зобов\'язань', value: 5 },
  { label: 'несанкціоновані зміни та модифікації', value: 6 },
  { label: 'пошкодження внаслідок форс-мажорних обставин', value: 7 },
  { label: 'неправильне встановлення або монтаж', value: 8 },
  { label: 'механічні пошкодження та сліди зовнішнього втручання', value: 9 },
];

export const closedByPartnerOptions = [
  { label: 'Порушення термінів повернення', value: 1 },
  { label: 'Покупець скасував заявку на повернення', value: 2 },
  { label: 'Втрата товарного вигляду товару', value: 3 },
  { label: 'Покупець не надіслав повернення більше 72 год', value: 4 },
  { label: 'Товар належить до переліку тих, що не підлягають поверненню', value: 5 },
  { label: 'Відсутність документів, що підтверджують покупку', value: 6 },
  { label: 'Відсутність дефектів у товарі належної якості', value: 7 },
  { label: 'Порушення умов експлуатації', value: 8 },
  { label: 'Не вдалось зв\'язатися/Не вірні контактні дані', value: 9 },
];

export const canceledByAlloOptions = [
  { label: 'Відсутній контакт з покупцем', value: 1 },
  { label: 'Продавець більше не співпрацює з АЛЛО', value: 2 },
  { label: 'Невиконання умов з боку покупця', value: 3 },
  { label: 'Покупець скасував заявку на повернення', value: 4 },
];


export const statusOptions = [
  { label: 'Відправлений в сервісний центр', value: 'sent_to_service' },
  { label: 'Відмінена АЛЛО', value: 'canceled_by_allo' },
  { label: 'Проведений обмін товару', value: 'exchange_processed' },
  { label: 'Відмова у гарантійному обслуговуванні', value: 'warranty_denied' },
  { label: 'Очікуємо посилку від клієнта', value: 'awaiting_package' },
  { label: 'Новий', value: 'new' },
  { label: 'Проведене повернення коштів', value: 'refund_processed' },
  { label: 'Закрита партнером', value: 'closed_by_partner' },
  { label: 'Протерміновані', value: 'overdue' },
  { label: 'Товар відправлений покупцю після ремонту', value: 'returned_after_repair' },
];

export const finalStatuses = ['warranty_denied', 'refund_processed', 'returned_after_repair', 'exchange_processed', 'closed_by_partner', 'canceled_by_allo'];
