// Helpers
import { getActionCreator } from '../../../_helpers/getActionCreator';
// Types
import { types } from './types';

export const actions = Object.freeze({
  setInitialState(initialState) {
    return getActionCreator(types.SET_PRODUCT_PRICE_COMPARISON_INITIAL_STATE, initialState);
  },
  setList(data) {
    return getActionCreator(types.SET_PRODUCT_PRICE_COMPARISON_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_PRODUCT_PRICE_COMPARISON_CURRENT_PAGE, currentPage);
  },
  setReload(data) {
    return getActionCreator(types.SET_PRODUCT_PRICE_COMPARISON_RELOAD, data);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_PRODUCT_PRICE_COMPARISON_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_PRODUCT_PRICE_COMPARISON_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_PRODUCT_PRICE_COMPARISON_SORTING, sorting);
  },
  setSelection(selected) {
    return getActionCreator(types.SET_PRODUCT_PRICE_COMPARISON_SELECTION, selected);
  },
});
