// Core
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  useState, useEffect, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import ArrowBack from '@mui/icons-material/ArrowBack';
import SaveAndReturnIcon from '@mui/icons-material/CallMissed';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import declineWord from 'decline-word';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
import SectionHolder from '../../../../containers/SectionHolder/SectionHolder';
import AddProductSidebar from './components/AddProductSidebar';
import CancelModal from '../../../../containers/Modals/CancelModal/CancelModal';
// Engine
import { pageLinks } from '../../../../../engine/config/routes';
import { api } from '../../../../../engine/config/api';
import { setErrorMessage, setSuccessMessage, setWarningMessage } from '../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
// Helpers
import { transformDataForTORPsPreviewTable } from './utils/transformDataForTORPsPreviewTable';
import { COLOR } from '../../../../containers/App/AppStyles';
import CategorySelect from './components/CategorySelect';

import { asyncActions } from '../../../../../engine/core/contentProduct/saga/asyncActions';
import { selectors } from '../../../../../engine/config/selectors';
import TORPsPreviewTable from './components/TORPsPreviewTable';
import { getDate } from '../../../../../_helpers/getDate';
import SKUInput from './components/SKUInput';
import { InfoLine } from '../../Grouping/Add/ProductsAddGroup';
import { actions } from '../../../../../engine/core/TORPs/actions';
import DeleteModal from '../components/DeleteModal';
import { getFileFromBlob } from '../../../../../_helpers/getFileFromBlob';

const DEFAULT_VALUES = {
  category: '', tags: [],
};

const TorpsAdd = () => {
  const { hashId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categoriesList = useSelector(selectors.contentProduct.productCategoriesList);
  const categoryOptions = categoriesList.toJS();
  const {
    handleSubmit, control, formState: { errors, touchedFields, dirtyFields }, watch, setValue,
  } = useForm({
    mode: 'all', defaultValues: DEFAULT_VALUES,
  });
  const editMode = !!hashId;
  const [previewRows, setPreviewRows] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [TORPInfo, setTORPInfo] = useState({});
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(asyncActions.getContentCategoriesListAsync({
      context: 'select',
    }));
  }, []);

  const { category: partnerCategory, tags } = watch();


  const pageTitle = editMode ? 'Редагування ТОРПу' : 'Групування ТОРПів';

  useEffect(() => {
    if (editMode) {
      (async () => {
        const response = await api.torps.getTORP(hashId);
        if (response.status >= 200 && response.status < 400) {
          const {
            updatedByUser, updatedAt, sku, products,
          } = response.data;
          const productsInfo = Object.values(products).map(({ productId, productSku, isAlloProduct }) => ({ productId, productSku, isAlloProduct }));
          setValue('tags', productsInfo);
          setTORPInfo({ sku, user: updatedByUser.label, updatedAt: getDate(updatedAt) });
        }
      })();
    }
  }, [hashId]);

  const handleNavigateBack = () => {
    navigate(pageLinks.productsAndPrices.torps);
  };


  const onSubmit = useCallback(async () => {
    setIsLoading(true);

    const dataToCreate = previewRows.map(item => ({
      categoryId: partnerCategory?.value,
      vendorCode: item?.vendorCode,
      productIds: item?.productIds,
    }));
    const dataToEdit = {
      competitiveProducts: tags,
    };
    const data = editMode
      ? dataToEdit
      : dataToCreate;

    if (editMode) {
      const response = await api.torps.updateTORP({ data, hashId });
      if (response.status >= 200 && response.status < 400) {
        dispatch(setSuccessMessage('ТОРП успішно змінено', response.data.statusTitle));
        handleNavigateBack();
      } else {
        dispatch(setErrorMessage(response.data?.errors, response.data.statusTitle));
      }
    } else {
      const promises = data.map(promise => api.torps.createTORP(promise));
      const responses = await Promise.allSettled(promises);
      const successResponses = responses.filter(resp => resp?.status === 'fulfilled' && resp?.value?.data?.status === 'success');
      if (successResponses.length === responses.length) {
        dispatch(setSuccessMessage(`Успішно додано ${successResponses.length} ${declineWord(successResponses.length, 'ТОРП', '', 'а', 'ів')}`, 'Успіх'));
      }
      if (successResponses.length === 0) {
        dispatch(setErrorMessage('Помилка при додаванні ТОРПів', 'Помилка'));
        setIsLoading(false);
        return;
      }
      if (successResponses.length < responses.length) {
        dispatch(setWarningMessage(`Успішно додано ${successResponses.length} ${declineWord(successResponses.length, 'ТОРП', '', 'а', 'ів')}, ${responses.length - successResponses.length} не вдалось створити`, 'Частково додано'));
      }
      handleNavigateBack();
    }
    setIsLoading(false);
  }, [editMode, previewRows, tags]);

  const handleFindTORPs = async () => {
    const response = await api.torps.getTORPsList({ categoryId: partnerCategory?.value });
    const contentType = response.headers['content-type'];
    if (contentType.includes('application/json')) {
      const jsonText = await response.data.text();
      const responseObj = JSON.parse(jsonText);
      if (response.status >= 200 && response.status < 400) {
        if (responseObj.status === 'success') {
          const rows = transformDataForTORPsPreviewTable(responseObj?.previews);
          setPreviewRows(rows);
        }
        if (responseObj.status === 'error') {
          dispatch(setErrorMessage(responseObj?.message, responseObj?.statusTitle));
        }
      } else {
        dispatch(setErrorMessage(responseObj?.errors ?? t('Ошибка'), responseObj?.statusTitle));
      }
    } else {
      getFileFromBlob('torp_preview_list.xlsx', response);
      dispatch(setSuccessMessage('Результати завантажено в файл', 'Кількість ТОРПів завелика'));
      handleNavigateBack();
      return null;
    }
  };

  const handleDownloadTORPs = async () => {
    const response = await api.torps.getTORPsList({ categoryId: partnerCategory?.value, forceXlsx: 1 });
    getFileFromBlob('torp_list_preview.xlsx', response);
  };

  const findTORPsDisabled = !partnerCategory?.value;

  const handleAddTORP = () => setIsSidebarOpen(true);

  const closeSidebar = () => setIsSidebarOpen(false);

  const handleReturn = () => {
    if ((partnerCategory) && !editMode) {
      setCancelModalOpen(true);
    } else if (editMode && dirtyFields.tags) {
      setCancelModalOpen(true);
    } else {
      handleNavigateBack();
    }
  };

  const handleOpenDeleteModal = () => {
    dispatch(actions.setDeleteTORPModal(true));
  };

  return (
    <>
      <PageHeader title={pageTitle}>
        {editMode && (
        <ButtonWithIcon text={t('Удалить')} onClick={handleOpenDeleteModal}>
          <DeleteOutline />
        </ButtonWithIcon>
        )}
        <ButtonWithIcon text={t('Вернуться')} onClick={handleReturn}>
          <ArrowBack />
        </ButtonWithIcon>
        <ButtonWithIconAndCircularProgress text={t('Сохранить и вернуться')} onClick={handleSubmit(onSubmit)} disabled={editMode ? isLoading || !dirtyFields.tags : (findTORPsDisabled || !previewRows.length || isLoading)}>
          <SaveAndReturnIcon />
        </ButtonWithIconAndCircularProgress>
      </PageHeader>
      <SectionHolder>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container sx={{ height: '100%' }} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              {editMode && (
              <Box sx={{
                display: 'flex', flexDirection: 'column', fontSize: '13px', color: COLOR.black[72], marginBottom: '40px',
              }}
              >
                <InfoLine title="SKU ТОРПа" value={TORPInfo.sku} />
                <InfoLine title={t('Пользователь')} value={TORPInfo.user} />
                <InfoLine title={t('Дата изменения')} value={TORPInfo.updatedAt} />
              </Box>
              )}
              <Typography fontWeight={700} fontSize={14}>{t('Общие настройки')}</Typography>
            </Grid>
            <Grid item xs={8} sm={6} md={5}>

              {!editMode && (
                <>
                  <CategorySelect control={control} categoryOptions={categoryOptions} isPartnerInfoLoading={false} errors={errors} editMode={editMode} touchedFields={touchedFields} />
                  <Box sx={{ display: 'flex', gap: '8px' }}>
                    <ButtonWithIcon onClick={handleFindTORPs} sx={{ marginTop: '32px', gap: '8px' }} disabled={findTORPsDisabled || previewRows.length > 0}>
                      <AddCircleIcon />
                      {t('Знайти ТОРПи')}
                    </ButtonWithIcon>
                    <ButtonWithIcon onClick={handleDownloadTORPs} sx={{ marginTop: '32px', gap: '8px' }} disabled={findTORPsDisabled}>
                      <CloudDownloadIcon />
                      {t('Завантажити ТОРПи')}
                    </ButtonWithIcon>
                  </Box>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Box sx={{ display: 'flex', padding: '6px 0px', justifyContent: 'flex-end' }}>
                  {editMode && (
                  <ButtonWithIcon sx={{ gap: '8px' }} onClick={handleAddTORP}>
                    <AddCircleIcon />
                    {t('Добавить товар')}
                  </ButtonWithIcon>
                  )}
                </Box>
                {previewRows.length > 0 && <TORPsPreviewTable rows={previewRows} setRows={setPreviewRows} />}
              </Box>
            </Grid>
            <Grid item xs={6}>
              {editMode && tags.length > 0 && <SKUInput control={control} />}
            </Grid>
          </Grid>
        </form>
      </SectionHolder>
      <Drawer open={isSidebarOpen} onClose={closeSidebar} anchor="right">
        <AddProductSidebar TORPName={TORPInfo.sku} hashId={hashId} setRows={setPreviewRows} close={closeSidebar} setValue={setValue} tags={tags} />
      </Drawer>
      <CancelModal closeModal={cancelModalOpen} handleCloseModal={() => setCancelModalOpen(false)} handleCancel={handleNavigateBack} />
      <DeleteModal isEditPage hashId={hashId} torpSKU={TORPInfo.sku} />
    </>
  );
};

export default TorpsAdd;
