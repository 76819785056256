import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { promotionsSelectors } from '../../../../../engine/core/promotions/selectors';
import { asyncActions } from '../../../../../engine/core/promotions/saga/asyncActions';
import { useAccessList } from '../../../../hooks/useAccessList';
import { accessList } from '../../../../../engine/config/access';
import { selectors } from '../../../../../engine/config/selectors';
import { convertSortingObjectToUrlParams } from '../../../../../_helpers/convertDataToUrlParams';
import { requestDelay } from '../../../../../engine/config/globalConfig';

export function usePromotionsList(refresh) {
  const currentPage = useSelector(promotionsSelectors.currentPage);
  const limit = useSelector(promotionsSelectors.pageSize);
  const filters = useSelector(promotionsSelectors.filters);
  const sorting = useSelector(promotionsSelectors.sorting);
  const reload = true;
  const routeKey = useSelector(selectors.router.key);
  const dispatch = useDispatch();
  const hasAccessList = useAccessList(accessList.promotions_general);
  const offset = limit * currentPage;
  const filtersStringify = JSON.stringify(filters.toJS());
  const sortingStringify = JSON.stringify(sorting.toJS());

  const orderBy = useMemo(() => (
    convertSortingObjectToUrlParams(JSON.parse(sortingStringify))
  ), [sortingStringify]);

  const paramsByFilter = useMemo(() => {
    const params = {};
    JSON.parse(filtersStringify).forEach(({ columnName, value }) => {
      params[columnName] = value;
    });
    return params;
  }, [filtersStringify]);

  const getListAsync = useCallback(() => {
    if (hasAccessList) {
      const params = {
        ...paramsByFilter,
        orderBy,
        limit,
        offset,
      };
      dispatch(asyncActions.getListAsync(params));
    }
  }, [hasAccessList, paramsByFilter, orderBy, limit, offset, dispatch]);

  const timeoutId = useRef(0);

  useEffect(() => {
    if (reload || refresh) {
      timeoutId.current = setTimeout(() => {
        getListAsync();
      }, requestDelay);
    }
    return () => clearTimeout(timeoutId.current);
  }, [getListAsync, reload, refresh, routeKey]);
}
