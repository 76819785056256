
import { asyncTypes } from './asyncTypes';
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = {
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_PROMOTIONS_LIST_ASYNC, params);
  },
  getPromotionById(hashId) {
    return getActionCreator(asyncTypes.GET_PROMOTIONS_FOR_EDIT_ASYNC, hashId);
  },
  getPromotionHistoryList(hashId) {
    return getActionCreator(asyncTypes.GET_PROMOTION_HISTORY_LIST, hashId);
  },
  createPromotionAsync(data) {
    return getActionCreator(asyncTypes.CREATE_PROMOTION_ASYNC, data);
  },
  putSettingsAsync(param) {
    return getActionCreator(asyncTypes.PUT_SETTINGS_ASYNC, param);
  },
  deletePromotionById(hashId) {
    return getActionCreator(asyncTypes.DELETE_PROMOTION_BY_ID, hashId);
  },
  createPromotionParticipant(hashId) {
    return getActionCreator(asyncTypes.CREATE_PROMOTION_PARTICIPANT, hashId);
  },
  updatePromoAsync(hashId, data) {
    return getActionCreator(asyncTypes.UPDATE_PROMOTION_ASYNC, {
      hashId, data,
    });
  },
};
