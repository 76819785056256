import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { asyncActions } from '../../../../engine/core/promotions/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import {
  getJoinedLabels, getLabelByPromotionType, getStatusColor, promotionStatuses,
} from '../../../../engine/config/promotions';
import { pageLinks } from '../../../../engine/config/routes';
import { PromoNoteSvgIcon } from '../icons/PromoNoteSvgIcon';
import BoxWithShadow from '../../../containers/Box/BoxWithShadow';
import Loading from '../../../components/Loading/Loading';
import { useUserRole } from '../../../hooks/useUserRole';
import { userRoles } from '../../../../engine/config/userRoles';
import CancelModal from '../../../containers/Modals/CancelModal/CancelModal';
import { PageHeaderWithControls } from '../../../components/PageHeader/PageHeaderWithControls';
import { useModalContext } from '../../../containers/Context/UseModalContext';
import { ButtonVisibility } from '../../../components/Buttons/ButtonVisibility';
import { CategoriesModal } from './CategoriesModal';

const titlesStyle = {
  fontSize: '12px',
  color: 'rgba(48, 48, 48, 0.87)',
};

const fieldsStyle = {
  fontSize: '14px',
  textAlign: 'end',
};

const boxStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
};

export function PromotionViewPage() {
  const { hashId } = useParams();
  const dispatch = useDispatch();
  const isPending = useSelector(selectors.promotions.isPending);
  const promotion = useSelector(selectors.promotions.getPromotionForEdit);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isPartnerActiveRole = useUserRole(userRoles.partnerActive);
  const isPromotionScheduled = promotion?.promoStatus === promotionStatuses.STATUS_SCHEDULED;
  const isPromotionFinished = promotion?.promoStatus === promotionStatuses.STATUS_FINISHED;
  const isNeedToDisabledParticipantButton = isPartnerActiveRole && isPromotionScheduled || promotion?.isAlreadyParticipated;
  const isNeedToShowParticipantButton = !(isPartnerActiveRole && isPromotionFinished);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { showModal } = useModalContext();
  useEffect(() => {
    dispatch(asyncActions.getPromotionById(hashId));
  }, []);

  const handleNavigateBack = () => {
    navigate(pageLinks.advertising.promoList);
  };

  const handleParticipate = (showConfirm = true) => {
    if (showConfirm) {
      setIsConfirmModalOpen(true);
    } else {
      dispatch(asyncActions.createPromotionParticipant(hashId));
      dispatch(asyncActions.getPromotionById(hashId));
    }
  };

  if (isPending || promotion.size === 0) {
    return (
      <>
        <Box sx={{
          width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
        }}
        >
          <Loading isLoading={isPending} />
        </Box>
      </>
    );
  }

  return (
    <>
      <PageHeaderWithControls sx={{ mb: '0px' }} returnBack={handleNavigateBack} title={t('Перегляд акції')}>
        {isNeedToShowParticipantButton && (
        <Button
          sx={{ height: '32px', mr: '8px' }}
          variant={'contained'}
          disabled={isNeedToDisabledParticipantButton}
          onClick={handleParticipate}
        >
          {promotion?.isAlreadyParticipated ? t('Заявка відправлена') : t('Хочу взяти участь') }
        </Button>
        )}
      </PageHeaderWithControls>
      <Paper sx={{ ml: '20px', mr: '20px', mt: '10px' }}>
        <Grid container>
          <Grid item md={4}>
            <Box sx={{ border: '1px solid rgba(48, 48, 48, 0.08)', p: '20px' }}>
              <Box
                display="flex"
                alignItems="center"
              >
                <Typography fontWeight={700} fontSize={'16px'} color={'rgba(48, 48, 48, 1)'}>
                  {promotion?.promoName}
                </Typography>
              </Box>
              <Divider sx={{ mt: '10px', mb: '10px' }} />
              <Box sx={boxStyle}>
                <Typography sx={titlesStyle}>Статус акції:</Typography>
                <Box width={'10px'} />
                <Typography sx={fieldsStyle}><Box sx={{ color: getStatusColor(promotion.promoStatus) }}>{promotion.statusLabel}</Box></Typography>
              </Box>
              <Divider sx={{ mt: '10px', mb: '10px' }} />
              <Box sx={boxStyle}>
                <Typography sx={titlesStyle}>Період акції:</Typography>
                <Box width={'10px'} />
                <Typography sx={fieldsStyle}>з {promotion.promoDateRange?.split(' — ')[0]}</Typography>
              </Box>
              <Box sx={boxStyle}>
                <Box width={'10px'} />
                <Typography sx={fieldsStyle}>до {promotion.promoDateRange?.split(' — ')[1]}</Typography>
              </Box>
              <Divider sx={{ mt: '10px', mb: '10px' }} />
              <Box sx={boxStyle}>
                <Typography sx={titlesStyle}>Прийом заявок:</Typography>
                <Box width={'10px'} />
                <Typography sx={fieldsStyle}>з {promotion.promoRegistrationDateRange?.split(' — ')[0]}</Typography>
              </Box>
              <Box sx={boxStyle}>
                <Box width={'10px'} />
                <Typography sx={fieldsStyle}>до {promotion.promoRegistrationDateRange?.split(' — ')[1]}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={8}>
            <Box>
              <Box sx={{ m: '20px' }}>
                <Typography fontSize={'16px'} fontWeight={700}>
                  {t('Акція')}
                </Typography>
              </Box>
              <BoxWithShadow>
                <Box display="flex" alignItems="center" gap={1} sx={{ mb: '15px' }}>
                  <PromoNoteSvgIcon />
                  <Typography fontSize={'14px'} fontWeight={700}>
                    {t('Про акцію')}
                  </Typography>
                </Box>
                <Divider sx={{ mb: '20px' }} />
                <Grid sx={{ p: '20px' }} container rowSpacing={2}>
                  <Grid item xs={2}>
                    <Tooltip title={'“Знижки” - товари зі знижкою. “Безкоштовна доставка” - товари з безкоштовною доставкою за рахунок продавця. “Оплата частинами” - товари, які можна сплатити частинами лише на час акції (для участі у вас має бути підключена послуга “Оплата частинами Monobank” або “Покупка частинами ПриватБанк”).'} arrow>
                      <Typography sx={titlesStyle}>{t('Тип акції')}:</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography fontSize={'14px'}>
                      { promotion.promoTypes?.map(element => getLabelByPromotionType(element))?.join(', ') }
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title={'Категорії, які беруть участь у акції'} arrow>
                      <Typography sx={titlesStyle}>{t('Категорії')}:</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10}>
                    <Box display="flex" alignItems="center" gap={2} justifyContent={'start'}>
                      <Typography fontSize={'14px'}>
                        {getJoinedLabels(promotion.categories.slice(0, 3))}
                        {promotion.categories.length > 3 && '...'}
                      </Typography>
                      {promotion.categories.length > 3 && (
                        <ButtonVisibility tooltipText={'Переглянути всі категорії'} onClick={() => showModal(promotion.categoriesTree)} />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title={'Бренди, які НЕ допускаються до участі в акції'} arrow>
                      <Typography sx={titlesStyle}>{t('Cтоп-бренди')}:</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography fontSize="14px">
                      {getJoinedLabels(promotion.stopBrands)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography sx={titlesStyle}>{t('Опис акції')}:</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography fontSize="14px">
                      {promotion.description}
                    </Typography>
                  </Grid>
                </Grid>
              </BoxWithShadow>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <CategoriesModal title={'Список категорій Алло в акції'} />
      <CancelModal
        closeModal={isConfirmModalOpen}
        titleText={`${t('Заявка на участь в акції')} ${promotion.promoName}`}
        sendText={'Ok'}
        text={'Будь ласка, уважно ознайомтесь з терміном та умовами акції. Після подання заявки з вами зв’яжеться менеджер для узгодження подальших дій. Відправити заявку на участь?'}
        handleCancel={() => {
          handleParticipate(false);
          setIsConfirmModalOpen(false);
        }}
        handleCloseModal={() => setIsConfirmModalOpen(false)}
        navigateBack={handleNavigateBack}
      />
    </>
  );
}
