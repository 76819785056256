import Box from '@mui/material/Box';
import { COLOR } from '../../../containers/App/AppStyles';

const ErrorBox = ({ label }) => <Box sx={{ color: COLOR.error }}>{label}</Box>;

const AwaitingBox = ({ label }) => <Box sx={{ color: COLOR.serviceRequestStatusColor.awaitingApprove }}>{label}</Box>;

const SuccessBox = ({ label }) => <Box sx={{ color: COLOR.success }}>{label}</Box>;

export const convertValueToLabel = (val) => {
  switch (val) {
    case 'sent_to_service': {
      return <AwaitingBox label="Відправлений в сервісний центр" />;
    }
    case 'canceled_by_allo': {
      return <ErrorBox label="Відмінена АЛЛО" />;
    }
    case 'exchange_processed': {
      return <SuccessBox label="Проведений обмін товару" />;
    }
    case 'warranty_denied': {
      return <ErrorBox label="Відмова у гарантійному обслуговуванні" />;
    }
    case 'awaiting_package': {
      return <AwaitingBox label="Очікуємо посилку від клієнта" />;
    }
    case 'new': {
      return <Box sx={{ color: COLOR.blue.main }}>Новий</Box>;
    }
    case 'refund_processed': {
      return <SuccessBox label="Проведене повернення коштів" />;
    }
    case 'closed_by_partner': {
      return <SuccessBox label="Закрита партнером" />;
    }
    case 'overdue': {
      return <ErrorBox label="Протерміновані" />;
    }
    default: {
      return <SuccessBox label="Товар відправлений покупцю після ремонту" />;
    }
  }
};
