// Core
import React, { useState, useMemo, memo } from 'react';
import * as PropTypes from 'prop-types';
import { DateRangePicker, Calendar } from 'react-date-range';

// hooks
import { useStyles } from '../../hooks/useStyles';

// helpers
import { getDateFnsObjectByLang } from '../../../_helpers/getDate';
import { defaultInputRanges, defaultStaticRanges, expandedStaticRanges } from './DataRangeStaticRanges';
import i18n from '../../../engine/init/i18n';

// Style
import DataRangeStyles from './DataRangeStyles';
import 'react-date-range/dist/styles.css'; // main style file
import './DataRangeStyle.css';


const AUTOTRANSLATE_FILTER = 'translatedAt';

function DateRange(props) {
  const {
    onChange, startDate, endDate, range, columnName, minDate, maxDate,
  } = props;
  const [selectionRange, setSelectionRange] = useState({
    startDate,
    endDate,
    key: 'selection',
  });
  const classes = useStyles(DataRangeStyles);
  const language = i18n.language;

  const handleSelect = (params) => {
    const { selection } = params;
    const value = range ? selection : {
      endDate: params,
      startDate: params,
    };
    if (value.startDate.getTime() === new Date(0).getTime()) {
      setSelectionRange({
        startDate: new Date(), endDate: new Date(), key: 'selection', empty: 'empty',
      });
      onChange({ empty: selection.empty });
    } else {
      setSelectionRange({ ...value, empty: false });
      onChange({ ...value, empty: false });
    }
  };

  const getCurrentLocale = useMemo(() => getDateFnsObjectByLang(language), [language]);
  return range
    ? (
      <DateRangePicker
        minDate={minDate}
        maxDate={maxDate}
        color="#8a1a1c"
        locale={getCurrentLocale}
        className={classes.dataRange}
        ranges={[selectionRange]}
        onChange={handleSelect}
        staticRanges={columnName === AUTOTRANSLATE_FILTER ? expandedStaticRanges : defaultStaticRanges}
        inputRanges={defaultInputRanges}
        weekdayDisplayFormat="EEEEEE"
      />
    )
    : (
      <Calendar
        color="#8a1a1c"
        locale={getCurrentLocale}
        className={classes.dataRange}
        date={new Date()}
        onChange={handleSelect}
        inputRanges={defaultInputRanges}
      />
    );
}

DateRange.displayName = 'DateRange';

DateRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  range: PropTypes.bool,
  columnName: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
};

DateRange.defaultProps = {
  range: true,
  startDate: new Date(),
  endDate: new Date(),
};

export default memo(DateRange);
