export const types = Object.freeze({
  SET_PRODUCT_PRICE_COMPARISON_LIST: 'SET_PRODUCT_PRICE_COMPARISON_LIST',
  SET_PRODUCT_PRICE_COMPARISON_CURRENT_PAGE: 'SET_PRODUCT_PRICE_COMPARISON_CURRENT_PAGE',
  SET_PRODUCT_PRICE_COMPARISON_FILTERS: 'SET_PRODUCT_PRICE_COMPARISON_FILTERS',
  SET_PRODUCT_PRICE_COMPARISON_PAGE_SIZE: 'SET_PRODUCT_PRICE_COMPARISON_PAGE_SIZE',
  SET_PRODUCT_PRICE_COMPARISON_SORTING: 'SET_PRODUCT_PRICE_COMPARISON_SORTING',
  SET_PRODUCT_PRICE_COMPARISON_INITIAL_STATE: 'SET_PRODUCT_PRICE_COMPARISON_INITIAL_STATE',
  SET_PRODUCT_PRICE_COMPARISON_SELECTION: 'SET_PRODUCT_PRICE_COMPARISON_SELECTION',
  SET_PRODUCT_PRICE_COMPARISON_RELOAD: 'SET_PRODUCT_PRICE_COMPARISON_RELOAD',
});
