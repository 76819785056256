import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useParams } from 'react-router';
import Modal from '../../../../../containers/Modals/Modal/Modal';
import { selectors } from '../../../../../../engine/config/selectors';
import { actions } from '../../../../../../engine/core/contentProduct/actions';
import { asyncActions } from '../../../../../../engine/core/content/saga/asyncActions';


const AlloExpressWarningPopup = () => {
  const modalInfo = useSelector(selectors.contentProduct.alloExpressWarningPopup);
  const {
    showModal, productId, productsValues, callback,
  } = modalInfo;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { categoryId } = useParams();

  const onClose = () => {
    dispatch(actions.setAlloExpressWarningPopup({ showModal: false }));
  };

  const handleSend = () => {
    if (callback) {
      callback();
      return;
    }
    const params = {
      categoryId,
      products: [productId],
      productsValues,
    };
    dispatch(asyncActions.putProductsAsync(params));
    dispatch(actions.setAlloExpressWarningPopup({ showModal: false }));
  };

  const text = t('Введені логістичні дані не дозволяють транспортування товару доставкою АЛЛО Express');

  return (
    <>
      {showModal && (
      <Modal
        modalOpen={showModal}
        handleModalToggle={onClose}
        handleModalCancel={onClose}
        title={t('Внимание')}
        buttonSendIcon={null}
        sendText={'Змінити'}
        cancelText={'Скасувати'}
        modalMaxWidth="sm"
        handleModalSend={handleSend}
      >
        <Box sx={{ whiteSpace: 'pre-line' }}><b>{t('Внимание')}!</b> {text}</Box>

      </Modal>
      )}
    </>
  );
};

export default AlloExpressWarningPopup;
