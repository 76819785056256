// Core
import { apply, select, put } from 'redux-saga/effects';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';

import { localData } from '../../../../config/localData';
import { selectors } from '../../../../config/selectors';

export function* callRemoveFiltersWorker() {
  const pathname = yield select(selectors.router.pathname);
  const limit = yield select(selectors.refundRequests.pageSize);
  const currentPage = yield select(selectors.refundRequests.currentPage);
  const offset = currentPage * limit;
  const params = { limit, offset };
  yield put(actions.setList({ pending: true }));
  const response = yield apply(api, api.refundRequests.getList, [params]);
  yield apply(localData, localData.setItem, [pathname, null]);

  if (response.status >= 200 && response.status < 400) {
    yield put(actions.setList(response.data));
  }
}
