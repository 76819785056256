import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import Modal from '../../../containers/Modals/Modal/Modal';
import { pageLinks } from '../../../../engine/config/routes';

const InformModal = ({
  informModal: { open = false, titleStatus, text },
  handleCloseModal,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      modalOpen={open}
      handleModalToggle={handleCloseModal}
      handleModalSend={() => navigate(pageLinks.serviceDesk.support)}
      title={`Заяка в статусі "${titleStatus}"`}
      modalMaxWidth="sm"
      sendText={'Підтримка АЛЛО'}
      buttonSendIcon={null}
    >
      <Box sx={{ padding: '16px' }}>{text} </Box>
    </Modal>
  );
};


export default InformModal;
