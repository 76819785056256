// Core
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Field } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
// Parts
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '../../../../../components/_Form/TextField/TextField/TextField';
import FieldWithClearButton from '../../../../../containers/FieldWithClearButton/FieldWithClearButton';
import InfoText from './InfoText';
import HelperText from './HelperText';
import FieldWithFeatureDisabled from './FieldWithFeatureDisabled';
import RenderCheckbox from './RenderCheckbox';
// Helpers
import { formName } from '../../../../../../_helpers/data/pages/contentMenagement/contentProducts/contentProductEditForm';
import { useStyles } from '../../../../../hooks/useStyles';
import ContentProductEditStyles from '../ContentProductEditStyles';
import { useIsValueWasChanged } from '../hooks/useIsValueWasChanged';
import { getCheckboxName, inputValueEmpty } from '../ContentProductEdit';
import { onChangeWithValidateWeight } from '../utils/validateWeight';
import { getDefaultCategoryValue } from '../../ContentProducts/utils';

export const FocusComponent = () => {
  const { t } = useTranslation();
  return (
    <InfoText>
      {t('Введите число (до 5 символов), можно использовать точку или запятую')}
    </InfoText>
  );
};

function RenderWeightFormField(props) {
  const {
    margin, massEdit, input, meta, disabled,
    isRecommended, refresh, featureDisabled,
    onRefresh, required, label,
    setTouchField, changeFieldValue, canEditProductLogistics,
    featureAutoUpdate, defaultValueHelpText, defaultValueHelp, isShowDefaultValueHelp, defaultLogisticsValues,
  } = props;

  const touched = meta.touched;
  const error = !!(touched && meta.error);
  const { isValueChanged, value, showChangedMessage } = useIsValueWasChanged(input);
  const canNotEdit = canEditProductLogistics === false;
  const isDisabled = disabled || featureDisabled?.disabled || canNotEdit;
  const checkboxName = getCheckboxName(input.name);
  const [willBeClear, setWillBeClear] = useState(false);
  const classes = useStyles(ContentProductEditStyles);
  const { t } = useTranslation();

  useEffect(() => {
    changeFieldValue(checkboxName, isValueChanged);
  }, [changeFieldValue, checkboxName, isValueChanged]);

  const classNames = cx(classes.formControl, {
    [classes.formControlHelperTextChange]: showChangedMessage,
    [classes.changedFormControl]: showChangedMessage,
    [classes.formControlRecommended]: isRecommended && inputValueEmpty(value) && !isDisabled,
  });

  const onClick = (!touched && !isDisabled) ? () => setTouchField(input.name) : null;
  const onChange = (e) => {
    onChangeWithValidateWeight(e, input.onChange);
  };
  const inputValue = input.value ? input.value : getDefaultCategoryValue(defaultLogisticsValues, input.name);
  const inputProps = {
    ...input,
    value: inputValue,
    onChange,
  };

  const handleBlur = () => {
    if (input.value.endsWith('.')) {
      input.onChange(input.value.substring(0, input.value.length - 1));
    }
    if (input.value !== '') {
      input.onChange(Number(input.value));
    }
  };

  return (
    <FormControl
      error={error}
      className={classNames}
      margin={margin}
      onClick={onClick}
    >
      <Grid container>
        <Grid item xs={1}>
          {massEdit && (
            <Field
              component={RenderCheckbox}
              error={error}
              name={checkboxName}
              disabled={isDisabled}
              inputValue={input.value}
              setWillBeClear={setWillBeClear}
              isValueChanged={isValueChanged}
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <FieldWithClearButton
            refresh={refresh}
            onRefresh={onRefresh}
            formName={formName}
            disabled={isDisabled}
            {...input}
            name={input.name.toString()}
          >
            <FieldWithFeatureDisabled showTooltip={featureDisabled?.showTooltip}>
              <TextField
                meta={meta}
                fullWidth
                disabled={isDisabled}
                input={inputProps}
                required={required}
                isRecommended={isRecommended}
                label={label}
                margin="none"
                focusComponent={<FocusComponent />}
                customOnBlur={handleBlur}
                inputmode="decimal"
              />
              {canNotEdit && !input.value && featureAutoUpdate ? (
                <FormHelperText>
                  {t('Значение для этого поля отсутствует в прайс-листе или не соответствует требованиям: значение (до 6 символов), можно использовать точку или запятую')}
                </FormHelperText>
              ) : undefined}
            </FieldWithFeatureDisabled>
          </FieldWithClearButton>
          <HelperText
            error={meta?.error}
            isError={error}
            isValueChanged={showChangedMessage}
            isValueClear={willBeClear}
            touched={touched}
          />
          { isShowDefaultValueHelp
            ? (<Box component="p" className={classes.defaultValueHelpText}>{defaultValueHelpText}<Box className={classes.defaultValueHelp}>{defaultValueHelp}</Box></Box>)
            : null
          }
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </FormControl>
  );
}

export default RenderWeightFormField;
