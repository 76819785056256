// Core
import React, {
  memo, useEffect, useState, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// UI
import { PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import Paging from './components/Paging/Paging';
// Engine
import { pageSizes } from '../../../../../../engine/config/globalConfig';
// Hooks
import { usePrevious } from '../../../../../hooks/usePrevious';

function PagingPanelComponent(props) {
  const {
    customPageSizes, updateHooks, isLoading,
    bottomComponent, totalCount, rowsLength,
    setPagingHeight, hiddeRowsLabel, refreshHook,
  } = props;
  const { i18n, t } = useTranslation();
  const [showHookComponent, setShowHookComponent] = useState(false);
  const prevLanguage = usePrevious(i18n.language);
  const currentLanguage = i18n.language;
  const isPatchedPaging = Boolean(updateHooks) || Boolean(bottomComponent);

  const onRefreshHook = useCallback(() => {
    setShowHookComponent(true);
    if (!isLoading) {
      setTimeout(() => {
        setShowHookComponent(false);
      }, 1000);
    }
  }, [isLoading]);

  useEffect(() => {
    if (prevLanguage !== undefined && prevLanguage !== currentLanguage && isLoading === false) {
      onRefreshHook();
    }
  }, [isLoading, currentLanguage, onRefreshHook, prevLanguage]);

  const PagingContainer = pagingContainerProps => (
    <Paging
      setPagingHeight={setPagingHeight}
      isPatchedPaging={isPatchedPaging}
      onRefreshHook={onRefreshHook}
      updateHooks={updateHooks}
      isLoading={isLoading}
      showHookComponent={showHookComponent}
      bottomComponent={bottomComponent}
      pagingContainerProps={pagingContainerProps}
      rowsLength={rowsLength}
      totalCount={totalCount}
      hiddeRowsLabel={hiddeRowsLabel}
      refreshHook={refreshHook}
    />
  );

  return (
    <PagingPanel
      pageSizes={customPageSizes || pageSizes}
      containerComponent={PagingContainer}
      messages={{
        showAll: t('Показать все'),
        rowsPerPage: `${t('Выводить по')}`,
        info: t('{from} - {to} из {count}'),
      }}
    />
  );
}

PagingPanelComponent.propTypes = {
  customPageSizes: PropTypes.arrayOf(PropTypes.number),
  updateHooks: PropTypes.func,
  setPagingHeight: PropTypes.func,
  hiddeRowsLabel: PropTypes.bool,
  isLoading: PropTypes.bool,
  bottomComponent: PropTypes.element,
  totalCount: PropTypes.number,
  rowsLength: PropTypes.number,
  refreshHook: PropTypes.func,
};


export default memo(PagingPanelComponent);
