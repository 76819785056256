export const types = Object.freeze({
  SET_CONTENT_PRODUCT_LIST: 'SET_CONTENT_PRODUCT_LIST',
  SET_CONTENT_PRODUCT_SEND_QUERY: 'SET_CONTENT_PRODUCT_SEND_QUERY',
  MERGE_CONTENT_PRODUCT_STATUS_VALUE: 'MERGE_CONTENT_PRODUCT_STATUS_VALUE',
  SET_CONTENT_PRODUCT_CURRENT_PAGE: 'SET_CONTENT_PRODUCT_CURRENT_PAGE',
  SET_CONTENT_PRODUCT_FILTERS: 'SET_CONTENT_PRODUCT_FILTERS',
  SET_CONTENT_PRODUCT_PAGE_SIZE: 'SET_CONTENT_PRODUCT_PAGE_SIZE',
  SET_CONTENT_PRODUCT_SORTING: 'SET_CONTENT_PRODUCT_SORTING',
  SET_CONTENT_PRODUCT_RELOAD: 'SET_CONTENT_PRODUCT_RELOAD',
  SET_CONTENT_PRODUCT_INITIAL_STATE: 'SET_CONTENT_PRODUCT_INITIAL_STATE',
  SET_CONTENT_PRODUCT_SETTING_STATUS: 'SET_CONTENT_PRODUCT_SETTING_STATUS',
  SET_CONTENT_PRODUCT_SETTING_PENDING: 'SET_CONTENT_PRODUCT_SETTING_PENDING',
  SET_CONTENT_PRODUCT_SELECTION: 'SET_CONTENT_PRODUCT_SELECTION',
  SET_ALL_PRODUCTS_FEATURE_STATE: 'SET_ALL_PRODUCTS_FEATURE_STATE',
  SET_ACTIVE_PRODUCT_CATEGORY: 'SET_ACTIVE_PRODUCT_CATEGORY',
  SET_PRODUCT_CATEGORIES_LIST: 'SET_PRODUCT_CATEGORIES_LIST',
  CHANGE_PRODUCT_DATA_ACTIVE_EDIT: 'CHANGE_PRODUCT_DATA_ACTIVE_EDIT',
  SET_CONTENT_PRODUCT_ITEMS: 'SET_CONTENT_PRODUCT_ITEMS',
  SET_MODAL_CHANGE_CATEGORY_ERROR_OPEN: 'SET_MODAL_CHANGE_CATEGORY_ERROR_OPEN',
  SET_IS_CONTENT_PRODUCT_CATEGORIES_LIST_LOADING: 'SET_IS_CONTENT_PRODUCT_CATEGORIES_LIST_LOADING',
  MERGE_CONTENT_PRODUCT_LOG: 'MERGE_CONTENT_PRODUCT_LOG',
  SET_MODAL_WIDTH_CATEGORY_OPEN: 'SET_MODAL_WIDTH_CATEGORY_OPEN',
  SET_MODAL_WIDTH_CATEGORY_VALUE: 'SET_MODAL_WIDTH_CATEGORY_VALUE',
  SET_ALLO_EXPRESS_MODAL: 'SET_ALLO_EXPRESS_MODAL',
  SET_ALLO_EXPRESS_WARNING_POPUP: 'SET_ALLO_EXPRESS_WARNING_POPUP',
});
