import Box from '@mui/material/Box';
import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { GridSettingsIcon } from '../Icons/GridSettingsIcon';

export function ButtonGridSettings({
  onClick, putSetting, sx, tooltipText, ...props
}) {
  const [isClicked, setIsClicked] = useState(false);
  const handleMouseDown = () => setIsClicked(true);
  const handleMouseUp = () => setIsClicked(false);

  const handleOpen = (e) => {
    document.getElementById('main-wrapper').style.overflow = 'hidden';
    putSetting(e);
    setTimeout(() => {
      document.getElementById('main-wrapper').style.removeProperty('overflow');
    }, 2000);
  };

  return (
    <Tooltip title={tooltipText || ''} placement="bottom-end">
      <Box
        role="button"
        onClick={handleOpen}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        tabIndex={0}
        sx={{
          ...sx,
          m: '5px',
          cursor: 'pointer',
          transition: 'transform 0.15s ease',
          transform: isClicked ? 'scale(0.9)' : 'scale(1)',
          '&:hover': { opacity: 0.8 },
        }}
        {...props}
      >
        <GridSettingsIcon />
      </Box>
    </Tooltip>
  );
}
