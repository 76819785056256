// Core
import React, { useState, memo } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// UI
import TextField from '@mui/material/TextField';
// Components
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NumberFormatCustom from '../../../../../../../../components/NumberFormat/NumberFormatCustom';
// Containers
import ConfirmButtons from '../../../../../../../../components/Buttons/ConfirmButtons/ConfirmButtons';
import EditableContainer from '../../../../../../../../components/_Table/TableCell/components/EditableContainer/EditableContainer';
import { onChangeWithValidateWeight } from '../../../../../ContentProductEdit/utils/validateWeight';
import { setErrorMessage } from '../../../../../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { maxWeight } from '../../../../../../../../../_helpers/validationsForm';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'block',
    '@global input': {
      boxSizing: 'border-box',
      fontSize: 14,
      height: 35,
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(1.25),
      paddingTop: theme.spacing(0.5),
      textOverflow: 'ellipsis',
    },
    '@global .MuiOutlinedInput-adornedEnd': {
      paddingRight: theme.spacing(1.25),
    },
    '@global legend': {
      display: 'none',
    },
    '@global fieldset': {
      top: 0,
      borderColor: 'inherit !important',
    },
  },
}));

function DecimalFieldWithConfirmButtons(props) {
  const {
    error, disabled, featureName, isEditable, onConfirm, productId,
    success, value, required, type, valuesPlain, fullText, showDeleteButton, code,
  } = props;

  const initValue = value || '';
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [textFieldValue, setTextFieldValue] = useState(initValue);
  const classes = useStyles();
  const isLogisticsWeight = code === 'product_logistics_weight';

  const onChangeHandler = (e) => {
    if (isLogisticsWeight) {
      onChangeWithValidateWeight(e, setTextFieldValue);
    } else {
      const { value: text } = e;
      setTextFieldValue(text);
    }
  };

  const onConfirmHandler = (payload) => {
    if (isLogisticsWeight && Number(payload) > maxWeight) {
      dispatch(setErrorMessage(t('Введите значение меньше 65 535'), t('Ошибка')));
      return;
    }
    onConfirm({ [featureName]: payload });
  };

  const handleOnBlur = () => {
    if (isLogisticsWeight) {
      if (textFieldValue.endsWith('.')) {
        setTextFieldValue(textFieldValue.substring(0, textFieldValue.length - 1));
      }
    }
  };

  return (
    <EditableContainer isEditable={isEditable} fullText={fullText} text={textFieldValue}>
      <ConfirmButtons
        key={productId}
        error={error}
        disabled={disabled}
        required={required}
        notEmpty={textFieldValue !== ''}
        onConfirm={onConfirmHandler}
        onReject={setTextFieldValue}
        success={success}
        type={type}
        value={textFieldValue.trim()}
        valuesPlain={valuesPlain}
        initValue={initValue}
        showDeleteButton={showDeleteButton}
      >
        <TextField
          className={classes.input}
          value={textFieldValue}
          onChange={onChangeHandler}
          id={`formatted-decimal-input__${productId}`}
          InputProps={{
            inputComponent: !isLogisticsWeight ? NumberFormatCustom : undefined,
          }}
          margin="none"
          variant="outlined"
          onBlur={handleOnBlur}
        />
      </ConfirmButtons>
    </EditableContainer>
  );
}

DecimalFieldWithConfirmButtons.propTypes = {
  showDeleteButton: PropTypes.bool,
  error: PropTypes.bool,
  fullText: PropTypes.bool,
  disabled: PropTypes.bool,
  featureName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isEditable: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  success: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  valuesPlain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  code: PropTypes.string,
};

DecimalFieldWithConfirmButtons.defaultProps = {
  showDeleteButton: true,
  error: false,
  required: false,
  disabled: false,
  isEditable: false,
  success: false,
  value: '',
};

export default memo(DecimalFieldWithConfirmButtons);
