import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';
import { useCallback } from 'react';
import Modal from '../../../../../containers/Modals/Modal/Modal';

const CancelModal = ({ closeModal, navigateBack, handleCloseModal }) => {
  const { t } = useTranslation();

  const handleCancel = useCallback(() => {
    navigateBack();
  }, []);

  return (
    <Modal
      modalOpen={closeModal}
      handleModalToggle={handleCloseModal}
      handleModalCancel={handleCloseModal}
      handleModalSend={handleCancel}
      title={t('Хотите закрыть страницу без сохранения?')}
      modalMaxWidth="sm"
      sendText={t('Закрыть страницу')}
      cancelText={t('Отменить')}
      buttonSendIcon={null}
    >
      <Box sx={{ padding: '16px' }}>{t('Подчеркиваем, что введенные данные сохранены не будут.')} </Box>
    </Modal>
  );
};

CancelModal.propTypes = {
  closeModal: PropTypes.bool,
  navigateBack: PropTypes.func,
  handleCloseModal: PropTypes.func,
};

export default CancelModal;
