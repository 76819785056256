import { apply, put } from 'redux-saga/effects';
import { api } from '../../../../config/api';
import { actions } from '../../actions';

export function* callGetPromotionHistoryListWorker({ payload }) {
  yield put(actions.setList({ pending: true }));
  const response = yield apply(api, api.promotions.getPromotionHistoryList, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.setPromotionHistoryList(response.data));
  }
}
