import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import RefreshIcon from '@mui/icons-material/Refresh';
import HistoryIcon from '@mui/icons-material/History';
import Button from '@mui/material/Button';
import { pageLinks } from '../../../../engine/config/routes';
import { asyncActions } from '../../../../engine/core/promotions/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { PromoForm } from './PromoForm';
import { collectCategoryIds, getStatusColor, promotionStatuses } from '../../../../engine/config/promotions';
import Loading from '../../../components/Loading/Loading';
import TabsNav from '../../../containers/Tabs/TabsNav';
import TabsNavMenu from '../../../containers/Tabs/TabsNavMenu';
import TabItemMenu from '../../../components/Tabs/TabMenuItem/TabItemMenu';
import TabsPanel from '../../../containers/Tabs/TabsPanel';
import { IconButtonStyles } from '../../../components/_Table/TableCell/Styles';
import TabsContainer from '../../../containers/Tabs/TabsContainer';
import { useUserRole } from '../../../hooks/useUserRole';
import { userRoles } from '../../../../engine/config/userRoles';
import { PromoChangeHistoryTable } from './PromoChangeHistoryTable';
import CancelModal from '../../../containers/Modals/CancelModal/CancelModal';
import { PageHeaderWithControls } from '../../../components/PageHeader/PageHeaderWithControls';
import { SplitButtonLight } from '../../../components/Buttons/SplitButtonLight';

export function PromotionEditPage() {
  const { hashId } = useParams();
  const dispatcher = useDispatch();
  const promotion = useSelector(selectors.promotions.getPromotionForEdit);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('editPromo');
  const isPending = useSelector(selectors.promotions.isPending);

  useEffect(() => {
    dispatcher(asyncActions.getPromotionById(hashId));
  }, []);

  const handleChangeTab = (event, value) => {
    setSelectedTab(value);
  };

  const methods = useForm({
    defaultValues: promotion ?? {},
    mode: 'onTouched',
    shouldUnregister: false,
  });

  useEffect(() => {
    if (promotion && JSON.stringify(promotion) !== JSON.stringify(methods.getValues())) {
      methods.reset(promotion, { keepDirty: false, keepTouched: false });
    }
  }, [promotion, methods]);

  const {
    handleSubmit,
    formState: { isDirty, isValid },
  } = methods;

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const fetchHistoryHandler = () => {
    dispatcher(asyncActions.getPromotionHistoryList(hashId));
  };
  const onSubmit = (data) => {
    if (!isValid) {
      return;
    }
    const categoriesIds = collectCategoryIds(data.categories);
    const brandsIds = data.stopBrands.map(brand => brand.value);

    const promotionData = {
      ...data,
      categories: categoriesIds,
      stopBrands: brandsIds,
    };

    dispatcher(asyncActions.updatePromoAsync(hashId, promotionData));
    fetchHistoryHandler();
  };
  const handleNavigateBack = () => {
    navigate(pageLinks.advertising.promoList);
  };
  const handleReturn = () => {
    if (isDirty) {
      setCancelModalOpen(true);
    } else {
      handleNavigateBack();
    }
  };

  const isAdminRole = useUserRole(userRoles.admin);
  const displayTab = currentTab => (selectedTab === currentTab) ? 'flex' : 'none';

  useEffect(() => {
    fetchHistoryHandler();
  }, []);

  const isPromotionFinished = promotion?.promoStatus === promotionStatuses.STATUS_FINISHED;

  const titlesStyle = {
    fontSize: '12px',
    color: 'rgba(48, 48, 48, 0.87)',
  };

  const fieldsStyle = {
    fontSize: '14px',
    textAlign: 'end',
  };

  const boxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    p: '20px',
  };

  const handleDelete = (showModal = true) => {
    if (showModal) {
      setDeleteModalOpen(true);
    } else {
      dispatcher(asyncActions.deletePromotionById(hashId));
      handleNavigateBack();
    }
  };

  if (isPending) {
    return (
      <>
        <Box sx={{
          width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
        }}
        >
          <Loading isLoading={isPending} />
        </Box>
      </>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageHeaderWithControls returnBack={handleReturn} title={t('Редагування акції')}>
          <Button
            sx={{ height: '32px', mr: '8px' }}
            variant={'outlined'}
            onClick={handleDelete}
          >
            {t('Удалить')}
          </Button>
          <SplitButtonLight options={[
            {
              title: t('Зберегти і повернутися'),
              onClick: () => {
                handleSubmit(onSubmit)();
                handleNavigateBack();
              },
              disabled: isPending || !isDirty || isPromotionFinished,
            },
            {
              title: t('Зберегти зміни'),
              onClick: () => {
                handleSubmit(onSubmit)();
              },
              disabled: isPending || !isDirty || isPromotionFinished,
            },
          ]}
          />
        </PageHeaderWithControls>
        <Paper sx={{ ml: '20px', mr: '20px' }}>
          <Grid
            container
            justifyContent="center"
            sx={{
              m: '0', p: '0', btlr: '6px !important', height: '92%',
            }}
          >
            <TabsContainer padding={'0px'}>
              <TabsNav>
                <Box sx={{ borderBottom: '1px solid rgba(48, 48, 48, 0.08)' }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ p: '20px' }}
                  >
                    <Typography fontWeight={400} fontSize={'16px'} color={'rgba(48, 48, 48, 1)'}>
                      {promotion?.promoName}
                    </Typography>
                  </Box>
                  <Divider sx={{ ml: '20px', mr: '20px' }} />
                  <Box sx={boxStyle}>
                    <Typography sx={titlesStyle}>Статус акції:</Typography>
                    <Box width={'10px'} />
                    <Typography sx={fieldsStyle}><Box sx={{ color: getStatusColor(promotion.promoStatus) }}>{promotion.statusLabel}</Box></Typography>
                  </Box>
                </Box>
                <TabsNavMenu
                  value={selectedTab}
                  onChange={handleChangeTab}
                >
                  <TabItemMenu value="editPromo" label={'Редагування акції'} />
                  {isAdminRole && (<TabItemMenu value={'changeHistory'} label={'Історія змін'} />)}
                </TabsNavMenu>
              </TabsNav>
              <TabsPanel style={{ padding: '0', display: displayTab('editPromo') }} value={'editPromo'} index={'editPromo'} padding={'0px'}>
                <Grid item xs={12} lg={12}>
                  <PromoForm disabledEdit={isPromotionFinished} />
                </Grid>
              </TabsPanel>
              {isAdminRole && (
                <>
                  <TabsPanel
                    style={{ display: displayTab('changeHistory') }}
                    value={'changeHistory'}
                    index={'changeHistory'}
                    padding={'0px'}
                  >
                    <Grid item xs={12} lg={12} sx={{ p: '5px' }}>
                      <Box sx={{ mb: '5px', width: 'inherit' }}>
                        <Box sx={{ mb: '15px' }}>
                          <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          >
                            <Typography color={'#303030'} fontWeight={700} fontSize={18}>Історія змін</Typography>
                            <IconButtonStyles
                              sx={{
                                borderRadius: '5px', padding: '5px', mr: '10px', border: '1px solid rgba(48, 48, 48, 0.08)',
                              }}
                              onClick={fetchHistoryHandler}
                              color="inherit"
                              size="large"
                            >
                              <RefreshIcon />
                            </IconButtonStyles>
                          </Box>
                        </Box>
                        <Paper sx={{ p: '10px' }}>
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            padding: '10px',
                          }}
                          >
                            <IconButtonStyles disabled sx={{ borderRadius: '5px', padding: '5px', mr: '10px' }} color="inherit" size="large">
                              <HistoryIcon />
                            </IconButtonStyles>
                            <Typography fontWeight={700} fontSize={'14px'}>Історія змін</Typography>
                            <Divider />
                          </Box>
                          <PromoChangeHistoryTable hashId={hashId} />
                        </Paper>
                      </Box>
                    </Grid>
                  </TabsPanel>
                </>
              )}
            </TabsContainer>
          </Grid>
        </Paper>
        <CancelModal
          titleText={t('Хотите закрыть страницу без сохранения?')}
          text={t('Подчеркиваем, что введенные данные сохранены не будут.')}
          cancelText={t('Отменить')}
          closeModal={cancelModalOpen}
          sendText={t('Покинути сторінку')}
          handleCancel={() => {
            setCancelModalOpen(false);
            handleNavigateBack();
          }}
          handleCloseModal={() => setCancelModalOpen(false)}
        />
        <CancelModal
          closeModal={deleteModalOpen}
          titleText={'Бажаєте видалити акцію?'}
          sendText={'Видалити'}
          handleCancel={() => {
            handleDelete(false);
          }}
          text={'Акція буде повністю видалена з Кабінету без можливості відновлення'}
          handleCloseModal={() => setDeleteModalOpen(false)}
          navigateBack={handleNavigateBack}
        />
      </form>
    </FormProvider>
  );
}
