// Core
import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';
// UI
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
// Icons
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';

import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { COLOR } from '../../../containers/App/AppStyles';
import { useUserSomeRole } from '../../../hooks/useUserSomeRole';
import { userRoles } from '../../../../engine/config/userRoles';

const styles = {
  holder: {
    display: 'flex',
    background: '#ebf0f6',
    border: '1px solid #94b3df',
    borderRadius: '6px',
    marginBottom: '25px',
    padding: '5px 15px',
    boxShadow: 'none',
    width: '100%',
  },
  icon: {
    fontSize: '20px',
    marginTop: '3px',
    color: COLOR.controls.dark,
  },
  textHolder: {
    paddingLeft: '13px',
    paddingRight: '20px',
    fontSize: '16px',
    lineHeight: 1.4,
    color: COLOR.grey.main,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '16px',
    margin: '0',
  },
  schedule: {
    margin: '25px 0 0',
  },
  email: {
    textDecoration: 'underline',
    marginLeft: '5px',
  },
  mainText: {
    margin: '0',
  },
};
const useStyles = makeStyles(styles);

const today = new Date();
const startDate = new Date(2024, 11, 24);
const endDate = new Date(2025, 0, 2);
const isWithinRange = today >= startDate && today <= endDate;

const ALLO_EXPRESS_DELIVERY_POINTS_URL = 'https://docs.google.com/spreadsheets/d/1E-LP7mpgf3vw_TNWtp-M0kGsXUiAGps5/edit?gid=2121675966#gid=2121675966';
const ALLO_EXPRESS_DELIVERY_POINTS_CHRISTMAS_URL = 'https://docs.google.com/spreadsheets/d/1i6Z6qk52TNqf2Bmq0z-oC6kyek8d4qOzJq2mlsXTvyA/edit?gid=0#gid=0';

function OrderAlloExpressInformer({ shippingId }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const hasAccess = useUserSomeRole([
    userRoles.admin,
    userRoles.manager,
    userRoles.salesManager,
    userRoles.partner,
    userRoles.partnerActive,
    userRoles.callCenter,
  ]);

  if (!hasAccess || !(shippingId === '17' || shippingId === '18')) {
    return null;
  }

  return (
    <Paper className={classes.holder}>
      <SpeakerNotesOutlinedIcon className={classes.icon} />
      <Box className={classes.textHolder}>
        <Box component="h3" className={classes.title}>{t('Доставка ALLO Express')}</Box>
        <Box component="p" className={classes.mainText}>
          {t('Вы можете самостоятельно доставить этот заказ в пункт приема посылок. Ознакомиться с адресами пунктов можно')}&nbsp;
          <Link href={ALLO_EXPRESS_DELIVERY_POINTS_URL} target="_blank">{t('по ссылке')}</Link>
        </Box>
        {isWithinRange && (
          <>
            <Box sx={{ height: '20px' }} />
            <Box component="p" className={classes.mainText}>
              <b>{'Звертаємо вашу увагу'}</b>{', що графік роботи точок прийому посилок буде оновлено на наступні дати:'}
            </Box>
            <Box component="p" className={classes.mainText}>{'24.12.2024 – 25.12.2024'}</Box>
            <Box component="p" className={classes.mainText}>{'31.12.2024 – 01.01.2025'}</Box>
            <Box component="p" className={classes.mainText}> {'Ознайомитися з оновленим графіком можна '}
              <Link href={ALLO_EXPRESS_DELIVERY_POINTS_CHRISTMAS_URL} target="_blank">{t('по ссылке')}</Link>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
}


export default withStyles(styles)(OrderAlloExpressInformer);
