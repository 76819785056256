import { apply, put } from 'redux-saga/effects';
import { api } from '../../../../config/api';
import { actions } from '../../actions';

export function* callGetPromotionsListWorker({ payload: params }) {
  yield put(actions.setList({ pending: true }));
  const response = yield apply(api, api.promotions.getList, [params]);

  let data = {
    items: [],
    totalCount: 0,
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    data = Object.assign(data, response.data);
  }
  yield put(actions.setList(data));
}
