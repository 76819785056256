import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import TooltipCantFinish from '../../TooltipCantFinish';


const FinishImportButton = ({
  isEditMode, disabled, showConfirmModal, control,
}) => {
  const { isDirty } = useFormState({ control });
  const { t } = useTranslation();

  if (!isDirty || !isEditMode) {
    return null;
  }

  return (
    <>
      <TooltipCantFinish>
        <LoadingButton
          variant="contained"
          sx={{ mt: '30px', mr: 1 }}
          disabled={disabled}
          loading={disabled}
          onClick={showConfirmModal}
        >
          {t('Завершить импорт')}
        </LoadingButton>
      </TooltipCantFinish>
    </>
  );
};

export default FinishImportButton;
