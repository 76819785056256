/* eslint-disable no-param-reassign */
export const transformDataForProductsGroupTable = (products = [], features = []) => {
  const featuresObj = products?.[0]?.features ?? {};
  const featuresInResponse = Object.keys(featuresObj).map(Number);
  const additionalColumns = features.filter(feat => featuresInResponse.includes(feat.value)).map(item => ({ ...item, name: item.value.toString(), title: item.label }));

  const rows = Object.values(products).map((product) => {
    const featuresKeys = Object.keys(product.features).reduce((acc, feature) => {
      acc[feature] = product.features[feature].label ?? product.features[feature].value;
      return acc;
    }, {});

    return { ...product, ...featuresKeys, value: product.value ?? product.id };
  });


  return { rows, additionalColumns };
};
