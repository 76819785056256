// Types
import { asyncTypes } from './asyncTypes';
// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';

export const asyncActions = Object.freeze({
  getInitialStateAsync(path) {
    return getActionCreator(asyncTypes.GET_PRICE_COMPARISON_LIST_INITIAL_STATE_ASYNC, path);
  },
  getListAsync(params) {
    return getActionCreator(asyncTypes.GET_PRODUCT_PRICE_COMPARISON_LIST_ASYNC, params);
  },
  putExportAsync(param) {
    return getActionCreator(asyncTypes.PUT_PRICE_COMPARISON_LIST_EXPORT_ASYNC, param);
  },
  putImportAsync(param) {
    return getActionCreator(asyncTypes.PUT_PRICE_COMPARISON_LIST_IMPORT_ASYNC, param);
  },
});
