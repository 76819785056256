// Components
import {
  Grid, Table, TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import LoaderComponent from '../_Form/Selects/FormSelect/components/LoaderComponent';
import TableCell from '../_Table/TableCell/TableCell';

const HeaderCellComponent = ({ children, ...headerRowProps }) => {
  if (headerRowProps.tableColumn.column.name === 'delete') {
    return <VirtualTable.Cell />;
  }
  return (<VirtualTable.Cell {...headerRowProps} sx={{ fontSize: '12px !important', paddingLeft: '8px !important' }}>{children}</VirtualTable.Cell>);
};

const RowComponent = ({ row, ...rowProps }) => (<VirtualTable.Row {...rowProps} bgcolor="#fff">{rowProps.children}</VirtualTable.Row>);

const DeleteCell = ({ onDelete, row, rowId }) => (
  <Table.Cell sx={{
    '&.MuiTableCell-root.MuiTableCell-body.TableCell-cell': {
      padding: '4px',
    },
  }}
  >
    <IconButton onClick={() => onDelete(row[rowId], rowId)}>
      <DeleteIcon />
    </IconButton>
  </Table.Cell>
);

const Cell = (cellProps) => {
  const {
    column, row, handleDelete, rowId,
  } = cellProps;
  if (column.name === 'delete') {
    return <DeleteCell row={row} onDelete={handleDelete} rowId={rowId} />;
  }
  return (
    <TableCell
      {...cellProps}
    />
  );
};

const tableColumnExtensions = [
  { columnName: 'delete', width: '48px' },
];


const PreviewTable = ({
  rows, setRows, rowId, columns = [],
}) => {
  const allColumns = [...columns, { name: 'delete', title: null }];
  const handleDelete = (value, id) => {
    const updatedRows = rows.filter(row => row[id] !== value);
    setRows(updatedRows);
  };
  return (
    <>
      {rows?.length === 0 ? <LoaderComponent /> : (
        <Paper>
          <Grid
            rows={rows}
            columns={allColumns}
            getRowId={row => row[rowId]}
            sx={{ maxWidth: '100%' }}
          >
            <Table
              cellComponent={cellProps => <Cell {...cellProps} handleDelete={handleDelete} rowId={rowId} />}
              columnExtensions={tableColumnExtensions}
              rowComponent={RowComponent}
            />
            <TableHeaderRow cellComponent={HeaderCellComponent} />
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default PreviewTable;
