import { COLOR } from '../../../../../containers/App/AppStyles';

const tableHeadFilterCellStyles = theme => ({
  container: props => ({
    paddingRight: 0,
    position: 'relative',
    display: 'flex',
    alignItems: 'end',

    '&:hover svg': !props.disabled && {
      color: COLOR.grey['72'],
    },
  }),
  buttonSmall: {
    position: 'absolute',
    bottom: '3px',
    right: 0,
    height: 24,
    width: 24,
    padding: theme.spacing(0.5),
    '&.Mui-disabled': {
      opacity: '0',
    },
  },
  iconSmall: {
    color: COLOR.grey['38'],
    fontSize: 18,
    '&:hover': {
      color: COLOR.grey['72'],
    },
  },
  inputWithClear: {
    flexGrow: 1,
    overflow: 'hidden',

    '& .MuiInputBase-root': {
      minHeight: '28px',
      paddingLeft: '15px',
      paddingRight: '26px',
      caretColor: COLOR.controls.dark,
      color: COLOR.grey.main,

      '&:after': {
        borderColor: COLOR.grey['72'],
      },

      '&.Mui-focused': {
        '&:after': {
          borderColor: COLOR.controls.dark,
        },
      },
    },
    '& .MuiInput-input': {
      paddingBottom: 5,
      '&::placeholder': {
        color: COLOR.grey['38'],
        opacity: 1,
      },
    },
    '& .select_tags--is-disabled .MuiTypography-root + div': {
      display: 'none',
    },
    '& .MuiFormControl-root': {
      overflowX: 'visible',
    },
  },
  selectFilter: props => ({
    flexGrow: 1,
    maxWidth: '100%',
    position: 'relative',

    '& .select-tags__value-container': {
      '& .select-tags__single-value': {
        paddingLeft: '7px',
      },
    },

    '& .MuiInputBase-input': {
      padding: 0,
      '& .select-tags__value-container': {
        maxWidth: 'calc(100% - 24px)',
      },
      '& > .MuiBox-root': {
        marginTop: '-4px',
      },
      '& > div': {
        marginTop: '-2px',
      },
    },

    '& .MuiInputAdornment-positionEnd': {
      alignSelf: 'flex-end',
      height: '23px',
    },

    '& .MuiInputBase-root': {
      paddingRight: '24px',
      minHeight: 'initial',
      marginTop: '2px',
      cursor: 'pointer',
      '&:after': {
        borderColor: COLOR.grey['72'],
      },
    },
    '& .select_tags--is-disabled .MuiTypography-root + div': {
      display: 'none',
    },
    '& .MuiFormControl-root': {
      overflowX: 'visible',
    },
    '& .select-tags__indicator': {
      padding: 0,
    },
    '&:hover': !props.disabled && {
      '&:after': {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        borderBottom: `2px solid ${COLOR.grey['72']}`,
      },
    },
  }),
  dateWithClear: {
    flexGrow: 1,
    overflow: 'hidden',
    position: 'relative',
    '& .MuiInput-input': {
      paddingBottom: 0,
      cursor: 'pointer',
    },
    '&:hover': {
      '&:after': {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        borderBottom: `2px solid ${COLOR.grey['72']}`,
      },
    },
  },
  inputWithClearActive: {
    '& .select_tags__dropdown-indicator': {
      color: COLOR.grey['78'],
    },
  },

  inputWrapper: {
    verticalAlign: 'bottom',
    paddingBottom: '0',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    maxWidth: 1,
    background: COLOR.grey['2'],

    '&.MuiTableCell-head': {
      padding: 0,
    },
    '&:first-child': {
      paddingLeft: 0,
    },
    '& .MuiInputBase-root': {
      '&:before': {
        display: 'none',
      },
    },
  },
  selectWrapper: {
    padding: 0,
    fontSize: 13,
    background: COLOR.grey['2'],
    verticalAlign: 'bottom',
    '&.MuiTableCell-head': {
      padding: 0,
    },
  },
});

export default tableHeadFilterCellStyles;
