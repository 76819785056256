import { useDispatch, useSelector } from 'react-redux';
import SplitButton from '../../../../../../components/Buttons/SplitButton/SplitButton';
import AlloExpressIcon from '../../../../../../icons/AlloExpressIcon';
import { selectors } from '../../../../../../../engine/config/selectors';
import { usePartnerRole } from '../../../../../../hooks/usePartnerRole';
import { actions } from '../../../../../../../engine/core/contentProduct/actions';

const alloExpressOptions = ['Додати товари до Алло Express', 'Видалити товари з Алло Express'];

const AlloExpressAddButton = () => {
  const dispatch = useDispatch();
  const selection = useSelector(selectors.contentProduct.selection);
  const alloExpressStatus = useSelector(selectors.user.alloExpressStatus);
  const isPartner = usePartnerRole();
  const partnerNotConnectedToAlloExpress = isPartner && alloExpressStatus !== 'CONNECTED';

  const handleOpenAlloExpressCategoriesModal = (index, option) => {
    dispatch(actions.setAlloExpressModal({ showModal: true, type: option }));
  };

  return (
    <SplitButton
      options={alloExpressOptions}
      sx={{ maxHeight: '32px' }}
      disabled={!selection.size || partnerNotConnectedToAlloExpress}
      handleClick={handleOpenAlloExpressCategoriesModal}
      customButtonSx={{ paddingLeft: '12px', paddingRight: '12px' }}
      icon={<AlloExpressIcon sx={{ height: '24px', width: '24px' }} />}
      hideSelectedOption
    />
  );
};

export default AlloExpressAddButton;
