export function PromoTypeSvgIcon() {
  return (
    <>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="30" height="30" rx="7" fill="#303030" fillOpacity="0.08" />
        <path
          d="M19.5 6C20.7426 6 21.75 7.00736 21.75 8.25V13.4247C21.3936 13.2425 21.0172 13.0938 20.625 12.9829V8.25C20.625 7.62868 20.1213 7.125 19.5 7.125C18.8787 7.125 18.375 7.62868 18.375 8.25V12.7752C17.9887 12.81 17.6126 12.8804 17.25 12.9829V8.25C17.25 7.00736 18.2574 6 19.5 6ZM12.8253 19.9054C12.9251 20.5407 13.1216 21.144 13.3992 21.6996C12.3851 21.4813 11.625 20.5794 11.625 19.5V11.625C11.625 10.3824 12.6324 9.375 13.875 9.375C15.1176 9.375 16.125 10.3824 16.125 11.625V13.4247C15.7225 13.6304 15.3455 13.879 15 14.1643V11.625C15 11.0037 14.4963 10.5 13.875 10.5C13.2537 10.5 12.75 11.0037 12.75 11.625V19.5C12.75 19.6429 12.7767 19.7796 12.8253 19.9054ZM8.25 12.75C9.49264 12.75 10.5 13.7574 10.5 15V19.5C10.5 20.7426 9.49264 21.75 8.25 21.75C7.00736 21.75 6 20.7426 6 19.5V15C6 13.7574 7.00736 12.75 8.25 12.75ZM9.375 15C9.375 14.3787 8.87132 13.875 8.25 13.875C7.62868 13.875 7.125 14.3787 7.125 15V19.5C7.125 20.1213 7.62868 20.625 8.25 20.625C8.87132 20.625 9.375 20.1213 9.375 19.5V15ZM24 18.9375C24 21.7334 21.7334 24 18.9375 24C16.1416 24 13.875 21.7334 13.875 18.9375C13.875 16.1416 16.1416 13.875 18.9375 13.875C21.7334 13.875 24 16.1416 24 18.9375ZM19.473 15.9681C19.3045 15.4273 18.5705 15.4273 18.402 15.9681L17.8648 17.6913H16.1267C15.5812 17.6913 15.3544 18.419 15.7957 18.7532L17.2019 19.8182L16.6648 21.5415C16.4962 22.0823 17.09 22.532 17.5313 22.1977L18.9375 21.1328L20.3437 22.1977C20.785 22.532 21.3788 22.0823 21.2102 21.5415L20.6731 19.8182L22.0793 18.7532C22.5206 18.419 22.2938 17.6913 21.7483 17.6913H20.0102L19.473 15.9681Z"
          fill="#303030"
        />
      </svg>
    </>
  );
}
