// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ForEach from 'lodash/forEach';
// UI
import Button from '@mui/material/Button';
import { accessList } from '../../../../../../../engine/config/access';
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions } from '../../../../../../../engine/core/content/actions';
// Hooks
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { useQueryParams } from '../../../../../../hooks/useQueryParams';
import { api } from '../../../../../../../engine/config/api';
import { handleExportResponse } from '../../../../../../../_helpers/handleExportResponse';
import { setErrorMessage, setSuccessMessage } from '../../../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { convertSortingObjectToUrlParams, convertJSObjectToUrlParams } from '../../../../../../../_helpers/convertDataToUrlParams';
import { usePartnerRole } from '../../../../../../hooks/usePartnerRole';

function AlloExpressButtonGroups(props) {
  const { sx } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  const attributeSetId = useQueryParams('attributeSetId');
  // TODO: figure out is special accesslist exist or change the rules
  const hasAccessExport = useAccessList(accessList.productsExportPrices);
  const hasAccessImport = useAccessList(accessList.productsImportPrices);
  const isLoading = useSelector(selectors.content.productDownloadPending);
  const selection = useSelector(selectors.contentProduct.selection);
  const filter = useSelector(selectors.contentProduct.filters);
  const sorting = useSelector(selectors.contentProduct.sorting);
  const isPartner = usePartnerRole();


  const downloadProductsProperties = async () => {
    const orderBy = convertSortingObjectToUrlParams(sorting);
    const data = filter.reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, {});
    const paramsByFilter = {};
    const convertedData = convertJSObjectToUrlParams(data);
    ForEach(convertedData, (key, value) => {
      const filterName = value.replace('filter[', '').replace(']', '');
      paramsByFilter[filterName] = convertedData[value];
    });
    const products = selection.toArray().length > 0 ? selection.toArray().join() : undefined;
    const params = {
      categoryId,
      attributeSetId,
      products,
      filter: paramsByFilter,
      orderBy,
    };
    const response = await api.products.exportAlloExpress(params);
    handleExportResponse(response, dispatch, setSuccessMessage, setErrorMessage, t, 'alloExpressProducts.xlsx');
  };
  const handleImport = () => {
    dispatch(actions.setAlloExpressModal({ isOpen: true }));
  };
  return (
    <>
      {hasAccessImport && !isPartner && (
        <Button
          fullWidth
          sx={sx}
          onClick={handleImport}
          disable={isLoading}
          id="import_button"
        >
          {t('Імпорт товарів АЛЛО Express')}
        </Button>
      )}
      {hasAccessExport && !isPartner && (
        <Button
          onClick={downloadProductsProperties}
          fullWidth
          disable={isLoading}
          sx={sx}
          id="export_button"
        >
          {t('Експорт товарів АЛЛО Express')}
        </Button>
      )}
    </>
  );
}


export default AlloExpressButtonGroups;
