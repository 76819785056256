import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import React from 'react';

export function PageHeaderLight({ title, children, ...props }) {
  return (
    <Grid
      {...props}
      sx={{
        pl: '20px', pr: '20px', pt: '20px', pb: '16px',
      }}
      container
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Typography fontSize={'20px'} fontWeight={700}>
          {title}
        </Typography>
      </Box>
      {children}
    </Grid>
  );
}
