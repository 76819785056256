export const getDefaultCategoryValue = (value, type) => {
  switch (type) {
    case 'logisticsWeight': {
      return value?.weight;
    }
    case 'logisticsHeight': {
      return value?.height;
    }
    case 'logisticsWidth': {
      return value?.width;
    }
    case 'logisticsLength': {
      return value?.length;
    }
    default: {
      return null;
    }
  }
};
