import React from 'react';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import { Controller } from 'react-hook-form';
import { COLOR } from '../../../../../containers/App/AppStyles';

const MIN_AMOUNT_OF_PRODUCTS_SKU = 1;


const SKUInput = (props) => {
  const {
    disabled, control,
  } = props;


  return (
    <Controller
      name="tags"
      control={control}
      render={({ field: { value, onChange } }) => (
        <FormControl
          component="fieldset"
          sx={{
            border: 1,
            borderColor: COLOR.grey[38],
            borderRadius: 1,
            height: 'auto',
            minWidth: '33%',
            marginBottom: '2px',
            display: 'flex',
            flexDirection: 'row',
            padding: value.length > 0 ? '14px' : 0,
            '&:hover': {
              borderColor: COLOR.grey[72],
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '8px',
              flex: 1,
              maxWidth: '85%',
            }}
          >
            <InputLabel
              shrink={value.length > 0}
              sx={{
                backgroundColor: 'white',
                padding: '0px 4px',
                margin: 0,
                top: value.length > 0 ? 0 : '-25%',
                color: COLOR.grey[54],
              }}
            >
              SKU товарів
            </InputLabel>
            {value.map((tag, index) => (
              <Chip
                key={index}
                disabled={disabled || value.length === MIN_AMOUNT_OF_PRODUCTS_SKU}
                label={tag.productSku}
                onDelete={() => {
                  const newTags = value.filter((_, i) => i !== index);
                  onChange(newTags);
                }}
                size="small"
                deleteIcon={<ClearIcon />}
                sx={{
                  width: 'auto',
                  display: 'flex',
                  margin: 0,
                  height: '28px',
                  padding: '4px 8px 4px 12px',
                  backgroundColor: tag.isAlloProduct ? COLOR.paleRed : COLOR.grey[4],
                  '&:hover': {
                    cursor: 'text',
                  },
                  '&:focus': {
                    background: COLOR.grey[4],
                    borderColor: COLOR.grey[24],
                  },
                }}
              />
            ))}
          </Box>
        </FormControl>
      )}
    />

  );
};

export default SKUInput;
