export const onChangeWithValidateWeight = (e, callback) => {
  const regex = /^[0-9](?:(?:(?!.*[.,].*[.,])[0-9.,]{0,4}))$/;
  if (regex.test(e.target.value) || e.target.value === '') {
    const inputValue = e.target.value.replace(/,/g, '.');
    callback(inputValue);
  }
  if (e.target.value.length === 1
    && (e.target.value === '0' || e.target.value === '.' || e.target.value === ',')
    && e.nativeEvent.inputType !== 'deleteContentBackward') {
    callback('0.');
  }
};
