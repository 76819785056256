import { COLOR } from '../../../../containers/App/AppStyles';

const SidebarItemStyles = theme => ({
  badge: {
    height: 20,
    minWidth: 20,
    display: 'inline-flex',
    zIndex: 1,
    flexWrap: 'wrap',
    fontSize: '14px',
    lineHeight: '1',
    alignItems: 'center',
    borderRadius: 5,
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '3.5px 3px',
  },
  badgePadding: {
    padding: '3px 6px',
  },
  menuLink: {
    position: 'relative',
    display: 'block',
  },
  miniMenuListBox: {
    opacity: 0,
    backgroundColor: COLOR.darkStyle.main,
    marginLeft: 0,
  },
  miniMenuListBoxActive: {
    opacity: 1,
  },
  menuItem: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: 'center',
    '&:hover': {
      background: COLOR.darkStyle.white['8'],
    },
  },
  menuIcon: {
    minWidth: 'auto',
    marginLeft: '14px',
    marginRight: '10px',
    fontSize: 20,
    height: 24,
    width: 24,
    '& svg': {
      fill: COLOR.darkStyle.fontColorMain,
    },
  },
  menuText: {
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: '14px',
    color: COLOR.darkStyle.fontColorMain,
  },
  menuTextCollapsed: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '700',
    display: 'flex',
    color: COLOR.darkStyle.fontColorMain,
  },
  menuLabel: {
    background: COLOR.darkStyle.white['8'],
  },
  menuSubItem: {
    paddingTop: 14,
    paddingBottom: 13,
    paddingLeft: 55,
    paddingRight: 20,
    '&:hover': {
      background: COLOR.darkStyle.white['8'],
    },
  },
  menuCollapsed: {
    position: 'relative',
  },
  menuActive: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    '& $menuText': {
      color: '#fff !important',
    },
    '& $menuIcon svg': {
      fill: '#fff !important',
    },
  },
  menuClosed: {
    position: 'relative',
    backgroundColor: 'transparent',
  },
  caret: {
    minWidth: 'auto',
    marginLeft: '13px',
    marginRight: '10px',
    color: COLOR.darkStyle.fontColorMain,
  },
  primary: {
    backgroundColor: '#F97988',
    color: theme.palette.primary.contrastText,
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  miniMenuList: {
    position: 'absolute',
    top: 0,
    left: '100%',
    width: '220px',
    '& $menuSubItem': {
      paddingLeft: 20,
    },
  },
  miniMenuListBottom: {
    bottom: '0',
    top: 'unset',
    position: 'fixed',
    left: '48px',
    paddingBottom: '10px',
  },
});

export default SidebarItemStyles;
