import { getActionCreator } from '../../../_helpers/getActionCreator';
import { types } from './types';

export const actions = {
  setList(data) {
    return getActionCreator(types.SET_PROMOTIONS_LIST, data);
  },
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_PROMOTIONS_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_PROMOTIONS_FILTERS, filters);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_PROMOTIONS_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_PROMOTIONS_SORTING, sorting);
  },
  setInitialState(initialState) {
    return getActionCreator(types.SET_PROMOTIONS_INITIAL_STATE, initialState);
  },
  setSelection(selected) {
    return getActionCreator(types.SET_PROMOTIONS_SELECTION, selected);
  },
  setPromotionPending(value) {
    return getActionCreator(types.SET_PROMOTION_PENDING, value);
  },
  setPromotionForEdit(value) {
    return getActionCreator(types.SET_PROMOTION_FOR_EDIT, value);
  },
  setPromotionHistoryList(data) {
    return getActionCreator(types.SET_PROMOTION_HISTORY_LIST, data);
  },
  setPromotionListSettingsStatus(settingsStatus) {
    return getActionCreator(types.SET_PROMOTION_LIST_SETTING_STATUS, settingsStatus);
  },
  setPromotionListSettingsPending(settingPending) {
    return getActionCreator(types.SET_PROMOTION_LIST_SETTING_PENDING, settingPending);
  },
};
