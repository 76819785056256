
export function VisibilityIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="4.5" stroke="#303030" strokeOpacity="0.08" />
      <g clipPath="url(#clip0_915_49897)">
        <path d="M12 9.09091C14.0673 9.09091 15.9109 10.2527 16.8109 12.0909C15.9109 13.9291 14.0727 15.0909 12 15.0909C9.92727 15.0909 8.08909 13.9291 7.18909 12.0909C8.08909 10.2527 9.93273 9.09091 12 9.09091ZM12 8C9.27273 8 6.94364 9.69636 6 12.0909C6.94364 14.4855 9.27273 16.1818 12 16.1818C14.7273 16.1818 17.0564 14.4855 18 12.0909C17.0564 9.69636 14.7273 8 12 8ZM12 10.7273C12.7527 10.7273 13.3636 11.3382 13.3636 12.0909C13.3636 12.8436 12.7527 13.4545 12 13.4545C11.2473 13.4545 10.6364 12.8436 10.6364 12.0909C10.6364 11.3382 11.2473 10.7273 12 10.7273ZM12 9.63636C10.6473 9.63636 9.54545 10.7382 9.54545 12.0909C9.54545 13.4436 10.6473 14.5455 12 14.5455C13.3527 14.5455 14.4545 13.4436 14.4545 12.0909C14.4545 10.7382 13.3527 9.63636 12 9.63636Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_915_49897">
          <rect width="16" height="16" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
}
