/* eslint-disable react/no-unescaped-entities */
// Core
import React from 'react';
// UI
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from '@mui/material';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header';
import Logo from '../../../assets/img/logo_light.svg';
import LogoMobile from '../../../assets/img/logo_short.svg';
import ContentWrapper from '../../containers/ContentWrapper/ContentWrapper';


const ServiceContracts = () => (
  <>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <Header
      logoAltText="АЛЛО - личный кабинет"
      logo={Logo}
      LogoMobile={LogoMobile}
      onlyLogo
      isHideLangMenu
    />
    <ContentWrapper>
      <Container maxWidth="lg">
        <Box
          component="div"
          boxShadow={3}
          style={{
            padding: '15px 20px',
            minHeight: '100%',
          }}
        >
          <Box sx={{ padding: '16px' }}><Typography variant="h4" component="h1" gutterBottom color="primary">Як замовити послуги</Typography>

            <Typography variant="body1" gutterBottom>
              Компанія "Алло" пропонує комплекс послуг із відповідними умовами (далі – Пакет сервісу), які надаються шляхом приєднання Учасника до умов Договору.
              Пакет сервісу може бути активним (доступним для замовлення) або неактивним (недоступним для замовлення), що відображається в Особистому кабінеті Учасника.
              Приєднатися до умов Договору та замовити Пакет сервісу може лише суб’єкт господарювання, який має договірні відносини з ТОВ «Алло» та користується Особистим кабінетом.
            </Typography>
          </Box>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="h4" component="h1" gutterBottom color="primary">Послідовність дій Сторін щодо підключення Пакету сервісу:</Typography>
            <ol>
              <li>Учасник може ознайомитися з умовами Договору у своєму Особистому кабінеті.</li>
              <li>У разі ухвалення рішення щодо приєднання та користування Пакетом сервісу Учасник оформлює та підписує Заяву про приєднання.
                Ця Заява направляється до компанії "Алло" за допомогою сервісу електронного документообігу.
              </li>
              <li>Учасник в своєму Особистому кабінеті ознайомлюється з умовами вибраного Пакету сервісу.</li>
              <li>Натискання на кнопку «Підключити» вибраного Пакету сервісу дає Учаснику можливість користуватися функціональними можливостями в Особистому кабінеті та послугами цього Пакету сервісу.</li>
            </ol>
            Відображення в Особистому кабінеті Учасника відповідних функціональних можливостей користування Пакетом сервісу – означає підключення Учасника до вибраного Пакету сервісу. Доступ до вибраного Пакету сервісу надається на період, передбачений умовами цього Пакету сервісу.
          </Box>
          <Link href="/docs/Договір_співробітництва_24.02.2025.pdf" rel="noreferrer" target="_blank">
            <div style={{ display: 'inline-flex', flexDirection: 'row' }}><PictureAsPdfIcon />&nbsp;Договір співробітництва від 24.02.2025
            </div>
          </Link>
        </Box>
      </Container>
    </ContentWrapper>
  </>
);


export default ServiceContracts;
