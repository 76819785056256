import React from 'react';

export function ClearFilterIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="4.5" stroke="#303030" strokeOpacity="0.08" />
      <path d="M20.179 11.8811C19.3289 11.8801 18.4938 12.1054 17.7595 12.5338C17.0252 12.9622 16.4181 13.5783 16.0005 14.3188V11.2383H14.7148V16.381H19.8576V15.0954H17.04C17.3263 14.5368 17.7562 14.0647 18.2856 13.7275C18.8149 13.3903 19.4245 13.2003 20.0517 13.177C20.6789 13.1536 21.3009 13.2979 21.8539 13.5948C22.4068 13.8917 22.8706 14.3306 23.1976 14.8663C23.5246 15.402 23.7029 16.0152 23.7142 16.6427C23.7255 17.2702 23.5694 17.8893 23.262 18.4365C22.9545 18.9836 22.5068 19.439 21.965 19.7556C21.4231 20.0723 20.8066 20.2388 20.179 20.2381H19.8576V21.5238H20.179C21.4577 21.5238 22.6841 21.0159 23.5882 20.1117C24.4924 19.2075 25.0004 17.9812 25.0004 16.7025C25.0004 15.4238 24.4924 14.1974 23.5882 13.2933C22.6841 12.3891 21.4577 11.8811 20.179 11.8811Z" fill="#303030" />
      <path d="M22.4283 9.95366H8.28569V11.9921L13.0518 16.7582L13.4285 17.1349V22.8106H15.9998V21.5249H17.2855V22.8106C17.2855 23.1516 17.1501 23.4786 16.909 23.7197C16.6679 23.9608 16.3408 24.0963 15.9998 24.0963H13.4285C13.0875 24.0963 12.7605 23.9608 12.5193 23.7197C12.2782 23.4786 12.1428 23.1516 12.1428 22.8106V17.6678L7.37671 12.9011C7.13558 12.6601 7.00007 12.3331 7 11.9921V9.95366C7 9.61267 7.13546 9.28565 7.37657 9.04454C7.61768 8.80343 7.94471 8.66797 8.28569 8.66797H22.4283V9.95366Z" fill="#303030" />
    </svg>
  );
}
