
export const types = Object.freeze({
  SET_ORDERS_LIST: 'SET_ORDERS_LIST',
  SET_ORDERS_CURRENT_PAGE: 'SET_ORDERS_CURRENT_PAGE',
  SET_ORDERS_FILTERS: 'SET_ORDERS_FILTERS',
  SET_ORDERS_PAGE_SIZE: 'SET_ORDERS_PAGE_SIZE',
  SET_ORDERS_SORTING: 'SET_ORDERS_SORTING',
  SET_ORDERS_INITIAL_STATE: 'SET_ORDERS_INITIAL_STATE',
  SET_ORDERS_SETTING_STATUS: 'SET_ORDERS_SETTING_STATUS',
  SET_ORDERS_SETTING_PENDING: 'SET_ORDERS_SETTING_PENDING',
  SET_ORDERS_ITEM_BY_HASH_ID: 'SET_ORDERS_ITEM_BY_HASH_ID',
  MERGE_ORDER_EDIT: 'MERGE_ORDER_EDIT',
  MERGE_ORDERS_STATISTICS: 'MERGE_ORDERS_STATISTICS',
  SET_ORDERS_RELOAD: 'SET_ORDERS_RELOAD',
  SET_ORDERS_EXPANDED_ROW_IDS: 'SET_ORDERS_EXPANDED_ROW_IDS',
  SET_ORDERS_STATUSES_LIST: 'SET_ORDERS_STATUSES_LIST',
  SET_ORDERS_STATUSES_LIST_PENDING: 'SET_ORDERS_STATUSES_LIST_PENDING',
  SET_ORDERS_PAYMENT_TYPES: 'SET_ORDERS_PAYMENT_TYPES',
  SET_ORDERS_SHIPPING_TYPES: 'SET_ORDERS_SHIPPING_TYPES',
  MERGE_ORDERS_UPLOAD: 'MERGE_ORDERS_UPLOAD',
  SET_ORDERS_DOWNLOAD_CSV: 'SET_ORDERS_DOWNLOAD_CSV',
  SET_ORDERS_CSV_DATA: 'SET_ORDERS_CSV_DATA',
  MERGE_ORDER_UNBIND_SHIPPING_TRACKING: 'MERGE_ORDER_UNBIND_SHIPPING_TRACKING',
  SET_IS_DOWNLOAD_FULL_ORDERS_LOADING: 'SET_IS_DOWNLOAD_FULL_ORDERS_LOADING',
  MERGE_ERROR_MODAL: 'MERGE_ERROR_MODAL',
  SET_ORDER_SHIPPING_TRACKING_HISTORY_REQUEST: 'ORDER_SHIPPING_TRACKING_HISTORY_REQUEST',
});
