// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = fromJS({
  logisticsDataByCategoryList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    treeDataRowIds: List(),
    reload: false,
    selection: List(),
    selectedItems: List(),
  }),
  logs: Map({
    items: List(),
    pending: false,
    hashId: null,
    showPopover: false,
  }),
  itemByHashId: fromJS({
    data: Map(),
    pendingId: undefined,
    mode: undefined,
    pending: false,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'logisticsDataByCategory',
  logisticsDataByCategoryList: 'logisticsDataByCategoryList',
  isOpen: 'isOpen',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  item: 'item',
  expandedRowIds: 'expandedRowIds',
  treeDataRowIds: 'treeDataRowIds',
  submitting: 'submitting',
  pending: 'pending',
  pendingId: 'pendingId',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
  selection: 'selection',
  import: 'import',
  export: 'export',
  itemByHashId: 'itemByHashId',
  mode: 'mode',
  data: 'data',
  logs: 'logs',
  showPopover: 'showPopover',
  hashId: 'hashId',
  selectedItems: 'selectedItems',
});

export const logisticsDataByCategoryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.logisticsDataByCategoryList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.logisticsDataByCategoryList, stateKeys.pending], pending)
        .setIn([stateKeys.logisticsDataByCategoryList, stateKeys.items], List(items))
        .setIn([stateKeys.logisticsDataByCategoryList, stateKeys.totalCount], totalCount);
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_SORTING: {
      return state.setIn([stateKeys.logisticsDataByCategoryList, stateKeys.sorting], List(payload));
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.logisticsDataByCategoryList, stateKeys.filters], List(filters));
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_PAGE_SIZE: {
      return state.setIn([stateKeys.logisticsDataByCategoryList, stateKeys.pageSize], payload);
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_RELOAD: {
      return state.setIn([stateKeys.logisticsDataByCategoryList, stateKeys.reload], payload);
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_TREE_DATA_ROW_IDS: {
      return state.setIn([stateKeys.logisticsDataByCategoryList, stateKeys.treeDataRowIds], List(payload));
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_CURRENT_PAGE: {
      return state.setIn([stateKeys.logisticsDataByCategoryList, stateKeys.currentPage], payload);
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.logisticsDataByCategoryList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.logisticsDataByCategoryList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.logisticsDataByCategoryList, stateKeys.filters], List(filters))
        .setIn([stateKeys.logisticsDataByCategoryList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.logisticsDataByCategoryList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.logisticsDataByCategoryList, stateKeys.reload], reload)
        .setIn([stateKeys.logisticsDataByCategoryList, stateKeys.sorting], List(currentSorting))
        .setIn([stateKeys.logisticsDataByCategoryList, stateKeys.selection], List());
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_SETTING_STATUS: {
      return state.setIn([stateKeys.logisticsDataByCategoryList, stateKeys.settingStatus], payload);
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_SETTING_PENDING: {
      return state.setIn([stateKeys.logisticsDataByCategoryList, stateKeys.settingPending], payload);
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_ITEM: {
      return state.mergeIn([stateKeys.categoryItem], fromJS(payload));
    }
    case types.SET_CATEGORIES_COMMISSIONS_UPLOAD_UPLOADING: {
      return state.setIn([stateKeys.commissionsUpload, stateKeys.pending], fromJS(payload));
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_SELECTION: {
      const items = state.getIn([stateKeys.logisticsDataByCategoryList, stateKeys.items]);
      const previoslySelectedItems = state.getIn([stateKeys.logisticsDataByCategoryList, stateKeys.selectedItems]);
      const selectedItems = payload.filter(it => items.find(item => (item.id === it && !item.alloExpressEnabled) || previoslySelectedItems.includes(it)));
      const sel = new Set(selectedItems);
      return state.setIn([stateKeys.logisticsDataByCategoryList, stateKeys.selectedItems], List(sel))
        .setIn([stateKeys.logisticsDataByCategoryList, stateKeys.selection], List(payload));
    }
    case types.MERGE_LOGISTICS_DATA_BY_CATEGORY_IMPORT: {
      return state.mergeIn([stateKeys.import], payload);
    }
    case types.MERGE_LOGISTICS_DATA_BY_CATEGORY_EXPORT: {
      return state.mergeIn([stateKeys.export], payload);
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_DATA: {
      return state.setIn([stateKeys.itemByHashId, stateKeys.data], Map(payload));
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_MODE: {
      return state.setIn([stateKeys.itemByHashId, stateKeys.mode], payload);
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_PENDING_ID: {
      return state.setIn([stateKeys.itemByHashId, stateKeys.pendingId], payload);
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_ITEM_BY_HASH_ID_PUT_PENDING: {
      return state.setIn([stateKeys.itemByHashId, stateKeys.pending], payload);
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_LOGS: {
      const {
        items, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.logs, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.logs, stateKeys.pending], pending)
        .setIn([stateKeys.logs, stateKeys.items], List(items));
    }
    case types.SET_LOGISTICS_DATA_BY_CATEGORY_LOGS_DATA: {
      return state.mergeIn([stateKeys.logs], Map(payload));
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
