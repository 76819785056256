// Core
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  Field, Form, reduxForm, reset, change,
} from 'redux-form/lib/immutable';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
// Parts
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Modal from '../../../../containers/Modals/Modal/Modal';
import FormTextArea from '../../../_Form/TextArea/FormTextArea';
import FormInput from '../../../_Form/TextField/FormTextField/FormTextField';
import FilesUploader from '../../../_Form/FilesUploader/FilesUploader';
import FormSelect from '../../../_Form/Selects/FormSelect/FormSelect';
import { formName, formFields } from './form';
// Engine
import { uiActions } from '../../../../../engine/core/ui/action';
import { uiActionAsync } from '../../../../../engine/core/ui/saga/asyncAction';
import { mediaActionAsync } from '../../../../../engine/core/media/saga/asyncAction';
import { selectors } from '../../../../../engine/config/selectors';
import { validators } from '../../../../../_helpers/validationsForm';

export const ServiceSupportModalOptions = [ // TODO перевод в компанентах, иначе в локалФоредж не работает сохранение фильтров.
  {
    value: 0,
    label: 'Заказы',
  },
  {
    value: '1',
    label: 'Контент',
  },
  {
    value: '2',
    label: 'Баланс',
  },
  {
    value: '3',
    label: 'Другие вопросы',
  },
  {
    value: '4',
    label: 'Возврат',
  },
];

function ServiceSupportModal(props) {
  const { handleSubmit } = props;
  const dispatch = useDispatch();
  const isWorkingHour = useSelector(selectors.statistics.isWorkingHours);
  const workTimeStart = useSelector(selectors.statistics.weekdaysFrom);
  const workTimeEnd = useSelector(selectors.statistics.weekdaysTo);
  const workTimeWeekendStart = useSelector(selectors.statistics.weekendsFrom);
  const workTimeWeekendEnd = useSelector(selectors.statistics.weekendsTo);
  const workTimeFrom = `${workTimeStart?.hours}:${workTimeStart?.minutes}`;
  const workTimeTo = `${workTimeEnd?.hours}:${workTimeEnd?.minutes}`;
  const workTimeWeekendFrom = `${workTimeWeekendStart?.hours}:${workTimeWeekendStart?.minutes}`;
  const workTimeWeekendTo = `${workTimeWeekendEnd?.hours}:${workTimeWeekendEnd?.minutes}`;
  const { t } = useTranslation();
  const isOpen = useSelector(selectors.ui.serviceSupportModalIsOpen);
  const isLoading = useSelector(selectors.serviceDesk.fileIsLoading);
  const newTicketPending = useSelector(selectors.serviceDesk.newTicketPending);
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const postMediasAsync = data => dispatch(mediaActionAsync.postMediasAsync({
    ...data,
    // TODO для множественной загрузки
    // multipleModeContext: true,
  }));

  const options = ServiceSupportModalOptions.map(item => ({
    label: t(item.label),
    value: item.value,
  }));

  const handleClose = () => {
    dispatch(uiActions.mergeServiceSupportModal({ isOpen: false }));
    dispatch(reset(formName));
  };
  const onSubmit = (formData) => {
    if (formData && formData.toJS) {
      const data = formData.toJS();
      const dataToServer = {
        updateServiceDeskList: true,
        questionType: data[formFields.subject],
        questionTheme: data[formFields.title],
        message: data[formFields.text],
        messageFiles: isEmpty(data[formFields.media]) ? [] : [data[formFields.media][0].id],
      };
      dispatch(uiActionAsync.postServiceSupport(dataToServer));
    }
  };
  const onPaste = (event) => {
    const currentFile = formValues.get(formFields.media);
    if (event.clipboardData.files.length && (currentFile === undefined || currentFile.length === 0)) {
      dispatch(change(formName, formFields.media, event.clipboardData.files[0]));
    }
  };

  const subjectField = formValues.get(formFields.subject);
  const titleField = formValues.get(formFields.title);
  const isRefund = subjectField === '4';

  useEffect(() => {
    if (isRefund) {
      dispatch(change(formName, formFields.title, 'Повернення/коригування комісії'));
    } else if (titleField === 'Повернення/коригування комісії') {
      dispatch(change(formName, formFields.title, ''));
    }
  }, [subjectField]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      modalOpen={isOpen}
      handleModalToggle={handleClose}
      title={t('Служба Поддержки')}
      disabledSend={isLoading || newTicketPending}
      loadingSend={isLoading || newTicketPending}
      fullWidth
      handleModalSend={handleSubmit}
      modalMaxWidth="sm"
    >
      {!isWorkingHour && (
        <Box sx={{
          fontSize: '14px',
          fontWeight: '600',
          padding: '0 24px',
          textAlign: 'center',
        }}
        >{t('Добрый день. Наша служба поддержки работает с ')}{workTimeFrom} {t('до ')} {workTimeTo} {t('по будням и с ')}{workTimeWeekendFrom} {t('до ')} {workTimeWeekendTo} {t('по выходным. ')} <br />{t('Утром, мы обязательно ответим на ваше обращение.')}
        </Box>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Field
          component={FormSelect}
          name={formFields.subject}
          fullWidth
          multiline
          validation
          variant="outlined"
          margin="normal"
          options={options}
          label={t('Тип вопроса')}
          formSelectWidth="100%"
          validate={[validators.required]}
        />
        <Field
          component={FormInput}
          name={formFields.title}
          label={t('Тема вопроса')}
          validate={[validators.required]}
          fullWidth
          validation
          variant="outlined"
          margin="normal"
          disabled={isRefund}
        />
        <Field
          onPaste={onPaste}
          component={FormTextArea}
          name={formFields.text}
          label={t('Подробное описание проблемы')}
          validate={[validators.required]}
          fullWidth
          multiline
          validation
          rows={10}
          variant="outlined"
          margin="normal"
        />
        {isRefund && <Typography fontSize={12} color="red">Для підтвердження повернення у полі &quot;Детальний опис проблеми&quot; обов&apos;язково вкажіть <b>номер замовлення, суму повернення, повний номер ТТН</b> повернення. Прикріпіть <b>копії документів: скан квитанції про повернення коштів, копію ТТН повернення</b>. Якщо повернення коштів було <b>готівкою</b>, додайте копії <b>заяви на повернення</b>, підписану клієнтом та <b>видаткового документу</b>. Повний та коректний перелік реквізитів і документів у зверненні пришвидшить обробку заявки на повернення комісії адміністрацією Маркетплейсу АЛЛО.</Typography>}
      </Form>

      <Field
        name={formFields.media}
        formName={formName}
        changeFromAction
        component={FilesUploader}
        validation
        fullWidth
        margin="normal"
        textBefore={t('Прикрепленный файл')}
        context="support_message"
        uploadMedia={postMediasAsync}
      />
    </Modal>
  );
}


ServiceSupportModal.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: formName,
  enableReinitialize: true, // this is needed!!
})(ServiceSupportModal);
