// Core
import React, {
  useCallback, useMemo, useState,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Routes,
  Route,
  NavLink,
} from 'react-router-dom';
// Parts
import { Tabs } from '@mui/base/Tabs';
import { TabPanel } from '@mui/base/TabPanel';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import SidebarItem from './SidebarItem/SidebarItem';
import SidebarStyles, { TabsListTopStyled, TabTopStyled } from '../SidebarStyle';
import FaqIcon from '../../../icons/FaqIcon';
import InstructionIcon from '../../../icons/InstructionIcon';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { accessList } from '../../../../engine/config/access';
import { uiActions } from '../../../../engine/core/ui/action';
import routes, { routersLink } from '../../../../engine/config/routes';
import { userRoles } from '../../../../engine/config/userRoles';
import { useAccessList } from '../../../hooks/useAccessList';
// Helpers
import { useStyles } from '../../../hooks/useStyles';
import { useUserRole } from '../../../hooks/useUserRole';
import { COLOR } from '../../../containers/App/AppStyles';

const RenderMenu = (props) => {
  const { opened } = props;
  const classes = useStyles(SidebarStyles);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userConfigurations = useSelector(selectors.user.configurations);
  const sidebarActiveMenu = useSelector(selectors.ui.sidebarActiveMenu);
  const hasFaqQuestionList = useAccessList(accessList.infoCenterLinkShow);
  const [activeRoute, setActiveRoute] = useState(undefined);
  const userAccessList = useSelector(selectors.user.accessList);
  const isCallCenterRole = useUserRole(userRoles.callCenter);
  const rolesList = useSelector(selectors.user.rolesList);
  const infoCenterLink = useMemo(() => userConfigurations.get('INFO_CENTER_LINK'), [
    userConfigurations,
  ]);

  const toggleMenu = useCallback((index) => {
    let itemIndex = index;
    if (activeRoute === itemIndex && opened) {
      itemIndex = undefined;
    }
    setActiveRoute(itemIndex);
  }, [
    activeRoute, setActiveRoute, opened,
  ]);

  const Menu = (
    <List component="div" sx={{ paddingTop: '0px' }}>
      {routes.items
        .filter(route => route.icon && (route.accessName === undefined || userAccessList.includes(route.accessName)))
        .map((route, index) => {
          if (
            (typeof route.off === 'boolean' && route.off)
            || (Array.isArray(route.off) && route.off.some(offRole => rolesList.includes(offRole)))
            || (Array.isArray(route.on) && !route.on.some(onRole => rolesList.includes(onRole)))
          ) {
            return null;
          }
          return (
            <SidebarItem
              key={index}
              setActiveRoute={setActiveRoute}
              index={index}
              route={route}
              activeRoute={activeRoute}
              toggleMenu={toggleMenu}
              isMenuOpened={opened}
            />
          );
        })
        .filter(Boolean)
      }
    </List>
  );

  const PagesWithSidebar = useMemo(() => (
    routes.items.flatMap(
      routesItem => routesItem.children?.map(
        routesItemChildren => (
          routesItemChildren.sidebarAdditionalTab
            ? { ...routesItemChildren, controller: routesItem.path }
            : undefined
        ),
      ),
    ).filter(Boolean)
  ), [routes]);

  const onChangeTab = () => dispatch(uiActions.setSidebarActiveMenu(sidebarActiveMenu === 0 ? 1 : 0));

  const FooterMenu = (!isEmpty(infoCenterLink) && hasFaqQuestionList) ? (
    <Box
      sx={{
        marginTop: 'auto',
        borderTop: `1px solid ${COLOR.darkStyle.white['8']}`,
        marginBottom: '5px',
      }}
    >
      {(!isEmpty(infoCenterLink) && hasFaqQuestionList) ? (
        <Link
          target="_blank"
          href={infoCenterLink}
          className={!opened ? classes.buttonLinkSmall : ''}
          underline="none"
        >
          <Button
            sx={{
              borderRadius: 0,
              justifyContent: 'left',
              padding: '15px',
              color: `${COLOR.darkStyle.brand.main}`,
              lineHeight: 1,
              '&.MuiButton-root:hover': {
                background: `${COLOR.darkStyle.white['8']}`,
              },
            }}
          >
            <FaqIcon sx={{ fontSize: '20px', marginRight: '15px' }} />
            {opened ? t('Вопросы и ответы') : ''}
          </Button>
        </Link>
      ) : null}
      <NavLink
        to={routersLink.instruction.controller}
        className={({ isActive }) => `${classes.buttonLink} ${isActive ? classes.activeButtonLink : ''}`}
      >
        <Button
          sx={{
            width: '100%',
            padding: 0,
            borderRadius: 0,
            '&:hover': {
              background: `${COLOR.darkStyle.white['8']}`,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              padding: '15px',
            }}
          >
            <InstructionIcon sx={{ fontSize: '20px', marginRight: '15px' }} />
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
              }}
            >
              {opened ? t('Инструкция') : ''}
            </Typography>
          </Box>
        </Button>
      </NavLink>
    </Box>
  ) : null;

  return (
    <Routes>
      {opened && PagesWithSidebar
        .map(item => (
          <Route
            exact
            key={`${item.controller}${item.path}`}
            path={`${item.controller}${item.path}`}
            element={(
              <>
                <Tabs onChange={onChangeTab} value={sidebarActiveMenu}>
                  {!isCallCenterRole && ( // TODO You need to check the access list for each control button
                  <TabsListTopStyled>
                    <TabTopStyled>{t('Главное меню')}</TabTopStyled>
                    <TabTopStyled id="manageSideBar">{t('Управление')}</TabTopStyled>
                  </TabsListTopStyled>
                  )}
                  <TabPanel value={0}>
                    {Menu}
                  </TabPanel>
                  <TabPanel value={1}>
                    <Box sx={{ padding: '10px' }}>
                      <item.sidebarAdditionalTab />
                    </Box>
                  </TabPanel>
                </Tabs>
                {FooterMenu}
              </>
            )}
          />

        ))
        .filter(Boolean)
      }
      <Route
        path="*"
        element={(
          <>
            {Menu}
            {FooterMenu}
          </>
        )}
      />
    </Routes>
  );
};

export default RenderMenu;
