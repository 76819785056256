import { NavLink } from 'react-router-dom';
import React, { Fragment } from 'react';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { IconButtonStyles } from '../../../../components/_Table/TableCell/Styles';
import { promotionStatuses } from '../../../../../engine/config/promotions';
import { useUserRole } from '../../../../hooks/useUserRole';
import { userRoles } from '../../../../../engine/config/userRoles';

const EditComponent = (props) => {
  const {
    tableRow, hasAccessEdit, hasAccessShow, editLink, showLink,
  } = props;
  const hashId = tableRow.row.hashId;
  const ifStatusFinished = tableRow.row.promoStatus === promotionStatuses.STATUS_FINISHED;
  const isAdminRole = useUserRole(userRoles.admin);

  return (
    <>
      {hasAccessEdit && (
        <>
          {ifStatusFinished ? (
            <NavLink to={isAdminRole ? editLink(hashId) : showLink(hashId)}>
              <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" size="large">
                <RemoveRedEyeOutlinedIcon sx={{ p: '2px' }} />
              </IconButtonStyles>
            </NavLink>
          ) : (
            <NavLink to={editLink(hashId)}>
              <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" size="large">
                <ModeOutlinedIcon sx={{ p: '2px' }} />
              </IconButtonStyles>
            </NavLink>
          )}
        </>
      )}
      {hasAccessShow && (
        <NavLink to={showLink(hashId)}>
          <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" size="large">
            <RemoveRedEyeOutlinedIcon sx={{ p: '2px' }} />
          </IconButtonStyles>
        </NavLink>
      )}
    </>
  );
};

export default EditComponent;
