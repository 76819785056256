import { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { KeyboardArrowRight } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { getJoinedLabels, getStatusColor } from '../../../../engine/config/promotions';
import { COLOR } from '../../../containers/App/AppStyles';

const titlesStyle = {
  fontSize: '12px',
  color: 'rgba(48, 48, 48, 0.87)',
};

export function HistoryTableRow(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  const promoStatus = row.currState?.promoStatus;
  const statusLabel = row.currState?.statusLabel;
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{
        border: 'solid 1px rgba(48, 48, 48, 0.08)',
        borderRadius: '5px',
        margin: '3px',
        padding: '1px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
      }}
      >
        <Grid container spacing={1} sx={{ marginTop: '0' }} alignItems="center">
          <Grid item xs={4} lg={4}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <Box width={'38px'}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRight />}
                </IconButton>
              </Box>
              <Box>
                <Typography fontSize={'13px'} fontWeight={400} color={COLOR.black['54']}>{row.createdAt}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} lg={4}>
            <Box sx={{ color: getStatusColor(promoStatus) }}>
              <Typography fontSize={'13px'} fontWeight={400}>{statusLabel}</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} lg={4}>
            <Typography fontSize={'13px'} fontWeight={400}>{row.changedBy}</Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {row.prevState?.promoName !== row.currState?.promoName && (
                <Box sx={{ marginLeft: '20px', mb: '20px' }}>
                  <Divider sx={{ mb: '5px', mr: '20px' }} />
                  <Typography fontSize={'14px'} fontWeight={700}>Назва акції</Typography>
                  <Grid sx={{ mt: '5px' }} container spacing={1}>
                    <Grid item xs={5}>
                      <Typography sx={titlesStyle}>Попереднє значення поля:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontSize={'12px'}>{row.prevState?.promoName}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography sx={titlesStyle}>Нове значення поля:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={titlesStyle}><Box sx={{ color: COLOR.success }}>{row.currState?.promoName}</Box></Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {row.prevState?.statusLabel !== row.currState?.statusLabel && (
              <Box sx={{ marginLeft: '20px', mb: '20px' }}>
                <Divider sx={{ mb: '5px', mr: '20px' }} />
                <Typography fontSize="14px" fontWeight={700}>Статус акції</Typography>
                <Grid sx={{ mt: '5px' }} container spacing={1}>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Попереднє значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontSize="12px">{row.prevState?.statusLabel}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Нове значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={titlesStyle}>
                      <Box sx={{ color: COLOR.success }}>{row.currState?.statusLabel}</Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              )}
              {row.prevState?.promoDateRange !== row.currState?.promoDateRange && (
              <Box sx={{ marginLeft: '20px', mb: '20px' }}>
                <Divider sx={{ mb: '5px', mr: '20px' }} />
                <Typography fontSize="14px" fontWeight={700}>Період акції</Typography>
                <Grid sx={{ mt: '5px' }} container spacing={1}>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Попереднє значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontSize="12px">{row.prevState?.promoDateRange}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Нове значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={titlesStyle}>
                      <Box sx={{ color: COLOR.success }}>{row.currState?.promoDateRange}</Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              )}
              {row.prevState?.promoRegistrationDateRange !== row.currState?.promoRegistrationDateRange && (
              <Box sx={{ marginLeft: '20px', mb: '20px' }}>
                <Divider sx={{ mb: '5px', mr: '20px' }} />
                <Typography fontSize="14px" fontWeight={700}>Прийом заявок</Typography>
                <Grid sx={{ mt: '5px' }} container spacing={1}>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Попереднє значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontSize="12px">
                      {row.prevState?.promoRegistrationDateRange}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Нове значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={titlesStyle}>
                      <Box sx={{ color: COLOR.success }}>
                        {row.currState?.promoRegistrationDateRange}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              )}
              {row.prevState?.freeDelivery !== row.currState?.freeDelivery && (
              <Box sx={{ marginLeft: '20px', mb: '20px' }}>
                <Divider sx={{ mb: '5px', mr: '20px' }} />
                <Typography fontSize="14px" fontWeight={700}>Безкоштовна доставка</Typography>
                <Grid sx={{ mt: '5px' }} container spacing={1}>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Попереднє значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontSize="12px">
                      {row.prevState?.freeDelivery === true ? 'Увімкнена' : 'Вимкнена'}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Нове значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={titlesStyle}>
                      <Box sx={{ color: COLOR.success }}>
                        {row.currState?.freeDelivery === true ? 'Увімкнена' : 'Вимкнена'}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              )}
              {row.prevState?.partialPay !== row.currState?.partialPay && (
              <Box sx={{ marginLeft: '20px', mb: '20px' }}>
                <Divider sx={{ mb: '5px', mr: '20px' }} />
                <Typography fontSize="14px" fontWeight={700}>Оплата частинами</Typography>
                <Grid sx={{ mt: '5px' }} container spacing={1}>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Попереднє значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontSize="12px">
                      {row.prevState?.partialPay === true ? 'Увімкнена' : 'Вимкнена'}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Нове значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={titlesStyle}>
                      <Box sx={{ color: COLOR.success }}>
                        {row.currState?.partialPay === true ? 'Увімкнена' : 'Вимкнена'}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              )}
              {row.prevState?.specialPrice !== row.currState?.specialPrice && (
              <Box sx={{ marginLeft: '20px', mb: '20px' }}>
                <Divider sx={{ mb: '5px', mr: '20px' }} />
                <Typography fontSize="14px" fontWeight={700}>Знижки</Typography>
                <Grid sx={{ mt: '5px' }} container spacing={1}>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Попереднє значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontSize="12px">
                      {row.prevState?.specialPrice === true ? 'Увімкнена' : 'Вимкнена'}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Нове значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={titlesStyle}>
                      <Box sx={{ color: COLOR.success }}>
                        {row.currState?.specialPrice === true ? 'Увімкнена' : 'Вимкнена'}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              )}
              {getJoinedLabels(row.prevState?.categories) !== getJoinedLabels(row.currState?.categories) && (
              <Box sx={{ marginLeft: '20px', mb: '20px' }}>
                <Divider sx={{ mb: '5px', mr: '20px' }} />
                <Typography fontSize="14px" fontWeight={700}>Категорії</Typography>
                <Grid sx={{ mt: '5px' }} container spacing={1}>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Попереднє значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontSize="12px">
                      {getJoinedLabels(row.prevState?.categories)}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Нове значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={titlesStyle}>
                      <Box sx={{ color: COLOR.success }}>
                        {getJoinedLabels(row.currState?.categories)}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              )}
              {getJoinedLabels(row.prevState?.brands) !== getJoinedLabels(row.currState?.brands) && (
              <Box sx={{ marginLeft: '20px', mb: '20px' }}>
                <Divider sx={{ mb: '5px', mr: '20px' }} />
                <Typography fontSize="14px" fontWeight={700}>Стоп-бренди</Typography>
                <Grid sx={{ mt: '5px' }} container spacing={1}>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Попереднє значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontSize="12px">
                      {getJoinedLabels(row.prevState?.brands)}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Нове значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={titlesStyle}>
                      <Box sx={{ color: COLOR.success }}>
                        {getJoinedLabels(row.currState?.brands)}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              )}
              {row.prevState?.description !== row.currState?.description && (
              <Box sx={{ marginLeft: '20px', mb: '20px' }}>
                <Divider sx={{ mb: '5px', mr: '20px' }} />
                <Typography fontSize="14px" fontWeight={700}>{t('Опис акції')}</Typography>
                <Grid sx={{ mt: '5px' }} container spacing={1}>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Попереднє значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontSize="12px">{row.prevState?.description}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography sx={titlesStyle}>Нове значення поля:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={titlesStyle}>
                      <Box sx={{ color: COLOR.success }}>{row.currState?.description}</Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              )}
            </Collapse>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
