import { getIn, List } from 'immutable';
// Store keys
import { stateKeys as torpsKeys } from './reducer';
import { pageSizes } from '../../config/globalConfig';

export const torps = Object.freeze({
  currentPage: state => getIn(state, [torpsKeys.controller, torpsKeys.torpsList, torpsKeys.currentPage], 0),
  filters: state => getIn(state, [torpsKeys.controller, torpsKeys.torpsList, torpsKeys.filters], List()),
  isLoading: state => getIn(state, [torpsKeys.controller, torpsKeys.torpsList, torpsKeys.pending], false),
  list: state => getIn(state, [torpsKeys.controller, torpsKeys.torpsList, torpsKeys.items], List()),
  pageSize: state => getIn(state, [torpsKeys.controller, torpsKeys.torpsList, torpsKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [torpsKeys.controller, torpsKeys.torpsList, torpsKeys.reload], false),
  totalCount: state => getIn(state, [torpsKeys.controller, torpsKeys.torpsList, torpsKeys.totalCount], 0),
  sorting: state => getIn(state, [torpsKeys.controller, torpsKeys.torpsList, torpsKeys.sorting], List()),
  settingStatus: state => getIn(state, [torpsKeys.controller, torpsKeys.torpsList, torpsKeys.settingStatus], false),
  settingPending: state => getIn(state, [torpsKeys.controller, torpsKeys.torpsList, torpsKeys.settingPending], false),
  selection: state => getIn(state, [torpsKeys.controller, torpsKeys.torpsList, torpsKeys.selection], List()),
  deleteModal: state => getIn(state, [torpsKeys.controller, torpsKeys.torpsList, torpsKeys.deleteModalOpen], false),
});
