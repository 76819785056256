// Core
import { apply, put, select } from 'redux-saga/effects';
import get from 'lodash/get';
// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions as contentActions } from '../../../contentProduct/actions';
import { actions as priceComparsionActions } from '../../../productPriceComparison/actions';
import { actions } from '../../actions';
import { asyncActions } from '../../../productPriceComparison/saga/asyncActions';
import { selectors } from '../../../../config/selectors';
import { convertSortingObjectToUrlParams } from '../../../../../_helpers/convertDataToUrlParams';
// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callPutProductsWorker({ payload }) {
  const {
    products, productsValues, type, categoryId, isPriceComparsion,
  } = payload;

  const normalizedProductsValues = {};
  for (let i = 0; i < products.length; i++) {
    const productId = products[i];
    for (const key in productsValues) { // eslint-disable-line
      const feature = productsValues[key];

      if (type === 'multipleSelect') {
        normalizedProductsValues[key] = feature.map(item => item.value);
      } else {
        normalizedProductsValues[key] = get(feature, 'value', feature);
      }

      if (type === 'productStatus') {
        yield put(contentActions.mergeContentProductValue({
          productId,
          value: {
            type,
            active: true,
            pending: true,
          },
        }));
      } else {
        yield put(contentActions.setAllProductsProductFeatureState({
          productId,
          key,
          value: feature,
          error: false,
          success: false,
          pending: true,
        }));
      }
    }
  }

  const response = yield apply(
    api, api.products.putProducts,
    [{
      ...payload,
      productsValues: normalizedProductsValues,
    }],
  );

  if (response && response.status >= 200 && response.status < 400) {
    const { status, statusTitle } = response.data;
    switch (status) {
      case requestStatus.success: {
        const { message, product } = response.data;
        for (let i = 0; i < products.length; i++) {
          const productId = products[i];
          yield put(contentActions.mergeContentProductValue({
            productId,
            value: {
              type,
              active: false,
              success: true,
              pending: false,
            },
          }));
          for (let key in productsValues) {// eslint-disable-line
            const feature = productsValues[key];
            yield put(contentActions.setAllProductsProductFeatureState({
              productId,
              key,
              newProductData: product,
              success: true,
              label: get(feature, 'label', feature),
              value: get(feature, 'value', feature),
            }));
          }
        }
        yield put(setSuccessMessage(message, statusTitle));

        if (isPriceComparsion) {
          const currentPage = yield select(selectors.productPriceComparison.currentPage);
          const limit = yield select(selectors.productPriceComparison.pageSize);
          const filters = yield select(selectors.productPriceComparison.filters);
          const sorting = yield select(selectors.productPriceComparison.sorting);
          const offset = limit * currentPage;

          const paramsByFilter = filters.reduce((acc, { columnName, value }) => {
            acc[columnName] = value;
            return acc;
          }, {});

          const orderBy = convertSortingObjectToUrlParams(sorting.toJS());
          const params = {
            ...paramsByFilter,
            categoryId,
            orderBy,
            limit,
            offset,
          };
          yield put(asyncActions.getListAsync(params));
          yield put(priceComparsionActions.setSelection([]));
        }
        break;
      }
      case requestStatus.error: {
        const { errors, product, needForceAction } = response.data;
        if (needForceAction) {
          yield put(actions.setConfirmModal({ isOpen: true, text: errors?.[0]?.message, params: payload }));
          break;
        }
        for (let i = 0; i < products.length; i++) {
          const productId = products[i];
          yield put(contentActions.mergeContentProductValue({
            productId,
            value: {
              type,
              active: true,
              error: true,
              pending: false,
            },
          }));
          for (let key in productsValues) {// eslint-disable-line
            const feature = productsValues[key];
            yield put(contentActions.setAllProductsProductFeatureState({
              productId,
              key,
              newProductData: product,
              error: true,
              label: get(feature, 'label', feature),
              value: get(feature, 'value', feature),
            }));
          }
        }
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }
}
