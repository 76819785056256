import { useState, useMemo } from 'react';
import declineWord from 'decline-word';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Modal from '../../../../../../containers/Modals/Modal/Modal';
import { api } from '../../../../../../../engine/config/api';
import { selectors } from '../../../../../../../engine/config/selectors';
import { setErrorMessage, setSuccessMessage } from '../../../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { actions } from '../../../../../../../engine/core/contentProduct/actions';
import { asyncActions } from '../../../../../../../engine/core/contentProduct/saga/asyncActions';
import { convertSortingObjectToUrlParams } from '../../../../../../../_helpers/convertDataToUrlParams';

const ADD_ALLO_EXPRESS_CATEGORY_SELECT_OPTION = 'Додати товари до Алло Express';

const TemplateText = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div>{t('До сервісу АЛЛО Express можуть бути додані лише товари, які')}:
      <ul>
        <li>{t('належать до обраних для перевезення АЛЛО Express категорій')};</li>
        <li>{t('габаритні розміри (в упаковці) яких не перевищують дозволених: Ширина х Висота х Довжина - до 120см х 70см х 70см, Вага - до 30кг')}</li>
      </ul>
      {children}
    </div>
  );
};


const AlloExpressModal = () => {
  const open = useSelector(selectors.contentProduct.alloExpressModalOpen);
  const type = useSelector(selectors.contentProduct.alloExpressModalType);
  const selection = useSelector(selectors.contentProduct.selection);
  const selectedItems = useSelector(selectors.contentProduct.selectedItems);
  const currentPage = useSelector(selectors.contentProduct.currentPage);
  const limit = useSelector(selectors.contentProduct.pageSize);
  const filters = useSelector(selectors.contentProduct.filters);
  const sorting = useSelector(selectors.contentProduct.sorting);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClose = () => {
    dispatch(actions.setAlloExpressModal({ showModal: false }));
  };

  const offset = limit * currentPage;

  const orderBy = useMemo(() => (
    convertSortingObjectToUrlParams(sorting.toJS())
  ), [sorting]);
  const [loading, setIsLoading] = useState(false);
  const isAdd = type === ADD_ALLO_EXPRESS_CATEGORY_SELECT_OPTION;

  const productWord = declineWord(selectedItems.size, '', t('товар, який задовільняє перерахованим вимогам та може бути доданий до  сервісу АЛЛО Express'), t('товари, які задовольняють перерахованим вимогам та можуть бути додані до  сервісу АЛЛО Express'), t('товарів, які задовольняють перерахованим вимогам та можуть бути додані до  сервісу АЛЛО Express'));
  const productDeleteWord = declineWord(selection.size - selectedItems.size, '', t('товар, який можна видалити з сервісу АЛЛО Express'), t('товари, які можна видалити з сервісу АЛЛО Express'), t('товарів, які можна видалити з сервісу АЛЛО Express'));

  const noCategoriesToAddText = <TemplateText>{t('Серед обраних товарів відсутні товари, які задовольняють перерахованим вимогам')}</TemplateText>;
  const noCategoriesToDeleteText = t('Серед обраних - відсутні товари, які можна видалити з сервісу АЛЛО Express');
  const addText = <TemplateText>{t('Серед обраних')} - {selectedItems.size} {productWord}</TemplateText>;
  const deleteText = `${t('Серед обраних')} - ${selection.size - selectedItems.size} ${productDeleteWord}`;


  const text = (() => {
    if (isAdd && selectedItems.size) {
      return addText;
    } if (!isAdd && (selection.size - selectedItems.size)) {
      return deleteText;
    }
    if (isAdd) {
      return noCategoriesToAddText;
    }

    return noCategoriesToDeleteText;
  })();

  const handleModalCancel = (() => {
    if (isAdd && selectedItems.size) {
      return onClose;
    } if (!isAdd && (selection.size - selectedItems.size)) {
      return onClose;
    }
    if (isAdd) {
      return undefined;
    }

    return undefined;
  })();

  const buttonText = (() => {
    if (isAdd && selectedItems.size) {
      return t('Додати');
    } if (!isAdd && (selection.size - selectedItems.size)) {
      return t('Видалити');
    }
    return 'OK';
  })();

  const handleSend = async () => {
    if (isAdd && selectedItems.size === 0) {
      onClose();
      return;
    }
    if (!isAdd && ((selection.size - selectedItems.size) === 0)) {
      onClose();
      return;
    }
    setIsLoading(true);
    const products = isAdd ? selectedItems.toArray() : selection.filter(sel => !selectedItems.includes(sel)).toArray();
    const response = await api.products.putProducts({
      products,
      productsValues: {
        alloExpressEnabled: isAdd,
      },
    });
    if (response.status >= 200 && response.status < 400) {
      if (response.data?.status === 'error') {
        dispatch(setErrorMessage(response.data?.errors ?? 'Виникла помилка', response.data?.statusTitle));
        onClose();
        return;
      }
      dispatch(setSuccessMessage(response.data?.message, response.data.statusTitle));
      onClose();
      dispatch(actions.setSelection([]));

      const paramsByFilter = filters.reduce((acc, { columnName, value }) => {
        acc[columnName] = value;
        return acc;
      }, {});

      const params = {
        ...paramsByFilter,
        limit,
        offset,
        orderBy,
      };
      dispatch(asyncActions.getListAsync(params));
    } else {
      dispatch(setErrorMessage(response.data?.errors ?? 'Виникла помилка', response.data?.statusTitle));
    }
    setIsLoading(false);
  };

  const title = isAdd ? t('Додавання товарів до сервісу ALLO Express') : t('Видалення товарів з ALLO Express');

  return (
    <>
      {open && (
      <Modal
        modalOpen={open}
        handleModalToggle={onClose}
        handleModalCancel={handleModalCancel}
        title={title}
        buttonSendIcon={null}
        sendText={buttonText}
        cancelText={t('Отменить')}
        modalMaxWidth="sm"
        handleModalSend={handleSend}
        disabledSend={loading}
      >
        <Box sx={{ whiteSpace: 'pre-line' }}><b>{t('Внимание')}!</b> {text}</Box>
      </Modal>
      )}
    </>
  );
};

export default AlloExpressModal;
