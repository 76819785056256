import Box from '@mui/material/Box';

const BoxWithShadow = ({
  children, border = '1px solid rgba(0, 0, 0, 0.12)', borderRadius = '5px', p = '15px', m = '20px', ...props
}) => (
  <Box
    border={border}
    sx={{
      borderRadius,
      p,
      m,
      ...props.sx, // Allow additional styles to be passed
    }}
    {...props}
  >
    {children}
  </Box>
);

export default BoxWithShadow;
