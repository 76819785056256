// Core
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
// Components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '../../../../../components/_Form/TextField/TextField/TextField';
// Engine
import { api } from '../../../../../../engine/config/api';
import { setErrorMessage, setSuccessMessage } from '../../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
// Utils
import { transformDataForProductsGroupTable } from '../utils/transformDataForProductsGroupTable';

const AddProductSidebar = ({
  groupName, hashId, features, setRows, close,
}) => {
  const { t } = useTranslation();
  const [sku, setSku] = useState('');
  const dispatch = useDispatch();

  const input = { value: sku, onChange: e => setSku(e.target.value) };

  const handleAddProduct = async () => {
    const response = await api.productsGroups.addProductToGroup({ productSku: sku, hashId });
    const { status, data } = response;
    if (status >= 200 && status < 400) {
      const { rows } = transformDataForProductsGroupTable([data], features);
      setRows(current => [...current, rows?.[0]]);
      dispatch(setSuccessMessage(t('Успех')));
      close();
    } else {
      dispatch(setErrorMessage(data.message, data.statusTitle));
    }
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', padding: '30px', minWidth: '380px', height: '100%', justifyContent: 'space-between',
    }}
    >
      <Box sx={{ gap: '16px', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography fontWeight={700} fontSize={16}>{t('Добавление товара в группу', { name: groupName })}</Typography>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Box>
        <TextField input={input} fullWidth label={t('SKU товара')} variant="outlined" />
      </Box>
      <Button onClick={handleAddProduct} disabled={!sku} variant="contained" sx={{ alignSelf: 'flex-end' }}>{t('Добавить товар')}</Button>
    </Box>
  );
};

export default AddProductSidebar;
