import { Link } from 'react-router-dom';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { useTranslation } from 'react-i18next';
import TableCell from '../../../components/_Table/TableCell/TableCell';
import { pageLinks } from '../../../../engine/config/routes';
import { COLOR } from '../../../containers/App/AppStyles';
import { convertValueToLabel } from '../utils/convertValueToLabel';


const RefundRequestsTableCell = (props) => {
  const { t } = useTranslation();
  const { column, value, row } = props;
  switch (column.name) {
    case 'order': {
      return (
        <VirtualTable.Cell>
          <Link to={pageLinks.orders.edit(row.orderHashId)} style={{ color: COLOR.blue.main, textDecoration: 'underline' }}>{value}</Link>
        </VirtualTable.Cell>
      );
    }
    case 'type': {
      return (
        <VirtualTable.Cell>
          {t(value)}
        </VirtualTable.Cell>
      );
    }
    case 'status': {
      return (
        <VirtualTable.Cell>
          {convertValueToLabel(value)}
        </VirtualTable.Cell>
      );
    }
    default: {
      return (
        <TableCell
          {...props}
          showShortText
          isNotEllipsis
        />
      );
    }
  }
};

export default RefundRequestsTableCell;
