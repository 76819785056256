import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchGroupData } from '../utils/fetchGroupData';
import { api } from '../../../../../../engine/config/api';

export const useFetchOptions = (partner, partnerCategory, brand, editMode) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [featuresOptions, setFeaturesOptions] = useState([]);
  const [isPartnerInfoLoading, setIsPartnerInfoLoading] = useState(false);

  const { i18n } = useTranslation();
  useEffect(() => {
    if (partner?.hashId && !editMode) {
      fetchGroupData(api.partner.getPartnerCategories, { partnerId: partner?.value }, setCategoryOptions, 'categories', setIsPartnerInfoLoading);
    }
  }, [partner?.hashId]);

  useEffect(() => {
    if (partner?.hashId && partnerCategory?.value && !editMode) {
      fetchGroupData(api.partner.getPartnerBrands, { partnerId: partner?.value, categoryId: partnerCategory.value }, setBrandsOptions, 'brands', setIsPartnerInfoLoading);
    }
  }, [partner?.hashId, partnerCategory?.value]);

  useEffect(() => {
    if (partner?.hashId && partnerCategory && brand && !editMode) {
      (async () => {
        setIsPartnerInfoLoading(true);
        const response = await api.categories.getIsConfigurableFeatures(partnerCategory.value ?? partnerCategory.id);
        const isUkr = i18n.language === 'ua';
        const featureOptions = response?.data?.features?.map(feature => ({ value: feature.id, label: isUkr ? feature.nameUa : feature.name }));
        setFeaturesOptions(featureOptions);
        setIsPartnerInfoLoading(false);
      })();
    }
  }, [partner?.hashId, partnerCategory?.value, brand?.value]);

  return {
    categoryOptions, brandsOptions, featuresOptions, isPartnerInfoLoading,
  };
};
