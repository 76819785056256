export const types = Object.freeze({
  SET_REFUND_REQUESTS_LIST: 'SET_REFUND_REQUESTS_LIST',
  SET_REFUND_REQUESTS_CURRENT_PAGE: 'SET_REFUND_REQUESTS_CURRENT_PAGE',
  SET_REFUND_REQUESTS_FILTERS: 'SET_REFUND_REQUESTS_FILTERS',
  SET_REFUND_REQUESTS_PAGE_SIZE: 'SET_REFUND_REQUESTS_PAGE_SIZE',
  SET_REFUND_REQUESTS_SORTING: 'SET_REFUND_REQUESTS_SORTING',
  SET_REFUND_REQUESTS_INITIAL_STATE: 'SET_REFUND_REQUESTS_INITIAL_STATE',
  SET_REFUND_REQUESTS_SETTING_STATUS: 'SET_REFUND_REQUESTS_SETTING_STATUS',
  SET_REFUND_REQUESTS_SETTING_PENDING: 'SET_REFUND_REQUESTS_SETTING_PENDING',
  SET_REFUND_REQUESTS_SELECTION: 'SET_REFUND_REQUESTS_SELECTION',
  REMOVE_REFUND_REQUESTS_FILTERS: 'REMOVE_REFUND_REQUESTS_FILTERS',
});
