export const transformDataForTORPsPreviewTable = (previews = []) => {
  const rows = previews.map(preview => ({
    vendorCode: preview.vendorCode,
    productNames: preview.productNames.map(item => item.label).join(),
    partnerMagentoIds: preview.partnerMagentoIds.map(item => item.label).join(),
    productsSku: preview.products.map(item => item.label).join(),
    productIds: preview.products.map(item => item.value),
  }));

  return rows;
};
