import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { api } from '../../../../engine/config/api';
import Drawer from '../../../components/Drawer/Drawer';
import InfoRow from './InfoRow';
import TextField from '../../../components/_Form/TextField/TextField/TextField';
import Select from '../../../components/_Form/Selects/Select/Select';
import { generateInput } from '../../Products/Grouping/Add/utils/generateInput';
import CancelModal from '../../../containers/Modals/CancelModal/CancelModal';
import { setErrorMessage, setSuccessMessage } from '../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import {
  canceledByAlloOptions, closedByPartnerOptions, finalStatuses, statusOptions, warrantyDeniedOptions,
} from '../constants';
import InformModal from './InformModal';

const notShownFields = ['id', 'type', 'status', 'note', 'defectNote', 'additionalDocuments', 'actIssueDate', 'reason', 'orderHashId'];

const refundPaymentModalText = 'Для повернення комісії, будь ласка, напишіть звернення в розділі "Питання та відповіді"/"Підтримка АЛЛО". Оберіть тип питання "Повернення", прикладіть у форму звернення скан/фото копію квитанції про повернення коштів покупцеві та ТТН. Якщо повернення було готівкою, додайте в лист копію заяви на повернення від покупця та видаткової накладної. Ваш запит буде розглянуто. Після підтвердження адміністрацією АЛЛО сума комісії повернеться на баланс магазину.';
const exchangeProductModalText = 'Якщо  ви обміняли товар по гарантії без зміни вартості замовлення, комісію перераховувати не потрібно. Якщо у виконаному замовленні ви обміняли товар на інший (з іншою вартістю), напишіть звернення в розділі "Питання та відповіді"/"Підтримка АЛЛО". Оберіть тип питання "Повернення", вкажіть номер замовлення і товар(-и), які покупець повернув, та товар(-и), і їх вартість, які ви відправили на заміну. Комісія буде перерахована відповідно до нової вартості замовлення.';

const RefundRequestEdit = ({
  open, setOpenEditModal, selectedRowId, setRefresh,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [info, setInfo] = useState({});
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [informModal, setInformModal] = useState({ open: false, titleStatus: '', text: '' });
  const [reasonOptions, setReasonOptions] = useState([]);
  const [initialStatus, setInitialStatus] = useState('');
  const {
    handleSubmit, control, reset, resetField, watch, formState: { isDirty },
  } = useForm({
    mode: 'all',
    defaultValues: {},
  });

  const statusField = watch('status')?.value;
  const isEdit = !finalStatuses.includes(initialStatus);

  useEffect(() => {
    if (statusField === 'canceled_by_allo') {
      setReasonOptions(canceledByAlloOptions);
    } else if (statusField === 'closed_by_partner') {
      setReasonOptions(closedByPartnerOptions);
    } else if (statusField === 'warranty_denied') {
      setReasonOptions(warrantyDeniedOptions);
    } else {
      setReasonOptions([]);
      resetField('reason', { defaultValue: { value: 0, label: '' } });
    }
  }, [statusField]);

  useEffect(() => {
    if (open) {
      (async () => {
        const response = await api.refundRequests.getRefundInfoById(selectedRowId);
        if (response.status >= 200 && response.status < 400) {
          const {
            status, note, reason, ...rest
          } = response.data;
          setInitialStatus(status);
          const statusLabel = statusOptions.find(item => item.value === status);
          reset({ status: { value: status, label: statusLabel?.label }, note: note ?? '', reason: { value: reason, label: reason } });
          const requestDate = new Date(rest.requestDate).toLocaleString('uk-UA');
          setInfo({ ...rest, requestDate });
        }
      }
      )();
    } else {
      setRefresh(false);
    }
  }, [selectedRowId, open]);

  const toggleDrawer = () => setOpenEditModal(false);

  const handleCancel = () => {
    if (isDirty) {
      setCancelModalOpen(true);
    } else {
      toggleDrawer();
    }
  };

  const handleCloseModal = () => setCancelModalOpen(false);

  const closeDrawerAndModal = () => {
    toggleDrawer();
    handleCloseModal();
  };

  const handleSave = async (formData) => {
    // TODO: upgrade reason field after backend improvement
    const data = {
      id: info.id, status: formData.status.value, note: formData.note, reason: formData.reason.label,
    };
    const response = await api.refundRequests.patchRefundInfo(data);
    if (response.status >= 200 && response.status < 400) {
      dispatch(setSuccessMessage('Статус заявки успішно змінений', response.data?.statusTitle));
      toggleDrawer();
      setRefresh(true);
      if (formData.status.value === 'refund_processed') {
        setInformModal({ open: true, titleStatus: 'Проведене повернення коштів', text: refundPaymentModalText });
      }
      if (formData.status.value === 'exchange_processed') {
        setInformModal({ open: true, titleStatus: 'Проведений обмін товару', text: exchangeProductModalText });
      }
    } else {
      dispatch(setErrorMessage(response.data?.errors));
    }
  };

  return (
    <>
      <Drawer
        open={open}
        onClose={handleCancel}
        title="Редагування заявки"
        saveButtonTitle="Зберегти зміни"
        handleCancel={handleCancel}
        handleSave={handleSubmit(handleSave)}
        saveButtonDisabled={!isDirty}
      >
        <Box sx={{
          maxWidth: '320px', display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '16px',
        }}
        >
          <Controller
            name="status"
            control={control}
            render={({ field }) => {
              const input = generateInput(field);
              return (
                <Select
                  input={input}
                  options={statusOptions}
                  variant="outlined"
                  placeholder=""
                  disabled={!isEdit}
                  label="Статус заявки"
                />
              );
            }}
          />
          <Controller
            name="reason"
            control={control}
            render={({ field }) => {
              const input = generateInput(field);
              return (
                <Select
                  input={input}
                  options={reasonOptions}
                  variant="outlined"
                  placeholder=""
                  disabled={!isEdit || reasonOptions.length === 0}
                  label="Причина"
                />
              );
            }}
          />
          <Controller
            name="note"
            control={control}
            render={({ field }) => {
              const input = generateInput(field);
              return (
                <TextField
                  multiline
                  fullWidth
                  label="Примітка"
                  input={input}
                  inputLabelProps={{ shrink: true }}
                  maxLength={500}
                />
              );
            }}
          />
        </Box>
        {Object.entries(info).filter(item => !notShownFields.includes(item?.[0])).map(([key, value]) => (<InfoRow objectKey={key} key={key} value={value} />))}
      </Drawer>
      <CancelModal
        titleText={t('Хотите закрыть страницу без сохранения?')}
        text={t('Подчеркиваем, что введенные данные сохранены не будут.')}
        cancelText={t('Отменить')}
        closeModal={cancelModalOpen}
        handleCloseModal={handleCloseModal}
        handleCancel={closeDrawerAndModal}
      />
      <InformModal informModal={informModal} handleCloseModal={() => setInformModal({ open: false })} />
    </>
  );
};


export default RefundRequestEdit;
