// Core
import React, { useState, memo } from 'react';
import * as PropTypes from 'prop-types';
// Parts
import TextField from '../../../../../../../../components/_Form/TextField/TextField/TextField';
import ConfirmButtons from '../../../../../../../../components/Buttons/ConfirmButtons/ConfirmButtons';
import EditableContainer from '../../../../../../../../components/_Table/TableCell/components/EditableContainer/EditableContainer';
// Engine
import { accessList } from '../../../../../../../../../engine/config/access';
// Helpers
import { useAccessList } from '../../../../../../../../hooks/useAccessList';

function PriceFormatFieldWithConfirmButtons(props) {
  const {
    error, disabled, featureName, onConfirm, productId, isEditable,
    success, value, required, type, fullText, showDeleteButton, textColor, color,
  } = props;
  const hasProductPricesImportAccess = useAccessList(accessList.productsImportPrices);
  const initValue = value?.toString() || '';
  const [textFieldValue, setTextFieldValue] = useState(initValue);
  const textToShow = value || value === 0 ? `${value}` : '';

  const onChangeHandler = (args) => {
    const { value: text } = args;
    setTextFieldValue(text);
  };

  const onConfirmHandler = (payload) => {
    onConfirm({ [featureName]: payload });
  };

  return (
    <EditableContainer fullText={fullText} isEditable={isEditable && hasProductPricesImportAccess} text={textToShow} color={color}>
      <ConfirmButtons
        key={productId}
        error={error}
        required={required}
        disabled={disabled || !hasProductPricesImportAccess}
        notEmpty={textFieldValue !== ''}
        onConfirm={onConfirmHandler}
        onReject={setTextFieldValue}
        success={success}
        type={type}
        value={textFieldValue.trim()}
        valuesPlain={textToShow}
        initValue={initValue}
        showDeleteButton={showDeleteButton}
        color={color}
      >
        <TextField
          isPrice
          input={{
            onChange: onChangeHandler,
            value: textFieldValue,
          }}
          textColor={textColor}
        />
      </ConfirmButtons>
    </EditableContainer>
  );
}

PriceFormatFieldWithConfirmButtons.propTypes = {
  error: PropTypes.bool,
  fullText: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  featureName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isEditable: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  success: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textColor: PropTypes.string,
  color: PropTypes.string,
};

PriceFormatFieldWithConfirmButtons.defaultProps = {
  required: false,
  error: false,
  showDeleteButton: true,
  disabled: false,
  isEditable: false,
  success: false,
  value: '',
};

export default memo(PriceFormatFieldWithConfirmButtons);
