import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const FormTextFieldInput = styled(TextField)(({ multiline }) => ({
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& label.Mui-error': {
    color: '#f44336',
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
  },
  '& .MuiInputLabel-asterisk': {
    color: '#f44336', // Red color for asterisk
  },
  '& .MuiInputBase-formControl': {
    height: multiline ? 'auto' : '40px',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E3E7',
      borderWidth: '1px',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(111,126,140,0.24)',
      borderWidth: '1px',
    },
    '&.Mui-error fieldset': {
      borderColor: '#f44336', // Red color for error state
      borderWidth: '1px',
    },
  },
  // Adjust the specificity for the focused and error states on the label
  '&.Mui-focused.Mui-error .MuiInputLabel-root': {
    color: '#f44336', // This ensures that the error color takes precedence
  },
  // Default error state without focus
  '&.Mui-error .MuiInputLabel-root': {
    color: '#f44336', // Red color for label in error state
  },
}));

export default FormTextFieldInput;
