import React, { forwardRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import HistoryIcon from '@mui/icons-material/History';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { styled } from '@mui/system';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { IconButtonStyles } from '../../../components/_Table/TableCell/Styles';
import DocumentStatusBox, { STATUS_COLORS, STATUS_TITLES, STATUS_TITLES_FOR_HER } from './DocumentStatusBox';
import { COLOR } from '../../../containers/App/AppStyles';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: '370px',
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const DocumentStatusHistoryTooltip = forwardRef(({
  documents,
  contractName,
  tooltipId,
  openTooltipId,
  setOpenTooltipId,
  onClickAway,
  onToggle, ...props
}, ref) => {
  const isOpen = openTooltipId === tooltipId;
  const [openRows, setOpenRows] = useState({});
  const toggleRow = (id) => {
    setOpenRows(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const sortedHistory = documents.sort(
    (a, b) => b.id - a.id,
  );

  return (
    <HtmlTooltip
      ref={ref}
      {...props}
      placement="left-start"
      PopperProps={{
        disablePortal: true,
      }}
      open={isOpen}
      title={(

        <ClickAwayListener onClickAway={onClickAway}>
          <Box>
            <Typography color={COLOR.grey.main} fontWeight={600} fontSize={14} gutterBottom>Історія змін</Typography>
            <Divider sx={{ mb: '10px', mt: '10px' }} />
            <Grid
              container
              justifyContent="center"
              spacing={1}
            >
              {sortedHistory.length > 0 && sortedHistory.map(document => (
                <>
                  <Grid item xs={12} lg={12}>
                    <Box sx={{ mb: '5px' }} display="flex" alignItems="center" justifyContent={'space-between'}>
                      <Box>

                        <Typography lineHeight={1} color={COLOR.grey.main} fontWeight={700} fontSize={14} gutterBottom>
                          {document.type === 'CONTRACT' ? (`${contractName} ${document.createdAt}`) : (document.createdAt) }
                        </Typography>
                        <Typography lineHeight={1} color={COLOR.grey.main} fontWeight={500} fontSize={14} gutterBottom>
                          {document.updatedAt}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" justifyContent={'space-between'}>
                        <DocumentStatusBox isHer={document.type === 'POWER_OF_ATTORNEY'} status={document.status} />
                        {document.history.length > 0 && (
                          <Box>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => toggleRow(document.id)}
                            >
                              {openRows[document.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Collapse in={openRows[document.id]} timeout="auto" unmountOnExit>
                      {document.history.length > 0 && document.history.map(history => (
                        <>
                          <Box sx={{ mt: '10px' }} display="flex" alignItems="center" justifyContent={'space-between'}>
                            <Box>
                              <Typography lineHeight={1} color={COLOR.grey.main} fontWeight={500} fontSize={14} gutterBottom>
                                {history.createdAt}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography lineHeight={1} color={STATUS_COLORS[history.status]} fontWeight={500} fontSize={14} gutterBottom>
                                {history.type === 'POWER_OF_ATTORNEY' ? STATUS_TITLES_FOR_HER[history.status] : STATUS_TITLES[history.status]}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider sx={{ mt: '3px', mb: '2px' }} />
                        </>
                      ))}
                    </Collapse>
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        </ClickAwayListener>
        )}
    >
      <IconButtonStyles
        sx={{
          borderRadius: '5px', padding: '5px', mr: '10px', border: '1px solid rgba(48, 48, 48, 0.08)',
        }}
        onClick={onToggle}
        color="inherit"
        size="large"
      >
        <HistoryIcon sx={{ color: COLOR.grey.main, cursor: 'pointer' }} />
      </IconButtonStyles>
    </HtmlTooltip>
  );
});

export default DocumentStatusHistoryTooltip;
