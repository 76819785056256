// Core
import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import cx from 'classnames';
// Icons
import ClearIcon from '@mui/icons-material/Clear';
// UI
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
// Styles
import TableHeadFilterCellStyles from './TableHeadFilterCellStyles';
import { COMPONENTS_TYPES } from '../../../../../containers/App/AppStyles';

const { TABLE_CELL_FILTER } = COMPONENTS_TYPES;

const useStyles = makeStyles(TableHeadFilterCellStyles);

function TableHeadFilterCell(props) {
  const {
    children, clearButton, disabled, inputWrapper, resetFilter,
    selectWrapper, cellType,
  } = props;
  const classes = useStyles(props);

  const tableCellClassNames = cx({
    [classes.inputWrapper]: inputWrapper,
    [classes.selectWrapper]: selectWrapper,
  });
  const inputWithClearClasses = cx({
    [classes.inputWithClear]: cellType === TABLE_CELL_FILTER.inputFilter,
    [classes.selectFilter]: cellType === TABLE_CELL_FILTER.selectFilter,
    [classes.dateWithClear]: cellType === TABLE_CELL_FILTER.dateFilter,
    [classes.inputWithClearActive]: !disabled,
  });

  return (
    <TableCell className={tableCellClassNames}>
      {clearButton
        ? (
          <div className={classes.container}>
            <div className={inputWithClearClasses}>
              {children}
            </div>
            <IconButton
              className={classes.buttonSmall}
              component="span"
              disabled={disabled}
              onClick={resetFilter}
              size="large"
            >
              <ClearIcon className={classes.iconSmall} />
            </IconButton>
          </div>
        ) : (
          children
        )
      }
    </TableCell>
  );
}

TableHeadFilterCell.propTypes = {
  children: PropTypes.any,
  clearButton: PropTypes.bool,
  disabled: PropTypes.bool,
  inputWrapper: PropTypes.bool,
  resetFilter: PropTypes.func,
  selectWrapper: PropTypes.bool,
  cellType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TableHeadFilterCell.defaultProps = {
  clearButton: true,
  children: null,
  disabled: false,
  inputWrapper: true,
  selectWrapper: false,
  cellType: TABLE_CELL_FILTER.inputFilter,
};

export default memo(TableHeadFilterCell);
