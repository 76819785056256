
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { COLOR } from '../../../containers/App/AppStyles';

const InfoRow = ({ objectKey, value }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', marginBottom: '16px' }}>
      <Typography fontSize={13} lineHeight="16px" color={COLOR.black[72]} sx={{ width: '150px', marginRight: '16px' }}>{t(objectKey)}</Typography>
      <Typography fontSize={14} sx={{ maxWidth: '340px' }}>{value}</Typography>
    </Box>
  );
};


export default InfoRow;
