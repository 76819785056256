
export function PromoDescriptionSvgIcon() {
  return (
    <>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="30" height="30" rx="7" fill="#303030" fillOpacity="0.08" />
        <path d="M11.2239 8C10.2159 8 9.39878 8.81715 9.39878 9.82515V14.1126C9.53126 14.0937 9.66668 14.0839 9.8044 14.0839C10.0857 14.0839 10.3581 14.125 10.6156 14.2017V9.82515C10.6156 9.48915 10.8879 9.21677 11.2239 9.21677H18.9301C19.2661 9.21677 19.5385 9.48915 19.5385 9.82515V21.3844H11.4267V22.3984C11.4267 22.4667 11.4241 22.5343 11.419 22.6012H21.3637C22.8197 22.6012 24 21.4209 24 19.9649V16.923H20.7553V9.82515C20.7553 8.81715 19.9381 8 18.9301 8H11.2239ZM13.8316 15.7062H17.3078C17.6438 15.7062 17.9162 15.4338 17.9162 15.0978C17.9162 14.7618 17.6438 14.4894 17.3078 14.4894H13.3515C13.599 14.8443 13.7675 15.2584 13.8316 15.7062ZM21.3637 21.3844H20.7553V18.1397H22.7832V19.9649C22.7832 20.7489 22.1477 21.3844 21.3637 21.3844ZM12.2379 11.8531C12.2379 11.5171 12.5103 11.2447 12.8463 11.2447H17.3078C17.6438 11.2447 17.9162 11.5171 17.9162 11.8531C17.9162 12.1891 17.6438 12.4615 17.3078 12.4615H12.8463C12.5103 12.4615 12.2379 12.1891 12.2379 11.8531Z" fill="#303030" />
        <path d="M12.4567 14.847C12.9228 14.847 13.3006 15.2249 13.3006 15.691V17.7397C13.3006 18.063 13.1722 18.373 12.9437 18.6016L9.7552 21.791C9.27907 22.2662 8.50802 22.2666 8.03124 21.7916L6.35779 20.1205C5.88128 19.6449 5.88067 18.873 6.35638 18.3966L9.54434 15.2046C9.77299 14.9757 10.0833 14.847 10.4068 14.847H12.4567ZM11.4252 16.1607C11.1145 16.1607 10.8627 16.4125 10.8627 16.7233C10.8627 17.034 11.1145 17.2859 11.4252 17.2859C11.736 17.2859 11.9878 17.034 11.9878 16.7233C11.9878 16.4125 11.736 16.1607 11.4252 16.1607Z" fill="#303030" />
      </svg>
    </>
  );
}
