import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useModalContext } from '../../../containers/Context/UseModalContext';
import RightSlideModal from '../../../containers/Modals/RightSlideModal/RightSlideModal';
import { ExpandMoreIcon } from '../../../components/Icons/ExpandMoreIcon';
import { ChevronRightIcon } from '../../../components/Icons/ChevronRightIcon';

const renderTree = nodes => nodes.map(node => (
  <TreeItem
    sx={{
      '& .MuiTreeItem-content': {
        padding: '4px 12px',
        borderRadius: '4px',
        backgroundColor: 'transparent',
        cursor: node.children?.length > 0 ? 'pointer' : 'default',
      },
      '& .MuiTreeItem-content:hover': {
        backgroundColor: node.children?.length > 0 ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
        cursor: node.children?.length > 0 ? 'pointer' : 'default',
      },
      '&  .MuiTreeItem-label': {
        fontSize: '14px !important',
        padding: '6px',
      },
      '& .Mui-selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
      },
    }}
    key={node.id}
    nodeId={node.id.toString()}
    label={node.label}
  >
    {node.children?.length > 0 && renderTree(node.children)}
  </TreeItem>
));

export function CategoriesModal({ title }) {
  const { open, selectedTree, hideModal } = useModalContext();
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    if (selectedTree && Array.isArray(selectedTree)) {
      setTreeData(selectedTree);
    } else {
      setTreeData([]);
    }
  }, [selectedTree]);

  return (
    <RightSlideModal
      fullScreen
      margin={0}
      open={open}
      scroll="body"
      onClose={hideModal}
      PaperProps={{
        style: {
          width: '380px',
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          margin: 0,
          borderRadius: 0,
        },
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Box
          borderBottom={'1px solid rgba(48, 48, 48, 0.12)'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            pl: '20px', pr: '20px', pt: '14px', pb: '14px',
          }}
        >
          <Box>
            <Typography fontWeight={700} color={'#303030'} fontSize={18}>{title}</Typography>
          </Box>
          <IconButton
            sx={{ p: '0px' }}
            edge="start"
            color="inherit"
            onClick={hideModal}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ p: '20px' }}>
          {treeData.length > 0 ? (
            <TreeView
              aria-label="tree"
              disableSelection
              disabledItemsFocusable
              defaultExpandIcon={<ExpandMoreIcon />}
              defaultCollapseIcon={<ChevronRightIcon />}
              defaultExpanded={[]}
            >
              {renderTree(treeData)}
            </TreeView>
          ) : (
            <Box sx={{ padding: 2, textAlign: 'center', color: 'gray' }}>
              Елементи відсутні
            </Box>
          )}
        </Box>
      </Box>
    </RightSlideModal>
  );
}
