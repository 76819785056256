
export const types = {
  SET_PROMOTIONS_LIST: 'SET_PROMOTIONS_LIST',
  SET_PROMOTIONS_CURRENT_PAGE: 'SET_PROMOTIONS_CURRENT_PAGE',
  SET_PROMOTIONS_FILTERS: 'SET_PROMOTIONS_FILTERS',
  SET_PROMOTIONS_PAGE_SIZE: 'SET_PROMOTIONS_PAGE_SIZE',
  SET_PROMOTIONS_SORTING: 'SET_PROMOTIONS_SORTING',
  SET_PROMOTIONS_INITIAL_STATE: 'SET_PROMOTIONS_INITIAL_STATE',
  SET_PROMOTIONS_SELECTION: 'SET_PROMOTIONS_SELECTION',
  SET_PROMOTION_DATA: 'SET_PROMOTION_DATA',
  SET_PROMOTION_PENDING: 'SET_PROMOTION_PENDING',
  PROMOTION_CREATED_SUCCESSFULLY: 'PROMOTION_CREATED_SUCCESSFULLY',
  SET_PROMOTION_FOR_EDIT: 'SET_PROMOTION_FOR_EDIT',
  SET_PROMOTION_HISTORY_LIST: 'SET_PROMOTION_HISTORY_LIST',
  SET_PROMOTION_LIST_SETTING_STATUS: 'SET_PROMOTION_LIST_SETTING_STATUS',
  SET_PROMOTION_LIST_SETTING_PENDING: 'SET_PROMOTION_LIST_SETTING_PENDING',
};
