/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
// Core
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
// Components
import ArrowBack from '@mui/icons-material/ArrowBack';
import SaveAndReturnIcon from '@mui/icons-material/CallMissed';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
import SectionHolder from '../../../../containers/SectionHolder/SectionHolder';
import ProductsGroupTable from './components/ProductGroupTable';
import AddProductSidebar from './components/AddProductSidebar';
import CancelModal from './components/CancelModal';
// Engine
import { pageLinks } from '../../../../../engine/config/routes';
import { api } from '../../../../../engine/config/api';
import { setErrorMessage, setSuccessMessage } from '../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
// Helpers
import { transformDataForProductsGroupTable } from './utils/transformDataForProductsGroupTable';
import { COLOR } from '../../../../containers/App/AppStyles';
import { getDate } from '../../../../../_helpers/getDate';
import { useFetchOptions } from './hooks/useFetchOptions';
import PartnerSelect from './components/PartnerSelect';
import CategorySelect from './components/CategorySelect';
import BrandSelect from './components/BrandSelect';
import FeaturesSelect from './components/FeaturesSelect';
import GroupNameField from './components/GroupNameField';

export const InfoLine = ({ title, value }) => <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}><span>{title}:</span> <Typography sx={{ color: 'black', fontSize: '13px' }}>{value}</Typography></Box>;


const DEFAULT_VALUES = {
  partner: '',
  category: '',
  brand: '',
  features: [],
  groupName: '',
};

const ProductAddGroup = () => {
  const { hashId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleSubmit, control, formState: { errors, touchedFields }, watch, reset, resetField,
  } = useForm({
    mode: 'all', defaultValues: DEFAULT_VALUES,
  });
  const editMode = !!hashId;
  const [groupRows, setGroupRows] = useState([]);
  const [groupColumns, setGroupColumns] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [groupInfo, setGroupInfo] = useState({});
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const previousValuesRef = useRef({
    partner: '',
    category: '',
    brand: '',
  });

  const allFields = watch();
  const {
    partner, category: partnerCategory, brand, features, groupName,
  } = allFields;

  const {
    categoryOptions, brandsOptions, featuresOptions, isPartnerInfoLoading,
  } = useFetchOptions(partner, partnerCategory, brand, editMode);

  const pageTitle = editMode ? t('Редактирование группы', { groupName, interpolation: { escapeValue: false } }) : t('Добавление группы товаров');

  useEffect(() => {
    const previousValues = previousValuesRef.current;
    const changedField = Object.keys(allFields).find(key => allFields[key]?.value !== previousValues[key]?.value);
    if (changedField === 'partner' && previousValues[changedField]) {
      reset({ partner });
    }
    if (changedField === 'category' && previousValues[changedField]) {
      reset({ partner, category: partnerCategory });
    }
    if (changedField === 'brand' && previousValues[changedField]) {
      reset({ partner, category: partnerCategory, brand });
    }
    previousValuesRef.current = { ...allFields };
  }, [allFields]);


  useEffect(() => {
    if (editMode) {
      (async () => {
        const response = await api.productsGroups.getProductGroupByHashId(hashId);
        if (response.status >= 200 && response.status < 400) {
          const {
            brand, category, features: featuresFromResponse, partner, products, name: groupName, updatedBy, updatedAt, sku,
          } = response.data;
          const featureValues = Object.values(featuresFromResponse);
          const { rows, additionalColumns } = transformDataForProductsGroupTable(Object.values(products), featureValues);
          setGroupInfo({ user: updatedBy.name, sku, updatedAt: getDate(updatedAt) });
          setGroupColumns(additionalColumns);
          setGroupRows(rows);
          reset({
            brand, category, features: featureValues, partner, groupName,
          });
        }
      })();
    }
  }, [hashId]);

  const handleNavigateBack = () => {
    navigate(pageLinks.productsAndPrices.grouping);
  };

  const featureIds = features?.map(feature => feature.value);
  const productIds = groupRows?.map(group => group.value);

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    const data = editMode
      ? { productIds, name: groupName }
      : {
        partnerId: partner.value,
        brandId: brand.value,
        categoryId: partnerCategory.value,
        featureIds,
        productIds,
      };
    const apiPath = editMode ? api.productsGroups.updateProductGroup(data, hashId) : api.productsGroups.postProductGroup(data);
    const response = await apiPath;

    if (response.status >= 200 && response.status < 400) {
      const successMessage = editMode ? t('Группа успешно изменена') : t('Группа успешно создана');
      dispatch(setSuccessMessage(successMessage, response.data?.statusTitle));
      handleNavigateBack();
    } else {
      dispatch(setErrorMessage(response.data.errors, response.data?.message ?? t('Ошибка')));
    }
    setIsLoading(false);
  }, [editMode, partner?.value, groupName, brand?.value, partnerCategory?.value, featureIds, productIds]);

  const handleAddGroup = async () => {
    const params = {
      partnerId: partner.value,
      brandId: brand.value,
      categoryId: partnerCategory.value,
      featureIds,
    };

    const response = await api.productsGroups.getProductGroups(params);
    if (response.status >= 200 && response.status < 400) {
      const products = response.data.products;
      if (Object.values(products).length) {
        const { rows, additionalColumns } = transformDataForProductsGroupTable(Object.values(products), features);
        setGroupColumns(additionalColumns);
        setGroupRows(rows);
      } else {
        dispatch(setErrorMessage(response?.data?.errors ?? t('Ошибка')));
      }
    } else {
      dispatch(setErrorMessage(response?.data?.errors ?? t('Ошибка'), response.data?.statusTitle));
    }
  };

  const addGroupButtonDisabled = !(partner?.hashId && partnerCategory?.value && brand?.value && features?.length);

  const handleAddProduct = () => setIsSidebarOpen(true);

  const closeSidebar = () => setIsSidebarOpen(false);

  const handleDeleteGroupName = () => {
    resetField('groupName', { defaultValue: '' });
  };

  const handleReturn = () => {
    if ((partner || partnerCategory || brand) && !editMode) {
      setCancelModalOpen(true);
    } else if (editMode && touchedFields.groupName) {
      setCancelModalOpen(true);
    } else {
      handleNavigateBack();
    }
  };

  return (
    <>
      <PageHeader title={pageTitle}>
        <ButtonWithIcon text={t('Вернуться')} onClick={handleReturn}>
          <ArrowBack />
        </ButtonWithIcon>
        <ButtonWithIconAndCircularProgress text={t('Сохранить и вернуться')} onClick={handleSubmit(onSubmit)} disabled={editMode ? isLoading : (addGroupButtonDisabled || !groupRows.length || isLoading)}>
          <SaveAndReturnIcon />
        </ButtonWithIconAndCircularProgress>
      </PageHeader>
      <SectionHolder>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container sx={{ height: '100%' }} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              {editMode && (
              <Box sx={{
                display: 'flex', flexDirection: 'column', fontSize: '13px', color: COLOR.black[72], marginBottom: '40px',
              }}
              >
                <InfoLine title={t('SKU группы')} value={groupInfo.sku} />
                <InfoLine title={t('Пользователь')} value={groupInfo.user} />
                <InfoLine title={t('Дата изменения')} value={groupInfo.updatedAt} />
              </Box>
              )}
              <Typography fontWeight={700} fontSize={14}>{t('Общие настройки')}</Typography>
            </Grid>
            <Grid item xs={8} sm={6} md={4}>
              {editMode && (<GroupNameField control={control} errors={errors} />)}
              <PartnerSelect control={control} errors={errors} editMode={editMode} touchedFields={touchedFields} />
              <CategorySelect control={control} categoryOptions={categoryOptions} isPartnerInfoLoading={isPartnerInfoLoading} errors={errors} editMode={editMode} touchedFields={touchedFields} />
              <BrandSelect control={control} errors={errors} editMode={editMode} brandsOptions={brandsOptions} isPartnerInfoLoading={isPartnerInfoLoading} touchedFields={touchedFields} />
              <FeaturesSelect control={control} editMode={editMode} featuresOptions={featuresOptions} isPartnerInfoLoading={isPartnerInfoLoading} disabledField={!partner || !brand || !partnerCategory} />
              {!editMode && (
              <ButtonWithIcon onClick={handleAddGroup} sx={{ marginTop: '32px', gap: '8px' }} disabled={addGroupButtonDisabled}>
                <AddCircleIcon />
                {t('Добавить группу')}
              </ButtonWithIcon>
              )}
            </Grid>
            {editMode && (
            <Grid xs={4} sm={6} md={4}>
              <IconButton
                sx={{
                  marginTop: '22px',
                  marginLeft: '8px',
                  backgroundColor: COLOR.grey['8'],
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: COLOR.brand.dark,
                  borderRadius: '3px',
                  width: '28px',
                  height: '28px',
                }}
                disabled={false}
                onClick={handleDeleteGroupName}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Grid>
            )}
            {groupRows.length > 0 && (
            <Box sx={{ marginTop: '40px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '6px 0px' }}>
                <Typography fontWeight={700} fontSize={16}>
                  {t('Товары')}
                </Typography>
                {editMode && (
                <ButtonWithIcon sx={{ gap: '8px' }} onClick={handleAddProduct}>
                  <AddCircleIcon />
                  {t('Добавить товар')}
                </ButtonWithIcon>
                )}
              </Box>
              <ProductsGroupTable rows={groupRows} setRows={setGroupRows} additionalColumns={groupColumns} />
            </Box>
            )}
          </Grid>
        </form>
      </SectionHolder>
      <Drawer open={isSidebarOpen} onClose={closeSidebar} anchor="right">
        <AddProductSidebar features={features} groupName={groupName} hashId={hashId} setRows={setGroupRows} close={closeSidebar} />
      </Drawer>
      <CancelModal closeModal={cancelModalOpen} handleCloseModal={() => setCancelModalOpen(false)} navigateBack={handleNavigateBack} />
    </>
  );
};

export default ProductAddGroup;
