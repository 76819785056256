// Core
import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Engine
import { asyncActions } from '../../../../engine/core/refundRequests/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
// Helpers
import { convertSortingObjectToUrlParams } from '../../../../_helpers/convertDataToUrlParams';
import { localData } from '../../../../engine/config/localData';
import { actions } from '../../../../engine/core/refundRequests/actions';

export function useRefundRequestsList(refresh) {
  const currentPage = useSelector(selectors.refundRequests.currentPage);
  const limit = useSelector(selectors.refundRequests.pageSize);
  const filters = useSelector(selectors.refundRequests.filters);
  const sorting = useSelector(selectors.refundRequests.sorting);
  const reload = useSelector(selectors.refundRequests.reload);
  const path = useSelector(selectors.router.pathname);
  const dispatch = useDispatch();
  const offset = limit * currentPage;

  const orderBy = useMemo(() => {
    const normalizeSorting = sorting.toJS();
    return convertSortingObjectToUrlParams(normalizeSorting);
  },
  [sorting]);

  const paramsByFilter = useMemo(() => {
    const params = {};
    const normalizeFilters = filters.toJS();

    return normalizeFilters.reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, params);
  }, [filters]);


  const getListAsync = useCallback(() => {
    const params = {
      ...paramsByFilter,
      orderBy,
      limit,
      offset,
    };
    dispatch(asyncActions.getListAsync(params));
  }, [orderBy, limit, offset, filters]);

  const getInitialListAsync = async () => {
    const localInfo = await localData.getItem(path);
    if (!refresh) {
      dispatch(actions.setInitialState(localInfo));
    }
  };

  const hasMounted = useRef(false);
  useEffect(() => {
    if (!hasMounted.current && !refresh) {
      hasMounted.current = true;
      (async () => {
        await getInitialListAsync();
      })();
    }
    if (reload || refresh) {
      getListAsync();
    }
  }, [refresh, filters, orderBy, limit, offset, reload]);

  return getListAsync;
}
