// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// Parts
import { ServiceSupportModalOptions } from '../../../Header/components/ServiceSupport/ServiceSupportModal';
import TableHeadFilterSelectMultiple from './TableHeadFilterSelectMultiple';
// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions as feedsAsyncActions } from '../../../../../engine/core/feeds/all/saga/asyncActions';
import { asyncActions as feedsProductsAsyncActions } from '../../../../../engine/core/feeds/products/saga/asyncActions';
import { asyncActions as partnersAsyncAction } from '../../../../../engine/core/partners/saga/asyncActions';
import { partnerAsyncAction } from '../../../../../engine/core/_partners/saga/asyncAction';
import { asyncActions as configurationsAsyncActions } from '../../../../../engine/core/configurations/saga/asyncActions';
import { asyncActions as serviceDeskAsyncActions } from '../../../../../engine/core/serviceDesk/saga/asyncActions';
import { asyncActions as transactionsPaymentAsyncActions } from '../../../../../engine/core/transactionsPayment/saga/asyncActions';
import { asyncActions as faqAsyncActions } from '../../../../../engine/core/faq/saga/asyncActions';
import { asyncActions as ordersAsyncAction } from '../../../../../engine/core/orders/saga/asyncActions';
import { asyncActions as brandAsyncAction } from '../../../../../engine/core/brands/saga/asyncActions';
import { billingAsyncAction } from '../../../../../engine/core/billing/saga/asyncAction';
// Helpers
import { faqStatuses } from '../../../../page/Faq/_hooks/useFaqList';
import { tableDataTypes } from '../../../../../_helpers/data/tableDataTypes';
import { tableFiltersTypes } from '../../../../../_helpers/data/pages/contentMenagement/contentProducts/tableFilters';
import { usePrevious } from '../../../../hooks/usePrevious';
import { pageLinks } from '../../../../../engine/config/routes';
import { partnerStatuses } from '../../../../../engine/config/partnerStatuses';
import { promotionTypesSelectValues } from '../../../../../engine/config/promotions';

const MultipleFilter = (props) => {
  const {
    filters, columnType, columnName, columnValues,
    disabled, placeholder, onSelectChange,
    closeMenuOnScroll,
  } = props;
  const [value, setValue] = useState(filters);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const pathname = useSelector(selectors.router.pathname);
  const problemStatusesList = useSelector(selectors.partner.problemStatusesList);
  const stoppedStatusesList = useSelector(selectors.partner.stoppedStatusesList);
  const ordersPaymentTypes = useSelector(selectors.orders.paymentTypes);
  const ordersShippingType = useSelector(selectors.orders.ordersShippingType);
  const feedsStatusesList = useSelector(selectors.feeds.statusesList);
  const feedsStatusesListPending = useSelector(selectors.feeds.statusesPending);
  const partnerStatusesList = useSelector(selectors.partner.statusesList);
  const partnerStatusesListPending = useSelector(selectors.partner.statusesListPending);
  const configurationsTypeList = useSelector(selectors.configurations.types);
  const configurationsTypeListPending = useSelector(selectors.configurations.typesPending);
  const prioritiesList = useSelector(selectors.feeds.prioritiesList);
  const prioritiesListPending = useSelector(selectors.feeds.prioritiesListPending);
  const feedsProductsStatusesList = useSelector(selectors.feedsProducts.statusesList);
  const feedsProductsStatusesListPending = useSelector(selectors.feedsProducts.statusesListPending);
  const feedsProductsElaborationStatusesList = useSelector(selectors.feedsProducts.statusesElaborationList);
  const feedsProductsElaborationStatusesListPending = useSelector(selectors.feedsProducts.statusesElaborationListPending);
  const partnersPaymentTypesList = useSelector(selectors.partnersPaymentTypes);
  const partnersPaymentTypesListPending = useSelector(selectors.partners.paymentTypesPending);
  const cancelStatusesList = useSelector(selectors.partner.cancelStatusesList);
  const disableStatusesList = useSelector(selectors.partner.disableStatusesList);
  const serviceDeskStatusesList = useSelector(selectors.serviceDesk.statusesList);
  const serviceDeskStatusesListPending = useSelector(selectors.serviceDesk.statusesListPending);
  const productStatuses = useSelector(selectors.allProductsTable.productStatuses);
  const productsStatusesPending = useSelector(selectors.allProductsTable.productsStatusesPending);
  const cancelStatuses = useSelector(selectors.allProductsTable.cancelStatuses);
  const transactionsPaymentStatuses = useSelector(selectors.transactionsPayment.transactionsPaymentStatuses);
  const transactionsPaymentStatusesPending = useSelector(selectors.transactionsPayment.transactionsPaymentStatusesPending);
  const feedsIsItemByHashIdItem = useSelector(selectors.feeds.isItemByHashIdItem);
  const feedsIsItemByHashIdLoading = useSelector(selectors.feeds.isItemByHashIdLoading);
  const faqStatusesList = useSelector(selectors.faqs.statusesList);
  const faqsStatusesListPending = useSelector(selectors.faqs.statusesListPending);
  const brandsList = useSelector(selectors.brands.brandsListContext);
  const brandsListContextPending = useSelector(selectors.brands.brandsListContextPending);
  const postUsersHashIdPending = useSelector(selectors.user.postUsersHashIdPending);
  const prevPostUsersHashIdPending = usePrevious(postUsersHashIdPending);

  const currentFiltersTypeList = useSelector(selectors.ui.currentFiltersTypeList);
  const currentFiltersNameList = useSelector(selectors.ui.currentFiltersNameList);

  const prevLanguage = usePrevious(i18n.language);
  const languageWasChanged = (i18n.language !== prevLanguage) && (prevLanguage !== undefined && i18n.language !== undefined);

  useEffect(() => {
    if (!currentFiltersTypeList.includes(columnType)) {
      switch (columnType) {
        case tableFiltersTypes.brand: {
          dispatch(brandAsyncAction.getBrandsListsContextAsync());
          break;
        }
        case tableFiltersTypes.feedStatus: {
          dispatch(feedsAsyncActions.getFeedsStatusesListAsync({ type: 'feed' }));
          break;
        }
        case tableFiltersTypes.feedProductsStatus: {
          dispatch(feedsProductsAsyncActions.getStatusesListAsync({ type: 'product' }));
          break;
        }
        case tableFiltersTypes.feedProductsElaborationStatus: {
          dispatch(feedsProductsAsyncActions.getStatusesListAsync({ type: 'productElaboration' }));
          break;
        }
        case tableDataTypes.partnerPaymentTypes.type: {
          dispatch(partnersAsyncAction.getPaymentTypeAsync());
          break;
        }
        case tableDataTypes.partnerStatus.type: {
          if (columnName !== tableDataTypes.statusFrom.name && columnName !== tableDataTypes.statusTo.name) {
            dispatch(partnerAsyncAction.getPartnerStatusesList());
          }
          break;
        }
        case tableDataTypes.feedPriority.type: {
          dispatch(feedsAsyncActions.getFeedsPrioritiesAsync());
          break;
        }
        case tableDataTypes.configurationsType.type: {
          dispatch(configurationsAsyncActions.getTypesListAsync());
          break;
        }
        case tableDataTypes.faqServiceDescStatus.type: {
          dispatch(serviceDeskAsyncActions.getStatusesList());
          break;
        }
        case tableDataTypes.partnerPaymentTypeTransactionStatuses.type: {
          dispatch(transactionsPaymentAsyncActions.getTransactionsPaymentStatusesAsync());
          break;
        }
        case tableDataTypes.faqStatus.type: {
          dispatch(faqAsyncActions.getFaqStatusesList());
          break;
        }
        case tableDataTypes.ordersPaymentType.type: {
          dispatch(ordersAsyncAction.getOrdersPaymentTypesAsync({ context: 'select' }));
          break;
        }
        case tableDataTypes.ordersShippingType.type: {
          dispatch(ordersAsyncAction.getOrdersShippingTypesAsync());
          break;
        }
        case tableDataTypes.productFeedCategoryStatus.name: {
          dispatch(billingAsyncAction.getBillingStatusesListAsync());
          break;
        }
        case tableFiltersTypes.feedCategoryStatus: {
          dispatch(feedsProductsAsyncActions.getStatusesListAsync({ type: 'category' }));
          break;
        }
        default:
          break;
      }
    }
    if (!currentFiltersNameList.includes(columnName)) {
      switch (columnName) {
        case tableFiltersTypes.brand: {
          dispatch(brandAsyncAction.getBrandsListsContextAsync());
          break;
        }
        default:
          break;
      }
    }
  }, [
    columnType, currentFiltersTypeList,
    columnName, currentFiltersNameList,
  ]);

  useEffect(() => {
    if (prevPostUsersHashIdPending !== postUsersHashIdPending && prevPostUsersHashIdPending === true) {
      switch (columnType) {
        case tableDataTypes.partnerStatus.type: {
          dispatch(partnerAsyncAction.getPartnerStatusesList());
          break;
        }
        case tableFiltersTypes.feedStatus: {
          dispatch(feedsAsyncActions.getFeedsStatusesListAsync({ type: 'feed', forceUpdate: true }));
          break;
        }
        case tableDataTypes.partnerPaymentTypes.type: {
          dispatch(partnersAsyncAction.getPaymentTypeAsync());
          break;
        }
        case tableDataTypes.partnerPaymentTypeTransactionStatuses.type: {
          dispatch(transactionsPaymentAsyncActions.getTransactionsPaymentStatusesAsync());
          break;
        }
        default: {
          switch (columnName) {
            case tableDataTypes.productFeedCategoryStatus.name: {
              dispatch(billingAsyncAction.getBillingStatusesListAsync());
              break;
            }
            default: {
              break;
            }
          }
        }
      }
    }
  }, [
    dispatch,
    columnType,
    columnName,
    prevPostUsersHashIdPending,
    postUsersHashIdPending,
  ]);

  useEffect(() => {
    switch (columnType) {
      case tableFiltersTypes.multipleSelect: {
        if (columnName === 'promoTypes') {
          setData(promotionTypesSelectValues);
        } else {
          setData(columnValues);
        }
        break;
      }
      case tableFiltersTypes.brand: {
        setData(brandsList);
        setLoading(brandsListContextPending);
        break;
      }
      case tableDataTypes.feedType.type: {
        setData([
          { label: t('XML файл'), value: 1 },
          { label: t('XML ссылка'), value: 3 },
          { label: t('XLSX шаблон'), value: 2 },
        ]);
        break;
      }
      case tableDataTypes.productFeedCategoryStatus.type: {
        const { stepsData } = feedsIsItemByHashIdItem.toJS();
        setData(stepsData?.[2]?.productFeedCategoryStatuses);
        setLoading(feedsIsItemByHashIdLoading);
        break;
      }
      case tableDataTypes.productFeedAttributeStatus.type: {
        const { stepsData } = feedsIsItemByHashIdItem.toJS();
        setData(stepsData?.[3]?.productFeedAttributeStatuses.filter(item => item.hidden !== true));
        setLoading(feedsIsItemByHashIdLoading);
        break;
      }
      case tableFiltersTypes.feedStatus: {
        setData(feedsStatusesList.toJS());
        setLoading(feedsStatusesListPending);
        break;
      }
      case tableFiltersTypes.feedProductsStatus: {
        setData(feedsProductsStatusesList.toJS());
        setLoading(feedsProductsStatusesListPending);
        break;
      }
      case tableFiltersTypes.feedProductsElaborationStatus: {
        setData(feedsProductsElaborationStatusesList.toJS());
        setLoading(feedsProductsElaborationStatusesListPending);
        break;
      }
      case tableDataTypes.partnerPaymentTypes.type: {
        setData(partnersPaymentTypesList.toJS());
        setLoading(partnersPaymentTypesListPending);
        break;
      }
      case tableDataTypes.partnerStatus.type: {
        setData(partnerStatusesList.toJS());
        setLoading(partnerStatusesListPending);
        break;
      }
      case tableDataTypes.feedPriority.type: {
        setData(prioritiesList.toJS());
        setLoading(prioritiesListPending);
        break;
      }
      case tableDataTypes.configurationsType.type: {
        setData(configurationsTypeList.toJS());
        setLoading(configurationsTypeListPending);
        break;
      }
      case tableDataTypes.faqServiceDescStatus.type: {
        setData(serviceDeskStatusesList.toJS());
        setLoading(serviceDeskStatusesListPending);
        break;
      }
      case tableFiltersTypes.productStatus:
      case tableDataTypes.productStatus.type: {
        setData(productStatuses.toJS());
        setLoading(productsStatusesPending);
        break;
      }
      case tableFiltersTypes.productCancelStatus: {
        setData(cancelStatuses.toJS());
        setLoading(productsStatusesPending);
        break;
      }
      case tableDataTypes.partnerPaymentTypeTransactionStatuses.type: {
        setData(transactionsPaymentStatuses.toJS().items);
        setLoading(transactionsPaymentStatusesPending);
        break;
      }
      case tableDataTypes.faqStatus.type: {
        setData(faqStatusesList.toJS());
        setLoading(faqsStatusesListPending);
        break;
      }
      case tableDataTypes.ordersPaymentType.type: {
        const paymentTypes = ordersPaymentTypes.toJS();
        setData(paymentTypes.items);
        break;
      }
      case tableDataTypes.ordersShippingType.type: {
        const shippingTypes = ordersShippingType.toJS();
        setData(shippingTypes.items);
        break;
      }
      case tableDataTypes.partnerCancelStatus.type: {
        setData([
          ...cancelStatusesList.toJS(),
          ...disableStatusesList.toJS(),
          ...problemStatusesList.toJS(),
          ...stoppedStatusesList.toJS(),
        ]);
        break;
      }
      case tableFiltersTypes.faqServiceDescQuestionType: {
        setData(ServiceSupportModalOptions.map(item => ({
          label: t(item.label),
          value: item.value,
        })));
        break;
      }
      default:
        switch (columnName) {
          case tableFiltersTypes.brand: {
            setData(brandsList);
            setLoading(brandsListContextPending);
            break;
          }
          default:
            break;
        }
    }
  }, [
    columnType, columnName, feedsIsItemByHashIdLoading, feedsStatusesListPending, feedsProductsStatusesListPending,
    feedsProductsElaborationStatusesListPending, partnersPaymentTypesListPending, partnerStatusesListPending,
    partnerStatusesListPending, prioritiesListPending, configurationsTypeListPending, serviceDeskStatusesListPending,
    productsStatusesPending, transactionsPaymentStatusesPending, faqsStatusesListPending,
    feedsIsItemByHashIdItem, feedsStatusesList, feedsProductsStatusesList, feedsProductsElaborationStatusesList,
    partnersPaymentTypesList, partnerStatusesList, prioritiesList, configurationsTypeList, serviceDeskStatusesList,
    productStatuses, cancelStatuses, transactionsPaymentStatuses, faqStatusesList, ordersPaymentTypes, ordersShippingType, cancelStatusesList,
    disableStatusesList, dispatch, brandsList, brandsListContextPending,
  ]);

  useEffect(() => {
    if (columnType === tableDataTypes.faqStatus.type) {
      switch (pathname) {
        case pageLinks.faq.new: {
          setValue({
            value: [data.find(item => item.value === faqStatuses.new)],
          });
          break;
        }
        case pageLinks.faq.review: {
          setValue({
            value: [data.find(item => item.value === faqStatuses.review)],
          });
          break;
        }
        case pageLinks.faq.published: {
          setValue({
            value: [data.find(item => item.value === faqStatuses.published)],
          });
          break;
        }
        default: {
          setValue(filters);
          break;
        }
      }
    } else if (columnType === tableDataTypes.partnerStatus.type) {
      switch (pathname) {
        case pageLinks.partner.new: {
          setValue({
            value: [data.find(item => item.value === partnerStatuses.questionnaireFill)],
          });
          break;
        }
        case pageLinks.partner.waiting: {
          setValue({
            value: [data.find(item => item.value === partnerStatuses.contractSigning)],
          });
          break;
        }
        case pageLinks.partner.rejected: {
          setValue({
            value: [
              data.find(item => item.value === partnerStatuses.cancelStatus),
              data.find(item => item.value === partnerStatuses.frozenSigning),
            ],
          });
          break;
        }
        default: {
          setValue(filters);
          break;
        }
      }
    }
  }, [columnType, pathname, data]);

  useEffect(() => {
    if (
      !currentFiltersNameList.includes(columnName)
      || languageWasChanged
    ) {
      switch (columnName) {
        case tableDataTypes.ordersShippingType.name: {
          dispatch(ordersAsyncAction.getOrdersShippingTypesAsync());
          break;
        }
        default:
          break;
      }
    }
  }, [dispatch, columnName, currentFiltersNameList, languageWasChanged]);

  return (
    <TableHeadFilterSelectMultiple
      values={data}
      loading={isLoading}
      disabled={disabled}
      placeholder={placeholder}
      filters={value}
      onSelectChange={onSelectChange}
      closeMenuOnScroll={closeMenuOnScroll}
    />
  );
};

export default MultipleFilter;

MultipleFilter.propTypes = {
  filters: PropTypes.object,
  columnValues: PropTypes.array,
  columnType: PropTypes.string,
  columnName: PropTypes.string,
  disabled: PropTypes.bool,
  closeMenuOnScroll: PropTypes.bool,
  placeholder: PropTypes.string,
  onSelectChange: PropTypes.func,
};
