import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { COLOR } from '../../../containers/App/AppStyles';
import { asyncActions } from '../../../../engine/core/promotions/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { HistoryTableRow } from './HistoryTableRow';

export function PromoChangeHistoryTable({ hashId }) {
  const promotionHistory = useSelector(selectors.promotions.getPromotionHistoryList);
  const dispatcher = useDispatch();

  useEffect(() => {
    if (promotionHistory.size === 0) {
      dispatcher(asyncActions.getPromotionHistoryList(hashId));
    }
  }, [promotionHistory]);

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={4} lg={4}>
          <Typography marginLeft={'50px'} fontSize={'12px'} fontWeight={400} color={COLOR.black['54']}>Дата зміни</Typography>
        </Grid>
        <Grid item xs={4} lg={4}>
          <Typography fontSize={'12px'} fontWeight={400} color={COLOR.black['54']}>Статус акції</Typography>
        </Grid>
        <Grid item xs={4} lg={4}>
          <Typography fontSize={'12px'} fontWeight={400} color={COLOR.black['54']}>
            Користувач, який вніс зміни
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          {promotionHistory.map(row => (
            <HistoryTableRow key={row.id} row={row} />
          ))}
        </Grid>
      </Grid>
    </>
  );
}
