// Core
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Field } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import { change } from 'redux-form';
// Parts
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import RenderCheckbox from './RenderCheckbox';
import FieldWithFeatureDisabled from './FieldWithFeatureDisabled';
import TextField from '../../../../../components/_Form/TextField/TextField/TextField';
import FieldWithClearButton from '../../../../../containers/FieldWithClearButton/FieldWithClearButton';
import { formName } from '../../../../../../_helpers/data/pages/contentMenagement/contentProducts/contentProductEditForm';
import InfoText from './InfoText';
import HelperText from './HelperText';
// Helpers
import { useStyles } from '../../../../../hooks/useStyles';
import ContentProductEditStyles from '../ContentProductEditStyles';
import { useIsValueWasChanged } from '../hooks/useIsValueWasChanged';
import { getCheckboxName, inputValueEmpty } from '../ContentProductEdit';
import { getDefaultCategoryValue } from '../../ContentProducts/utils';

export const FocusComponent = () => {
  const { t } = useTranslation();
  return (
    <InfoText>
      {t('Введите целое число (до 5 цифр) без запятых и точек')}
    </InfoText>
  );
};

function RenderFormFieldDimensions(props) {
  const {
    margin, massEdit, input, meta, disabled,
    isRecommended, required, featureDisabled,
    refresh, onRefresh, type, label, id,
    changeFieldValue, setTouchField, isFiledPercentWarning,
    canEditProductLogistics, fieldFormat, featureAutoUpdate,
    defaultValueHelpText, defaultValueHelp, isShowDefaultValueHelp,
    defaultLogisticsValues,
  } = props;
  const touched = meta.touched;
  const error = !!(meta.touched && meta.error);
  const { isValueChanged, value, showChangedMessage } = useIsValueWasChanged(input, fieldFormat);
  const checkboxName = getCheckboxName(input.name);
  const [willBeClear, setWillBeClear] = useState(false);
  const canNotEdit = canEditProductLogistics === false;
  const isDisabled = disabled || featureDisabled?.disabled || canNotEdit;
  const classes = useStyles(ContentProductEditStyles);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    changeFieldValue(checkboxName, isValueChanged);
  }, [changeFieldValue, checkboxName, isValueChanged]);

  useEffect(() => {
    if (!input.value && getDefaultCategoryValue(defaultLogisticsValues, input.name)) {
      dispatch(change(formName, input.name, getDefaultCategoryValue(defaultLogisticsValues, input.name)));
    }
  }, []);

  const classNames = cx(classes.formControl, {
    [classes.formControlHelperTextChange]: showChangedMessage,
    [classes.changedFormControl]: showChangedMessage,
    [classes.formControlRecommended]: isRecommended && inputValueEmpty(value) && !isDisabled,
  });

  const onClick = (!touched && !isDisabled) ? () => setTouchField(input.name) : null;

  const inputProps = {
    ...input,
    onChange: (event) => {
      if (!event.target.value.startsWith('0')) {
        input.onChange(event.target.value.replace(/[^0-9]/g, '').slice(0, 5));
      }
    },
  };

  return (
    <FormControl
      error={error}
      className={classNames}
      margin={margin}
      onClick={onClick}
    >
      <Grid container>
        <Grid item xs={1}>
          {massEdit && (
            <Field
              component={RenderCheckbox}
              error={error}
              name={checkboxName}
              disabled={isDisabled}
              inputValue={input.value}
              setWillBeClear={setWillBeClear}
              isValueChanged={isValueChanged}
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <FieldWithClearButton
            refresh={refresh}
            onRefresh={onRefresh}
            formName={formName}
            {...input}
            name={input.name.toString()}
            disabled={isDisabled}
          >
            <FieldWithFeatureDisabled showTooltip={featureDisabled?.showTooltip}>
              <TextField
                disabled={isDisabled}
                input={inputProps}
                fullWidth
                label={label}
                isRecommended={isRecommended}
                required={required}
                id={id}
                isRed={isFiledPercentWarning}
                type={type}
                focusComponent={<FocusComponent />}
              />
              {canNotEdit && !input.value && featureAutoUpdate ? (
                <FormHelperText>
                  {t('Значение для этого поля отсутствует в прайс-листе или не соответствует требованиям: целое число (до 4 цифр) без запятых и точек.')}
                </FormHelperText>
              ) : undefined}
            </FieldWithFeatureDisabled>
          </FieldWithClearButton>
          <HelperText
            error={meta?.error}
            isError={error}
            isValueChanged={showChangedMessage}
            isValueClear={willBeClear}
            touched={touched}
          />
          { isShowDefaultValueHelp
            ? (<Box component="p" className={classes.defaultValueHelpText}>{defaultValueHelpText}<Box className={classes.defaultValueHelp}>{defaultValueHelp}</Box></Box>)
            : null
          }
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </FormControl>
  );
}

export default RenderFormFieldDimensions;
