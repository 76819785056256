import { getIn, List, Map } from 'immutable';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import { stateKeys as userKeys } from './reducers';

export const user = Object.freeze({
  twoFactorAuth: state => getIn(state, [userKeys.controller, userKeys.twoFactorAuth], Map()),
  mailSettings: state => getIn(state, [userKeys.controller, userKeys.mailSettings], Map()),
  putUserInfo: state => getIn(state, [userKeys.controller, userKeys.putUserInfo], Map()),
  phoneVerifyConfirmCode: state => getIn(state, [userKeys.controller, userKeys.phoneVerifyConfirmCode], Map()),
  userSubmitting: state => getIn(state, [userKeys.controller, userKeys.userSubmitting], false),
  configurations: state => getIn(state, [userKeys.controller, userKeys.userConfigurations], Map()),
  userSettings: state => getIn(state, [userKeys.controller, userKeys.userSettings], {}),
  userRoles: state => getIn(state, [userKeys.controller, userKeys.usersRoles], []),
  userInfo: state => getIn(state, [userKeys.controller, userKeys.userInfo], {}),
  userNotificationsPopup: state => getIn(state, [userKeys.controller, userKeys.userNotifications, userKeys.popups], List()),
  userNotificationsMessage: state => getIn(state, [userKeys.controller, userKeys.userNotifications, userKeys.messages], List()),
  accessList: state => getIn(state, [userKeys.controller, userKeys.userInfo, userKeys.accessLists], []),
  userRemember: state => getIn(state, [userKeys.controller, userKeys.userInfo, userKeys.rememberMe], false),
  partnerStatus: state => getIn(state, [userKeys.controller, userKeys.userInfo, userKeys.partnerStatus], null),
  rolesList: state => getIn(state, [userKeys.controller, userKeys.userInfo, userKeys.rolesList], []),
  userPartnerId: state => getIn(state, [userKeys.controller, userKeys.userInfo, userKeys.partnerId], null),
  userInfoEmail: state => getIn(state, [userKeys.controller, userKeys.userInfo, userKeys.email], ''),
  xmlFileLink: state => getIn(state, [userKeys.controller, userKeys.userInfo, userKeys.xmlFileLink], ''),
  isUserByHashIdLoading: state => getIn(state, [userKeys.controller, userKeys.userByHashId, userKeys.pending], false),
  isUserByHashIdData: state => getIn(state, [userKeys.controller, userKeys.userByHashId, userKeys.controller], {}),
  userAddPending: state => getIn(state, [userKeys.controller, userKeys.userAdd, userKeys.pending], false),
  putUsersHashIdPending: state => getIn(state, [userKeys.controller, userKeys.putUsersHashId, userKeys.pending], false),
  userPartnerRolesItems: state => getIn(state, [userKeys.controller, userKeys.userPartnerRoles, userKeys.items], List()),
  userPartnerRolesPending: state => getIn(state, [userKeys.controller, userKeys.userPartnerRoles, userKeys.pending], false),
  userPartnerHashId: state => getIn(state, [userKeys.controller, userKeys.userPartnerHashId], null),
  postUsersHashIdPending: state => getIn(state, [userKeys.controller, userKeys.postUserInfo, userKeys.pending], false),
  alloExpressStatus: state => getIn(state, [userKeys.controller, userKeys.userInfo, 'alloExpressStatus'], {}),
});

export const userSettings = createSelector(
  user.userSettings,
  settings => get(settings, 'order.gridSettings.columnsOrderItem', []),
);
