import { getIn, List } from 'immutable';
// Store keys
import { stateKeys as productsPriceComparisonKeys } from './reducer';
import { pageSizes } from '../../config/globalConfig';

export const productPriceComparison = Object.freeze({
  currentPage: state => getIn(state, [productsPriceComparisonKeys.controller, productsPriceComparisonKeys.productPriceComparisonList, productsPriceComparisonKeys.currentPage], 0),
  filters: state => getIn(state, [productsPriceComparisonKeys.controller, productsPriceComparisonKeys.productPriceComparisonList, productsPriceComparisonKeys.filters], List()),
  isLoading: state => getIn(state, [productsPriceComparisonKeys.controller, productsPriceComparisonKeys.productPriceComparisonList, productsPriceComparisonKeys.pending], false),
  list: state => getIn(state, [productsPriceComparisonKeys.controller, productsPriceComparisonKeys.productPriceComparisonList, productsPriceComparisonKeys.items], List()),
  pageSize: state => getIn(state, [productsPriceComparisonKeys.controller, productsPriceComparisonKeys.productPriceComparisonList, productsPriceComparisonKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [productsPriceComparisonKeys.controller, productsPriceComparisonKeys.productPriceComparisonList, productsPriceComparisonKeys.reload], false),
  totalCount: state => getIn(state, [productsPriceComparisonKeys.controller, productsPriceComparisonKeys.productPriceComparisonList, productsPriceComparisonKeys.totalCount], 0),
  sorting: state => getIn(state, [productsPriceComparisonKeys.controller, productsPriceComparisonKeys.productPriceComparisonList, productsPriceComparisonKeys.sorting], List()),
  selection: state => getIn(state, [productsPriceComparisonKeys.controller, productsPriceComparisonKeys.productPriceComparisonList, productsPriceComparisonKeys.selection], List()),
});
