import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { generateInput, generateMeta } from '../utils/generateInput';
import Select from '../../../../../components/_Form/Selects/Select/Select';
import { Loader } from '../../../../../components/Loader/Loader';

const CategorySelect = ({
  control, categoryOptions, isPartnerInfoLoading, errors, editMode, touchedFields,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name="category"
      control={control}
      render={({ field }) => {
        const input = generateInput(field);
        const meta = generateMeta(errors.category?.message, !!touchedFields.category);
        return (
          <Box sx={{ marginTop: '30px', position: 'relative' }}>
            {isPartnerInfoLoading && <Loader />}
            <Select
              input={input}
              options={categoryOptions}
              variant="outlined"
              placeholder=""
              disabled={!categoryOptions?.length || isPartnerInfoLoading || editMode}
              label={t('Категория')}
              isSearchable
              required
              meta={meta}
              isCategorySelect
              customOnBlur={field.onBlur}
            />
          </Box>
        );
      }}
      rules={{ required: t('Это обязательное поле') }}
    />
  );
};

export default CategorySelect;
