// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPutSettingsWorker,
  callGetOrdersHashIdWorker,
  callPutOrdersHashIdWorker,
  callGetOrdersStatusesListWorker,
  callGetOrdersPaymentTypesList,
  callGetOrdersShippingTypesList,
  callGetOrdersDownloadWorker,
  callPostOrdersUploadWorker,
  callPutOrderUnbindShippingTrackingWorker,
  callGetOrdersStatisticsWorker,
} from './workers';
import { getOrderShippingHistoryWorker } from './workers/getOrderShippingHistoryWorker';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_ORDERS_LIST_ASYNC, callGetListWorker);
}
function* watchPutSettings() {
  yield takeEvery(asyncTypes.PUT_ORDERS_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_ORDERS_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchGetOrdersHashId() {
  yield takeEvery(asyncTypes.GET_ORDERS_HASH_ID_ASYNC, callGetOrdersHashIdWorker);
}
function* watchPutOrdersHashId() {
  yield takeEvery(asyncTypes.PUT_ORDERS_HASH_ID_ASYNC, callPutOrdersHashIdWorker);
}
function* watchGetOrdersStatusesList() {
  yield takeEvery(asyncTypes.GET_ORDERS_STATUSES_LIST_ASYNC, callGetOrdersStatusesListWorker);
}
function* watchGetOrdersPaymentTypes() {
  yield takeEvery(asyncTypes.GET_ORDERS_PAYMENT_TYPES_ASYNC, callGetOrdersPaymentTypesList);
}
function* watchGetOrdersShippingTypes() {
  yield takeEvery(asyncTypes.GET_ORDERS_SHIPPING_TYPES_ASYNC, callGetOrdersShippingTypesList);
}
function* watchGetOrdersStatistics() {
  yield takeEvery(asyncTypes.GET_ORDERS_STATISTICS_ASYNC, callGetOrdersStatisticsWorker);
}
function* watchGetOrdersDownload() {
  yield takeEvery(asyncTypes.GET_ORDERS_DOWNLOAD_ASYNC, callGetOrdersDownloadWorker);
}
function* watchPostOrdersUpload() {
  yield takeEvery(asyncTypes.POST_ORDERS_UPLOAD_ASYNC, callPostOrdersUploadWorker);
}
function* watchPutOrderUnbindShippingTracking() {
  yield takeEvery(asyncTypes.PUT_ORDER_UNBIND_SHIPPING_TRACKING, callPutOrderUnbindShippingTrackingWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_ORDERS_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_ORDERS_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_ORDERS_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_ORDERS_SORTING, callSetToLocalDataWorker);
}
function* watchFetchShippingTrackingHistory() {
  yield takeEvery(asyncTypes.FETCH_ORDER_SHIPPING_TRACKING_HISTORY_REQUEST, getOrderShippingHistoryWorker);
}

export function* watchersOrders() {
  yield all([
    watchGetList(),
    watchGetInitialState(),
    watchPutSettings(),
    watchGetOrdersHashId(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchGetOrdersStatistics(),
    watchPutOrderUnbindShippingTracking(),
    watchPostOrdersUpload(),
    watchGetOrdersDownload(),
    watchGetOrdersPaymentTypes(),
    watchGetOrdersShippingTypes(),
    watchGetOrdersStatusesList(),
    watchPutOrdersHashId(),
    watchSetFilters(),
    watchSetSorting(),
    watchFetchShippingTrackingHistory(),
  ]);
}
