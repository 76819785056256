
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function SplitButton({
  handleClick, options, sx, customArrowSx, customOptionsSx, customButtonSx, label, icon = null, disabled, variant = 'contained', marginTop = '0px', hideSelectedOption,
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(label ?? options[0]);
  const splitButtonOptions = hideSelectedOption ? options.filter(opt => opt !== selectedOption) : options;

  const { t } = useTranslation();

  const handleMenuItemClick = (option, index) => {
    setSelectedIndex(index);
    setSelectedOption(option);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleButtonClick = () => {
    handleClick(selectedIndex, selectedOption);
  };

  return (
    <>
      <ButtonGroup
        variant={variant}
        ref={anchorRef}
        aria-label="splitButton"
        disableElevation
        sx={sx}
        disabled={disabled}
      >
        <Button sx={{ gap: '8px', width: '100%', ...customButtonSx }} onClick={handleButtonClick}> {icon}{t(selectedOption)}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          disableElevation
          sx={{ maxWidth: '36px', ...customArrowSx }}
          className="exceptionHeaderMobileDialog"
        >
          <KeyboardArrowDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1302 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className="exceptionHeaderMobileDialog"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
              marginTop,
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {splitButtonOptions.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(option, index)}
                      sx={{ minWidth: '280px', ...customOptionsSx }}
                    >
                      {t(option)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default SplitButton;
