export const types = Object.freeze({
  SET_TORPS_LIST: 'SET_TORPS_LIST',
  SET_TORPS_CURRENT_PAGE: 'SET_TORPS_CURRENT_PAGE',
  SET_TORPS_FILTERS: 'SET_TORPS_FILTERS',
  SET_TORPS_PAGE_SIZE: 'SET_TORPS_PAGE_SIZE',
  SET_TORPS_SORTING: 'SET_TORPS_SORTING',
  SET_TORPS_INITIAL_STATE: 'SET_TORPS_INITIAL_STATE',
  SET_TORPS_SETTING_STATUS: 'SET_TORPS_SETTING_STATUS',
  SET_TORPS_SETTING_PENDING: 'SET_TORPS_SETTING_PENDING',
  SET_TORPS_SELECTION: 'SET_TORPS_SELECTION',
  SET_DELETE_TORP_MODAL: 'SET_DELETE_TORP_MODAL',
});
