import { all, takeEvery } from 'redux-saga/effects';
import { asyncTypes } from './asyncTypes';
import { callGetPromotionsListWorker } from './workers/callGetPromotionsListWorker';
import { callCreatePromotionWorker } from './workers/callCreatePromotionWorker';
import { callGetPromotionByIdWorker } from './workers/callGetPromotionByIdWorker';
import { callUpdatePromotionByIdWorker } from './workers/callUpdatePromotionByIdWorker';
import { callGetPromotionHistoryListWorker } from './workers/callGetPromotionHistoryListWorker';
import { callPutSettingsWorker } from './workers/callPutSettingsWorker';
import { callDeletePromotionById } from './workers/callDeletePromotionById';
import { callCreatePromotionParticipantWorker } from './workers/callCreatePromotionParticipantWorker';

function* watchGetList() {
  yield takeEvery(asyncTypes.GET_PROMOTIONS_LIST_ASYNC, callGetPromotionsListWorker);
}
function* watchCreatePromotion() {
  yield takeEvery(asyncTypes.CREATE_PROMOTION_ASYNC, callCreatePromotionWorker);
}
function* watchGetPromotionById() {
  yield takeEvery(asyncTypes.GET_PROMOTIONS_FOR_EDIT_ASYNC, callGetPromotionByIdWorker);
}
function* watchUpdatePromotionAsync() {
  yield takeEvery(asyncTypes.UPDATE_PROMOTION_ASYNC, callUpdatePromotionByIdWorker);
}
function* watchGetPromotionHistoryListAsync() {
  yield takeEvery(asyncTypes.GET_PROMOTION_HISTORY_LIST, callGetPromotionHistoryListWorker);
}
function* watchPutSettingAsync() {
  yield takeEvery(asyncTypes.PUT_SETTINGS_ASYNC, callPutSettingsWorker);
}
function* watchDeletePromotionById() {
  yield takeEvery(asyncTypes.DELETE_PROMOTION_BY_ID, callDeletePromotionById);
}
function* watchCreatePromotionParticipant() {
  yield takeEvery(asyncTypes.CREATE_PROMOTION_PARTICIPANT, callCreatePromotionParticipantWorker);
}
export function* watchersPromotions() {
  yield all([
    watchGetList(),
    watchCreatePromotion(),
    watchGetPromotionById(),
    watchUpdatePromotionAsync(),
    watchGetPromotionHistoryListAsync(),
    watchPutSettingAsync(),
    watchDeletePromotionById(),
    watchCreatePromotionParticipant(),
  ]);
}
