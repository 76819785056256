// Core
import React from 'react';
// UI
import SvgIcon from '@mui/material/SvgIcon';

function RefundIcon(props) {
  return (
    <SvgIcon fontSize="inherit" {...props} viewBox="0 0 22 22">
      <path d="M4 4.92857C4 3.86345 4.86345 3 5.92857 3H15.7857C16.8508 3 17.7143 3.86345 17.7143 4.92857V11.1413C17.3079 10.9718 16.877 10.8491 16.4286 10.7798V4.92857C16.4286 4.57353 16.1408 4.28571 15.7857 4.28571H5.92857C5.57353 4.28571 5.28571 4.57353 5.28571 4.92857V18.2143C5.28571 18.5693 5.57353 18.8571 5.92857 18.8571H10.6276C10.8738 19.3294 11.1856 19.7621 11.5511 20.1429H5.92857C4.86345 20.1429 4 19.2794 4 18.2143V4.92857ZM14.5 10.7143C14.5951 10.7143 14.6854 10.7349 14.7666 10.772C13.7278 10.9223 12.7817 11.3592 12.0112 12H7.21429C6.85925 12 6.57143 11.7122 6.57143 11.3571C6.57143 11.0021 6.85925 10.7143 7.21429 10.7143H14.5ZM7.21429 6.85714C6.85925 6.85714 6.57143 7.14496 6.57143 7.5C6.57143 7.85504 6.85925 8.14286 7.21429 8.14286H14.5C14.855 8.14286 15.1429 7.85504 15.1429 7.5C15.1429 7.14496 14.855 6.85714 14.5 6.85714H7.21429Z" fill="white" />
      <path d="M20.2857 16.2858C20.2857 18.8894 18.1751 21.0001 15.5714 21.0001C12.9678 21.0001 10.8571 18.8894 10.8571 16.2858C10.8571 13.6822 12.9678 11.5715 15.5714 11.5715C18.1751 11.5715 20.2857 13.6822 20.2857 16.2858ZM14.5888 14.446C14.7561 14.2786 14.7561 14.0073 14.5888 13.8399C14.4214 13.6725 14.15 13.6725 13.9827 13.8399L12.697 15.1256C12.5296 15.293 12.5296 15.5644 12.697 15.7317L13.9827 17.0174C14.15 17.1848 14.4214 17.1848 14.5888 17.0174C14.7561 16.8501 14.7561 16.5787 14.5888 16.4113L14.0347 15.8572H15.7857C16.8508 15.8572 17.7143 16.7207 17.7143 17.7858V18.0001C17.7143 18.2368 17.9062 18.4287 18.1429 18.4287C18.3796 18.4287 18.5714 18.2368 18.5714 18.0001V17.7858C18.5714 16.2473 17.3242 15.0001 15.7857 15.0001H14.0347L14.5888 14.446Z" fill="white" />
    </SvgIcon>
  );
}

export default RefundIcon;
