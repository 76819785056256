/**
 * Use for displaying page sizes in the tables
 * @constant {Number[]}
 * */
export const pageSizes = [20, 30, 50, 100];
export const pageSizesProducts = [20, 50, 100, 200, 500, 1000];

/**
 * Use for displaying date in that format
 * @constant {String}
 * */
export const dateOnlyYearStringFormat = 'YYYY-MM-DD';
export const dateStringFormat = 'DD MMMM YYYY HH:mm';
export const dateStringShortFormat = 'DD MMMM YYYY';
export const dateStringShortMonthDayFormat = 'MM/DD/YYYY';
export const dateNumberFormat = 'DD.MM.YYYY HH:mm';

/**
 * Use for set timeout delay before request
 * @constant {Number}
 * */
export const requestDelay = 1000 / 2; // 500ms
export const filtersDelay = 2000; // 2s

/**
 * Use for display max counters number
 * If set to 0 show current counter number
 * @constant {Number}
 */
export const countersMaxNumber = 0;

/**
 * Use to determine the page mode
 * @constant {Object} pageMode
 * @property {String} show - Indicates that the page is in "show" mode, and so on.
 * */
export const pageMode = {
  show: 'show',
  edit: 'edit',
  add: 'add',
};

export const alloExpressConfig = {
  logisticsWeight: 30,
  logisticsLength: 70,
  logisticsWidth: 120,
  logisticsHeight: 70,
};
