import { getIn, List, Map } from 'immutable';
import { stateKeys } from './reducer';

// config
import { pageSizes } from '../../config/globalConfig';

export const contentProduct = Object.freeze({
  editValue: state => getIn(state, [stateKeys.controller, stateKeys.contentProductList, stateKeys.editValue], Map()),
  currentPage: state => getIn(state, [stateKeys.controller, stateKeys.contentProductList, stateKeys.currentPage], 0),
  filters: state => getIn(state, [stateKeys.controller, stateKeys.contentProductList, stateKeys.filters], List()),
  isLoading: state => getIn(state, [stateKeys.controller, stateKeys.contentProductList, stateKeys.pending], false),
  list: state => getIn(state, [stateKeys.controller, stateKeys.contentProductList, stateKeys.items], List()),
  pageSize: state => getIn(state, [stateKeys.controller, stateKeys.contentProductList, stateKeys.pageSize], pageSizes[0]),
  sendQuery: state => getIn(state, [stateKeys.controller, stateKeys.contentProductList, stateKeys.sendQuery], undefined),
  reload: state => getIn(state, [stateKeys.controller, stateKeys.contentProductList, stateKeys.reload], false),
  totalCount: state => getIn(state, [stateKeys.controller, stateKeys.contentProductList, stateKeys.totalCount], 0),
  sorting: state => getIn(state, [stateKeys.controller, stateKeys.contentProductList, stateKeys.sorting], List()),
  settingStatus: state => getIn(state, [stateKeys.controller, stateKeys.contentProductList, stateKeys.settingStatus], false),
  settingPending: state => getIn(state, [stateKeys.controller, stateKeys.contentProductList, stateKeys.settingPending], false),
  selection: state => getIn(state, [stateKeys.controller, stateKeys.contentProductList, stateKeys.selection], List()),
  productCategoriesList: state => getIn(state, [stateKeys.controller, stateKeys.categoriesList, stateKeys.items], List()),
  productCategoryActiveItem: state => getIn(state, [stateKeys.controller, stateKeys.categoriesList, stateKeys.active], undefined),
  isCategoriesListLoading: state => getIn(state, [stateKeys.controller, stateKeys.categoriesList, stateKeys.pending], false),
  logShowModal: state => getIn(state, [stateKeys.controller, stateKeys.productStatusesLog, stateKeys.showModal], false),
  logHashId: state => getIn(state, [stateKeys.controller, stateKeys.productStatusesLog, stateKeys.hashId], false),
  historyType: state => getIn(state, [stateKeys.controller, stateKeys.productStatusesLog, stateKeys.historyType], 0),
  modalChangeCategoryErrorOpen: state => getIn(state, [stateKeys.controller, stateKeys.modal, stateKeys.changeCategoryErrorOpen], false),
  isOpenModalWidthCategory: state => getIn(state, [stateKeys.controller, stateKeys.modalWidthCategory, stateKeys.showModal], false),
  modalWidthCategoryValue: state => getIn(state, [stateKeys.controller, stateKeys.modalWidthCategory, stateKeys.value], Map()),
  alloExpressModalOpen: state => getIn(state, [stateKeys.controller, stateKeys.alloExpressModal, stateKeys.showModal], false),
  alloExpressModalType: state => getIn(state, [stateKeys.controller, stateKeys.alloExpressModal, stateKeys.type], 0),
  selectedItems: state => getIn(state, [stateKeys.controller, stateKeys.contentProductList, stateKeys.selectedItems], List()),
  alloExpressWarningPopup: state => getIn(state, [stateKeys.controller, stateKeys.alloExpressWarningPopup], Map()),
});
