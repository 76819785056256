import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { generateInput } from '../utils/generateInput';
import Select from '../../../../../components/_Form/Selects/Select/Select';
import { Loader } from '../../../../../components/Loader/Loader';

const FeaturesSelect = ({
  control, isPartnerInfoLoading, featuresOptions, editMode, disabledField,
}) => {
  const { t } = useTranslation();

  return (
    <Controller
      name="features"
      control={control}
      render={({ field }) => {
        const input = generateInput(field);
        return (
          <Box sx={{ marginTop: '30px', position: 'relative' }}>
            {!featuresOptions?.length || isPartnerInfoLoading && <Loader />}
            <Select
              input={input}
              options={featuresOptions}
              variant="outlined"
              placeholder=""
              disabled={!featuresOptions?.length || disabledField || isPartnerInfoLoading || editMode}
              label={t('Характеристики')}
              isMulti
              closeMenuOnSelect={false}
            />
          </Box>
        );
      }}
      rules={{ required: t('Это обязательное поле') }}
    />
  );
};

export default FeaturesSelect;
