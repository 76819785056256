import Box from '@mui/material/Box';
import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { ClearFilterIcon } from '../Icons/ClearFilterIcon';

export function ButtonClearFilter({
  onClick, tooltipText, sx, ...props
}) {
  const [isClicked, setIsClicked] = useState(false);
  const handleMouseDown = () => setIsClicked(true);
  const handleMouseUp = () => setIsClicked(false);
  return (
    <Tooltip title={tooltipText || ''} placement="bottom-end">
      <Box
        role="button"
        onClick={onClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        tabIndex={0}
        sx={{
          ...sx,
          cursor: 'pointer',
          transition: 'transform 0.15s ease',
          transform: isClicked ? 'scale(0.9)' : 'scale(1)',
          '&:hover': { opacity: 0.8 },
        }}
        {...props}
      >
        <ClearFilterIcon />
      </Box>
    </Tooltip>
  );
}
