
export function AdvertisingSvgIcon() {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.2117 2.23747C17.3427 1.84453 17.1304 1.41976 16.7375 1.28872C16.3445 1.15768 15.9198 1.37 15.7887 1.76294L15.0387 4.01192C14.9077 4.40486 15.12 4.82963 15.5129 4.96067C15.9059 5.09171 16.3306 4.87939 16.4617 4.48645L17.2117 2.23747ZM21.7803 2.21988C22.0732 2.51278 22.0732 2.98765 21.7803 3.28054L19.2803 5.78054C18.9874 6.07344 18.5126 6.07344 18.2197 5.78054C17.9268 5.48765 17.9268 5.01278 18.2197 4.71988L20.7197 2.21988C21.0126 1.92699 21.4874 1.92699 21.7803 2.21988ZM9.07629 4.31838C9.78611 2.84518 11.7547 2.54566 12.8706 3.74109L19.8769 11.2471C20.9679 12.4159 20.5809 14.3117 19.119 14.9594L15.6991 16.4746C15.8932 16.9447 16.0003 17.46 16.0003 18.0002C16.0003 20.2094 14.2095 22.0002 12.0003 22.0002C10.4048 22.0002 9.0275 21.0661 8.38545 19.7149L6.96799 20.3429C6.30531 20.6365 5.53118 20.504 5.00386 20.0067L3.56512 18.6499C2.99437 18.1116 2.83795 17.2657 3.17848 16.559L9.07629 4.31838ZM9.75795 19.1068C10.1662 19.9324 11.0169 20.5002 12.0003 20.5002C13.3811 20.5002 14.5003 19.3809 14.5003 18.0002C14.5003 17.6763 14.4387 17.3667 14.3266 17.0826L9.75795 19.1068ZM11.774 4.76462C11.3781 4.34042 10.6795 4.4467 10.4276 4.96947L4.5298 17.2101C4.47304 17.3279 4.49912 17.4689 4.59425 17.5586L6.03299 18.9154C6.12088 18.9983 6.24992 19.0204 6.36038 18.9714L18.5114 13.5879C19.0302 13.3581 19.1675 12.6854 18.7803 12.2706L11.774 4.76462ZM19 8.24948C19 7.83527 19.3358 7.49948 19.75 7.49948H21.75C22.1642 7.49948 22.5 7.83527 22.5 8.24948C22.5 8.66369 22.1642 8.99948 21.75 8.99948H19.75C19.3358 8.99948 19 8.66369 19 8.24948Z"
          fill="white"
        />
      </svg>
    </>
  );
}
