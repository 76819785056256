import { call, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { actions } from '../../actions';
import { api } from '../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callCreatePromotionWorker({ payload }) {
  try {
    yield put(actions.setPromotionPending(true));
    const response = yield call(api.promotions.createPromotion, payload);
    const { status, statusTitle } = response.data;
    if (response && response.status >= 200 && response.status < 400) {
      yield put(setSuccessMessage(status, statusTitle));
      yield put(push('/promotions/list'));
    } else {
      yield put(setErrorMessage(status, statusTitle));
    }
  } finally {
    yield put(actions.setPromotionPending(false));
  }
}
