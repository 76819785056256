
export const asyncTypes = {
  GET_PROMOTIONS_LIST_ASYNC: 'GET_PROMOTIONS_LIST_ASYNC',
  CREATE_PROMOTION_ASYNC: 'CREATE_PROMOTION_ASYNC',
  GET_PROMOTIONS_FOR_EDIT_ASYNC: 'GET_PROMOTIONS_FOR_EDIT_ASYNC',
  UPDATE_PROMOTION_ASYNC: 'UPDATE_PROMOTION_ASYNC',
  GET_PROMOTION_HISTORY_LIST: 'GET_PROMOTION_HISTORY_LIST',
  GET_ASYNC_SOMETHING: 'GET_ASYNC_SOMETHING',
  PUT_SETTINGS_ASYNC: 'PUT_SETTINGS_ASYNC',
  DELETE_PROMOTION_BY_ID: 'DELETE_PROMOTION_BY_ID',
  CREATE_PROMOTION_PARTICIPANT: 'CREATE_PROMOTION_PARTICIPANT',
};
