import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();
export function ModalContextProvider({ children }) {
  const [selectedTree, setSelectedTree] = useState(null);
  const [open, setOpen] = useState(false);

  const showModal = (tree) => {
    console.log(tree);
    setSelectedTree(tree);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setSelectedTree(null);
  };

  return (
    <ModalContext.Provider value={{
      open, selectedTree, showModal, hideModal,
    }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export const useModalContext = () => useContext(ModalContext);
