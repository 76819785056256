export const tableFiltersTypes = Object.freeze({
  brand: 'brand',
  faqStatus: 'faqStatus',
  recipientVerificationStatus: 'recipientVerificationStatus',
  partnerStatus: 'partnerStatus',
  filedPercent: 'filedPercent',
  boolean: 'boolean',
  enabled: 'enabled',
  date: 'date',
  datetimeNumber: 'datetimeNumber',
  dateString: 'dateString',
  category: 'category',
  rating: 'rating',
  decimal: 'decimal',
  contentManager: 'contentManager',
  partner: 'partner',
  manager: 'manager',
  media: 'media',
  datetime: 'datetime',
  dateTimeStringFromObject: 'dateTimeStringFromObject',
  productMedias: 'productMedias',
  productCancelStatus: 'productCancelStatus',
  cancelStatus: 'cancelStatus',
  productStatus: 'productStatus',
  note: 'note',
  productNote: 'productNote',
  select: 'select',
  sku: 'sku',
  text: 'text',
  orderStatus: 'orderStatus',
  textArea: 'textArea',
  feedStatus: 'feedStatus',
  feedPriority: 'feedPriority',
  feedCategoryStatus: 'feedCategoryStatus',
  feedProductsStatus: 'feedProductStatus',
  feedProductsElaborationStatus: 'feedProductElaborationStatus',
  multipleSelect: 'multipleSelect',
  price: 'price',
  idMagentoPartner: 'idMagentoPartner',
  sourceType: 'sourceType',
  integer: 'integer',
  unsignedInt: 'unsignedInt',
  faqServiceDescQuestionType: 'faqServiceDescQuestionType',
  partnerSku: 'partnerSku',
  promoStatus: 'promoStatus',
});
