import React, { useState, useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
// UI
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip/Tooltip';
// Icons
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FlipOutlinedIcon from '@mui/icons-material/FlipOutlined';
import CheckIcon from '@mui/icons-material/Check';
// Style
import { useSelector } from 'react-redux';
import FilesUploaderStyle from './FilesUploaderStyle';
import Loading from '../../Loading/Loading';
// Engine
import { mimeTypeExtensions, fileExtensions } from '../../../../engine/config/mimeTypeExtensions';
import { usePrevious } from '../../../hooks/usePrevious';
import UploadOutlinedIcon from '../../../icons/UploadOutlinedIcon';
import { selectors } from '../../../../engine/config/selectors';

// Our app
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType,
  FilePondPluginImageValidateSize,
);

const FilesUploader = (props) => {
  const {
    currentImageSrc, setPrevStateOnCropperCancel, prevStateOnCropperCancel, oldImageSrc,
    onApproveValue, compareImage, textBefore, textBefore2, minWidth, maxWidth,
    minHeight, maxHeight, desc, labelFileTypeNotAllowed, allowFileTypeValidation, acceptedFileTypes,
    textAfter, allowMultiple, maxFiles, fullWidth, margin, disabled,
    input, classes, changeFromAction, className, isLoading, approve, approvedValue, marginTop,
    allowImageValidateSize, loadFileFromSaga, classNameFilePond,
  } = props;

  const showDefaultFile = useSelector(selectors.ui.importPageFilePondDefault);

  const file = useMemo(() => {
    let result;
    if (!showDefaultFile) {
      return null;
    }

    switch (showDefaultFile) {
      case fileExtensions.xlsx: {
        result = new File(['Accessories.xlsx'], 'Accessories.xlsx', {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          source: 'Accessories.xlsx',
        });
        break;
      }
      case fileExtensions.xml: {
        result = new File(['Monitors'], 'Monitors.xml', {
          type: 'text/xml',
          name: 'Monitors.xml',
          source: 'Monitors.xml',
        });
        break;
      }
      default: {
        return;
      }
    }

    return result;
  }, [showDefaultFile]);


  const [files, setFiles] = useState([]);
  const [compare, setCompare] = useState(false);
  const [isShowUploadIcon, setIsShowUploadIcon] = useState(true);
  const [pond, setPond] = useState(null);
  const prevFiles = usePrevious(files);
  const prevInput = usePrevious(input);
  const { t } = useTranslation();


  const labelBefore = textBefore ? `<span>${textBefore}</span><br>` : '';
  const labelBefore2 = textBefore2 ? `<span>${textBefore2}</span><br>` : '';
  const labelAfter = textAfter ? `<br><span>${textAfter}</span>` : '';

  // ClassNames
  const wrapper = classNames({
    [classes.wrapper]: approve,
  });
  const wrapperFileUploader = classNames(classes.wrapperRelative, {
    [classes.wrapperFileUploader]: approve,
    [classes.disabled]: disabled,
  });
  const activeCompare = classNames(classes.button, classes.compareButton, {
    [classes.compareButtonActive]: compare,
  });
  const activeApproveTrue = classNames(classes.button, classes.checkButton, {
    [classes.checkButtonActive]: approvedValue === 'current',
  });
  const activeApproveFalse = classNames(classes.button, classes.cancelButton, {
    [classes.cancelButtonActive]: approvedValue === 'old',
  });
  const activeUploaderHolder = classNames(classes.filePondContainer, {
    [classes.filePondActiveLoader]: !isShowUploadIcon,
  });

  const handleCompare = () => {
    setCompare(state => !state);
  };

  const onApproveValueHandler = value => () => {
    if (typeof onApproveValue === 'function' && typeof input.name === 'string') {
      onApproveValue({ key: input.name, value });
    }
  };

  const handleInit = () => {
    let inputData;

    if (input) {
      inputData = input.value.toJS ? input.value.toJS() : input.value;
    }

    if (!isEmpty(inputData)) {
      setFiles(inputData);
    }
  };

  const changeFileTypeAndAddedToStorage = (fileItems) => {
    const filesArr = fileItems.map((fileItem) => {
      if (acceptedFileTypes) {
        let type = fileItem?.file?.type;
        if (type === '') {
          switch (fileItem.fileExtension) {
            case fileExtensions.xls: {
              type = mimeTypeExtensions.xls;
              break;
            }
            case fileExtensions.xlsx: {
              type = mimeTypeExtensions.xlsx;
              break;
            }
            case fileExtensions.png: {
              type = mimeTypeExtensions.png;
              break;
            }
            case fileExtensions.xPng: {
              type = mimeTypeExtensions.xPng;
              break;
            }
            case fileExtensions.pdf: {
              type = mimeTypeExtensions.pdf;
              break;
            }
            case fileExtensions.xml: {
              type = mimeTypeExtensions.xml;
              break;
            }
            case fileExtensions.jpeg: {
              type = mimeTypeExtensions.jpeg;
              break;
            }
            case fileExtensions.gif: {
              type = mimeTypeExtensions.gif;
              break;
            }
            case fileExtensions.rar: {
              type = mimeTypeExtensions.rar;
              break;
            }
            case fileExtensions.zip: {
              type = mimeTypeExtensions.zip;
              break;
            }
            case fileExtensions.docx: {
              type = mimeTypeExtensions.docx;
              break;
            }
            case fileExtensions.doc: {
              type = mimeTypeExtensions.doc;
              break;
            }
            case fileExtensions['7z']: {
              type = mimeTypeExtensions['7z'];
              break;
            }
            case fileExtensions.webp: {
              type = mimeTypeExtensions.webp;
              break;
            }
            default: {
              return;
            }
          }
        }
        if (!acceptedFileTypes.includes(type)) {
          setIsShowUploadIcon(false);
          return;
        }
      }
      return ({
        source: fileItem.file.name,
        options: {
          type: 'local',
          file: fileItem.file,
        },
      });
    }).filter(item => item);
    setFiles(filesArr);
  };

  const reduxFormChangeHandler = () => {
    if (input && input.onChange) {
      const data = files.map(fileItem => get(fileItem, 'options.file'));
      if (!isEqual(input.value, data)) {
        const {
          uploadCallback,
          formName,
          uploadMedia,
          context,
          uploadPartnerContract,
          hashID,
        } = props;
        if (setPrevStateOnCropperCancel && data.length === 0) {
          input.onChange(prevStateOnCropperCancel);
        } else {
          input.onChange(data);
        }

        if (uploadPartnerContract && typeof hashID === 'string') {
          uploadPartnerContract({ hashId: hashID, files: data });
        } else if (
          uploadMedia && formName && input && context
          && data.length > 0 && !data[0]?.id && data[0] !== undefined
        ) {
          uploadMedia({
            context, formName, files: data, inputName: input.name,
          });
        } else if (uploadCallback) {
          uploadCallback({ files: data });
        }
      }
    }
  };

  const onAddFile = (err) => {
    if (err) {
      return;
    }
    reduxFormChangeHandler();
  };

  const onInit = () => handleInit();

  const fileValidateTypeDetectType = (source, type) => new Promise((resolve) => {
  // read source data and determine type of file here, you can use extension or actually read the file data
    if (type === '') {
      if (source.name.match(/\.(xlsx)$/) !== null) {
        resolve(mimeTypeExtensions.xlsx);
        return;
      }
      if (source.name.match(/\.(xls)$/) !== null) {
        resolve(mimeTypeExtensions.xls);
        return;
      }
    }
    resolve(type);
  });

  useEffect(() => {
    setIsShowUploadIcon(!files.length > 0);
  }, [files]);

  useEffect(() => {
    if (loadFileFromSaga) {
      if (showDefaultFile) {
        setFiles([file]);
      } else {
        setFiles([]);
      }
    }
  }, [showDefaultFile]);

  useEffect(() => {
    if (input?.value?.type && files.length === 0 && changeFromAction) {
      // eslint-disable-next-line react/no-did-update-set-state
      setFiles([input.value]);
    }

    if (!isEqual(files, prevFiles || [])) {
      reduxFormChangeHandler();
    }

    if (
      (!isEqual(input?.value, prevInput?.value)
        && (typeof input?.value === 'string' || typeof input?.value === 'number')
        && files.length > 0)
    ) {
      if (files.length === 1) {
        pond.removeFiles();
      }
    }
  }, [
    input,
    files,
    pond,
    changeFromAction,
    prevInput,
    prevFiles,
  ]);

  return (
    <FormControl
      className={className}
      fullWidth={fullWidth}
      margin={margin}
    >
      <div className={className} style={{ marginTop }}>
        <div className={wrapper}>
          <div className={wrapperFileUploader}>
            <div className={classes.loader}>
              <Loading
                color="primary"
                isLoading={isLoading}
              />
            </div>
            {(compareImage && compare)
              ? (
                <div className={classes.compareImage}>
                  { oldImageSrc ? <img src={oldImageSrc} alt={t('Предыдущее значение')} /> : t('Нет предыдушего значения') }
                </div>
              ) : (
                <>
                  <div className={activeUploaderHolder}>
                    {(currentImageSrc) && (
                      <div className={classes.imagePreview}>
                        <img
                          alt=""
                          className={classes.imagePreviewImage}
                          src={currentImageSrc}
                        />
                      </div>
                    )}
                    <div className={classes.pondImageHolder}>
                      {isShowUploadIcon && (<UploadOutlinedIcon className={classes.pondImage} />)}
                      <FilePond
                        ref={pondSelf => setPond(pondSelf)}
                        dataStyleButtonRemoveItemPosition="right"
                        allowImageValidateSize={allowImageValidateSize}
                        allowFileTypeValidation={allowFileTypeValidation}
                        fileValidateTypeDetectType={fileValidateTypeDetectType}
                        labelButtonRemoveItem={t('Удалить')}
                        files={files}
                        allowMultiple={allowMultiple}
                        disabled={disabled}
                        maxFiles={maxFiles}
                        acceptedFileTypes={acceptedFileTypes}
                        labelFileTypeNotAllowed={labelFileTypeNotAllowed}
                        labelIdle={`${labelBefore} ${labelBefore2} <b class='filepond--label-action'>${t('Выберите файл')}</b> ${labelAfter}`}
                        oninit={onInit}
                        onaddfile={onAddFile}
                        onupdatefiles={changeFileTypeAndAddedToStorage}
                        // iconRemove={'<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="RemoveRoundedIcon" color="inherit" fill="inherit"><path d="M18 13H6c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1s-.45 1-1 1z"></path></svg>'}
                        iconRemove={'<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root" focusable="false" aria-hidden="true" viewBox="0 0 11 13" style="padding: 5px;" data-testid="RemoveRoundedIcon" color="inherit" fill="inherit"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.1119 1.41948C4.22042 1.31096 4.3676 1.25 4.52107 1.25H6.67837C6.83184 1.25 6.97902 1.31096 7.08754 1.41948C7.19606 1.528 7.25702 1.67518 7.25702 1.82865V2.4073H3.94242V1.82865C3.94242 1.67518 4.00338 1.528 4.1119 1.41948ZM8.25702 1.82865V2.4073H9.375C9.38599 2.4073 9.39691 2.40766 9.40772 2.40836H10.4579C10.734 2.40836 10.9579 2.63221 10.9579 2.90836C10.9579 3.1845 10.734 3.40836 10.4579 3.40836H9.875V10.4579C9.875 10.8765 9.70868 11.2781 9.41262 11.5741C9.11657 11.8702 8.71503 12.0365 8.29635 12.0365H2.90309C2.48441 12.0365 2.08287 11.8702 1.78681 11.5741C1.49076 11.2781 1.32444 10.8765 1.32444 10.4579V3.40836H0.75C0.473858 3.40836 0.25 3.1845 0.25 2.90836C0.25 2.63221 0.473858 2.40836 0.75 2.40836H1.79172C1.80253 2.40766 1.81344 2.4073 1.82444 2.4073H2.94242V1.82865C2.94242 1.40997 3.10874 1.00843 3.40479 0.712376C3.70085 0.416322 4.10238 0.25 4.52107 0.25H6.67837C7.09706 0.25 7.49859 0.416322 7.79465 0.712376C8.0907 1.00843 8.25702 1.40997 8.25702 1.82865ZM2.32444 3.40836V10.4579C2.32444 10.6113 2.3854 10.7585 2.49392 10.867C2.60244 10.9756 2.74962 11.0365 2.90309 11.0365H8.29635C8.44982 11.0365 8.597 10.9756 8.70552 10.867C8.81404 10.7585 8.875 10.6113 8.875 10.4579V3.40836H2.32444Z"></path></svg>'}
                        imageValidateSizeMinWidth={minWidth}
                        imageValidateSizeMaxWidth={maxWidth}
                        imageValidateSizeMinHeight={minHeight}
                        imageValidateSizeMaxHeight={maxHeight}
                        imageValidateSizeLabelExpectedMinSize={t('Минимальный размер изображения {minWidth} × {minHeight}')}
                        imageValidateSizeLabelExpectedMaxSize={t('Максимальный размер изображения {maxWidth} × {maxHeight}')}
                        imageValidateSizeLabelImageSizeTooSmall={t('Изображение меньше допустимого')}
                        imageValidateSizeLabelImageSizeTooBig={t('Изображение больше допустимого')}
                        fileValidateTypeLabelExpectedTypes={t('Валидный тип {allButLastType} или {lastType}')}
                        className={classNameFilePond}
                        id="file_pond_target"
                      />
                    </div>
                  </div>
                  { desc && <p className={classes.description}>{desc}</p>}
                </>
              )}
          </div>
          {approve && (
            <div className={classes.wrapperButton}>
              {compareImage && (
                <Tooltip title={t('Показать прошлые данные')} placement="top">
                  <IconButton
                    className={activeCompare}
                    onClick={handleCompare}
                    aria-label={t('Показать прошлые данные')}
                    size="large"
                  >
                    <FlipOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={t('Подтвердить')} placement="top">
                <IconButton
                  className={activeApproveTrue}
                  onClick={onApproveValueHandler('current')}
                  aria-label={t('Подтвердить')}
                  size="large"
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('Отклонить')} placement="top">
                <IconButton
                  className={activeApproveFalse}
                  onClick={onApproveValueHandler('old')}
                  aria-label={t('Отклонить')}
                  size="large"
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </FormControl>
  );
};

FilesUploader.propTypes = {
  changeFromAction: PropTypes.bool,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  allowImageValidateSize: PropTypes.bool,
  allowFileTypeValidation: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  textAfter: PropTypes.string,
  textBefore: PropTypes.string,
  textBefore2: PropTypes.string,
  labelFileTypeNotAllowed: PropTypes.string,
  acceptedFileTypes: PropTypes.array,
  className: PropTypes.string,
  classNameFilePond: PropTypes.string,
  approve: PropTypes.bool,
  allowMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
  compareImage: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadFileFromSaga: PropTypes.bool,
  setPrevStateOnCropperCancel: PropTypes.bool,
  prevStateOnCropperCancel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  hashID: PropTypes.string,
  uploadCallback: PropTypes.func,
  uploadMedia: PropTypes.func,
  uploadPartnerContract: PropTypes.func,
  formName: PropTypes.string,
  context: PropTypes.string,
  desc: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
  }),
  currentImageSrc: PropTypes.string,
  oldImageSrc: PropTypes.string,
  onApproveValue: PropTypes.func,
  approvedValue: PropTypes.oneOf(['current', 'old']),
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  minHeight: PropTypes.number,
  maxHeight: PropTypes.number,
  maxFiles: PropTypes.number,
  marginTop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};


FilesUploader.defaultProps = {
  fullWidth: true,
  allowMultiple: false,
  allowImageValidateSize: false,
  allowFileTypeValidation: false,
  loadFileFromSaga: false,
  maxFiles: 1,
  marginTop: 'auto',
};

export default withStyles(FilesUploaderStyle)(FilesUploader);
