import { put, apply } from 'redux-saga/effects';
import { actions } from '../../actions';
import { api } from '../../../../config/api';

export function* callGetPromotionByIdWorker({ payload }) {
  try {
    yield put(actions.setPromotionPending(true));
    const response = yield apply(api, api.promotions.getPromotionById, [payload]);
    if (response && response.status >= 200 && response.status < 400) {
      yield put(actions.setPromotionForEdit(response.data));
    }
  } finally {
    yield put(actions.setPromotionPending(false));
  }
}
