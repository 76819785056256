import { api } from '../../../../../../../../engine/config/api';

export async function getListAsync(payload, setValue, reset) {
  const abortController = new AbortController();

  try {
    const { isRepeatedRequest, ...restPayload } = payload;
    const response = await api.productFeedCategories.getList({ ...restPayload, signal: abortController.signal });

    let data = {
      items: [],
      totalCount: 0,
      pending: false,
    };

    if (response && response.status >= 200 && response.status < 400) {
      const allItems = response.data.items.map((item) => {
        const { connections, ...itemProps } = item;
        if (item.connections?.length) {
          return [{ ...itemProps }, ...item.connections];
        }
        return item;
      });
      data = Object.assign(data, {
        ...response.data,
        items: allItems.flat().map(item => ({
          ...item,
          productFeedCategory: {
            ...item.productFeedCategory,
            label: item.productFeedCategory.value ? `${item.productFeedCategory.value} | ${item.productFeedCategory.label}` : item.productFeedCategory.label,
          },
        })),
      });

      const fieldsValues = {};
      data.items.forEach((item) => {
        if (item?.category?.value) {
          fieldsValues[`${item.id}_category`] = item?.category;
        }
        fieldsValues[`${item.id}_ignored`] = item?.ignored;
      });

      if (Object.keys(fieldsValues).length && !isRepeatedRequest) {
        reset(fieldsValues);
      }
      if (isRepeatedRequest) {
        Object.keys(fieldsValues).forEach((key) => {
          setValue(key, fieldsValues[key]);
        });
      }
    }

    return data;
  } catch (e) {
    console.log(e);
  }
}
