// Core
import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { useMemo } from 'react';

export const useEmotionStyles = (stylesElement) => {
  const theme = useTheme();
  const rawClasses = typeof stylesElement === 'function' ? stylesElement(theme) : stylesElement;

  const prepared = useMemo(() => Object.entries(rawClasses).reduce((acc, [key, value = {}]) => {
    acc[key] = css(value);
    return acc;
  }, {}), []);

  return prepared;
};
