// Core
import React, { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { selectors } from '../../../../../engine/config/selectors';
// Hooks
import { api } from '../../../../../engine/config/api';
import Modal from '../../../../containers/Modals/Modal/Modal';
import { setSuccessMessage } from '../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { pageLinks } from '../../../../../engine/config/routes';
import { asyncActions } from '../../../../../engine/core/TORPs/saga/asyncActions';
import { actions } from '../../../../../engine/core/TORPs/actions';

function DeleteModal(props) {
  const {
    isEditPage, hashId, torpSKU,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageSize = useSelector(selectors.torps.pageSize);
  const filters = useSelector(selectors.torps.filters);
  const deleteModal = useSelector(selectors.torps.deleteModal);

  const stopDeleting = () => dispatch(actions.setDeleteTORPModal(false));

  const handleDelete = useCallback(async () => {
    const response = await api.torps.deleteTORP(hashId);
    if (response.status >= 200 && response.status < 400) {
      dispatch(setSuccessMessage(t('Успешно удалено'), t('Успех')));
      dispatch(actions.setDeleteTORPModal(false));
      if (isEditPage) {
        navigate(pageLinks.productsAndPrices.torps);
      } else {
        const params = filters.reduce((acc, { columnName, value }) => {
          acc[columnName] = value;
          return acc;
        }, { limit: pageSize });

        dispatch(asyncActions.getListAsync(params));
      }
    }
  }, [hashId]);


  return (
    <Modal
      modalOpen={deleteModal}
      handleModalToggle={stopDeleting}
      handleModalCancel={stopDeleting}
      handleModalSend={handleDelete}
      title={`Бажаєте видалити ТОРП ${torpSKU} ?`}
      modalMaxWidth="sm"
      sendText={t('Удалить')}
      cancelText={t('Отменить')}
      buttonSendIcon={null}
    >
      <Box sx={{ padding: '16px' }}>Ви впевнені, що хочете видалити ТОРП? Це незворотній процес, всі поточні дані будуть втраченні. </Box>
    </Modal>

  );
}

DeleteModal.propTypes = {
  isEditPage: PropTypes.bool,
  hashId: PropTypes.string,
  torpSKU: PropTypes.string,
};


export default memo(DeleteModal);
