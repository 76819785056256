// Core
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useParams } from 'react-router';
import { makeStyles } from '@mui/styles';
// Icons
import RefreshIcon from '@mui/icons-material/Refresh';
// Parts
import { PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { InputLabel } from '@mui/material';
import CustomPaginPanel from '../CustomPaginPanel/CustomPaginPanel';
import ButtonWithIcon from '../../../../../../Buttons/ButtonWithIcon/ButtonWithIcon';
import Select from '../../../../../../_Form/Selects/Select/Select';
// Helpers
import PagingStyles from './PagingStyles';

const useStyles = makeStyles(PagingStyles);
const HookWrapper = (props) => {
  const { updateHooks } = props;
  const { hashId } = useParams();
  if (updateHooks) {
    updateHooks(hashId, true);
  }
  return <></>;
};

// TODO: avoid using update hooks, use refresh hook instead
const Paging = (props) => {
  const {
    isPatchedPaging, onRefreshHook, updateHooks,
    isLoading, showHookComponent, bottomComponent,
    pagingContainerProps, rowsLength, totalCount,
    setPagingHeight, hiddeRowsLabel, refreshHook,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const mainClassNames = cx(classes.mainWrapper, {
    [classes.flex]: (isPatchedPaging || Boolean(refreshHook)),
  });
  const getHeight = (pagingRef) => {
    if (typeof pagingRef?.clientHeight === 'number') {
      setPagingHeight(pagingRef.clientHeight);
    }
  };

  const options = useMemo(
    () => pagingContainerProps.pageSizes.map(value => ({ label: value.toString(), value })),
    [pagingContainerProps.pageSizes],
  );

  const handleChange = value => pagingContainerProps.onPageSizeChange(value.value);

  return (
    <div ref={getHeight} className={mainClassNames}>
      {
        (isPatchedPaging || Boolean(refreshHook)) && (
          <div>
            {(Boolean(updateHooks) || Boolean(refreshHook)) && (
              <Tooltip title={t('Обновить данные таблицы')} aria-label={t('Обновить данные таблицы')}>
                <Box component="span">
                  <ButtonWithIcon
                    className={classes.mr}
                    disabled={isLoading || showHookComponent}
                    onClick={refreshHook ?? onRefreshHook}
                  >
                    {showHookComponent && <HookWrapper updateHooks={updateHooks} />}
                    {isLoading || showHookComponent ? <CircularProgress color="inherit" size={24} /> : <RefreshIcon />}
                  </ButtonWithIcon>
                </Box>
              </Tooltip>
            )}
            {Boolean(bottomComponent) && bottomComponent}
          </div>
        )
      }
      {totalCount || totalCount === 0
        ? (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div className={cx(classes.offsetSelect, classes.quantityItemsSelect)}>
              <InputLabel
                classes={{ root: classes.selectLabel }}
                htmlFor="customPaginOffsetSelect"
              >
                {t('Выводить по')}
              </InputLabel>
              <Select
                inputId="customPaginOffsetSelect"
                variant="outlined"
                filterValueBy="value"
                options={options}
                value={pagingContainerProps.pageSize}
                handleChange={handleChange}
              />
            </div>
            <PagingPanel.Container
              pageSizes={[]}
              getMessage={pagingContainerProps.getMessage}
              totalCount={pagingContainerProps.totalCount}
              pageSize={pagingContainerProps.pageSize}
              onPageSizeChange={pagingContainerProps.onPageSizeChange}
              onCurrentPageChange={pagingContainerProps.onCurrentPageChange}
              currentPage={pagingContainerProps.currentPage}
              totalPages={pagingContainerProps.totalPages}
              className={
                hiddeRowsLabel
                  ? classes.paddingDisableAndHiddeRowsLabel
                  : classes.paddingDisable
              }
            />
          </Box>
        )
        : <CustomPaginPanel rowsLength={rowsLength} totalCount={totalCount} {...pagingContainerProps} />
      }
    </div>
  );
};

export default Paging;
