// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../../ui/types';

const defaultProductFeedCategoriesListState = {
  currentPage: 0,
  filters: List(),
  items: List(),
  pending: true,
  sorting: List(),
  totalCount: 0,
  pageSize: pageSizes[0],
  settingStatus: false,
  settingPending: false,
  reload: false,
};
export const initialState = fromJS({
  productFeedCategoriesList: Map(defaultProductFeedCategoriesListState),
  productFeedCategoriesByHashId: Map({
    pending: false,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'productFeedCategories',
  productFeedCategoriesList: 'productFeedCategoriesList',
  productFeedCategoriesByHashId: 'productFeedCategoriesByHashId',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  pending: 'pending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
});

export const productFeedCategoriesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PRODUCT_FEED_CATEGORIES_BY_HASH_ID_PADDING: {
      return state.setIn([stateKeys.productFeedCategoriesByHashId, stateKeys.pending], payload);
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.productFeedCategoriesList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.productFeedCategoriesList, stateKeys.pending], pending)
        .setIn([stateKeys.productFeedCategoriesList, stateKeys.items], List(items))
        .setIn([stateKeys.productFeedCategoriesList, stateKeys.totalCount], totalCount);
    }
    case types.SET_PRODUCT_FEED_CATEGORY_BY_HASH_ID: {
      const index = state.getIn(['productFeedCategoriesList', 'items']).findIndex(
        item => item.id === payload.id,
      );

      return state.mergeIn(['productFeedCategoriesList', 'items', index], Map(payload));
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_SORTING: {
      return state.setIn([stateKeys.productFeedCategoriesList, stateKeys.sorting], List(payload));
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state
        .setIn([stateKeys.productFeedCategoriesList, stateKeys.filters], List(filters))
        .setIn([stateKeys.productFeedCategoriesList, stateKeys.currentPage], 0);
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_PAGE_SIZE: {
      return state.setIn([stateKeys.productFeedCategoriesList, stateKeys.pageSize], payload);
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_CURRENT_PAGE: {
      return state.setIn([stateKeys.productFeedCategoriesList, stateKeys.currentPage], payload);
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.productFeedCategoriesList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.productFeedCategoriesList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.productFeedCategoriesList, stateKeys.filters], List(filters))
        .setIn([stateKeys.productFeedCategoriesList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.productFeedCategoriesList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.productFeedCategoriesList, stateKeys.reload], reload)
        .setIn([stateKeys.productFeedCategoriesList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_SETTING_STATUS: {
      return state.setIn([stateKeys.productFeedCategoriesList, stateKeys.settingStatus], payload);
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_SETTING_PENDING: {
      return state.setIn([stateKeys.productFeedCategoriesList, stateKeys.settingPending], payload);
    }
    case types.SET_PRODUCT_FEED_CATEGORIES_LIST_DEFAULT_STATE: {
      return state.setIn([stateKeys.productFeedCategoriesList], defaultProductFeedCategoriesListState);
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
