import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import React from 'react';
import Button from '@mui/material/Button';
import { pageLinks } from '../../../../engine/config/routes';

export function AddPromotionButton() {
  const { t } = useTranslation();
  return (
    <>
      <NavLink to={pageLinks.advertising.promoCreate}>
        <Button sx={{ height: '32px' }} variant={'contained'}>{t('Додати акцію')}</Button>
      </NavLink>
    </>
  );
}
