import { fromJS, List, Map } from 'immutable';
import { types } from './types';
import { pageSizes } from '../../config/globalConfig';

const initialState = fromJS({
  promotionData: {},
  isPending: false,
  redirectUrl: null,
  promotionForEdit: {},
  something: {},
  promotionHistoryList: List(),
  promotionsList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    reload: false,
    selection: List(),
    settingStatus: false,
    settingPending: false,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'promotions',
  promotionsList: 'promotionsList',
  currentPage: 'currentPage',
  isPending: 'isPending',
  promotionData: 'promotionData',
  filters: 'filters',
  items: 'items',
  pending: 'pending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  reload: 'reload',
  selection: 'selection',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  promotionForEdit: 'promotionForEdit',
  promotionHistoryList: 'promotionHistoryList',
  something: 'something',
});
export const promotionsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PROMOTIONS_LIST: {
      const { items, totalCount, pending } = payload;
      if (pending) {
        return state.setIn([stateKeys.promotionsList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.promotionsList, stateKeys.pending], pending)
        .setIn([stateKeys.promotionsList, stateKeys.items], List(items))
        .setIn([stateKeys.promotionsList, stateKeys.totalCount], totalCount);
    }
    case types.SET_PROMOTIONS_SORTING: {
      return state.setIn([stateKeys.promotionsList, stateKeys.sorting], List(payload));
    }
    case types.SET_PROMOTIONS_FILTERS: {
      const filters = payload.filter(item => item.value !== null && item.value !== undefined);
      return state.setIn([stateKeys.promotionsList, stateKeys.filters], List(filters));
    }
    case types.SET_PROMOTIONS_PAGE_SIZE: {
      return state.setIn([stateKeys.promotionsList, stateKeys.pageSize], payload);
    }
    case types.SET_PROMOTIONS_SELECTION: {
      return state.setIn([stateKeys.promotionsList, stateKeys.selection], List(payload));
    }
    case types.SET_PROMOTIONS_CURRENT_PAGE: {
      return state.setIn([stateKeys.promotionsList, stateKeys.currentPage], payload);
    }
    case types.SET_PROMOTION_LIST_SETTING_STATUS: {
      return state.setIn([stateKeys.promotionsList, stateKeys.settingStatus], payload);
    }
    case types.SET_PROMOTION_LIST_SETTING_PENDING: {
      return state.setIn([stateKeys.promotionsList, stateKeys.settingPending], payload);
    }
    case types.SET_PROMOTIONS_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.promotionsList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.promotionsList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.promotionsList, stateKeys.filters], List(filters))
        .setIn([stateKeys.promotionsList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.promotionsList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.promotionsList, stateKeys.reload], reload)
        .setIn([stateKeys.promotionsList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_PROMOTION_PENDING:
      return state.set(stateKeys.isPending, payload);
    case types.SET_PROMOTION_FOR_EDIT:
      return state.set(stateKeys.promotionForEdit, payload);
    case types.SET_PROMOTION_HISTORY_LIST:
      return state.set(stateKeys.promotionHistoryList, payload);
    default:
      return state;
  }
};
