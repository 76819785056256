import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import {
  getStatusColor, getLabelByPromotionType, getJoinedLabels,
} from '../../../../../engine/config/promotions';
import { useUserRole } from '../../../../hooks/useUserRole';
import { userRoles } from '../../../../../engine/config/userRoles';
import { routersLink } from '../../../../../engine/config/routes';
import { useModalContext } from '../../../../containers/Context/UseModalContext';
import { ButtonVisibility } from '../../../../components/Buttons/ButtonVisibility';

export function TableCell({
  column,
  row,
  value,
  ...restProps
}) {
  const { showModal } = useModalContext();
  const isAdminRole = useUserRole(userRoles.admin);
  const viewLink = `${routersLink.advertising.promo.controller}/view/${row.hashId}`;
  const editLink = `${routersLink.advertising.promo.controller}/edit/${row.hashId}`;
  const link = isAdminRole ? editLink : viewLink;

  return (
    <VirtualTable.Cell
      {...restProps}
      tableColumn={column}
      tableRow={row}
    >
      {column.name === 'id' && (<Box><Typography>{row.id}</Typography></Box>)}
      {column.name === 'promoName' && (
        <Box>
          <RouterLink to={link}>
            <Link>
              <Typography fontSize={'13px'}>{row.promoName}</Typography>
            </Link>
          </RouterLink>
        </Box>
      )}
      {column.name === 'promoStatus' && (<Box sx={{ color: getStatusColor(row.promoStatus) }}>{row.statusLabel}</Box>)}
      {column.name === 'promoDateRange' && (<Box><Typography fontSize={'13px'}>{row.promoDateRange}</Typography></Box>)}
      {column.name === 'promoRegistrationDateRange' && (<Box><Typography fontSize={'13px'}>{row.promoRegistrationDateRange}</Typography></Box>)}
      {column.name === 'promoTypes' && (
      <Box>
        <Typography fontSize={'13px'}>
          {row.promoTypes?.map(element => getLabelByPromotionType(element))?.join(', ')}
        </Typography>
      </Box>
      )}
      {isAdminRole && column.name === 'categories' && row.categories && (
        <Box display="flex" alignItems="center" justifyContent={'space-between'}>
          <Typography fontSize={'13px'}>
            {getJoinedLabels(row.categories.slice(0, 2))}
            {row.categories.length > 2 && '...'}
          </Typography>
          <ButtonVisibility tooltipText={'Переглянути всі категорії'} onClick={() => showModal(row.categories)} />
        </Box>
      )}
      {isAdminRole && column.name === 'stopBrands' && (
        <Box>{getJoinedLabels(row.brands)}</Box>
      )}
    </VirtualTable.Cell>
  );
}
