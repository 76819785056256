// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
// Components
import InstructionCard from './components/InstructionCard';
// Containers
import PageHeader from '../../components/PageHeader/PageHeader';

const instructions = [
  { title: 'Графік обробки замовлень', link: 'https://drive.google.com/file/d/1qLlaUb2DcC_b55XuT9I4Wd4hTEayJvLo/' },
  { title: 'Еквайринг Monobank', link: 'https://drive.google.com/file/d/1yOm3k1gk3nA8fKjqbc-ZCXfYFoWNBQ-2/view' },
  { title: 'Сповіщення про нове замовлення (Viber/SMS)', link: 'https://drive.google.com/file/d/1Mh6FoROFdyd2HoduJkhSk39YBVUPQMzo/' },
  { title: 'Покупка частинами Monobank', link: 'https://drive.google.com/file/d/1gIOWT9luZRT2OK-DjPMn-cwvgn0yvXNo/view' },
  { title: 'Повернення "Покупка частинами Monobank"', link: 'https://drive.google.com/file/d/1CtWsKVf_iZSyyO3C67k5OVbjGxzJ-Jv2/view' },
  { title: 'Створення ТТН АЛЛО Express', link: 'https://drive.google.com/file/d/1PqY94OVvA5nxaEpC5WWYY1UZ7_xJ-7Tb/view' },
  { title: 'Оплата частинами Приватбанк', link: 'https://drive.google.com/file/d/1nc0_fG92KwOhSNoqPLdJIs8wJ4G2Ov82/view' },
  { title: 'Повернення "Оплата частинами Приватбанк"', link: 'https://drive.google.com/file/d/1FnlcL_Ic5IJznT6_SU8yfAHtdztAgjad/view' },
  { title: 'Порівняння цін', link: 'https://drive.google.com/file/d/10gmJNArFoOpe1MzD84S_naFO399x0C8D/view' },
  { title: 'Акції', link: 'https://drive.google.com/file/d/1clt-8nlSBhVqqgIMp9zmS3hflOZOIU3B/view' },
];

function InstructionPage() {
  const { t } = useTranslation();
  const sortedInstructions = instructions.sort((a, b) => a.title.localeCompare(b.title));

  return (
    <>
      <PageHeader title={t('Инструкция')} />
      <Box
        sx={{
          display: 'grid',
          gap: '10px',
          gridTemplateColumns: {
            xs: 'repeat(2, 1fr)',
            lg: 'repeat(4, 1fr)',
          },
        }}
      >
        {sortedInstructions.map(instruction => (
          <InstructionCard key={instruction.link} title={instruction.title} link={instruction.link} />
        ))}
      </Box>
    </>
  );
}

export default InstructionPage;
