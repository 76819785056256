import { call, put } from 'redux-saga/effects';
import { actions } from '../../actions';
import { api } from '../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import i18n from '../../../../init/i18n';

export function* callUpdatePromotionByIdWorker({ payload }) {
  try {
    yield put(actions.setPromotionPending(true));
    const response = yield call(api.promotions.updatePromotion, payload);
    if (response && response.status >= 200 && response.status < 400) {
      yield put(setSuccessMessage(response.data.status, i18n.t('Успех')));
    } else {
      yield put(setErrorMessage(response.data.status, i18n.t('Ошибка')));
    }
  } finally {
    yield put(actions.setPromotionPending(false));
  }
}
