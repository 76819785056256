// Core
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Parts
import PageHeader from '../../../components/PageHeader/PageHeader';
import LogisticsDataTable from './components/LogisticsDataTable/LogisticsDataTable';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
import OffCanvas from './components/OffCanvas/OffCanvas';
import ActionsButtonGroups from './components/ActionsButtonGroups';
import LogisticsDataByCategoryImportModal from './components/LogisticsDataByCategoryImportModal';
// Engine
import { actions } from '../../../../engine/core/logisticsDataByCategory/actions';
import { asyncActions } from '../../../../engine/core/logisticsDataByCategory/saga/asyncActions';
import { initialState, stateKeys } from '../../../../engine/core/logisticsDataByCategory/reducer';
import { selectors } from '../../../../engine/config/selectors';
// Hooks
import { useLogisticsDataList } from './_hooks/useLogisticsDataList';
import SplitButton from '../../../components/Buttons/SplitButton/SplitButton';
import AlloExpressCategoriesModal from './components/AlloExpressCategoriesModal';
import AlloExpressIcon from '../../../icons/AlloExpressIcon';

const alloExpressOptions = ['Додати категорії до АЛЛО Express', 'Видалити категорії з АЛЛО Express'];

function LogisticsDataPage() {
  useLogisticsDataList();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filters = useSelector(selectors.logisticsDataByCategory.filters);
  const sorting = useSelector(selectors.logisticsDataByCategory.sorting);
  const settingStatus = useSelector(selectors.logisticsDataByCategory.settingStatus);
  const settingPending = useSelector(selectors.logisticsDataByCategory.settingPending);
  const selection = useSelector(selectors.logisticsDataByCategory.selection);
  const [alloExpressCategoriesModal, setAlloExpressCategoriesModal] = useState({ open: false });

  const handleOpenAlloExpressCategoriesModal = (index, option) => setAlloExpressCategoriesModal({ open: true, type: option });

  const handleCloseAlloExpressCategoriesModal = () => setAlloExpressCategoriesModal({ open: false });

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.logisticsDataByCategoryList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.logisticsDataByCategoryList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.logisticsDataByCategoryList).get(stateKeys.currentPage)));
    dispatch(actions.setLogisticsDataByCategoryTreeDataRowIds([]));
  }, [dispatch]);

  const putSetting = () => {
    dispatch(asyncActions.putSettingsAsync());
  };

  useEffect(() => () => {
    dispatch(actions.setLogsRefresh({ hashId: null, showModal: false }));
    dispatch(actions.mergeLogisticsDataByCategoryImport({ isOpen: false }));
  }, []);

  return (
    <>
      <PageHeader title={t('Логистические данные по категориям')}>
        <ResetFiltersButton onReset={resetFilters} disabled={!(filters.size || sorting.size)} />
        <ActionsButtonGroups />
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={putSetting}
        />
        <SplitButton
          options={alloExpressOptions}
          sx={{ maxHeight: '32px' }}
          disabled={!selection.size}
          handleClick={handleOpenAlloExpressCategoriesModal}
          customButtonSx={{ paddingLeft: '12px', paddingRight: '12px' }}
          icon={<AlloExpressIcon sx={{ height: '24px', width: '24px' }} />}
          hideSelectedOption
        />
      </PageHeader>
      <LogisticsDataTable />
      <LogisticsDataByCategoryImportModal />
      <AlloExpressCategoriesModal modal={alloExpressCategoriesModal} onClose={handleCloseAlloExpressCategoriesModal} />
      <OffCanvas />
    </>
  );
}

export default LogisticsDataPage;
