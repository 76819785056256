export const generateInput = field => ({
  value: field.value,
  onChange: field.onChange,
  onBlur: field.onBlur,
});

export const generateMeta = (error, touched) => ({
  error,
  touched,
});
