import { put, call } from 'redux-saga/effects';
import { actions } from '../../actions';
import { api } from '../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';

export function* callCreatePromotionParticipantWorker({ payload }) {
  try {
    yield put(actions.setPromotionPending(true));
    const response = yield call(api.promotions.createPromotionParticipant, payload);
    const { status, statusTitle } = response.data;
    if (response && response.status >= 200 && response.status < 400) {
      yield put(setSuccessMessage(status, statusTitle));
    } else {
      yield put(setErrorMessage(status, statusTitle));
    }
  } finally {
    yield put(actions.setPromotionPending(false));
  }
}
