// Core
import {
  useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// Engine
import { actions } from '../../../../../../../../engine/core/products/productFeedCategories/actions';
import { accessList } from '../../../../../../../../engine/config/access';
import { selectors } from '../../../../../../../../engine/config/selectors';
// Hooks
import { useAccessList } from '../../../../../../../hooks/useAccessList';
// Helpers
import { convertSortingObjectToUrlParams } from '../../../../../../../../_helpers/convertDataToUrlParams';
import { getListAsync as getList } from '../utils/getListAsync';

export function useProductFeedCategoriesList(canSend, refresh, setValue, reset) {
  const { hashId } = useParams();
  const currentPage = useSelector(selectors.productFeedCategories.currentPage);
  const limit = useSelector(selectors.productFeedCategories.pageSize);
  const filters = useSelector(selectors.productFeedCategories.filters);
  const sorting = useSelector(selectors.productFeedCategories.sorting);
  const dispatch = useDispatch();
  const hasAccessList = useAccessList(accessList.feedShow);
  const offset = limit * currentPage;
  const filtersStringify = useMemo(() => filters.toJS(), [filters]);
  const sortingStringify = useMemo(() => sorting.toJS(), [sorting]);

  const orderBy = useMemo(() => (
    convertSortingObjectToUrlParams(sortingStringify)
  ), [sortingStringify]);

  const paramsByFilter = useMemo(() => {
    const params = {};

    return filtersStringify.reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, params);
  }, [filtersStringify]);

  const getListAsync = (async () => {
    if (hasAccessList && canSend) {
      const params = {
        ...paramsByFilter,
        orderBy,
        limit,
        offset,
      };
      const response = await getList({ hashId, params }, setValue, reset);
      return response;
    }
  });


  useEffect(() => {
    (async () => {
      dispatch(actions.setList({ pending: true }));
      const response = await getListAsync();
      dispatch(actions.setList({ pending: false, totalCount: response?.totalCount, items: response?.items }));
    })();
  }, [filters, sorting, offset, limit]);
}
