import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router';
import { Typography } from '@mui/material';
import { uiActions } from '../../../engine/core/ui/action';
import { selectors } from '../../../engine/config/selectors';
import Modal from '../../containers/Modals/Modal/Modal';
import { COLOR } from '../../containers/App/AppStyles';
import { pageLinks } from '../../../engine/config/routes';
import SupportInfo from './components/SupportInfo';
import {
  InfoKeyCell, InfoValueCell, InfoRow, MatchingResult, ButtonComponent,
} from './components/Items';
import { asyncActions as asyncActionsFeeds } from '../../../engine/core/feeds/all/saga/asyncActions';
import { asyncActions as asyncActionsProductsFeeds } from '../../../engine/core/feeds/products/saga/asyncActions';

const stepText = {
  0: 'Success',
  1: 'Automatching parameters',
  2: 'Automatching categories',
  3: 'Automatching features',
  4: 'pricelist loading',
};

const SUCCESS_STEP = 0;
const PARTLY_SUCCESS_STEP = 3;
export const ERROR_IMPORT_STEP = 4;


const PartnerWarningPopup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const popup = useSelector(selectors.ui.popup);
  const hashId = popup.getIn(['data', 'hashId']);
  const step = popup.getIn(['data', 'step']);
  const autocomplete = popup.getIn(['data', 'autocomplete']);
  const id = popup.getIn(['data', 'id']);
  const message = popup.getIn(['data', 'message']);
  const isOpen = popup.get('isOpen');
  const data = popup.getIn(['data', 'data']);
  const navigate = useNavigate();
  const isFirstStep = step === SUCCESS_STEP;
  const isLastStep = step === PARTLY_SUCCESS_STEP;
  const isErrorImportStep = step === ERROR_IMPORT_STEP;
  const isPartlyMatched = autocomplete === 'partial';

  const handleModalToggle = () => dispatch(uiActions.setPopup({ isOpen: false, data: {} }));

  const navigateRoute = !isErrorImportStep ? pageLinks.productsAndPrices.import(hashId) : pageLinks.productsAndPrices.prices;

  const goToPriceListImport = (selectedIndex) => {
    navigate(navigateRoute);
    const updatedData = isLastStep ? { data: { step: selectedIndex + 1 } } : undefined;
    dispatch(uiActions.setPopup({ isOpen: false, ...updatedData }));
  };

  const sendButtonText = !isErrorImportStep ? t('Перейти к сопоставлению') : t('go to price-list page');

  const statusColor = (() => {
    if ((step > 0 && step < PARTLY_SUCCESS_STEP) || isErrorImportStep) {
      return COLOR.error;
    }
    if (step === PARTLY_SUCCESS_STEP && isPartlyMatched) {
      return COLOR.serviceRequestStatusColor.awaitingApprove;
    }

    return COLOR.success;
  })();

  const statusText = (() => {
    if (step > SUCCESS_STEP && step < PARTLY_SUCCESS_STEP || isErrorImportStep) {
      return t('Ошибка');
    }
    if (isLastStep && isPartlyMatched) {
      return t('partly autocompare');
    }
    return t('Успех');
  })();

  const buttonOptions = ['Параметры', 'Категории', 'Характеристики'];

  const onConfirm = () => {
    handleModalToggle();
    dispatch(asyncActionsFeeds.putCompletionImportListItemAsync({ hashId, data: { isSaved: true } }));
    dispatch(asyncActionsProductsFeeds.postParseByHashIdAsync({ hashId }));
  };

  const buttonComponent = isLastStep ? () => (
    <ButtonComponent buttonText={t('Завершить импорт')} handleEdit={goToPriceListImport} handleFinishImport={onConfirm} options={buttonOptions} label={t('edit data')} />
  ) : null;

  return (
    <Modal
      modalOpen={isOpen}
      modalMaxWidth="sm"
      onClose={handleModalToggle}
      handleModalToggle={handleModalToggle}
      handleModalSend={goToPriceListImport}
      title={t('Результаты автосопоставления данных')}
      sendText={sendButtonText}
      buttonSendIcon={null}
      buttonComponent={buttonComponent}
    >
      <Box sx={{ display: 'flex', marginBottom: '24px', flex: 1 }}>
        <Box sx={{
          display: 'flex', flexDirection: 'column', color: COLOR.black[72], fontSize: '13px',
        }}
        >
          {id && (
            <InfoRow infoKey={'Прайс-лист:'} infoValue={id} />
          )}
          <InfoRow infoKey={t('Шаг')} infoValue={t(stepText[step ?? 0])} />
          <InfoRow infoKey={t('Статус')} infoValue={statusText} infoValueSx={{ color: statusColor }} />
          {!isLastStep && <InfoRow infoKey={t('Описание')} infoValue={message} />}
        </Box>
      </Box>
      {(isLastStep || isFirstStep) && (
        <>
          <Box sx={{
            display: 'flex', flexDirection: 'column', color: COLOR.black[72], fontSize: '13px', marginBottom: '16px',
          }}
          >
            <InfoKeyCell>{t('Automatching results')}</InfoKeyCell>
            <InfoRow infoKeySx={{ minWidth: '220px', fontSize: '14px', color: COLOR.black.initial }} infoKey={t('CategoryAll')} infoValue={data?.category?.count ?? 1} />
            <MatchingResult value={data?.category} />

            <InfoRow infoKeySx={{ minWidth: '220px', fontSize: '14px', color: COLOR.black.initial }} infoKey={t('FeaturesAll')} infoValue={data?.attributes?.count} />
            {isLastStep && isPartlyMatched && <InfoValueCell sx={{ color: COLOR.error }}>{t('Features do not autocompared')}</InfoValueCell>}
            {!isPartlyMatched && <MatchingResult value={data?.attributes} />}
            {isFirstStep || (isLastStep && !isPartlyMatched) && <InfoRow infoKeySx={{ minWidth: '220px', fontSize: '14px', color: COLOR.black.initial }} infoKey={t('FeatureValuesAll')} infoValue={data?.attribute_values?.count} />}
            {!isPartlyMatched && <MatchingResult value={data?.attribute_values} />}
            {(isLastStep || isFirstStep) && (<Typography fontSize="14px" sx={{ marginTop: '8px', marginBottom: '8px' }}><Trans i18nKey="warningPopupFooterText" components={{ strong: <strong /> }} /></Typography>)}
          </Box>
        </>
      )}
      <SupportInfo />
    </Modal>
  );
};

export default PartnerWarningPopup;
