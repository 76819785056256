// Core
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import toString from 'lodash/toString';
import isNil from 'lodash/isNil';
import { useParams } from 'react-router';
// Parts
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableCell from '../../../../../../../components/_Table/TableCell/TableCell';
import MediaCell from '../../../../../../../components/_Table/TableCell/components/MediaCell/MediaCell';
import { BlockIconWrapper } from './Components/BlockIconWrapper';
import SkuFieldWithConfirmButtons from './Components/SkuFieldWithConfirmButtons';
import TextFieldWithConfirmButtons from './Components/TextFieldWithConfirmButtons';
import CategoryWithConfirmButtons from './Components/CategoryWithConfirmButtons/CategoryWithConfirmButtons';
import EditableContainer from '../../../../../../../components/_Table/TableCell/components/EditableContainer/EditableContainer';
import SelectWidthConfirm from '../../../../../../../components/_Table/TableCell/components/SelectWidthConfirm/SelectWidthConfirm';
import EditTableCellWrapper from '../../../../../../../components/_Table/TableCell/components/EditTableCellWrapper/EditTableCellWrapper';
import BrandsSelectWithConfirm from './Components/BrandsSelectWithConfirm';
import MultipleSelectWidthConfirm from './Components/MultipleSelectWithConfirm/MultipleSelectWithConfirm';
import PriceFormatFieldWithConfirmButtons from './Components/PriceFormatFieldWithConfirmButtons';
import DatetimeFormatter from '../../../../../../../components/_Table/TableCell/components/DatetimeFormatter';
import DateFormatFieldWithConfirmButtons from './Components/DateFormatFieldWithConfirmButtons';
import DecimalFieldWithConfirmButtons from './Components/DecimalFieldWithConfirmButtons';
import UnsignedIntFieldWithConfirmButtons from './Components/UnsignedIntFieldWithConfirmButtons';
import { formFields } from '../../ContentProductsSidebar/ProductMultipleEditingForm';
// Helpers
import { useAccessList } from '../../../../../../../hooks/useAccessList';
import { tableFiltersTypes } from '../../../../../../../../_helpers/data/pages/contentMenagement/contentProducts/tableFilters';
import { tableDataTypes } from '../../../../../../../../_helpers/data/tableDataTypes';
// Styles
import { COLOR } from '../../../../../../../containers/App/AppStyles';
// Engine
import { accessList } from '../../../../../../../../engine/config/access';
import { asyncActions as contentAsyncActions } from '../../../../../../../../engine/core/content/saga/asyncActions';
import { pageLinks } from '../../../../../../../../engine/config/routes';
import { actions } from '../../../../../../../../engine/core/contentProduct/actions';
import { alloExpressConfig } from '../../../../../../../../engine/config/globalConfig';
import { selectors } from '../../../../../../../../engine/config/selectors';
import { setErrorMessage } from '../../../../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { getDefaultCategoryValue } from '../../../utils';
import { usePartnerRole } from '../../../../../../../hooks/usePartnerRole';

const getUniqueKey = (value, defaultKey = '') => get(value, 'value', defaultKey);


const logisticsColumns = ['logisticsWeight', 'logisticsHeight', 'logisticsWidth', 'logisticsLength'];

function ContentProductTableCell(props) {
  const {
    column, row, value, ...restProps
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasProductStatusEditAccess = useAccessList(accessList.productStatusEdit);
  const hasProductChangeQuantityAccess = useAccessList(accessList.productChangeQuantity);
  const hasProductEditAccess = useAccessList(accessList.productEdit);
  const isAlloExpressEnabled = useSelector(selectors.user.alloExpressStatus);
  const isPartner = usePartnerRole();
  const { categoryId } = useParams();
  const showDeleteButton = ![
    'enabledInMagento', 'featureAutoUpdate',
    'priceAutoUpdate', 'quantity', 'price',
    'salePrice',
  ].includes(column.name);

  const handlePutProducts = productId => (productsValues, type) => {
    const params = {
      categoryId,
      products: [productId],
      productsValues,
      type,
    };
    dispatch(contentAsyncActions.putProductsAsync(params));
  };

  const getValuesPlain = useMemo(() => get(value, 'valuesPlain', null), [value]);
  const productId = useMemo(() => get(row.id, 'values', row.id), [row.id]);
  const feature = useMemo(() => row[column.name] || { error: false, pending: false, success: false }, [column.name, row]);
  const isEditable = useMemo(() => row.editable && column.editable && hasProductEditAccess, [column.editable, hasProductEditAccess, row.editable]);

  const checkFeatureDisabled = useCallback(
    (args) => {
      const { name } = args;
      const currentDisable = row?.partnerDisabledFeatures?.value?.includes(name);
      if (currentDisable) {
        return {
          showIcon: true,
          disabled: row?.disableFeatures,
        };
      }
      return undefined;
    },
    [row],
  );

  const featureDisabled = checkFeatureDisabled({ name: column.name });

  switch (column.type) {
    case tableFiltersTypes.sku: {
      const sku = get(value, 'value', value);
      const prefix = get(value, 'prefix', '');

      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          <BlockIconWrapper
            showIcon={featureDisabled?.showIcon}
            disabled={featureDisabled?.disabled}
            valuesPlain={getValuesPlain}
          >
            <SkuFieldWithConfirmButtons
              key={sku}
              error={feature.error}
              disabled={featureDisabled?.disabled || feature.pending}
              featureName={column.name}
              required={column.required}
              onConfirm={handlePutProducts(row.id)}
              isEditable={isEditable}
              productId={productId}
              success={feature.success}
              sku={sku}
              userId={prefix}
              valuesPlain={getValuesPlain}
            />
          </BlockIconWrapper>
        </VirtualTable.Cell>
      );
    }
    case tableDataTypes.partner.name:
    case tableFiltersTypes.text: {
      let computedValue = value;
      let computedValuesPlain = value;
      if (isObject(value)) {
        computedValue = get(value, 'value', null);
        computedValuesPlain = get(value, 'valuesPlain', null);
      }

      if (!isString(computedValue)) {
        computedValue = toString(computedValue);
      }

      if (column.name === 'filedPercent') {
        const style = row.productNotComplete ? { color: COLOR.error } : undefined;
        return (
          <VirtualTable.Cell
            {...restProps}
            tableColumn={column}
            tableRow={row}
          >
            <Box component="span" sx={style}>{computedValue}&#37;</Box>
          </VirtualTable.Cell>
        );
      }

      const key = computedValue || column.name;
      const isIdOrIdMagento = column.name === 'id' || column.name === 'idMagentoPartner';
      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          <BlockIconWrapper
            showIcon={featureDisabled?.showIcon}
            disabled={featureDisabled?.disabled}
            valuesPlain={computedValuesPlain}
          >
            <TextFieldWithConfirmButtons
              key={key}
              error={feature.error}
              disabled={featureDisabled?.disabled || feature.pending}
              required={column.required}
              featureName={column.name}
              onConfirm={handlePutProducts(row.id)}
              isEditable={isEditable}
              fullText={isIdOrIdMagento}
              productId={productId}
              success={feature.success}
              value={computedValue}
              valuesPlain={computedValuesPlain}
              type={tableFiltersTypes.text}
              showDeleteButton={showDeleteButton}
            />
          </BlockIconWrapper>
        </VirtualTable.Cell>
      );
    }
    case tableFiltersTypes.select: {
      const currentValue = get(value, 'value', '');
      const checkIsDisabled = column.name === formFields.quantity
        ? featureDisabled?.disabled || feature.pending || !hasProductChangeQuantityAccess
        : featureDisabled?.disabled || feature.pending;
      const checkIsEditable = column.name === formFields.quantity
        ? isEditable && hasProductChangeQuantityAccess
        : isEditable;
      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          <BlockIconWrapper
            showIcon={featureDisabled?.showIcon}
            disabled={featureDisabled?.disabled}
            valuesPlain={getValuesPlain}
          >
            <EditableContainer isEditable={checkIsEditable} text={getValuesPlain}>
              <SelectWidthConfirm
                key={getUniqueKey(value, column.type)}
                error={feature.error}
                disabled={checkIsDisabled}
                required={column.required}
                featureName={column.name}
                success={feature.success}
                onConfirm={handlePutProducts(row.id)}
                options={column.values}
                value={currentValue}
                valuesPlain={getValuesPlain}
                label=""
                labelWidth={0}
                productId={productId}
                type={tableFiltersTypes.select}
                showDeleteButton={showDeleteButton}
              />
            </EditableContainer>
          </BlockIconWrapper>
        </VirtualTable.Cell>
      );
    }
    case tableFiltersTypes.textArea: {
      let computedValue = value;

      if (isObject(value)) {
        computedValue = get(value, 'value', '');
      }

      if (!isString(computedValue)) {
        computedValue = toString(computedValue);
      }

      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          <BlockIconWrapper
            showIcon={featureDisabled?.showIcon}
            disabled={featureDisabled?.disabled}
            valuesPlain={getValuesPlain}
          >
            <TextFieldWithConfirmButtons
              key={computedValue || column.name}
              error={feature.error}
              disabled={featureDisabled?.disabled || feature.pending}
              required={column.required}
              featureName={column.name}
              isEditable={isEditable}
              multiline
              onConfirm={handlePutProducts(row.id)}
              productId={productId}
              success={feature.success}
              value={computedValue}
              valuesPlain={getValuesPlain}
              type={tableFiltersTypes.textArea}
              showDeleteButton={showDeleteButton}
            />
          </BlockIconWrapper>
        </VirtualTable.Cell>
      );
    }
    case tableFiltersTypes.media: {
      const normalizedValue = get(value, 'value', value) || [];
      const showLightBox = normalizedValue.length > 0 || !row.editable || !hasProductEditAccess;
      const getCurrentUrl = row.editable
        ? pageLinks.content.products.edit(row.hashId)
        : pageLinks.content.products.show(row.hashId);

      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          {
            showLightBox
              ? (
                <MediaCell value={value} />
              )
              : (
                <EditTableCellWrapper>
                  <Typography
                    component="a"
                    href={getCurrentUrl}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </EditTableCellWrapper>
              )
          }
        </VirtualTable.Cell>
      );
    }
    case tableFiltersTypes.sourceType:
    case tableFiltersTypes.brand: {
      const text = get(value, 'label');
      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          <BlockIconWrapper
            showIcon={featureDisabled?.showIcon}
            disabled={featureDisabled?.disabled}
            valuesPlain={getValuesPlain}
          >
            <EditableContainer
              isEditable={isEditable && featureDisabled?.disabled !== true}
              text={text}
              name={column.name}
              hashId={row.hashId}
            >
              <BrandsSelectWithConfirm
                productId={productId}
                key={getUniqueKey(value, column.type)}
                feature={feature}
                required={column.required}
                onConfirm={handlePutProducts(row.id)}
                value={value}
                valuesPlain={getValuesPlain}
                type={tableFiltersTypes.brand}
              />
            </EditableContainer>
          </BlockIconWrapper>
        </VirtualTable.Cell>
      );
    }
    case tableFiltersTypes.multipleSelect: {
      const normalizedValue = get(value, 'value', value) || [];

      const key = normalizedValue.length
        ? normalizedValue.map(item => get(item, 'value', item)).sort().join('-')
        : tableFiltersTypes.multipleSelect;

      const text = Array.isArray(normalizedValue)
        ? normalizedValue.map(({ label: itemLabel }) => itemLabel).join('\n')
        : [];

      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          <BlockIconWrapper
            showIcon={featureDisabled?.showIcon}
            disabled={featureDisabled?.disabled}
            valuesPlain={getValuesPlain}
          >
            <EditableContainer isEditable={isEditable} text={text}>
              <MultipleSelectWidthConfirm
                key={key}
                error={feature.error}
                disabled={featureDisabled?.disabled || feature.pending}
                required={column.required}
                featureName={column.name}
                success={feature.success}
                onConfirm={handlePutProducts(row.id)}
                options={column.values}
                value={normalizedValue}
                label=""
                valuesPlain={getValuesPlain}
                productId={productId}
                type={tableFiltersTypes.multipleSelect}
                showDeleteButton={showDeleteButton}
              />
            </EditableContainer>
          </BlockIconWrapper>
        </VirtualTable.Cell>
      );
    }
    case tableFiltersTypes.boolean: {
      const isAutoField = [
        tableDataTypes.featureAutoUpdate.name,
        tableDataTypes.priceAutoUpdate.name,
      ].includes(column.name);
      const normalizedValue = get(value, 'value', value);
      const canChangeAuto = isAutoField && !row?.canChangeAuto;
      const text = get(value, 'valuesPlain', '');
      const isEditableCurrent = canChangeAuto ? false : isEditable;

      const key = get(normalizedValue, 'value', isNil(normalizedValue) ? tableFiltersTypes.boolean : normalizedValue);
      const options = isAutoField
        ? [{ value: 0, label: t('Ручное') }, { value: 1, label: t('Авто') }]
        : [{ value: '0', label: t('Нет') }, { value: '1', label: t('Да') }];

      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          <BlockIconWrapper
            showIcon={featureDisabled?.showIcon}
            disabled={featureDisabled?.disabled}
            valuesPlain={getValuesPlain}
          >
            <EditableContainer isEditable={isEditableCurrent} text={text}>
              <SelectWidthConfirm
                key={isObject(key) ? tableFiltersTypes.boolean : key}
                error={feature.error}
                disabled={featureDisabled?.disabled || feature.pending}
                required={column.required}
                featureName={column.name}
                success={feature.success}
                onConfirm={handlePutProducts(row.id)}
                options={options}
                value={normalizedValue}
                label=""
                labelWidth={0}
                productId={productId}
                valuesPlain={getValuesPlain}
                type={tableFiltersTypes.boolean}
                showDeleteButton={showDeleteButton}
                isStringTypeValue={!isAutoField}
              />
            </EditableContainer>
          </BlockIconWrapper>
        </VirtualTable.Cell>
      );
    }
    case tableFiltersTypes.productNote:
    case tableFiltersTypes.productStatus:
    case tableFiltersTypes.productCancelStatus: {
      if (categoryId && hasProductStatusEditAccess) {
        return <VirtualTable.Cell {...props} />;
      }
      const color = column.type === tableFiltersTypes.productNote
        ? get(row.cancelStatus, 'color')
        : get(value, 'color');

      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          <span style={{ color }}>
            {get(value, 'valuesPlain')}
          </span>
        </VirtualTable.Cell>
      );
    }
    case tableFiltersTypes.integer:
    case 'logisticsWeight':
    case 'logisticsHeight':
    case 'logisticsWidth':
    case 'logisticsLength':
    case tableFiltersTypes.price: {
      const normalizedValue = get(value, 'value', '');
      const key = normalizedValue || column.type;
      const defaultLogisticValue = getDefaultCategoryValue(row?.categoryLogistics, column.type);
      const textColor = !normalizedValue && defaultLogisticValue ? COLOR.grey[38] : undefined;

      const canEdit = logisticsColumns.includes(column.name) ? isEditable && !isPartner : isEditable;

      const renderValue = (() => {
        if (logisticsColumns.includes(column.name) && !normalizedValue) {
          return defaultLogisticValue;
        }
        return normalizedValue;
      })();

      const handleConfirm = (data) => {
        const params = {
          categoryId,
          products: [productId],
          productsValues: data,
          type: data?.type,
        };
        if (logisticsColumns.includes(column.name) && isAlloExpressEnabled) {
          const confirmKey = Object.keys(data)?.[0];
          const confirmValue = Object.values(data)?.[0];
          if (confirmKey === 'logisticsWeight' && Number(confirmValue) === 0) {
            dispatch(setErrorMessage(t('Не можна вводити 0'), t('Ошибка')));
            return;
          }
          if (confirmKey === 'logisticsHeight' && confirmValue < alloExpressConfig.logisticsHeight) {
            dispatch(contentAsyncActions.putProductsAsync(params));
            return;
          }
          if (confirmKey === 'logisticsWidth' && confirmValue < alloExpressConfig.logisticsWidth) {
            dispatch(contentAsyncActions.putProductsAsync(params));
            return;
          }
          if (confirmKey === 'logisticsWeight' && confirmValue < alloExpressConfig.logisticsWeight) {
            dispatch(contentAsyncActions.putProductsAsync(params));
            return;
          }
          if (confirmKey === 'logisticsLength' && confirmValue < alloExpressConfig.logisticsLength) {
            dispatch(contentAsyncActions.putProductsAsync(params));
            return;
          }
          // open popup
          dispatch(actions.setAlloExpressWarningPopup({ showModal: true, productId: row.id, productsValues: data }));
          return;
        }

        dispatch(contentAsyncActions.putProductsAsync(params));
      };


      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          <BlockIconWrapper
            showIcon={featureDisabled?.showIcon}
            disabled={featureDisabled?.disabled}
            valuesPlain={getValuesPlain}
          >
            <PriceFormatFieldWithConfirmButtons
              key={key}
              error={feature.error}
              disabled={featureDisabled?.disabled || feature.pending}
              required={column.required}
              featureName={column.name}
              onConfirm={handleConfirm}
              isEditable={canEdit}
              productId={productId}
              success={feature.success}
              value={renderValue}
              type={column.type}
              color={textColor}
              showDeleteButton={showDeleteButton}
            />
          </BlockIconWrapper>
        </VirtualTable.Cell>
      );
    }
    case tableFiltersTypes.datetime: {
      const normalizedValue = get(value, 'value', '');
      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          <DatetimeFormatter value={normalizedValue} />
        </VirtualTable.Cell>
      );
    }
    case tableDataTypes.statusChangedAt.type:
    case tableDataTypes.enabledInMagentoLog.type:
    case tableDataTypes.priceLog.type:
    case tableDataTypes.priceAutoUpdateLog.type:
    case tableDataTypes.salePriceLog.type:
    case tableDataTypes.feedPriceLog.type:
    case tableDataTypes.quantityLog.type:
    case tableDataTypes.feedQuantityLog.type:
    case tableDataTypes.feedSalePriceLog.type:
    case tableDataTypes.featureAutoUpdateLog.type: {
      const normalizedValue = typeof value === 'string'
        ? value
        : get(value, 'value', value);

      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          <EditableContainer
            isEditable={false}
            text={normalizedValue}
            name={column.name}
            hashId={row.hashId}
          >
            ${normalizedValue}
          </EditableContainer>
        </VirtualTable.Cell>
      );
    }
    case tableFiltersTypes.date: {
      const normalizedValue = get(value, 'value', '');
      const key = normalizedValue || tableFiltersTypes.date;
      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          <BlockIconWrapper
            showIcon={featureDisabled?.showIcon}
            disabled={featureDisabled?.disabled}
            valuesPlain={getValuesPlain}
          >
            <DateFormatFieldWithConfirmButtons
              key={key}
              error={feature.error}
              disabled={featureDisabled?.disabled || feature.pending}
              required={column.required}
              featureName={column.name}
              onConfirm={handlePutProducts(row.id)}
              isEditable={isEditable}
              productId={productId}
              success={feature.success}
              value={normalizedValue}
              valuesPlain={getValuesPlain}
              type={tableFiltersTypes.date}
              showDeleteButton={showDeleteButton}
            />
          </BlockIconWrapper>
        </VirtualTable.Cell>
      );
    }
    case tableFiltersTypes.decimal: {
      const normalizedValue = get(value, 'value', '');
      const key = normalizedValue || tableFiltersTypes.decimal;

      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          <BlockIconWrapper
            showIcon={featureDisabled?.showIcon}
            disabled={featureDisabled?.disabled}
            valuesPlain={getValuesPlain}
          >
            <DecimalFieldWithConfirmButtons
              key={key}
              error={feature.error}
              disabled={featureDisabled?.disabled || feature.pending}
              required={column.required}
              featureName={column.name}
              onConfirm={handlePutProducts(row.id)}
              isEditable={isEditable}
              productId={productId}
              success={feature.success}
              value={normalizedValue}
              valuesPlain={getValuesPlain}
              type={tableFiltersTypes.decimal}
              code={column.code}
              showDeleteButton={showDeleteButton}
            />
          </BlockIconWrapper>
        </VirtualTable.Cell>
      );
    }
    case tableFiltersTypes.unsignedInt: {
      const normalizedValue = get(value, 'value', '');
      const key = normalizedValue || tableFiltersTypes.unsignedInt;

      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          <BlockIconWrapper
            showIcon={featureDisabled?.showIcon}
            disabled={featureDisabled?.disabled}
            valuesPlain={getValuesPlain}
          >
            <UnsignedIntFieldWithConfirmButtons
              key={key}
              error={feature.error}
              disabled={featureDisabled?.disabled || feature.pending}
              required={column.required}
              featureName={column.name}
              onConfirm={handlePutProducts(row.id)}
              isEditable={column.editable && hasProductEditAccess}
              productId={productId}
              success={feature.success}
              value={normalizedValue}
              valuesPlain={getValuesPlain}
              type={tableFiltersTypes.unsignedInt}
              showDeleteButton={showDeleteButton}
              code={column.code}
            />
          </BlockIconWrapper>
        </VirtualTable.Cell>
      );
    }
    case tableFiltersTypes.category: {
      const onClickFilter = () => {
        dispatch(actions.setFilters([{ columnName: column.name, value: [value] }]));
      };

      return (
        <VirtualTable.Cell
          {...restProps}
          tableColumn={column}
          tableRow={row}
        >
          <EditableContainer
            isEditable={isEditable}
            text={getValuesPlain}
            name={column.name}
            onClickFilter={onClickFilter}
          >
            <CategoryWithConfirmButtons
              key={getUniqueKey(value, column.type)}
              error={feature.error}
              canChangeCategory={row.canChangeCategory}
              disabled={featureDisabled?.disabled || feature.pending}
              required={column.required}
              featureName={column.name}
              success={feature.success}
              onConfirm={handlePutProducts(row.id)}
              value={value}
              onClickFilter={onClickFilter}
              valuesPlain={getValuesPlain}
              productId={productId}
              showDeleteButton={showDeleteButton}
            />
          </EditableContainer>
        </VirtualTable.Cell>
      );
    }

    default: {
      return <TableCell {...props} />;
    }
  }
}

export default memo(ContentProductTableCell);
